import styles from "./styles.module.css";
import userWithDocumentationIllustration from "../../../../../assets/images/illustrations/user-with-documentation.svg";

function ModalInitial({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>Olá Investidor</h2>
        <p className={styles.description}>
          É necessário ter em mãos os seguintes documentos antes de criar sua
          conta Fido para que o processo seja rápido.
        </p>
        <img
          src={userWithDocumentationIllustration}
          className={styles.illustration}
          alt="Investidor com documentação na mão"
          loading="lazy"
        />
        <ul className={styles.documentsList}>
          <li>
            <strong>RG ou CNH</strong>
          </li>
          <li>
            <strong>Dados bancários</strong>
          </li>
        </ul>
        <button className={styles.primaryButton} onClick={closeModal}>
          Vamos lá
        </button>
      </aside>
    </div>
  );
}

export { ModalInitial };
