import styles from "../../create-account/components/ModalErrorCpf/styles.module.css";

function ModalReturnApi({ closeModal, title, description }) {

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>
          {title}
        </h2>
        <p className={styles.description}>
          {description}
        </p>
        <button className={styles.primaryButton} onClick={closeModal}>
          Voltar
        </button>
      </aside>
    </div>
  );
}

export { ModalReturnApi };
