import React from "react";

import styles from "./styles.module.css";
import { ReactComponent as PersonIcon } from "../../../../../assets/images/icons/person.svg";
import { ReactComponent as AttachMoneyIcon } from "../../../../../assets/images/icons/attach-money.svg";
import { ReactComponent as PhoneIcon } from "../../../../../assets/images/icons/phone.svg";
import { ReactComponent as PasswordIcon } from "../../../../../assets/images/icons/password.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/images/icons/mail.svg";
import { ReactComponent as HandshakeIcon } from "../../../../../assets/images/icons/handshake.svg";
import { ReactComponent as DescriptionIcon } from "../../../../../assets/images/icons/description.svg";
import { ReactComponent as AccountBalanceIcon } from "../../../../../assets/images/icons/account-balance.svg";

const listSteps = [
  { index: 1, title: "Identificação", icon: () => <PersonIcon /> },
  { index: 2, title: "Confirmação de e-mail", icon: () => <MailIcon /> },
  { index: 3, title: "Código via SMS", icon: () => <PhoneIcon /> },
  { index: 4, title: "Criação de senha", icon: () => <PasswordIcon /> },
  { index: 5, title: "Conta bancária", icon: () => <AccountBalanceIcon /> },
  { index: 6, title: "Termos de uso", icon: () => <HandshakeIcon /> },
  {
    index: 7,
    title: "Validação de documentos",
    icon: () => <DescriptionIcon />,
  },
];

function ListSteps({ currentStepNumber }) {
  function handleGetClassNameStepItem(currentStepNumber, stepIndex) {
    if (stepIndex === currentStepNumber) {
      return `${styles.stepItem} ${styles.stepItemActive}`;
    } else if (stepIndex < currentStepNumber) {
      return `${styles.stepItem} ${styles.stepItemDefault}`;
    }

    return `${styles.stepItem} ${styles.stepItemDisabled}`;
  }

  return (
    <ul className={styles.listSteps}>
      {listSteps?.map((stepItem) => (
        <li
          className={handleGetClassNameStepItem(
            currentStepNumber,
            stepItem.index
          )}
          key={stepItem.index}
        >
          {stepItem?.icon()}
          <span className={styles.stepItemTitle}>{stepItem.title}</span>
        </li>
      ))}
    </ul>
  );
}

export default ListSteps;
