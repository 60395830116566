import React from "react";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CustomTooltip } from "layouts/pages/home/components/CardMyWallet/styles";

import { formatMoney } from "utils";

import styles from "./styles.module.css";
import { Colors } from "common/Colors";
import suitcaseIcon from "assets/images/icons/suitcase.svg";
import clockIcon from "assets/images/icons/clock.svg";
import walletIcon from "assets/images/icons/wallet-soft-blue.svg";

function CardTotalBalance({ wallet }) {
  const amountInvested = wallet?.my_wallet?.amount_receivable;
  const amountInFunding = wallet?.my_wallet?.invested;
  const balanceAvailable = wallet?.my_wallet?.available;

  return (
    <MDBox
      px={4}
      py={2}
      width="min(1075px, 100%)"
      color="white"
      bgColor="linear-gradient(270deg, #305568 0%, #396277 100%)"
      borderRadius="lg"
      shadow="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="2rem"
      flexWrap="wrap"
    >
      <MDTypography
        color={Colors.WHITE}
        fontWeight="light"
        variant="h1"
        sx={{ fontSize: "2.1875rem", lineHeight: "120%" }}
      >
        Extrato
      </MDTypography>
      <MDBox container display="flex" gap="1.5rem" flexWrap="wrap">
        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Saldo disponível
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Saldo disponível para transferir para a sua conta bancária
                cadastrada ou para investir em novos empréstimos.
              </p>

              <strong>
                <Link to="/investir">Clique para investir</Link>
              </strong>
            </>
          }
        >
          <div className={styles.walletItem}>
            <img
              src={walletIcon}
              alt="Carteira"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Saldo disponível</p>
            <p className={styles.walletItemBody1}>
              {formatMoney(balanceAvailable)}
            </p>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor em captação
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma das ordens de investimento, em fase de distribuição para
                novos empréstimos.
              </p>
            </>
          }
        >
          <div className={styles.walletItem}>
            <img
              src={clockIcon}
              alt="Relógio"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor em captação</p>
            <p className={styles.walletItemBody1}>
              {formatMoney(amountInFunding)}
            </p>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor investido
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma dos empréstimos em andamento. <br />
                Obs: O valor investido poderá retornar à captação nos casos de
                empréstimos não concedidos.
              </p>
            </>
          }
        >
          <div className={styles.walletItem}>
            <img
              src={suitcaseIcon}
              alt="Maleta"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor investido</p>
            <p className={styles.walletItemBody1}>
              {formatMoney(amountInvested)}
            </p>
          </div>
        </CustomTooltip>
      </MDBox>
    </MDBox>
  );
}

export { CardTotalBalance };
