import React from "react";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";

import { sendCodeByEmail, firstUserAccess } from "services/user";

import arrowBackIcon from "assets/images/icons/arrow-back.svg";

const CONSTANTS = {
  STATUS_CODE_SUCCESS: 200,
};

function EmailForm({
  previousStep,
  openModalEmailSent,
  openModalEmailExists,
  email,
  setEmail,
  activateLoading,
  disableLoading,
  setIsFirstAccess,
  isFirstAccess,
  previousEmail,
  setPreviousEmail,
}) {
  function handleInputChangeEmail(event) {
    const { value } = event.target;

    setEmail(value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    activateLoading();

    // Verificar se email já existe
    // Se existir, devo exibir uma mensagem para ir para página de Login
    // Senão, é só prosseguir com a criação de conta
    try {
      const inviteCode = window.location.href.split("?c=").at(1);

      if (isFirstAccess || previousEmail !== email) {
        const responseFirstUserAccess = await firstUserAccess(
          email,
          inviteCode
        );
        setIsFirstAccess(false);
        if (responseFirstUserAccess?.status !== CONSTANTS.STATUS_CODE_SUCCESS) {
          openModalEmailExists();
          throw new Error();
        }
      }

      setPreviousEmail(email);

      const responseSendCodeByEmail = await sendCodeByEmail(email);
      if (responseSendCodeByEmail?.status !== CONSTANTS.STATUS_CODE_SUCCESS) {
        throw new Error();
      }
      openModalEmailSent();
    } catch (error) {
      console.error(error);
      console.error("Erro ao enviar email");
    } finally {
      disableLoading();
    }
  }

  return (
    <div className={mainPageStyles.card}>
      <header className={styles.header}>
        <button
          title="Voltar para etapa anterior"
          className={styles.backButton}
          onClick={previousStep}
        >
          <img src={arrowBackIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
          Confirme seu e-mail
        </h2>
      </header>

      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div className={mainPageStyles.formInputGroup}>
          <label htmlFor="email" className={mainPageStyles.formLabel}>
            E-mail
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className={mainPageStyles.formInput}
            value={email}
            onChange={handleInputChangeEmail}
            placeholder="Qual o seu e-mail?"
            required
          />
        </div>

        <button
          type="submit"
          className={`${mainPageStyles.primaryButton} ${styles.submitButton}`}
        >
          Continuar
        </button>
      </form>
    </div>
  );
}

export default EmailForm;
