import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";
import arrowBackIcon from "assets/images/icons/arrow-back.svg";

import {
  getOnlyNumbersOfString,
  stringHasOnlyNumbers,
} from "../../../../../utils";
import { getBankList } from "services/bank";

const CONSTANTS = {
  ACCOUNT_TYPES: ["Selecione", "Poupança", "Conta Corrente"],
  VALID_ACCOUNT_TYPES: ["Poupança", "Conta Corrente"],
  SIZE_AGENCY: 4,
  SIZE_DIGIT: 1,
  SIZE_BANK_CODE: 3,
};

function CardAccountBalance({
  previousStep,
  nextStep,
  bank,
  setBank,
  bankCode,
  setBankCode,
  agency,
  setAgency,
  accountNumber,
  setAccountNumber,
  digitAccount,
  setDigitAccount,
  accountType,
  setAccountType,
  disableLoading,
  activeLoading,
}) {
  const [bankList, setBankList] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  const openSnackbar = () => setIsOpenSnackbar(true);

  const closeSnackbar = () => setIsOpenSnackbar(false);

  function isValidAccountType(accountType = "") {
    return CONSTANTS.VALID_ACCOUNT_TYPES.some(
      (accountTypeItem) =>
        accountTypeItem.toUpperCase() === accountType.toUpperCase()
    );
  }

  function isValidAgency(agency = "") {
    return (
      agency.length === CONSTANTS.SIZE_AGENCY && stringHasOnlyNumbers(agency)
    );
  }

  function isValidAccountNumber(accountNumber = "") {
    return stringHasOnlyNumbers(accountNumber);
  }

  function isValidAccountDigit(digit = "") {
    return digit.length === CONSTANTS.SIZE_DIGIT && stringHasOnlyNumbers(digit);
  }

  function isValidBankCode(bankCode = "") {
    return (
      bankCode.length === CONSTANTS.SIZE_BANK_CODE &&
      stringHasOnlyNumbers(bankCode) &&
      bankList.some((bankItem) => bankItem.code === bankCode)
    );
  }

  function handleInputChange(event, setValue) {
    const { value = "" } = event.target;
    const onlyNumbersValue = getOnlyNumbersOfString(value);

    setValue(onlyNumbersValue);
  }

  function handleSelectChange(event, setValue) {
    const { value = "" } = event.target;

    setValue(value);
  }

  function handleAlertMessages() {
    let _alertMessages = [];

    if (!isValidAccountType(accountType)) {
      _alertMessages = [
        ..._alertMessages,
        "Você não selecionou um tipo de conta válido",
      ];
    }

    if (!isValidAgency(agency)) {
      _alertMessages = [
        ..._alertMessages,
        "Agência inválida. Verifique a agência inserido",
      ];
    }

    if (!isValidAccountNumber(accountNumber)) {
      _alertMessages = [
        ..._alertMessages,
        "Número de conta inválida. Verifique o número da conta inserido",
      ];
    }

    if (!isValidAccountDigit(digitAccount)) {
      _alertMessages = [
        ..._alertMessages,
        "Dígito inválido. Verifique o dígito inserido",
      ];
    }

    if (!isValidBankCode(bankCode)) {
      _alertMessages = [
        ..._alertMessages,
        "Banco inválido. Verifique o banco selecionado",
      ];
    }

    setAlertMessages(_alertMessages);
    return _alertMessages;
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    // console.table({
    //   agency,
    //   accountNumber,
    //   digitAccount,
    //   accountType,
    //   bankCode,
    //   bank,
    // });

    const alertMessages = handleAlertMessages();
    const hasAlerts = alertMessages.length > 0;

    if (hasAlerts) return;

    nextStep();
  }

  function sortBanksListAlphabetic(banks = []) {
    const banksListSorted = banks.sort((bankA, bankB) => {
      if (bankA.bank.at(0) > bankB.bank.at(0)) {
        return 1;
      } else if (bankA.bank.at(0) < bankB.bank.at(0)) {
        return -1;
      }

      return 0;
    });

    return banksListSorted;
  }

  function joinBanks(banks) {
    const allBanks = [...banks?.top_banks, ...banks?.others];
    return allBanks.map((bank, index) => ({
      ...bank,
      code: String(bank.code),
      id: String(index),
    }));
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    closeSnackbar();
  }

  useEffect(() => {
    const bankListStoraged = sessionStorage.getItem("bankList");

    async function loadBankList() {
      activeLoading();

      try {
        const dataBankList = await getBankList();
        const allBanks = joinBanks(dataBankList);
        const sortedBanksListAlphabetic = sortBanksListAlphabetic(allBanks);
        setBankList(sortedBanksListAlphabetic);

        sessionStorage.setItem("bankList", JSON.stringify(dataBankList));
      } catch (error) {
        console.error(error);
      } finally {
        disableLoading();
      }
    }

    if (!bankListStoraged) {
      loadBankList();
    } else {
      const allBanks = joinBanks(JSON.parse(bankListStoraged));
      const sortedBanksListAlphabetic = sortBanksListAlphabetic(allBanks);
      setBankList(sortedBanksListAlphabetic);
    }

    return () => {
      setBankList([]);
    };
  }, []);

  useEffect(() => {
    if (!bank) return;

    const dataBank = bank.split("-");
    if (dataBank.length < 2) return;

    const _code = dataBank.at(-1).trim();
    setBankCode(_code);
  }, [bank]);

  useEffect(() => {
    if (alertMessages?.length === 0) return;

    openSnackbar();
  }, [alertMessages]);

  return (
    <div className={`${mainPageStyles.card} ${styles.containerCard}`}>
      <header className={styles.header}>
        <button
          title="Voltar para etapa anterior"
          className={styles.backButton}
          onClick={previousStep}
        >
          <img src={arrowBackIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
          Informe sua conta bancária
        </h2>
      </header>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div className={mainPageStyles.formInputGroup}>
          <label htmlFor="bank" className={mainPageStyles.formLabel}>
            Banco
          </label>
          <input
            type="text"
            id="bank"
            name="bank"
            className={mainPageStyles.formInput}
            value={bank}
            onChange={(event) => handleSelectChange(event, setBank)}
            placeholder="Selecione ou pesquise um banco"
            list="banks"
            required
          />
          <datalist id="banks">
            {bankList?.map((bank) => (
              <option
                key={bank.id}
                value={`${bank.bank} - ${bank.code}`}
                code={bank.code}
                id={bank.id}
              />
            ))}
          </datalist>
        </div>

        <div className={mainPageStyles.formInputGroup}>
          <label htmlFor="account-types" className={mainPageStyles.formLabel}>
            Tipo de conta
          </label>
          <select
            id="account-types"
            name="account-types"
            className={mainPageStyles.formInput}
            defaultValue="Selecione"
            value={accountType}
            onChange={(event) => handleSelectChange(event, setAccountType)}
            required
          >
            {CONSTANTS.ACCOUNT_TYPES.map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className={mainPageStyles.formInputGroup}>
          <label htmlFor="agency" className={mainPageStyles.formLabel}>
            Agência
          </label>
          <input
            type="text"
            id="agency"
            name="agency"
            className={mainPageStyles.formInput}
            value={agency}
            onChange={(event) => handleInputChange(event, setAgency)}
            placeholder="XXXX"
            minLength={4}
            maxLength={4}
            required
          />
          <p className={styles.auxilliaryText}>
            Caso sua agência possua dígito, desconsidere-o.
          </p>
        </div>

        <div className={styles.rowAccount}>
          <div className={mainPageStyles.formInputGroup}>
            <label
              htmlFor="account-number"
              className={mainPageStyles.formLabel}
            >
              Conta
            </label>
            <input
              type="text"
              id="account-number"
              name="account-number"
              className={mainPageStyles.formInput}
              value={accountNumber}
              onChange={(event) => handleInputChange(event, setAccountNumber)}
              placeholder="Somente números"
              required
            />
          </div>

          <div className={mainPageStyles.formInputGroup}>
            <label htmlFor="digit" className={mainPageStyles.formLabel}>
              Dígito
            </label>
            <input
              type="text"
              id="digit"
              name="digit"
              className={mainPageStyles.formInput}
              value={digitAccount}
              onChange={(event) => handleInputChange(event, setDigitAccount)}
              placeholder="X"
              minLength={1}
              maxLength={1}
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className={`${mainPageStyles.primaryButton} ${styles.formButton}`}
        >
          Continuar
        </button>
      </form>
      {alertMessages.map((alertMessage) => (
        <Snackbar
          key={alertMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isOpenSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
}

export default CardAccountBalance;
