/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import SignInBasic from "layouts/authentication/sign-in/basic";
import NewProduct from "layouts/ecommerce/products/new-product";
import Contracts from "layouts/pages/contracts";
import Extract from "layouts/pages/extract";
import Help from "layouts/pages/help";
import Home from "layouts/pages/home";
import Invest from "layouts/pages/invest";
import MyProfile from "layouts/pages/my-profile";
import CreateAccount from "layouts/pages/create-account";
import ForgetPassword from "layouts/pages/forget-password";
import WaitDataValidation from "layouts/pages/wait-data-validation";
import ResendDocuments from "layouts/pages/resend-documents";
import ListLoans from "layouts/pages/direct-loan/list-loans";
import ConsultCode from "layouts/pages/direct-loan/consult-code";
import Messages from "layouts/pages/messages";
import InviteInvestors from "layouts/pages/inviteInvestors";
import PasswordRecovery from "layouts/pages/password-recovery";

// Material Dashboard 2 PRO React components

// @mui icons

// Images
import listContract from "assets/images/icons/iconContract.svg";
import extractPaperIcon from "assets/images/icons/extract-paper.svg";
import helpIcon from "assets/images/icons/help.svg";
import moneyIcon from "assets/images/icons/money.svg";
import profileIcon from "assets/images/icons/profile.svg";
import walletIcon from "assets/images/icons/wallet.svg";
import handWithMoneyIcon from "assets/images/icons/hand-with-money.svg";
import messageIcon from "assets/images/icons/mail-main-color.svg";
import inviteInvestorIcon from "assets/images/icons/inviteInvestor.svg";

const routes = [
  {
    title: "Aguardar validação de dados",
    name: "Aguardar validação de dados",
    key: "aguardar-validacao-dados",
    route: "/aguardar-validacao-dados",
    component: <WaitDataValidation />,
  },
  {
    title: "Reenvio de documentos",
    name: "Reenvio de documentos",
    key: "reenvio-documentos",
    route: "/reenvio-documentos",
    component: <ResendDocuments />,
  },
  {
    title: "Criar conta",
    name: "Criar conta",
    key: "criar-conta",
    route: "/criar-conta",
    component: <CreateAccount />,
  },
  {
    title: "Esqueceu senha",
    name: "Esqueceu senha",
    key: "esqueceu-senha",
    route: "/esqueceu-senha",
    component: <ForgetPassword />,
  },
  {
    title: "Recuperar minha senha",
    name: "Recuperar minha senha",
    key: "recuperar-minha-senha",
    route: "/recuperar-minha-senha/:token",
    component: <PasswordRecovery />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Carteira",
    key: "inicio",
    route: "/carteira",
    icon: <img src={walletIcon} alt="Carteira" title="Carteira" />,
    component: <Home />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Investir",
    key: "investir",
    route: "/investir",
    icon: <img src={moneyIcon} alt="Cédula de Dinheiro" title="Investir" />,
    component: <Invest />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Extrato",
    key: "extrato",
    route: "/extrato",
    icon: <img src={extractPaperIcon} alt="Papel de Extrato" title="Extrato" />,
    component: <Extract />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Contratos",
    key: "contratos",
    route: "/contratos",
    icon: (
      <img
        src={listContract}
        alt="Papel de Contrato e Lápis"
        title="Contratos"
      />
    ),
    component: <Contracts />,
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    icon: <img src={profileIcon} alt="Perfil" title="Perfil" />,
    collapse: [
      {
        name: "Meu perfil",
        key: "Meu perfil",
        route: "/meu-perfil",
        component: <MyProfile />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/login",
        component: <SignInBasic />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Mensagens",
    key: "mensagens",
    route: "/mensagens",
    icon: <img src={messageIcon} alt="Mensagem" title="Mensagem" />,
    component: <Messages />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Ajuda",
    key: "ajuda",
    route: "/ajuda",
    icon: <img src={helpIcon} alt="Ajuda" title="Ajuda" />,
    component: <Help />,
  },
  { type: "divider", key: "divider-0" },
  /*   {
    name: "Simulação",
    type: "collapse",
    noCollapse: true,
    key: "simulacao",
    route: "/simulacao",
    component: <NewProduct />,
  }, */
  {
    type: "collapse",
    name: "Empresta fácil",
    key: "direct-loan",
    icon: (
      <img
        src={handWithMoneyIcon}
        alt="Mão com cifrão de dinheiro"
        title="Empresta fácil"
      />
    ),
    collapse: [
      {
        name: "Consultar códigos",
        key: "Consultar códigos",
        route: "/empresta-facil/consultar-codigo",
        component: <ConsultCode />,
      },
      {
        name: "Listar empréstimos",
        key: "Listar empréstimos",
        route: "/empresta-facil/listar-emprestimos",
        component: <ListLoans />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Convidar investidores",
    key: "invite-investors",
    route: "/convidar-investidores",
    icon: (
      <img
        src={inviteInvestorIcon}
        alt="Convidar investidor"
        title="Convidar investidor"
      />
    ),
    component: <InviteInvestors />,
  },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/dashboards/analytics",
  //       component: <Analytics />,
  //     },
  //     {
  //       name: "Sales",
  //       key: "sales",
  //       route: "/dashboards/sales",
  //       component: <Sales />,
  //     },
  //   ],
  // },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-in/illustration",
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Sign Up",
  //       key: "sign-up",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-up/cover",
  //           component: <SignUpCover />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Reset Password",
  //       key: "reset-password",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/reset-password/cover",
  //           component: <ResetCover />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  // {
  //   name: "Foundation",
  //   key: "foundation",
  //   collapse: [
  //     {
  //       name: "Colors",
  //       key: "colors",
  //       href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //     },
  //     {
  //       name: "Grid",
  //       key: "grid",
  //       href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "base-typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //     },
  //     {
  //       name: "Borders",
  //       key: "borders",
  //       href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //     },
  //     {
  //       name: "Box Shadows",
  //       key: "box-shadows",
  //       href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //     },
  //     {
  //       name: "Functions",
  //       key: "functions",
  //       href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //     },
  //     {
  //       name: "Routing System",
  //       key: "routing-system",
  //       href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //     },
  //   ],
  // },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
