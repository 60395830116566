import React from "react";

import styles from "./styles.module.css";
import { ReactComponent as DescriptionIcon } from "../../../../../assets/images/icons/description.svg";
import { ReactComponent as AccountBalanceIcon } from "../../../../../assets/images/icons/account-balance.svg";

const listSteps = [
  { index: 1, title: "Conta bancária", icon: () => <AccountBalanceIcon /> },
  {
    index: 2,
    title: "Validação de documentos",
    icon: () => <DescriptionIcon />,
  },
];

function ListSteps({
  currentStepNumber,
  renderStepAccountBalance,
  renderStepValidationDocuments,
}) {
  function handleGetClassNameStepItem(currentStepNumber, stepIndex) {
    if (stepIndex === currentStepNumber) {
      return `${styles.stepItem} ${styles.stepItemActive}`;
    } else if (stepIndex < currentStepNumber) {
      return `${styles.stepItem} ${styles.stepItemDefault}`;
    }

    return `${styles.stepItem} ${styles.stepItemDisabled}`;
  }

  const renderAllSteps =
    renderStepAccountBalance && renderStepValidationDocuments;

  function handleRenderStep() {
    if (renderStepAccountBalance) {
      return (
        <li className={`${styles.stepItem} ${styles.stepItemActive}`}>
          <AccountBalanceIcon />
          <span className={styles.stepItemTitle}>Conta bancária</span>
        </li>
      );
    } else if (renderStepValidationDocuments) {
      return (
        <li className={`${styles.stepItem} ${styles.stepItemActive}`}>
          <DescriptionIcon />
          <span className={styles.stepItemTitle}>Validação de documentos</span>
        </li>
      );
    } else {
      return null;
    }
  }

  return (
    <ul className={styles.listSteps}>
      {renderAllSteps
        ? listSteps?.map((stepItem) => (
            <li
              className={handleGetClassNameStepItem(
                currentStepNumber,
                stepItem.index
              )}
              key={stepItem.index}
            >
              {stepItem?.icon()}
              <span className={styles.stepItemTitle}>{stepItem.title}</span>
            </li>
          ))
        : handleRenderStep()}
    </ul>
  );
}

export { ListSteps };
