import styles from "./styles.module.css";
import imageErrorDataPeople from "../../../../../assets/images/mao-com-celular.svg";

function ModalErrorDataPeople({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>
          Corrija seus dados para completar seu cadastro
        </h2>
        <img
          src={imageErrorDataPeople}
          className={styles.illustration}
          alt="Celular bloqueado"
          loading="lazy"
        />
        <p className={styles.description}>
          Por favor, revise seus dados de cadastro. Identificamos possíveis
          erros relacionados ao seu CPF ou data de nascimento.
        </p>
        <p className={styles.description}>
          É importante corrigi-los para prosseguir com o processo de cadastro.
          Garanta que todas as informações estejam corretas e atualizadas.
        </p>
        <button className={styles.primaryButton} onClick={closeModal}>
          Voltar
        </button>
      </aside>
    </div>
  );
}

export { ModalErrorDataPeople };
