import React from "react";

import { formatMoney } from "utils";

import styles from "./styles.module.css";
import walletIcon from "../../../../../assets/images/icons/wallet-soft-blue.svg";
import suitcaseIcon from "../../../../../assets/images/icons/suitcase.svg";

function CardTotalBalance({ wallet }) {
  const amountInvested = wallet?.my_wallet?.amount_receivable;
  const balanceAvailable = wallet?.my_wallet?.available;

  return (
    <div className={styles.card}>
      <h1 className={styles.title}>Empresta fácil</h1>
      <ul className={styles.listData}>
        <li className={styles.dataItem}>
          <img
            src={walletIcon}
            alt="Carteira"
            className={styles.dataItemIcon}
          />
          <p className={styles.dataItemLabel}>Saldo disponível</p>
          <strong className={styles.dataItemAmount}>
            {formatMoney(balanceAvailable)}
          </strong>
        </li>
        <li className={styles.dataItem}>
          <img
            src={suitcaseIcon}
            alt="Maleta"
            className={styles.dataItemIcon}
          />
          <p className={styles.dataItemLabel}>Valor investido</p>
          <strong className={styles.dataItemAmount}>
            {formatMoney(amountInvested)}
          </strong>
        </li>
      </ul>
    </div>
  );
}

export { CardTotalBalance };
