import React from "react";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

import threePeopleCommemorationIllustration from "../../../../../assets/images/illustrations/three-people-commemoration.svg";

import styles from "./styles.module.css";

function ModalAutomaticReinvestmentEnabled({ closeModal }) {
  return (
    <MDBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bgColor="rgba(0, 0, 0, 0.5)"
      zIndex="1201"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MDBox
        width="min(42rem, 90%)"
        px={2}
        py={5}
        bgColor="#fff"
        borderRadius="lg"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <MDTypography
          width="80%"
          variant="h3"
          fontWeight="light"
          sx={{
            fontSize: "1.5rem",
            lineHeight: "calc(1.5rem * 1.4)",
            color: "#3D555A",
          }}
        >
          O reinvestimento automático foi habilitado com sucesso!
        </MDTypography>
        <img
          src={threePeopleCommemorationIllustration}
          className={styles.illustration}
          alt="Três pessoas comemorando"
        />
        <MDTypography
          mt="3rem"
          width="90%"
          variant="body1"
          fontWeight="light"
          sx={{
            color: "#3D555A",
          }}
        >
          Agora, todos os recebimentos de empréstimos em andamento serão
          reinvestidos em novos empréstimos de até 12 meses
        </MDTypography>
        <button className={styles.secondaryButton} onClick={closeModal}>
          Fechar
        </button>
      </MDBox>
    </MDBox>
  );
}

export default ModalAutomaticReinvestmentEnabled;
