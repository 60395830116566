import { formatDateToPtBr } from "utils";

function useGenerateExtractCSV(transactions, investments, selectedMonth, selectedYear) {
  let csvContent;

  const getLabelInvestment = (investment) => {
    if (!investment?.risk) {
      return "Recebimento de Parcela";
    }
    if (investment?.risk === "menor_risco") {
      return "Conservador";
    }
    if (investment?.risk === "medio_risco") {
      return "Balanceado";
    }
    return "Arrojado";
  };

  const isReceivement = (investment) => {
    return investment.mainValue && investment.interestValue;
  };

  const getTitle = () => {
    csvContent = `Extrato - ${selectedMonth.name} ${selectedYear}\n\n`;
  }

  const getTransactions = () => {
    csvContent += "Transferências\n";
    csvContent += "Data,Tipo,Valor\n";
    transactions.forEach(transaction => {
      csvContent += `${formatDateToPtBr(transaction?.details?.dateCreated)},${transaction?.withdraw ? "Retirada" : "Depósito"},${transaction?.withdraw ? `-${transaction.amount}` : `+${transaction.amount}`}\n`;
    });
  }

  const getInvestments = () => {
    csvContent += "\nInvestimentos\n";
    csvContent += "Data,Tipo,Valor\n";
    investments.forEach(investment => {
      const investmentRow = [formatDateToPtBr(investment.date)];

      if (isReceivement(investment)) {
        csvContent += `${investmentRow.join(',')},Principal,${investment.mainValue}\n`;
        csvContent += `${investmentRow.join(',')},Juros,${investment.interestValue}\n`;
      } else {
        csvContent += `${investmentRow.join(',')},${getLabelInvestment(investment)},-${investment?.investment_amount ? investment.investment_amount : investment.amount}\n`;
      }
    });
  }

  const getContent = () => {
    getTitle();
    getTransactions();
    getInvestments();
  }

  const execute = () => {
    getContent();
    
    const fileName = `extrato_fido_${selectedMonth.month}_${selectedYear}.csv`;

    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  execute();

}

export { useGenerateExtractCSV };
