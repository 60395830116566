import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import styles from "../../styles.module.css";
import phoneSuccessIllustration from "assets/images/illustrations/phone-success.svg";

function StepLast({ handleClickGoHome }) {
  return (
    <MDBox display="flex" flexDirection="column" alignItems="center">
      <MDTypography
        maxWidth="90%"
        mt={3}
        variant="h3"
        fontWeight="light"
        sx={{
          fontSize: "1.5rem",
          lineHeight: "calc(1.5rem * 1.5)",
          color: "#3D555A",
          textAlign: "center",
        }}
      >
        Pronto, transferência concluída!
      </MDTypography>
      <img
        src={phoneSuccessIllustration}
        className={styles.lastIllustration}
        alt="Transferência realizada com sucesso"
      />
      <MDTypography
        mt={4}
        mb="5rem"
        variant="body1"
        fontWeight="light"
        sx={{ color: "#3D555A", textAlign: "center", width: "80%" }}
      >
        Acompanhe depósitos e transferências em "Extrato".
      </MDTypography>
      <MDBox mb="3rem" width="100%" display="flex" justifyContent="center">
        <button className={styles.secondaryButton} onClick={handleClickGoHome}>
          Voltar início
        </button>
      </MDBox>
    </MDBox>
  );
}

export { StepLast };
