import React, { useState } from "react";
import { Link } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import ModalSendEmail from "./components/ModalSendEmail";
import ModalCpfExists from "./components/ModalCpfExists";

import { userExists, forgetPassword } from "services/user";
import { cpfCnpjMask } from "utils/mask";

import styles from "./styles.module.css";

import arrowBackIcon from "../../../assets/images/icons/arrow-back.svg";
import logoFido from "../../../assets/images/Logos_Fido/principal-fundo-transparente.svg";

const CONSTANTS = {
  SIZE_CPF: 14,
  SIZE_CNPJ: 18,
};

function ForgetPassword() {
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [isOpenModalSendEmail, setIsOpenModalSendEmail] = useState(false);
  const [isOpenModalCpfExists, setIsOpenModalCpfExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activeLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  const openModalSendEmail = () => setIsOpenModalSendEmail(true);

  const closeModalSendEmail = () => setIsOpenModalSendEmail(false);

  const openModalCpfExists = () => setIsOpenModalCpfExists(true);

  const closeModalCpfExists = () => setIsOpenModalCpfExists(false);

  function handleChangeInputCpf(event) {
    const { value } = event.target;
    const maskedCpf = cpfCnpjMask(value);

    setCpf(maskedCpf);
  }

  async function handleFormSubmitResetPassword(event) {
    event.preventDefault();
    activeLoading();

    try {
      const response = await userExists(cpf);

      if (response?.status !== 200) {
        throw new Error();
      }

      if (!response?.data?.user_exists) {
        disableLoading();
        openModalCpfExists();
        return;
      }
    } catch (error) {
      console.error(error);
      disableLoading();
    }

    try {
      const response = await forgetPassword(cpf);

      if (response?.status !== 200) throw new Error();

      setEmail(response?.data?.email);
      openModalSendEmail();
    } catch (error) {
      console.error(error);
    } finally {
      disableLoading();
    }
  }

  return (
    <PageLayout width="100%" background="#F0F2F5">
      <MDBox
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div className={styles.card}>
          <header className={styles.header}>
            <Link to="/login" className={styles.backButton}>
              <img
                src={arrowBackIcon}
                alt="Seta para esquerda"
                title="Voltar ao login"
              />
            </Link>

            <img src={logoFido} alt="Logo da Fido" className={styles.logo} />
          </header>
          <h2 className={`${styles.h2} ${styles.title}`}>Resetar senha</h2>
          <p className={`${styles.b16} ${styles.description}`}>
            Digite o número do seu CPF ou CNPJ para enviarmos um link para a
            mudança da sua senha
          </p>
          <form
            className={styles.form}
            autoComplete="off"
            onSubmit={handleFormSubmitResetPassword}
          >
            <div className={styles.inputGroup}>
              <label htmlFor="cpf" className={styles.label}>
                CPF ou CNPJ
              </label>
              <input
                type="text"
                id="cpf"
                name="cpf"
                placeholder="XXX.XXX.XXX-XX ou XX.XXX.XXX/XXXX-XX"
                className={styles.input}
                value={cpf}
                onChange={handleChangeInputCpf}
                required
              />
              <p className={styles.descriptionInput}>
                {cpf?.length}/
                {cpf?.length <= CONSTANTS.SIZE_CPF
                  ? CONSTANTS.SIZE_CPF
                  : CONSTANTS.SIZE_CNPJ}
              </p>
            </div>

            <button
              type="submit"
              className={`${styles.primaryButton} ${styles.submitButton}`}
              disabled={
                cpf?.length != CONSTANTS.SIZE_CPF &&
                cpf?.length != CONSTANTS.SIZE_CNPJ
              }
            >
              Resetar senha
            </button>
          </form>
        </div>
      </MDBox>

      {isOpenModalSendEmail && (
        <ModalSendEmail closeModal={closeModalSendEmail} email={email} />
      )}

      {isOpenModalCpfExists && (
        <ModalCpfExists closeModal={closeModalCpfExists} />
      )}

      {isLoading && <Loading />}
    </PageLayout>
  );
}

export default ForgetPassword;
