import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar, TextField } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CardTotalBalance } from "../components/CardTotalBalance";
import { LoansTable } from "./components/LoansTable";
import { ModalContractDetails } from "./components/ModalContractDetails";
import Loading from "components/Loading";

import { getDirectLoansList } from "services/direct-loan";
import { getWalletData } from "services/investments";
import { getStoredValue } from "metrics/storage";

import styles from "./styles.module.css";

function ListLoans() {
  const token = getStoredValue("token");
  const navigate = useNavigate();
  const [wallet, setWallet] = useState({});
  const [directLoansList, setDirectLoansList] = useState([]);
  const [filteredDirectLoansList, setFilteredDirectLoansList] = useState([]);
  const [selectedDirectLoan, setSelectedDirectLoan] = useState({});
  const [takerNameToSearch, setTakerNameToSearch] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [isOpenModalContractDetails, setIsOpenModalContractDetails] =
    useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isActiveLoadingWallet, setIsActiveLoadingWallet] = useState(false);
  const [isActiveLoadingDirectLoansList, setIsActiveLoadingDirectLoansList] =
    useState(false);

  const activeLoading = () => setIsActiveLoading(true);

  const activeLoadingWallet = () => setIsActiveLoadingWallet(true);

  const activeLoadingDirectLoansList = () =>
    setIsActiveLoadingDirectLoansList(true);

  const disableLoading = () => setIsActiveLoading(false);

  const disableLoadingWallet = () => setIsActiveLoadingWallet(false);

  const disableLoadingDirectLoansList = () =>
    setIsActiveLoadingDirectLoansList(false);

  const openModalContractDetails = () => setIsOpenModalContractDetails(true);

  const closeModalContractDetails = () => setIsOpenModalContractDetails(false);

  function handleInputSearchTakerName(event) {
    const { value } = event.target;

    setTakerNameToSearch(value);
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlertConfig({
      ...alertConfig,
      isOpen: false,
    });
  }

  function handleAlertConfig(result) {
    let alert = {
      isOpen: true,
      message: "",
      type: "",
    };

    if (result.type === "approve") {
      if (result.success) {
        alert = {
          type: "success",
          message: "Empresta fácil aprovado com sucesso",
        };
      } else {
        alert = {
          type: "error",
          message: "Erro ao aprovar Empresta fácil. Tente novamente mais tarde",
        };
        if (result.error === "NO_SUCH_BALANCE") {
          alert.message =
            "Você não possui saldo suficiente para aprovar esse empréstimo.";
        }
      }
    } else if (result.type === "refuse") {
      if (result.success) {
        alert = {
          type: "success",
          message: "Empresta fácil reprovado com sucesso",
        };
      } else {
        alert = {
          type: "error",
          message:
            "Erro ao reprovar Empresta fácil. Tente novamente mais tarde",
        };
      }
    }

    alert = {
      ...alert,
      isOpen: true,
    };
    setAlertConfig(alert);
    sessionStorage.setItem("alertConfig", JSON.stringify(alert));
  }

  useEffect(() => {
    async function loadWallet() {
      activeLoadingWallet();

      try {
        const response = await getWalletData();

        if (response.status !== 200) {
          throw new Error();
        }

        setWallet(response.data);
      } catch (error) {
        console.error(error);
        console.error("Erro ao obter wallet");
      } finally {
        disableLoadingWallet();
      }
    }

    loadWallet();

    return () => {
      setWallet({});
    };
  }, []);

  useEffect(() => {
    async function loadDirectLoansList() {
      activeLoadingDirectLoansList();

      try {
        const response = await getDirectLoansList(token);

        if (response.status !== 200) {
          throw new Error();
        }

        const loans = response.data.map((loan) => ({
          id: loan._id.$oid,
          ...loan,
        }));
        setDirectLoansList(loans);
        setFilteredDirectLoansList(loans);
      } catch (error) {
        console.error(error);
        console.error("Erro ao obter lista de empréstimos diretos");
      } finally {
        disableLoadingDirectLoansList();
      }
    }

    loadDirectLoansList();

    return () => {
      setDirectLoansList([]);
    };
  }, []);

  useEffect(() => {
    const result = JSON.parse(
      sessionStorage.getItem("resultDirectLoanOperation")
    );

    if (!result) {
      sessionStorage.removeItem("resultDirectLoanOperation");
      return;
    }

    handleAlertConfig(result);
    sessionStorage.removeItem("resultDirectLoanOperation");

    return () => {
      setAlertConfig({ isOpen: false, message: "", type: "" });
      sessionStorage.removeItem("resultDirectLoanOperation");
    };
  }, []);

  useEffect(() => {
    const storedAlertConfig = sessionStorage.getItem("alertConfig");

    if (storedAlertConfig) {
      const alert = JSON.parse(storedAlertConfig);
      setAlertConfig(alert);
      sessionStorage.removeItem("alertConfig");
    }
  }, []);

  useEffect(() => {
    if (isActiveLoadingDirectLoansList || isActiveLoadingWallet) {
      activeLoading();
    } else {
      disableLoading();
    }

    return () => {
      setIsActiveLoading(false);
    };
  }, [isActiveLoadingDirectLoansList, isActiveLoadingWallet]);

  useEffect(() => {
    if (takerNameToSearch?.length === 0) {
      setFilteredDirectLoansList(directLoansList);
      return;
    }

    const filteredList = filteredDirectLoansList?.filter((directLoan) =>
      directLoan.taker.fullname
        .toLowerCase()
        .includes(takerNameToSearch.toLowerCase())
    );
    setFilteredDirectLoansList(filteredList);
  }, [takerNameToSearch]);

  if (token === "null") {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CardTotalBalance wallet={wallet} />
      <section className={styles.sectionListLoans}>
        <div className={styles.header}>
          <h2>Lista de empréstimos</h2>
          <form>
            <TextField
              id="standard-basic"
              label="Pesquisar nome"
              variant="standard"
              onChange={handleInputSearchTakerName}
              value={takerNameToSearch}
            />
          </form>
        </div>
        <div className={styles.containerTable}>
          <LoansTable
            openModal={openModalContractDetails}
            directLoansList={directLoansList}
            filteredDirectLoansList={filteredDirectLoansList}
            setFilteredDirectLoansList={setFilteredDirectLoansList}
            setSelectedDirectLoan={setSelectedDirectLoan}
          />

          {directLoansList?.length === 0 && (
            <p className={styles.messageEmptyTable}>
              Você não possui empréstimos fáceis no momento
            </p>
          )}

          {directLoansList?.length > 0 &&
          filteredDirectLoansList?.length === 0 ? (
            <p className={styles.messageEmptyTable}>
              Não encontramos o que você busca
            </p>
          ) : null}
        </div>
        {/* <button className={styles.downloadButton}>Baixar arquivo</button> */}
      </section>

      {isOpenModalContractDetails && (
        <ModalContractDetails
          closeModal={closeModalContractDetails}
          selectedDirectLoan={selectedDirectLoan}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          setAlertConfig={setAlertConfig}
        />
      )}

      {isActiveLoading && <Loading />}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertConfig.isOpen && !isActiveLoading}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alertConfig.type}
          sx={{ width: "100%" }}
        >
          {alertConfig.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default ListLoans;
