import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";

import styles from "../../styles.module.css";

function StepConfirmedSmsCode({
  handleSubmitFormConfirmedSmsCode,
  smsCode,
  setSmsCode,
  timeSmsCode,
  isActiveIimeSmsCode,
  sendSmsCode,
}) {
  return (
    <form
      className={styles.smsForm}
      onSubmit={handleSubmitFormConfirmedSmsCode}
    >
      <label htmlFor="smsCode" className={styles.formLabel2}>
        Você receberá um código via SMS no seu celular. Informe o código abaixo.
      </label>
      <input
        type="number"
        className={`${styles.formInput2} ${styles.mt3}`}
        id="smsCode"
        name="smsCode"
        autoComplete="off"
        placeholder="Ex: 12345"
        value={smsCode}
        onChange={(e) => setSmsCode(e.target.value)}
        required
      />
      <p className={styles.formAuxiliaryMessage}>
        O recebimento do SMS pode demorar até 30 segundos.
      </p>

      <MDBox
        mt={2}
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MDTypography
          variant="h4"
          fontWeight="medium"
          sx={{ fontSize: "1.25rem", lineHeight: "140%", color: "#3D555A" }}
        >
          {timeSmsCode}s
        </MDTypography>
      </MDBox>

      <Button
        type="button"
        variant="text"
        disabled={isActiveIimeSmsCode}
        onClick={sendSmsCode}
      >
        Enviar código
      </Button>

      <button
        type="submit"
        className={`${styles.formButton} ${styles.formButtonStep2}`}
      >
        Confirmar
      </button>
    </form>
  );
}

export { StepConfirmedSmsCode };
