import React, { useState, useEffect } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDBox from "../../../components/MDBox";
import CardTotalBalance from "./components/CardTotalBalance";
import CardInvestmentOrder from "./components/CardInvestmentOrder";
import CardInvestmentDetails from "./components/CardInvestmentDetails";
import CardConfirmedInvestment from "./components/CardConfirmedInvestment";
import Loading from "components/Loading";
import SectionAmountToInvest from "./layout/SectionAmountToInvest";
import SectionCustomizeInvestment from "./layout/SectionCustomizeInvestment";
import SectionDeadlineInvestment from "./layout/SectionDeadlineInvestment";
import SectionCustomInvestmentDetails from "./layout/SectionCustomInvestmentDetails";
import CardArrojadoInvest from "./components/CardArrojadoInvest";
import CardConservadorInvest from "./components/CardConservadorInvest";
import CardMediumInvest from "./components/CardMediumInvest";

import { Colors } from "common/Colors";

import { getInvestmentPlans } from "services/investments";
import { setStoredValue, getStoredValue } from "metrics/storage";
import { getWalletData } from "services/investments";

import { checkSessionTimer } from "utils";

export function Invest() {
  const [currentContentHTML, setCurrentContentHTML] = useState("initial");
  const [lowRisk, setLowRisk] = useState({});
  const [highRisk, setHighRisk] = useState({});
  const [mediumRisk, setMediumRisk] = useState({});
  const [amountToInvest, setAmountToInvest] = useState("100,00");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [isLoadingInvestments, setIsLoadingInvestments] = useState(false);
  const [wallet, setWallet] = useState({});
  const navigate = useNavigate();
  const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  const tokenStorage = localStorage.getItem("token");

  function submitLowRisk() {
    setStoredValue("plan", lowRisk);
  }

  function submitHighRisk() {
    setStoredValue("plan", highRisk);
  }

  function submitMediumRisk() {
    setStoredValue("plan", mediumRisk);
  }

  const contentsHTML = {
    initial: () => (
      <MDBox mt={3}>
        <CardTotalBalance wallet={wallet} />
        <MDTypography
          mt={4}
          mb={2}
          component="h4"
          fontWeight="bold"
          sx={{
            fontSize: "1.375",
            lineHeight: "130%",
            color: Colors.PROTOTYPE_GREY,
          }}
        >
          Selecione um tipo de investimento
        </MDTypography>
        <MDBox display="flex" gap="2rem" flexWrap="wrap">
          <CardArrojadoInvest
            submitHighRisk={submitHighRisk}
            setCurrentContentHTML={setCurrentContentHTML}
          />
          <CardMediumInvest
            submitMediumRisk={submitMediumRisk}
            setCurrentContentHTML={setCurrentContentHTML}
          />
          <CardConservadorInvest
            submitLowRisk={submitLowRisk}
            setCurrentContentHTML={setCurrentContentHTML}
          />
        </MDBox>
        <CardInvestmentOrder
          setIsLoading={setIsLoading}
          setIsLoadingInvestments={setIsLoadingInvestments}
        />
      </MDBox>
    ),

    amountToInvest: () => (
      <SectionAmountToInvest
        setCurrentContentHTML={setCurrentContentHTML}
        amountToInvest={amountToInvest}
        setAmountToInvest={setAmountToInvest}
        wallet={wallet}
      />
    ),

    investmentDetails: () => (
      <CardInvestmentDetails
        setCurrentContentHTML={setCurrentContentHTML}
        amountToInvest={amountToInvest}
        setIsLoading={setIsLoading}
        loggedUser={loggedUser}
      />
    ),

    confirmedInvestment: () => (
      <CardConfirmedInvestment setCurrentContentHTML={setCurrentContentHTML} />
    ),

    customizeInvestment: () => (
      <SectionCustomizeInvestment
        setCurrentContentHTML={setCurrentContentHTML}
        wallet={wallet}
      />
    ),

    formDeadline: () => (
      <SectionDeadlineInvestment
        setCurrentContentHTML={setCurrentContentHTML}
        wallet={wallet}
      />
    ),

    customInvestmentDetails: () => (
      <SectionCustomInvestmentDetails
        setCurrentContentHTML={setCurrentContentHTML}
        wallet={wallet}
      />
    ),
  };

  useEffect(() => {
    // activateLoading();
    setIsLoadingPlans(true);

    async function loadPlans() {
      try {
        const data = await getInvestmentPlans();
        data.forEach((value) => {
          if (value.risk === "maior_risco") {
            setHighRisk(value);
          }
          if (value.risk === "menor_risco") {
            setLowRisk(value);
          }
          if (value.risk === "medio_risco") {
            setMediumRisk(value);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        // disableLoading();
        setIsLoadingPlans(false);
      }
    }

    loadPlans();
  }, []);

  async function loadWallet() {
    setIsLoadingWallet(true);

    try {
      const response = await getWalletData();

      if (response.status !== 200) {
        throw new Error();
      }

      const { data } = response;
      setWallet(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingWallet(false);
    }
  }

  useEffect(() => {
    checkSessionTimer(
      () => loadWallet(),
      () => navigate('/login')
    );
  }, []);

  useEffect(() => {
    if (!isLoadingPlans && !isLoadingWallet && !isLoadingInvestments) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
  }, [isLoadingPlans, isLoadingWallet, isLoadingInvestments]);

  if (!tokenStorage) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {contentsHTML[currentContentHTML]()}
      {isLoading && <Loading />}
    </DashboardLayout>
  );
}
export default Invest;
