const Colors = {
  WHITE: "#FFFFFF",
  BLACK: "#040000",
  WHITE_TEXT: "#FFF7F7",

  // PRIMARY
  FIDO_PRIMARY_BLUE: "#305568",
  FIDO_SECONDARY_BLUE: "#396277",
  FIDO_TEXT_BLUE: "#78C5EC",

  // FIDO_BLUE: '#085669', (from Fido_Assinaturas_Cores.eps)
  FIDO_BLUE: "#4FADBF", // (from MarvelApp)
  FIDO_GREEN: "#0BAB8B",
  FIDO_GREEN_OPACITY: "#B7DDCD",
  DARK_RGBA: "rgba(36, 32, 32, 0.5)",

  // SECONDARY
  FIDO_ORANGE: "#F7921D",
  ORANGE_GRAFIC: "#DB8F1B",
  FIDO_RED: "#D30A55",
  FIDO_BABY_BLUE: "#557EBF",
  FIDO_MINT_GREEN: "#9ACF87",
  FIDO_YELLOW: "#fabc1d",
  // OTHER COLORS
  PROTOTYPE_GREY: "#919CAF",
  PROTOTYPE_DARK_BLUE: "#132A40",
  GREY_LABEL_FORM: "#828F99",
  RED_ERROR_FORM: "#E95E51",
  SOFT_GREY: "#DCDCDC",
  DARK_GREY: "#D3D3D3",
  // FIGMA COLORS
  MINT_GREEN: "#59A284",
  DARK_GREEN: "#487F68",
  LIGHTER_GREEN: "#68BB99",
  GREEN_BLUE: "#9AEADB",
  RED: "#B2004E",
  ICE: "#F2F4F5",
  DISABLED_BACKGROUND: "#F2F4F5",
  DISABLED_TITLE: "#DFDCDC",
  LIGHT_GREY: "#606061",
  LIGHT_BLUE: "#233A3D",
  DARK_ICE: "#EAE8E8",
  DARK_BLUE: "#3D555A",
  GREY: "#959899",
  LIGHTER_GREY: "#EBEDEE",
  MINT_GREEN_FOR_BACKGROUND: "#EAFAF3",
  GREEN_PAY: "#B1CC83",
  LINE_COLOR: "rgba(0, 0, 0, 0.14)",
  BORDER_COLOR: "#959899",
  FIDO_BLUE_TEXT: "#667DBB",
};

export { Colors };
