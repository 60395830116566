import React, { useState } from 'react'

import { Modal, Button } from '@mui/material'
import MDBox from 'components/MDBox'
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import Loading from 'components/Loading'
import { useGenerateExtractPDF } from './hocks/useGenerateExtractPDF'
import { useGenerateExtractCSV } from './hocks/useGenerateExtractCSV'

import styles from './styles.module.css'
import { Colors } from 'common/Colors'

function ModalGenerateExtract({
  isOpenModal,
  closeModal,
  transactionsPerSelectedMonth,
  investmentsPerMonthAndYear,
  selectedMounth,
  selectedYear
}) {
  const [isActiveLoading, setIsActiveLoading] = useState(false)

  const handleDownloadPDF = () => {
    useGenerateExtractPDF(
      transactionsPerSelectedMonth,
      investmentsPerMonthAndYear,
      selectedMounth,
      selectedYear
    );
  };

  const handleDownloadCSV = () => {
    useGenerateExtractCSV(
      transactionsPerSelectedMonth,
      investmentsPerMonthAndYear,
      selectedMounth,
      selectedYear
    );
  };

  return (
    <>
      {isActiveLoading
        ? (<Loading />)
        : (<Modal
          open={isOpenModal}
          closeModal={closeModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <MDBox
            minHeight="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MDBox
              component="aside"
              width="min(40rem, 90%)"
              maxHeight="85vh"
              bgColor="white"
              color="black"
              borderRadius="0.5rem"
              shadow="lg"
              overflow="auto"
              alignItems="center"
              justifyContent="center"
            >
              <div className={styles.closeButton}>
                <IconButton size="small" aria-label="close" color="inherit" onClick={closeModal}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              </div>
              <div className={styles.modalTitle}>
                <h2 className={styles.title}>Selecione o formato do seu extrato</h2>
              </div>
              <div className={styles.modalButtons}>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={handleDownloadPDF}
                  sx={{
                    width: '10rem',
                    alignSelf: 'center',
                    color: Colors.WHITE,
                    backgroundColor: Colors.FIDO_SECONDARY_BLUE,
                    borderColor: Colors.FIDO_SECONDARY_BLUE,
                    '&:hover': {
                      backgroundColor: Colors.FIDO_SECONDARY_BLUE,
                      borderColor: Colors.FIDO_SECONDARY_BLUE,
                    },
                  }}
                >
                  Baixar PDF
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={handleDownloadCSV}
                  sx={{
                    width: '10rem',
                    alignSelf: 'center',
                    color: Colors.WHITE,
                    borderColor: Colors.MINT_GREEN,
                    backgroundColor: Colors.MINT_GREEN,
                    '&:hover': {
                      backgroundColor: Colors.LIGHTER_GREEN,
                      borderColor: Colors.LIGHTER_GREEN,
                    },
                  }}
                >
                  Baixar CSV
                </Button>
              </div>
            </MDBox>
          </MDBox>
        </Modal>)
      }
    </>
  )
}

export { ModalGenerateExtract }