import React from "react";

import styles from "./styles.module.css";
import userWithPendingDocumentationIllustration from "../../../../../assets/images/illustrations/user-with-pending-documentation.svg";

function ModalInitial({
  closeModal,
  reprovedAccountStatusAsaas,
  reprovedAccountStatusMetaMap,
}) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>Olá Investidor</h2>
        <h3 className={styles.subtitle}>
          Identificamos algum tipo de problema no seu cadastro.
        </h3>
        <img
          src={userWithPendingDocumentationIllustration}
          alt="Usuário com pendência na documentação"
          className={styles.illustration}
        />
        <p className={styles.textBody16}>
          Encontramos problemas nos seguinte itens:
        </p>
        {reprovedAccountStatusAsaas && (
          <strong className={styles.pendingItem}>Dados bancários</strong>
        )}
        {reprovedAccountStatusMetaMap && (
          <strong className={styles.pendingItem}>
            Validação de documentos
          </strong>
        )}
        <p className={styles.textDescription}>
          Se você acredita que possa ter havido um equívoco, entre em contato
          conosco pelo chat.
        </p>
        <button className={styles.primaryButton} onClick={closeModal}>
          Resolver problemas
        </button>
      </aside>
    </div>
  );
}

export { ModalInitial };
