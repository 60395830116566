import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import styles from "../../styles.module.css";
import exclamationIcon from "assets/images/icons/exclamation.svg";

function StepConfirmedWithdraw({
  bankCode,
  agency,
  account,
  accountDigit,
  amountWithdraw,
  handleClickConfirmedWithdraw,
  loading,
}) {
  return (
    <MDBox display="flex" flexDirection="column" alignItems="center">
      <MDBox mt={3} display="flex" gap="1rem" alignItems="center" px={2}>
        <img src={exclamationIcon} alt="Exclamação" />
        <MDTypography
          variant="body1"
          sx={{ color: "#FA5050", textAlign: "center" }}
        >
          Quer mesmo fazer a transferência?
        </MDTypography>
      </MDBox>
      <MDBox
        mt={3}
        width="100%"
        flex="1"
        bgColor="#F2F4F5"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MDBox width="80%">
          <MDTypography
            mt={4}
            variant="body1"
            fontWeight="light"
            sx={{ color: "#3D555A" }}
          >
            A transferência ficará assim:
          </MDTypography>

          <MDBox mt={3} display="flex" flexDirection="column" gap="0.25rem">
            <MDTypography variant="body2" sx={{ color: "#3D555A" }}>
              Banco: <strong>{bankCode}</strong>
            </MDTypography>
            <MDTypography variant="body2" sx={{ color: "#3D555A" }}>
              Agência: <strong>{agency}</strong>
            </MDTypography>
            <MDTypography variant="body2" sx={{ color: "#3D555A" }}>
              Conta corrente:{" "}
              <strong>
                {account}-{accountDigit}
              </strong>
            </MDTypography>
            <MDTypography variant="body2" sx={{ color: "#3D555A" }}>
              Favorecido: <strong>Mesma titularidade</strong>
            </MDTypography>
            <MDTypography variant="body2" sx={{ color: "#3D555A" }}>
              Valor: <strong>R${amountWithdraw}</strong>
            </MDTypography>
          </MDBox>
        </MDBox>

        <button
          className={`${styles.button} ${styles.confirmedWithdrawButton}`}
          onClick={handleClickConfirmedWithdraw}
          disabled={loading}
        >
          Transferir
        </button>
      </MDBox>
    </MDBox>
  );
}

export { StepConfirmedWithdraw };
