import { api } from "./api";

async function downloadContract(token, takerCpf) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/investor/contracts/pdf",
      { taker_cpf: takerCpf },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function sendContractByEmail(token, takerCpf) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/investor/contracts/pdf",
      { taker_cpf: takerCpf, send_email: true },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getContracts(token, limit = 10, page = 1) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.get("/investor/contracts", {
      headers: { Authorization: tokenWithoutQuotes },
      params: { limit, page },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export { downloadContract, sendContractByEmail, getContracts };
