function setStoredValue(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return error;
  }
}

function getStoredValue(key) {
  try {
    var token = window.localStorage.getItem(key);
    window.localStorage.setItem(key, token);
    return token;
  } catch (error) {
    return error;
  }
}

function clearStoredValues() {
  // Limpa todos os dados salvos
  window.localStorage.clear();
  return true;
}

function removeStoredValue(key) {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return error;
  }
}

export { setStoredValue, getStoredValue, removeStoredValue, clearStoredValues };
