import React from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import BankItem from "../../../BankItem";

import styles from "../../styles.module.css";
import plusIcon from "assets/images/icons/plus.svg";

function StepInital({
  loggedUser,
  handleClickBankItem,
  handleClickAddAccount,
}) {
  return (
    <>
      <MDTypography
        variant="body1"
        fontWeight="light"
        sx={{
          fontSize: "1.25rem",
          lineHeight: "calc(1.25rem * 1.5)",
          color: "#3D555A",
          width: "80%",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        Você pode transferir o dinheiro da carteira Fido para qualquer conta
        bancária de sua titularidade.
      </MDTypography>
      <MDBox
        mt={3}
        bgColor="#F2F4F5"
        flex="1"
        p={2}
        borderRadius="lg"
        display="flex"
        flexDirection="column"
      >
        {loggedUser?.bankingDetails && (
          <MDBox display="flex" flexDirection="column" gap="1rem">
            <BankItem
              handleClickBankItem={handleClickBankItem}
              userBank={loggedUser?.bankingDetails}
            />
          </MDBox>
        )}
        <button
          className={styles.buttonAddBank}
          title="Adicionar banco"
          onClick={handleClickAddAccount}
        >
          <img src={plusIcon} alt="Adicionar" />
          Adicionar
        </button>
      </MDBox>
    </>
  );
}

export { StepInital };
