import React from "react";

import MDBox from "components/MDBox";

import CardCustomizeInvestment from "../../components/CardCustomizeInvestment";
import CardTotalBalance from "../../components/CardTotalBalance";

function SectionCustomizeInvestment({ setCurrentContentHTML, wallet }) {
  return (
    <MDBox mt={3} display="flex" flexDirection="column" gap="2rem">
      <CardTotalBalance wallet={wallet} />
      <CardCustomizeInvestment setCurrentContentHTML={setCurrentContentHTML} />
    </MDBox>
  );
}

export default SectionCustomizeInvestment;
