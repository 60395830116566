import React, { useEffect, useState } from "react";

import MDBox from "../../../../../components/MDBox";

// import { getUserBankDetails } from "../../../../../services/user";
import { getBankList } from "../../../../../services/bank";

import styles from "./styles.module.css";
import { Colors } from "common/Colors";

function CardAccount({ loggedUser, setIsLoadingBankList }) {
  const [userBankDetails, setUserBankDetails] = useState(undefined);
  const [banksList, setBanksList] = useState([]);
  const [userBank, setUserBank] = useState(null);

  function joinBanks(banks) {
    const allBanks = [...banks?.top_banks, ...banks?.others];
    return allBanks;
  }

  useEffect(() => {
    const bankListStoraged = sessionStorage.getItem("bankList");

    async function loadApiData() {
      try {
        setIsLoadingBankList(true);

        const dataBankList = await getBankList();
        const allBanks = joinBanks(dataBankList);
        setBanksList(allBanks);

        sessionStorage.setItem("bankList", JSON.stringify(dataBankList));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingBankList(false);
      }
    }

    if (!bankListStoraged) {
      loadApiData();
      return;
    }

    const allBanks = joinBanks(JSON.parse(bankListStoraged));
    setBanksList(allBanks);
  }, []);

  useEffect(() => {
    if (!loggedUser) return;

    setUserBankDetails(loggedUser.asaasAccount);
  }, [loggedUser]);

  useEffect(() => {
    if (!banksList?.length) return;

    const filteredBanks = banksList.filter(
      (bank) => bank?.code == userBankDetails?.bankCode
    );

    if (!filteredBanks?.length) {
      setUserBank(null);
      return;
    }

    const [userBank] = filteredBanks;
    setUserBank(userBank);
  }, [banksList, userBankDetails]);

  return (
    <MDBox
      width="100%"
      height="100%"
      color={Colors.WHITE}
      bgColor={Colors.WHITE}
      borderRadius="lg"
      shadow="lg"
      p={2}
    >
      <div>
        <h3 className={styles.title}>Dados da sua conta</h3>
      </div>
      <div className={styles.dataBank}>
        <strong className={styles.name}>
          {loggedUser?.fullname || "Não informado"}
        </strong>
        <p className={styles.dataItem}>
          Banco:{" "}
          <strong>
            {userBank
              ? `${userBank?.code} - ${userBank?.bank}`
              : "Não informado"}
          </strong>
        </p>
        <p className={styles.dataItem}>
          Agência: <strong>{userBankDetails?.agency || "Não informado"}</strong>
        </p>
        <p className={styles.dataItem}>
          Conta: <strong>{userBankDetails?.account || "Não informado"}</strong>
        </p>
        <p className={styles.dataItem}>
          Dígito:{" "}
          <strong>{userBankDetails?.accountDigit || "Não informado"}</strong>
        </p>
      </div>
    </MDBox>
  );
}

export default CardAccount;
