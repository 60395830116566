const moment = require("moment");

const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

const cpfCnpjMask = (v) => {
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.substring(0, 14); // limita em 14 números
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
};

const moneyMask = (value) => {
  value = value.toString();
  value = value.replace("R$ ", "");
  if (/^0./.test(value)) {
    value = parseInt(value.replace("0.", ""));
    if (isNaN(value)) {
      return "R$ ";
    }
    value = value.toString();
  }
  if (value.length == 1) {
    return "R$ " + "0.0" + value;
  }
  if (value.length == 2) {
    return "R$ " + "0." + value;
  }
  if (value.length == 3 && value.includes(".")) {
    value = value.replace(/(\d+).(\d+)/, "0.$1");
    return "R$ " + value;
  }

  value = value.replace(".", "");
  var f = value.slice(-2);
  value = value.slice(0, value.length - 2);
  value = value.replaceAll(",", "");
  if (value.length > 3) {
    var finalValue = "";
    var value = value.split("").reverse().join("");
    for (let index = 0; index < value.length; index++) {
      if (index % 3 === 0 && index != 0) {
        finalValue += "," + value[index];
      } else {
        finalValue += value[index];
      }
    }
    value = finalValue.split("").reverse().join("");
  }
  value = value + "." + f;
  return "R$ " + value;
};

const moneyMaskFormatPTBR = (value) => {
  value = value.toString();
  const newValue = value.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  const formattedValue = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(newValue) / 100
  );

  return `R$ ${formattedValue}`;
};

const dateMask = (date = "") => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    const [ano, mes, dia] = date.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  return date.length < 10
    ? date
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
    : date.substring(0, 10);
};

const validateDate = (date) => {
  try {
    date = moment(date, "DD/MM/YYYY");
    var now = moment();
    var valid_min = moment.min(now.subtract(1, "day"), date) === now;
    var valid_max = moment.max(now.add(2, "months"), date) === now;
    return valid_max && valid_min;
  } catch (error) {
    return false;
  }
};

const percentMask = (value) => {
  value = value.toString();
  if (value.includes("0.")) {
    value = parseInt(value.replace("0.", ""));
    if (isNaN(value)) {
      return "";
    }
    value = value.toString();
  }
  if (value.length == 1) {
    return "0.0" + value;
  }
  if (value.length == 2) {
    return "0." + value;
  }
  if (value.length == 3 && value.includes(".")) {
    value = value.replace(/(\d+).(\d+)/, "0.$1");
    return value;
  }
  value = value.replace(".", "");
  value = value.slice(0, value.length - 2) + "." + value.slice(-2);
  return value;
};

function phoneNumberMask(value) {
  let phoneNumber = value.replace(/\D/g, "");
  phoneNumber = phoneNumber.replace(/^0/, "");

  if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (phoneNumber.length > 5) {
    phoneNumber = phoneNumber.replace(
      /^(\d\d)(\d{4})(\d{0,4}).*/,
      "($1) $2-$3"
    );
  } else if (phoneNumber.length > 2) {
    phoneNumber = phoneNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    phoneNumber = phoneNumber.replace(/^(\d*)/, "($1");
  }
  return phoneNumber;
}

export {
  cpfMask,
  cpfCnpjMask,
  moneyMask,
  dateMask,
  percentMask,
  validateDate,
  phoneNumberMask,
  moneyMaskFormatPTBR,
};
