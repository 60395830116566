import { api } from "./api";

async function getMessages(token) {
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    const response = await api.get("/notifications", {
      headers: { Authorization: tokenWithoutQuotes },
      params: { accountTypes: 3 },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function readMessage(messageId = "") {
  let response = undefined;
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    response = await api.post(
      `/notifications/read/${messageId}`,
      {},
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export { getMessages, readMessage };
