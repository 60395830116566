import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";
import mainPageStyles from "../../styles.module.css";

import errorIcon from "../../../../../assets/images/icons/error-circle.svg";
import closeIcon from "../../../../../assets/images/icons/close-white.svg";


function ModalEmailExists({ closeModalEmailExists, email }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={`${mainPageStyles.h24} ${styles.title}`}>
            E-mail já cadastrado
          </h2>
          <button
            title="Fechar modal"
            className={styles.closeButton}
            onClick={closeModalEmailExists}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <section className={styles.mainContent}>
          <div className={styles.containerIcon}>
            <img
              src={errorIcon}
              alt="Sinal de exclamação em volta de um círculo"
            />
          </div>

          <p className={`${mainPageStyles.b16} ${styles.message}`}>
            O <strong>{email}</strong> já possui cadastro na nossa base de
            dados, tente outro e-mail ou <Link to="/login">faça login</Link>
          </p>
        </section>
      </aside>
    </div>
  );
}

export default ModalEmailExists;
