import React, { useRef } from "react";
import { useMediaQuery } from "@mui/material";

import styles from "./styles.module.css";
import arrowLeft from "assets/images/icons/chevron-left.svg";
import arrowRight from "assets/images/icons/chevron-right.svg";

function Pagination({ pagination, setPagination }) {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const containerButtonsRef = useRef(null);
  const maxPagesView = isMobile ? 3 : 5;
  const totalPages = pagination?.totalPages || 1;
  const pages = Array.from({ length: totalPages }, (_, page) => page + 1) || [];
  const hasInitialPage = pagination?.page === 1;
  const hasLastPage = pagination?.page === totalPages;

  const goToPage = (page = 1) => setPagination({ ...pagination, page });

  function nextPage() {
    if (hasLastPage) return;

    goToPage(pagination?.page + 1);

    if (pagination?.page > maxPagesView - 1) {
      containerButtonsRef.current.scrollLeft += 48;
    }
  }

  function previousPage() {
    if (hasInitialPage) return;

    goToPage(pagination?.page - 1);

    if (pagination?.page < maxPagesView + 1) {
      containerButtonsRef.current.scrollLeft -= 48;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentCenter}>
        <button
          className={`${styles.buttonPage} ${
            hasInitialPage ? styles.buttonPageDisabled : ""
          }`}
          title={hasInitialPage ? "" : "Voltar página"}
          onClick={previousPage}
        >
          <img src={arrowLeft} alt="Seta para esquerda" />
        </button>
        <div className={styles.containerButtons} ref={containerButtonsRef}>
          <ul className={styles.listButtons}>
            {pages.map((page) => (
              <li
                key={String(page)}
                className={`${styles.buttonPage} ${
                  page === pagination?.page
                    ? styles.buttonPageActive
                    : styles.buttonPageNotActive
                }`}
                role="button"
                onClick={() => goToPage(page)}
                title={`Ir para a página ${page}`}
              >
                {page}
              </li>
            ))}
          </ul>
        </div>
        <button
          className={`${styles.buttonPage} ${
            hasLastPage ? styles.buttonPageDisabled : ""
          }`}
          title={hasLastPage ? "" : "Avançar página"}
          onClick={nextPage}
        >
          <img src={arrowRight} alt="Seta para direita" />
        </button>
      </div>
    </div>
  );
}

export { Pagination };
