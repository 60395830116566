import React from "react";

import styles from "./styles.module.css";

import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";

function CardCustomInvestmentDetails({ setCurrentContentHTML }) {
  return (
    <div className={styles.card}>
      <div className={styles.contentTop}>
        <button
          title="Voltar para a seção anterior"
          className={styles.backButton}
          onClick={() => setCurrentContentHTML("formDeadline")}
        >
          <img src={arrowLeftIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${styles.h2} ${styles.title}`}>
          A sua escolha ficou assim:
        </h2>
        <div className={styles.circle}></div>
        <div className={styles.containerInvestedAmount}>
          <p>Investimento total</p>
          <h3 className={`${styles.h2}`}>R$4.000,00</h3>
        </div>
      </div>
      <div className={styles.contentBottom}>
        <p className={styles.dataText}>Prazo de 6 a 12 meses</p>
        <p className={styles.dataText}>
          Juros pagos pelas pessoas <strong className={styles.plus}>+</strong>{" "}
          30% a.a
        </p>
        <p className={styles.dataText}>
          Inadimplência esperada <strong className={styles.less}>+</strong> 10%{" "}
          a.a
        </p>
        <p className={styles.dataText}>
          <strong>Rentabilidade esperada = 20% a.a</strong>
        </p>

        <button
          className={styles.primaryButton}
          onClick={() => setCurrentContentHTML("confirmedInvestment")}
        >
          Investir
        </button>
        <button className={styles.textButton}>Editar</button>
      </div>
    </div>
  );
}

export default CardCustomInvestmentDetails;
