import styles from "./styles.module.css";

function ModalCreateCodeInfo({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>Olá Investidor</h2>
        <p className={styles.instructions}>
          Siga as instruções para criar um código do empresta fácil.
        </p>
        <p className={styles.description}>
          <strong>1.</strong> Crie um novo código do empresta fácil e atribua as
          informações relacionadas a ele, como valor mínimo e máximo de
          empréstimo, valor mínimo e máximo de parcelas, e taxa de juros de
          investidor.
        </p>
        <p className={styles.description}>
          <strong>2.</strong> Compartilhe o código do empréstimo com o tomador
          de empréstimo.
        </p>
        <p className={styles.description}>
          <strong>3.</strong> Oriente o tomador de empréstimo a acessar a Google
          Play em seu smartphone.
        </p>
        <p className={styles.description}>
          <strong>4.</strong> Na barra de pesquisa, digite "FIDO Empréstimos" e
          pressione enter.
        </p>
        <p className={styles.description}>
          <strong>5.</strong> Localize o aplicativo FIDO Empréstimos na lista de
          resultados e clique em "Instalar".
        </p>
        <p className={styles.description}>
          <strong>6.</strong> Aguarde a conclusão da instalação e, em seguida,
          abra o aplicativo.
        </p>
        <p className={styles.description}>
          <strong>7.</strong> Insira o código do empréstimo no início do fluxo
          de cadastro.
        </p>
        <p className={styles.description}>
          <strong>8.</strong> Prossiga com o processo de cadastro conforme as
          instruções no aplicativo.
        </p>
        <button className={styles.primaryButton} onClick={closeModal}>
          Estou ciente
        </button>
      </aside>
    </div>
  );
}

export { ModalCreateCodeInfo };
