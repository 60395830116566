/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React Components
import Loading from "components/Loading";
import MDAlert from "components/MDAlert";

import ReCAPTCHA from "react-google-recaptcha";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { cpfCnpjMask } from "utils/mask";
import { api } from "services/api";
import {
  getAccountStatusAsaas,
  resultDocumentationValidation,
  userExists,
} from "services/user";
import { setStoredValue, clearStoredValues } from "metrics/storage";

// Images
import logo from "assets/images/Logos_Fido/horizontal_branca_fundo_azul.png";

const CONSTANTS = {
  ARRAY_ACCOUNT_STATUS_APPROVED: [
    "verification_updated",
    "verification_completed",
  ],
};

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef(null);

  const isProd =
    window.location.hostname === "app.fido.com.vc" ||
    window.location.hostname ===
    "simulador-direto-investimento.homolog.fido.com.vc";
  const SITE_KEY_PROD = "6Lfu2GofAAAAAHZrRud_poeEiG2JhrVqBfRBGVwA";
  const SITE_KEY_DEV = "6LfPVKEgAAAAAIR4WMtEpw3GqMeXdilYG3hVWh9X";

  let navigate = useNavigate();

  const getRecaptchaToken = async () => {
    const captchaToken = await recaptchaRef.current.getValue();
    return captchaToken;
  };

  const userHasDocumentationValidated = (reportMati, reportAsaas) =>
    reportAsaas.general === "APPROVED" &&
    reportMati?.approved &&
    CONSTANTS.ARRAY_ACCOUNT_STATUS_APPROVED.some(
      (statusItem) => statusItem === reportMati?.last_status
    );

  const userHasReprovedDocumentation = (reportMati, reportAsaas) =>
    reportAsaas.general === "REJECTED" ||
    reportAsaas.bankAccountInfo === "REJECTED" ||
    (reportMati?.last_status !== "verification_updated" &&
      reportMati?.last_status !== "verification_completed" &&
      reportMati?.last_status !== "verification_inputs_completed") ||
    reportMati?.approved === false;

  const login = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    var captchaToken = await getRecaptchaToken();

    const expirationTime = new Date(Date.now() + 3600000); // 3600000ms = 1h
    
    let data = {
      cpf: cpf,
      password: password,
      useRecaptcha: true,
      captchaToken: captchaToken,
    };

    api
      .post("/investor/login", data)
      .then(async (response) => {
        const loggedUser = response.data;
        const { token, refresh_token } = loggedUser;
        let path = "/";

        setStoredValue("token", token);
        localStorage.setItem("expirationTimeSession", expirationTime.getTime());
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("refreshToken", JSON.stringify(refresh_token));

        try {
          const responseCpfExists = await userExists(cpf);

          if (responseCpfExists.data?.finish_register) {
            const responseAccountStatusAsaas = await getAccountStatusAsaas(cpf);
            const responseMatiReport = await resultDocumentationValidation(
              token
            );

            sessionStorage.setItem(
              "accountStatusInfo",
              JSON.stringify({
                asaas: responseAccountStatusAsaas.data,
                metamap: responseMatiReport.data,
              })
            );
            if (
              userHasDocumentationValidated(
                responseMatiReport.data,
                responseAccountStatusAsaas.data
              )
            ) {
              path = "/carteira";
              sessionStorage.setItem("userLoggedIn", true);
              sessionStorage.setItem("userStatus", "approved");
              handleLogoutTimer();
            } else if (
              userHasReprovedDocumentation(
                responseMatiReport.data,
                responseAccountStatusAsaas.data
              )
            ) {
              sessionStorage.setItem("loggedUser", JSON.stringify(loggedUser));
              sessionStorage.setItem("userStatus", "rejected");
              path = "/reenvio-documentos";
            } else {
              path = "/aguardar-validacao-dados";
              sessionStorage.setItem("userStatus", "pending");
            }
          } else {
            setStoredValue("registrationStarted", true);
            setStoredValue("userData", loggedUser);
            sessionStorage.setItem("loggedUser", JSON.stringify(loggedUser));
            path = "/criar-conta";
          }
        } catch (error) {
          console.error(error);
          console.error("Erro ao buscar por status da validacao de documentos");
        } finally {
          navigate(path);
        }
      })
      .catch((error) => {
        console.log(error.response?.status);
        recaptchaRef.current.reset();
        if (error.response.status == 400) {
          setError(true);
          setMsgError("Confirme o reCAPTCHA.");
          return false;
        }
        if (error.response.status == 401) {
          setError(true);
          setMsgError("Credenciais inválidas.");
          return false;
        }
        setError(true);
        setMsgError("Algo inesperado aconteceu.");
        return false;
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    sessionStorage.clear();
    // clearStoredValues();
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTimeSession");
    localStorage.removeItem("refreshToken");
  }, []);

  const handleLogoutTimer = () => {
    setTimeout(() => {
      alert("Sessão expirada\npara continuar faça o login novamente");
      navigate("/login");
    }, localStorage.getItem("expirationTimeSession") - new Date(Date.now()));
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="secondary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FIDO
          </MDTypography> */}
          <MDBox
            component="img"
            src={logo}
            alt="Logo da FIDO"
            borderRadius="lg"
            // shadow="sm"
            width="40%"
            height="40%"
          // position="relative"
          // zIndex={10}
          // mb={2}
          />
        </MDBox>
        <MDBox pt={6} pb={3} px={2}>
          <MDBox component="form" role="form" onSubmit={login}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                autoComplete="off"
                value={cpf}
                onChange={(event) => {
                  setCpf(cpfCnpjMask(event.target.value));
                }}
                label="CPF/CNPJ"
                required
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                label="Senha"
                required
                fullWidth
              />
            </MDBox>
            <MDBox mb={2} width="100%" display="flex" justifyContent="flex-end">
              <MDTypography
                component={Link}
                to="/esqueceu-senha"
                variant="button"
                fontWeight="medium"
                color="info"
              // sx={{ color: '#6f6b6b' }}
              >
                Esqueceu a senha?
              </MDTypography>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="center" width="100%">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={isProd ? SITE_KEY_PROD : SITE_KEY_DEV}
                hl="pt-br"
                size="compact"
              />
            </MDBox>
            <MDBox mt={error ? 2 : 4} mb={1}>
              {error && (
                <MDBox mt={2} mb={1}>
                  <MDAlert
                    color="error"
                    dismissible
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: "calc(0.875rem * 1.5)",
                    }}
                  >
                    {msgError}
                  </MDAlert>
                </MDBox>
              )}

              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                //onClick={login}
                fullWidth
                disabled={isLoading}
              >
                Login
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Não possui conta?{" "}
              <MDTypography
                component={Link}
                to="/criar-conta"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Cadastre-se
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
      {isLoading && <Loading />}
    </BasicLayout>
  );
}

export default Basic;
