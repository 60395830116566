import React from "react";

import { Tag } from "../Tag";

import { readMessage } from "services/messages";
import { formateDateWithOutHours } from "utils";

import styles from "./styles.module.css";

function CardMessage({
  dataMessage,
  openModal,
  setSelectedMessage,
  disableLoading,
  activeLoading,
  messages,
  setMessages,
  dispatch,
}) {
  function updateMessages() {
    const message = { ...dataMessage, read: true };
    setSelectedMessage(message);

    let _messages = messages?.filter(
      (messageItem) => messageItem?._id?.$oid !== dataMessage?._id?.$oid
    );
    _messages = [..._messages, message];
    setMessages(dispatch, _messages);
  }

  async function sendMessageRead() {
    activeLoading();

    try {
      const response = await readMessage(dataMessage?._id?.$oid);

      if (response?.status !== 200) {
        throw new Error("");
      }

      updateMessages();
    } catch (error) {
      console.error(error);
      console.error("Erro ao dar mensagem como lida");
    } finally {
      disableLoading();
    }
  }

  async function clickOpenMessage() {
    if (!dataMessage?.read) {
      await sendMessageRead();
    }
    setSelectedMessage(dataMessage);
    openModal();
  }

  return (
    <article
      className={`${styles.cardMessage} ${
        dataMessage?.read
          ? styles.cardMessageReadMessage
          : styles.cardMessageUnreadMessage
      }`}
      onClick={clickOpenMessage}
      title="Clique para ver a mensagem completa"
      role="button"
    >
      <div className={styles.cardMessageHeader}>
        <Tag type={dataMessage?.tag} isDisabled={dataMessage?.read} />
        <span className={styles.cardMessageUpdatedAt}>
          {formateDateWithOutHours(dataMessage?.init_date?.$date)}
        </span>
      </div>
      <h3 className={styles.cardMessageTitle}>{dataMessage?.title}</h3>
      <div className={styles.cardContainerMessageDescription}>
        <p className={styles.cardMessageDescription}>
          {dataMessage?.description}
        </p>
      </div>
    </article>
  );
}

export { CardMessage };
