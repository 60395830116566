import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MDBox from "../../../../../components/MDBox";
import { Alert, Snackbar } from "@mui/material";
import Loading from "components/Loading";
import { StepInital } from "./components/StepInitial";
import { StepFormPix } from "./components/StepFormPix";
import { StepChooseBank } from "./components/StepChooseBank";
import { StepFormAccount } from "./components/StepFormAccount";
import { StepFormAmount } from "./components/StepFormAmount";
import { StepConfirmedWithdraw } from "./components/StepConfirmedWithdraw";
import { StepConfirmedSmsCode } from "./components/StepConfirmedSmsCode";
import { StepConfirmedPassword } from "./components/StepConfirmedPassword";
import { StepLast } from "./components/StepLast";

import { getBankList } from "services/bank";
import { getWalletData } from "services/investments";
import { sendCodeBySMS, confirmCodeBySMS, login } from "services/user";
import { requestWithdraw } from "services/payments";
import { getStoredValue } from "metrics/storage";

import styles from "./styles.module.css";
import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";
import updateIcon from "../../../../../assets/images/icons/update.svg";

const CONSTANTS = {
  MOBILE_SCREEN_DIMENSIONS: 550,
  TABLET_SCREEN_DIMENSIONS: 768,
  MAX_AMOUNT_WITHDRAW: 15000,
  STATUS_CODE_SUCCESS: 200,
  SECONDS_TIME_SEND_SMS_CODE: 30,
  OPERATION_CAIXA_ECONOMICA_FEDERAL: 104,
};

function CardWithdraw({ setCurrentRenderedContent, loggedUser, wallet }) {
  const isTablet = useMediaQuery(
    `(max-width: ${CONSTANTS.TABLET_SCREEN_DIMENSIONS}px)`
  );
  const isMobile = useMediaQuery(
    `(max-width: ${CONSTANTS.MOBILE_SCREEN_DIMENSIONS}px)`
  );

  const [banksList, setBanksList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(0);
  const [smsCode, setSmsCode] = useState("");
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [currentPath, setCurrentPath] = useState([]);
  const [hasDataPix, setHasDataPix] = useState(true);
  const [accountType, setAccountType] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [inputComboBoxBank, setInputComboBoxBank] = useState("");
  const [agency, setAgency] = useState("");
  const [account, setAccount] = useState("");
  const [accountDigit, setAccountDigit] = useState("");
  const [amountWithdraw, setAmountWithdraw] = useState("0,00");
  const [operation, setOperation] = useState("");
  const [pix, setPix] = useState("");
  const [timeSmsCode, setTimeSmsCode] = useState(
    CONSTANTS.SECONDS_TIME_SEND_SMS_CODE
  );
  // const [valueProgressBarSmsCode, setValueProgressBarSmsCode] = useState(0);
  const [isActiveIimeSmsCode, setIsActiveTimeSmsCode] = useState(false);
  const [isPasswordView, setIsPasswordView] = useState(false);
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  let countdownSmsCode = undefined;
  const stepsPath = {
    bank: [
      "initial",
      "formAmount",
      "confirmedWithdraw",
      "confirmedSmsCode",
      "confirmedPassword",
      "last",
    ],

    addAccount: [
      "initial",
      "formAccount",
      "formAmount",
      "confirmedWithdraw",
      "confirmedSmsCode",
      "confirmedPassword",
      "last",
    ],

    doNotHavePix: [
      "initial",
      "formPix",
      "chooseBank",
      "formAccount",
      "formAmount",
      "confirmedWithdraw",
      "confirmedSmsCode",
      "confirmedPassword",
      "last",
    ],

    default: ["initial"],
  };

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlert({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  function previousStep() {
    if (currentStep === 0) {
      setCurrentRenderedContent("initial");
    }

    setCurrentStep(currentStep - 1);
  }

  function nextStep() {
    setCurrentStep(currentStep + 1);
  }

  function handleClickBankItem() {
    setSelectedBank(loggedUser?.bankingDetails?.bankCode);
    setBankCode(loggedUser?.bankingDetails?.bankCode);
    setAgency(loggedUser?.bankingDetails?.agency);
    setAccountType(loggedUser?.bankingDetails?.bankAccountType);
    setAccount(loggedUser?.bankingDetails?.account);
    setAccountDigit(loggedUser?.bankingDetails?.accountDigit);

    setCurrentPath([...stepsPath.bank]);

    nextStep();
  }

  function handleSubmitFormAmount(event) {
    event.preventDefault();

    nextStep();
  }

  async function handleSubmitFormConfirmedSmsCode(event) {
    event.preventDefault();

    // startTimeSmsCode();
    setLoading(true);

    const response = await confirmCodeBySMS(loggedUser.email, smsCode);

    setLoading(false);

    if (response?.status == CONSTANTS.STATUS_CODE_SUCCESS) {
      nextStep();
    } else if (response?.status === 403) {
      window.alert("Infelizmente sua sessão expirou. Faça login novamente");
      navigate("/login");
    } else {
      setAlert({
        isOpen: true,
        message: "Código incorreto. Tente novamente.",
        type: "error",
      });
      console.error("sms code error, invalid token");
    }
  }

  async function sendSmsCode() {
    startTimeSmsCode();

    try {
      const response = await sendCodeBySMS(loggedUser.phone, loggedUser.email);

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response?.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      console.error("Erro ao enviar código via SMS");
      setAlert({
        isOpen: true,
        message: "Erro ao enviar código via SMS. Tente novamente.",
        type: "error",
      });
    }
  }

  async function handleSubmitFormConfirmedPassword(event) {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await login(loggedUser.cpf, password);

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
      } else if (response?.status == CONSTANTS.STATUS_CODE_SUCCESS) {
        const response_withdraw = await withdraw();

        if (response_withdraw?.status === 403) {
          window.alert("Infelizmente sua sessão expirou. Faça login novamente");
          navigate("/login");
        } else if (response_withdraw?.status === CONSTANTS.STATUS_CODE_SUCCESS) {
          nextStep();
        }
        else {
          throw new Error();
        }
      } else {
        console.error("password error, invalid password");
        setAlert({
          isOpen: true,
          message: "Senha incorreta. Tente novamente.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Erro ao realizar saque", error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmitFormPix(event) {
    event.preventDefault();

    nextStep();
  }

  function handleSubmitFormChooseBank(event) {
    event.preventDefault();

    nextStep();
  }

  async function handleSubmitFormAccount(event) {
    event.preventDefault();

    if (
      (agency != "") &
      (account != "") &
      (accountDigit != "") &
      (bankCode != "")
    ) {
      setLoading(true);

      try {
        const token = getStoredValue("token").replace(/['"]+/g, "");
        const response = await getWalletData();

        if (response?.status === 403) {
          window.alert("Infelizmente sua sessão expirou. Faça login novamente");
          navigate("/login");
          throw new Error("Token expirado");
        } else if (response?.status !== 200) {
          throw new Error();
        }

        const _wallet = response.data;
        setAvailable(_wallet.my_wallet.available);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
      nextStep();
    }
  }

  async function handleClickConfirmedWithdraw() {
    setLoading(true);
    setLoading(false);
    nextStep();
  }

  function handleClickAddAccount() {
    setCurrentPath([...stepsPath.addAccount]);

    nextStep();
  }

  function handleClickDoNotHavePix() {
    setCurrentPath([...stepsPath.doNotHavePix]);

    nextStep();
  }

  function handleClickGoHome() {
    setCurrentRenderedContent("initial");
  }

  function handlePasswordView() {
    setIsPasswordView(!isPasswordView);
  }

  function startTimeSmsCode() {
    setTimeSmsCode(CONSTANTS.SECONDS_TIME_SEND_SMS_CODE);
    setIsActiveTimeSmsCode(true);
  }

  async function withdraw() {
    setLoading(true);

    try {
      const numericValue = parseFloat(amountWithdraw.replace(/[^\d]/g, '')) || 0;
      const formattedAmountWithdraw = (numericValue / 100).toFixed(2);

      const response = await requestWithdraw(
        formattedAmountWithdraw,
        bankCode,
        agency,
        account,
        accountDigit
      );

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
      } else if (response?.status !== 200) {
        if (response?.error) {
          setAlert({
            isOpen: true,
            message: response?.error,
            type: "error",
          });
        } else {
          throw new Error();
        }
      }

      return response;
    } catch (error) {
      console.error("Erro ao realizar saque", error);
      setAlert({
        isOpen: true,
        message: 'Erro ao realizar saque. Entre em contato com o suporte.',
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const steps = {
    initial: () => (
      <StepInital
        loggedUser={loggedUser}
        handleClickBankItem={handleClickBankItem}
        handleClickAddAccount={handleClickAddAccount}
      />
    ),

    formPix: () => (
      <StepFormPix
        pix={pix}
        setPix={setPix}
        handleSubmitFormPix={handleSubmitFormPix}
        handleClickDoNotHavePix={handleClickDoNotHavePix}
        hasDataPix={hasDataPix}
        isMobile={isMobile}
      />
    ),

    chooseBank: () => (
      <StepChooseBank
        isMobile={isMobile}
        handleSubmitFormChooseBank={handleSubmitFormChooseBank}
      />
    ),

    formAccount: () => (
      <StepFormAccount
        handleSubmitFormAccount={handleSubmitFormAccount}
        isTablet={isTablet}
        isMobile={isMobile}
        banksList={banksList}
        selectedBank={selectedBank}
        setSelectedBank={setSelectedBank}
        setBankCode={setBankCode}
        inputComboBoxBank={inputComboBoxBank}
        setInputComboBoxBank={setInputComboBoxBank}
        setAgency={setAgency}
        accountType={accountType}
        setAccountType={setAccountType}
        setAccount={setAccount}
        setAccountDigit={setAccountDigit}
        bankCode={bankCode}
        operation={operation}
        setOperation={setOperation}
        loading={loading}
      />
    ),

    formAmount: () => (
      <StepFormAmount
        nextStep={nextStep}
        available={available}
        amountWithdraw={amountWithdraw}
        setAmountWithdraw={setAmountWithdraw}
      />
    ),

    confirmedWithdraw: () => (
      <StepConfirmedWithdraw
        bankCode={bankCode}
        agency={agency}
        account={account}
        accountDigit={accountDigit}
        amountWithdraw={amountWithdraw}
        handleClickConfirmedWithdraw={handleClickConfirmedWithdraw}
        loading={loading}
      />
    ),

    confirmedSmsCode: () => (
      <StepConfirmedSmsCode
        handleSubmitFormConfirmedSmsCode={handleSubmitFormConfirmedSmsCode}
        smsCode={smsCode}
        setSmsCode={setSmsCode}
        timeSmsCode={timeSmsCode}
        isActiveIimeSmsCode={isActiveIimeSmsCode}
        sendSmsCode={sendSmsCode}
      />
    ),

    confirmedPassword: () => (
      <StepConfirmedPassword
        handleSubmitFormConfirmedPassword={handleSubmitFormConfirmedPassword}
        isPasswordView={isPasswordView}
        password={password}
        setPassword={setPassword}
        handlePasswordView={handlePasswordView}
        loading={loading}
      />
    ),

    last: () => <StepLast handleClickGoHome={handleClickGoHome} />,
  };

  function isRenderContent() {
    return steps && currentPath && currentStep;
  }

  function joinBanks(banks = []) {
    if (Array.isArray(banks)) return [];

    const allBanks = [...banks?.top_banks, ...banks?.others];
    return allBanks;
  }

  function sortBanksListAlphabetic(banks = []) {
    const banksListSorted = banks.sort((bankA, bankB) => {
      if (bankA.bank.at(0) > bankB.bank.at(0)) {
        return 1;
      } else if (bankA.bank.at(0) < bankB.bank.at(0)) {
        return -1;
      }

      return 0;
    });

    return banksListSorted;
  }

  async function loadBankList() {
    setLoading(true);

    try {
      const dataBankList = await getBankList();

      formatBanksList(dataBankList);
      sessionStorage.setItem("bankList", JSON.stringify(dataBankList));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function formatBanksList(banks = []) {
    const allBanks = joinBanks(banks);
    const sortedBanksListAlphabetic = sortBanksListAlphabetic(allBanks);

    setBanksList(
      sortedBanksListAlphabetic?.map((bank) => ({
        ...bank,
        code: String(bank.code),
      }))
    );

    const firstBank = sortedBanksListAlphabetic.at(0);
    setInputComboBoxBank(firstBank?.bank);
  }

  useEffect(() => {
    setCurrentPath([...stepsPath.default]);
  }, []);

  useEffect(() => {
    const banksListStoraged = JSON.parse(sessionStorage.getItem("bankList"));

    if (!banksListStoraged) {
      loadBankList();
    } else {
      formatBanksList(banksListStoraged);
    }
  }, []);

  useEffect(() => {
    if (isActiveIimeSmsCode && timeSmsCode > 0) {
      countdownSmsCode = setTimeout(() => {
        setTimeSmsCode(timeSmsCode - 1);
      }, 1000);
    } else if (isActiveIimeSmsCode && timeSmsCode === 0) {
      clearTimeout(countdownSmsCode);
      setIsActiveTimeSmsCode(false);
      setTimeout(
        () => setTimeSmsCode(CONSTANTS.SECONDS_TIME_SEND_SMS_CODE),
        2000
      );
    }
  }, [timeSmsCode, isActiveIimeSmsCode]);

  useEffect(() => {
    setAvailable(wallet?.my_wallet?.available);
  }, [wallet]);

  return (
    <MDBox
      width={isTablet ? "100%" : "min(27.5rem, 100%)"}
      color="dark"
      bgColor="#fff"
      borderRadius="lg"
      shadow="lg"
      display="flex"
      flexDirection="column"
    >
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        {currentPath[currentStep] != "last" && (
          <button className={styles.headerBackButton} onClick={previousStep}>
            <img src={arrowLeftIcon} alt="Voltar" title="Voltar" />
          </button>
        )}
        <h3 className={styles.headerTitle}>Transferência</h3>
        <img
          src={updateIcon}
          alt="Atualizar"
          className={styles.headerUpdateIcon}
        />
      </MDBox>
      {isRenderContent()
        ? steps[currentPath[currentStep]]()
        : steps["initial"]()}
      {/* {steps["last"]()} */}

      {loading && <Loading />}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
        sx={{ maxWidth: "37.5rem" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </MDBox>
  );
}

export default CardWithdraw;
