import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";

import PageLayout from "examples/LayoutContainers/PageLayout";
import ListSteps from "./layout/ListSteps";
import StepCodeBySMS from "./layout/StepCodeBySMS";
import StepConfirmationEmail from "./layout/StepConfirmationEmail";
import StepValidationDocuments from "./layout/StepValidationDocuments";
import IdentificationForm from "./components/IdentificationForm";
// import IncomeForm from "./components/IncomeForm";
import PasswordForm from "./components/PasswordForm";
import TermsOfUse from "./components/TermsOfUse";
import CardAccountBalance from "./components/CardAccountBalance";
import Loading from "components/Loading";
import { ModalInitial } from "./components/ModalInitial";

import { getStoredValue, removeStoredValue } from "metrics/storage";

import styles from "./styles.module.css";
import logoFido from "../../../assets/images/Logos_Fido/principal-fundo-transparente.svg";

const CONSTANTS = {
  STEP_IDENTIFICATION: 1,
  STEP_CONFIRM_EMAIL: 2,
  STEP_CONFIRM_PHONE_NUMBER: 3,
  STEP_PASSWORD: 4,
  STEP_ACCOUNT_BALANCE: 5,
  STEP_TERMS_OF_USE: 6,
  STEP_VALIDATION_DOCUMENTS: 7,
  AMOUNT_STEPS: 7,
};

function CreateAccount() {
  const registrationStarted = JSON.parse(getStoredValue("registrationStarted"));
  const userData = JSON.parse(getStoredValue("userData"));
  removeStoredValue("registrationStarted");
  removeStoredValue("userData");
  const [currentStepNumber, setCurrentStepNumber] = useState(
    registrationStarted
      ? CONSTANTS.STEP_ACCOUNT_BALANCE
      : CONSTANTS.STEP_IDENTIFICATION
  );
  const [cpf, setCpf] = useState(registrationStarted ? userData.cpf : "");
  const [birthdate, setBirthdate] = useState("");
  const [income] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(registrationStarted ? userData.email : "");
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState(registrationStarted ? userData.token : "");
  const [bank, setBank] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountType, setAccountType] = useState("Selecione");
  const [agency, setAgency] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [digitAccount, setDigitAccount] = useState("");
  const [operationAccount, setOperationAccount] = useState("");
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenInitialModal, setIsOpenInitialModal] = useState(true);

  const closeInitialModal = () => setIsOpenInitialModal(false);

  const activeLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  const showInitialModal = () =>
    isOpenInitialModal && currentStepNumber === CONSTANTS.STEP_IDENTIFICATION;

  function nextStep() {
    if (currentStepNumber >= CONSTANTS.AMOUNT_STEPS) return;

    setCurrentStepNumber(currentStepNumber + 1);
  }

  function previousStep() {
    if (currentStepNumber <= 1) return;
    if (
      registrationStarted &&
      currentStepNumber <= CONSTANTS.STEP_ACCOUNT_BALANCE
    )
      return;

    setCurrentStepNumber(currentStepNumber - 1);
  }

  function getStepHTML(currentStepNumber) {
    switch (currentStepNumber) {
      case CONSTANTS.STEP_IDENTIFICATION:
        return (
          <IdentificationForm
            nextStep={nextStep}
            cpf={cpf}
            setCpf={setCpf}
            birthdate={birthdate}
            setBirthdate={setBirthdate}
          />
        );

      case CONSTANTS.STEP_CONFIRM_EMAIL:
        return (
          <StepConfirmationEmail
            previousStep={previousStep}
            nextStep={nextStep}
            email={email}
            setEmail={setEmail}
          />
        );

      case CONSTANTS.STEP_CONFIRM_PHONE_NUMBER:
        return (
          <StepCodeBySMS
            previousStep={previousStep}
            nextStep={nextStep}
            email={email}
            phone={phone}
            setPhone={setPhone}
          />
        );

      case CONSTANTS.STEP_PASSWORD:
        return (
          <PasswordForm
            previousStep={previousStep}
            nextStep={nextStep}
            setToken={setToken}
            password={password}
            setPassword={setPassword}
            activateLoading={activeLoading}
            disableLoading={disableLoading}
            email={email}
            phone={phone}
            birthdate={birthdate}
            cpf={cpf}
            income={income}
          />
        );

      case CONSTANTS.STEP_ACCOUNT_BALANCE:
        return (
          <CardAccountBalance
            nextStep={nextStep}
            previousStep={previousStep}
            bank={bank}
            setBank={setBank}
            bankCode={bankCode}
            setBankCode={setBankCode}
            agency={agency}
            setAgency={setAgency}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
            digitAccount={digitAccount}
            setDigitAccount={setDigitAccount}
            accountType={accountType}
            setAccountType={setAccountType}
            operationAccount={operationAccount}
            setOperationAccount={setOperationAccount}
            activeLoading={activeLoading}
            disableLoading={disableLoading}
          />
        );

      case CONSTANTS.STEP_TERMS_OF_USE:
        return (
          <TermsOfUse
            previousStep={previousStep}
            nextStep={nextStep}
            token={token}
            email={email}
            cpf={cpf}
            activeLoading={activeLoading}
            disableLoading={disableLoading}
            setCurrentStepNumber={setCurrentStepNumber}
            setAlert={setAlert}
          />
        );

      case CONSTANTS.STEP_VALIDATION_DOCUMENTS:
        return (
          <StepValidationDocuments
            previousStep={previousStep}
            activeLoading={activeLoading}
            disableLoading={disableLoading}
            token={token}
            cpf={cpf}
            bankCode={bankCode}
            agency={agency}
            accountNumber={accountNumber}
            digitAccount={digitAccount}
            accountType={accountType}
          />
        );

      default:
        return null;
    }
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlert({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  return (
    <PageLayout width="100%">
      <main className={styles.mainContainer}>
        <img src={logoFido} className={styles.logo} alt="Logo da Fido" />
        <h1 className={`${styles.h40} ${styles.titlePage}`}>Criar conta</h1>
        <ListSteps currentStepNumber={currentStepNumber} />
        <section className={styles.sectionForm}>
          {getStepHTML(currentStepNumber)}
        </section>
      </main>

      {isLoading && <Loading />}

      {showInitialModal() && <ModalInitial closeModal={closeInitialModal} />}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </PageLayout>
  );
}

export default CreateAccount;
