import React from "react";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

import arrowRightIcon from "../../../../../assets/images/icons/arrow-right.svg";

const CONSTANTS = {
  ACCOUNT_TYPE_CONTA_CORRENTE: "CONTA_CORRENTE",
};

function BankItem({ handleClickBankItem, userBank }) {
  return (
    <MDBox
      width="100%"
      p={2}
      bgColor="#fff"
      borderRadius="lg"
      sx={{ cursor: "pointer" }}
      onClick={handleClickBankItem}
    >
      <MDTypography
        variant="h3"
        fontWeight="regular"
        sx={{
          fontSize: "1.125rem",
          lineHeight: "calc(1.125rem * 1.5)",
          color: "#666",
        }}
      >
        {userBank?.bankCode}
      </MDTypography>
      <MDBox
        mt={2}
        display="flex"
        gap="2rem"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <MDBox display="flex" flexDirection="column">
          <MDTypography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            Agência
          </MDTypography>
          <MDTypography
            variant="h4"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            {userBank?.agency}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" flexDirection="column">
          <MDTypography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            Conta
          </MDTypography>
          <MDTypography
            variant="h4"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            {userBank?.account}-{userBank?.accountDigit}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection="column">
          <MDTypography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            Tipo
          </MDTypography>
          <MDTypography
            variant="h4"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "calc(0.875rem * 1.5)",
              color: "#666",
            }}
          >
            {userBank?.bankAccountType === CONSTANTS.ACCOUNT_TYPE_CONTA_CORRENTE
              ? "Conta corrente"
              : "Poupança"}
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography
          variant="body2"
          sx={{
            fontSize: "0.875rem",
            lineHeight: "calc(0.875rem * 1.5)",
            color: "#666",
          }}
        >
          Favorecido: <strong>Mesma titularidade</strong>
        </MDTypography>
        <img src={arrowRightIcon} alt="Avançar" />
      </MDBox>
    </MDBox>
  );
}

export default BankItem;
