import React, { useState } from "react";

import { ModalRulesShare } from "../ModalRulesShare";
import styles from "./styles.module.css";

function InfoInvites() {
  const [showModal, setShowModal] = useState(false);
  const [close, setClose] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <h2 className={styles.h2}>Veja como funciona!</h2>
      <div className={styles.contentCenter}>
        <p>
          <strong>1.</strong> Compartilhe seu link exclusivo de investidor com
          amigos ou conhecidos interessados e permita que eles tenham acesso às
          mesmas oportunidades que você.
        </p>
        <p>
          <strong>2.</strong> Se e a pessoa indicada por você realizar um
          investimento mínimo de R$ 1.500,00 reais, você receberá uma recompensa
          de R$ 100,00.
        </p>
        <p>
          <strong>3.</strong> A recompensa será feita diretamente na sua conta
          FIDO após 15 dias da realização do investimento.
        </p>
        <p>
          <strong>4.</strong> Indique quantas pessoas quiser e ganhe ainda mais!
        </p>
      </div>
      <button
        type="button"
        className={styles.buttonShare}
        onClick={handleOpenModal}
      >
        <p>Regras da campanha</p>
      </button>
      {showModal && <ModalRulesShare closeModal={handleCloseModal} />}
    </>
  );
}

export { InfoInvites };
