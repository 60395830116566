import React from "react";

import styles from "./styles.module.css";

import arrowDownIcon from "../../../../assets/images/icons/arrow-down-green.svg";

function CardCommonQuestions() {
  return (
    <section className={styles.card}>
      <h2 className={styles.h2}>Perguntas Frequentes</h2>

      <ul className={styles.questionsList}>
        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                Por que diversificar a carteira?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                Diversificar o seu patrimônio em investimentos diferentes é a
                forma mais básica e efetiva de reduzir a volatilidade e os
                riscos. Conte com a FIDO para fazer parte do seu portfólio de
                investimentos!
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                A plataforma é segura?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                Usamos as melhores tecnologias de verificação antifraude para
                garantir a segurança dos seus investimentos.
              </p>
              <p>
                Checamos em bases confiáveis as informações de identificação de
                todas as pessoas cadastradas. Fazemos a checagem biométrica
                entre a foto tirada na selfie e a foto armazenada em bancos de
                dados públicos. Nos documentos apresentados à FIDO (identidade
                ou CNH), verificamos marcas de imagem que garantem a sua
                autenticidade.
              </p>
              <p>
                Além disso, a depender do risco avaliado pela FIDO, fazemos a
                checagem de protestos ativos, dívida ativa e negativação no
                mercado de crédito.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                Como é feita a cobrança?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                A Fido se encarrega de cobrar todas as pessoas com parcelas em
                atraso, sem custo para o investidor. A sequência de cobrança
                segue 4 fases: convencimento (0-10 dias), amigável (10-30 dias),
                negocial (30-60 dias) e extra-judicial (60-90 dias).
              </p>
              <p>
                A negativação do inadimplente é feita a partir do 1º dia de
                atraso junto Banco Central do Brasil e a partir do 45º dia de
                atraso junto ao SPC.
              </p>
              <p>
                Além disso, a Fido fará cobranças judiciais para os empréstimos
                de maior valor.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                O que é score de risco?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                O perfil de quem pega empréstimos com a FIDO é classificado por
                um score que vai de A a D.
              </p>
              <p>
                Score A: é um perfil de menor inadimplência, e, por isso,
                oferece taxas de juros menores (menor risco e menor potencial de
                retorno pra você).
              </p>
              <p>
                Score D: é um perfil de maior inadimplência, por isso oferece
                taxas de juros mais elevadas (maior risco e maior potencial de
                retorno pra você).
              </p>
              <p>
                A Fido não cadastra perfis que se enquadrem a um ou mais desses
                critérios:
              </p>
              <p> - score E, F e G;</p>
              <p> - idade inferior a 21 anos;</p>
              <p> - histórico menor que 3 anos no sistema bancário;</p>
              <p> - renda inferior a R$ 2 mil.</p>
              <p>
                O score é definido com base em algumas variáveis, nos dados do
                favorecido e no histórico junto ao SCR (Sistema de Informações
                de Crédito). O SCR concentra dados de todas as instituições
                financeiras reguladas pelo Banco Central do Brasil.
              </p>
              <p>
                Caso o histórico indique atrasos no pagamento de cheque
                especial, cartão de crédito rotativo e outros empréstimos, o
                score é reduzido.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>O que é TIR?</h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>TIR quer dizer taxa interna de retorno.</p>
              <p>
                É a taxa de desconto que, se aplicada a cada parcela do
                empréstimo no tempo, tem como resultado o investimento inicial.
              </p>
              <p>
                A TIR é uma das referências mais seguras para você avaliar a
                rentabilidade dos seus investimentos, pois considera o valor do
                dinheiro ao longo do tempo. Ela também é útil para a comparação
                de investimentos com prazos e montantes diferentes.
              </p>
              <p>O cálculo parece complicado, mas é super simples!</p>
              <p>
                {" "}
                Basta aplicar na fórmula a TIR e o valor de cada prestação “Pt”,
                sendo “t” o número do mês (fazer de 1 a 12 se o empréstimo for
                pago em um ano), e o resultado será o valor do investimento:
              </p>
              <p>n: número de prestações</p>
              <p>t: número do mês</p>
              <p>
                Pt: valor da prestação no mês “t” esse é o símbolo da soma,
                significa que os resultados da fórmula, conforme cada prestação,
                devem ser somados.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                O que muda no meu IR?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                É necessário que você inclua, na sua declaração do imposto de
                renda, os investimentos feitos com a FIDO.
              </p>
              <p>
                O primeiro passo é ter o informe de rendimentos FIDO que
                enviamos ao e-mail dos investidores.
              </p>
              <p>
                No próprio aplicativo da Receita Federal, insira os resultados
                dos empréstimos na aba “Rendimentos Sujeitos à Tributação
                Exclusiva/Definitiva”, “Outros”, usando o CPF de cada pessoa que
                pegou empréstimo com você.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                O que é inadimplência?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                É a falta pagamento por parte da pessoa que pegou empréstimo. A
                perda causada pela inadimplência é expressa em percentual do
                valor emprestado.
              </p>
              <p>
                Pelas regras do Banco Central do Brasil, as Sociedades de
                Empréstimo Entre Pessoas estão proibidas de oferecer qualquer
                garantia de pagamento dos empréstimos. Por isso, é importante
                considerar que a inadimplência faz parte deste tipo de
                investimento.
              </p>
              <p>
                Para efeito de comparação, mostramos a taxa de inadimplência em
                base anual, indicando o percentual em relação ao valor total do
                empréstimo.
              </p>
            </div>
          </details>
        </li>

        <li className={styles.questionsDivider}></li>

        <li>
          <details>
            <summary className={styles.questionSummary}>
              <h3 className={styles.questionSummaryTitle}>
                O que são as taxas da FIDO?
              </h3>
              <img
                src={arrowDownIcon}
                alt="Seta para baixo"
                className={styles.questionSummaryImg}
              />
            </summary>
            <div className={styles.questionAnswer}>
              <p>
                Não há nenhum custo para o investidor, que pode usar a
                plataforma à vontade, sem pagar nada.
              </p>
              <p>
                A Fido cobra uma taxa das pessoas que tomam empréstimo, que
                varia entre 5% a 8% do valor solicitado, a depender do score de
                risco.
              </p>
              <p>
                Tudo é muito transparente. Antes da contratação do empréstimo o
                favorecido pode verificar qual é a taxa Fido, o IOF e os juros
                do empréstimo.
              </p>
              <p>
                A Taxa Fido cobre todos os custos da plataforma e do empréstimo:
                as operações bancárias, os serviços de validação de documentos,
                anti-fraude, avaliação de risco, cobrança e demais custos da
                plataforma.
              </p>
              <p>
                O recolhimento da taxa Fido é feito 50% no momento do empréstimo
                e 50% distribuido ao longo das parcelas pagas pelo favorecido.
              </p>
            </div>
          </details>
        </li>
      </ul>
    </section>
  );
}

export default CardCommonQuestions;
