import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { formatDateToPtBr, formatMoney } from "utils";
import logoImage from "assets/images/Logos_Fido/principal-fundo-transparente.png";

function useGenerateExtractPDF(transactions, investments, selectedMonth, selectedYear) {
  const pdf = new jsPDF();

  const formatDateInFull = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
  }

  const getLabelInvestment = (investment) => {
    if (!investment?.risk) {
      return "Recebimento de Parcela";
    }
    if (investment?.risk === "menor_risco") {
      return "Conservador";
    }
    if (investment?.risk === "medio_risco") {
      return "Balanceado";
    }
    return "Arrojado";
  };

  const isReceivement = (investment) => {
    return investment.mainValue && investment.interestValue;
  };

  const currentFontSize = pdf.internal.getFontSize();

  const sortByDate = (a, b) => new Date(b.date) - new Date(a.date);
  const sortedTransactions = transactions.sort(sortByDate);

  const logo = () => {
    const logoPosX = 10;
    const logoPosY = 10;
    const logoWidth = 30;
    const logoHeight = 30;
    pdf.addImage(logoImage, 'PNG', logoPosX, logoPosY, logoWidth, logoHeight);
  }

  const currentDate = () => {
    const date = new Date();
    const formattedCurrentDate = formatDateInFull(date);
    pdf.setFontSize(12);

    const datePosX = 194;
    const datePosY = 32;
    pdf.text(formattedCurrentDate, datePosX, datePosY, { align: 'right' });

    pdf.setFontSize(currentFontSize);
  }

  const title = () => {
    const titlePosX = 75;
    const titlePosY = 50;
    const title = `Extrato - ${selectedMonth.name} ${selectedYear}`;
    pdf.text(title, titlePosX, titlePosY);
  }

  const transactionsTable = () => {
    const tranferCardX = 14;
    const tranferCardY = 60;
    const tranferCardWidth = 180;
    const tranferCardHeight = 9;
    pdf.rect(tranferCardX, tranferCardY, tranferCardWidth, tranferCardHeight, 'S');
    pdf.text('Transferências', 85, 66.5);

    const transactionsColumns = ["Data", "Tipo", "Valor"];
    const transactionsData = sortedTransactions.map(transaction => [
      formatDateToPtBr(transaction?.details?.dateCreated),
      transaction?.withdraw ? "Retirada" : "Depósito",
      transaction?.withdraw ? `-${formatMoney(transaction.amount)}` : `+${formatMoney(transaction.amount)}`
    ]);

    pdf.autoTable({
      startY: 75,
      head: [transactionsColumns],
      body: transactionsData,
      theme: 'plain',
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 60 },
        2: { cellWidth: 75 },
      },
    });
  }

  const investimentsTable = () => {
    const investimentCardX = 14;
    const investimentCardY = pdf.autoTable.previous.finalY + 20;
    const investimentCardWidth = 180;
    const investimentCardHeight = 9;
    pdf.rect(investimentCardX, investimentCardY, investimentCardWidth, investimentCardHeight, 'S');
    pdf.text('Investimentos', 85, pdf.autoTable.previous.finalY + 26);

    const sortedInvestments = investments.sort(sortByDate);
    const investmentsColumns = ["Data", "Tipo", "Valor"];
    const investmentsData = [];

    sortedInvestments.forEach(investment => {
      const investmentRow = [
        formatDateToPtBr(investment.date)
      ];
      if (isReceivement(investment)) {
        investmentsData.push([...investmentRow, 'Principal', `+${formatMoney(investment.mainValue)}`]);
        investmentsData.push([...investmentRow, 'Juros', `+${formatMoney(investment.interestValue)}`]);
      } else {
        investmentsData.push([...investmentRow, getLabelInvestment(investment),
        `-${investment?.investment_amount
          ? formatMoney(investment.investment_amount)
          : formatMoney(investment.amount)
        }`]);
      }
    });

    pdf.autoTable({
      startY: pdf.autoTable.previous.finalY + 35,
      head: [investmentsColumns],
      body: investmentsData,
      theme: 'plain',
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 60 },
        2: { cellWidth: 75 },
      },
    });
  }

  const execute = () => {
    logo();
    currentDate();
    title();
    transactionsTable();
    investimentsTable();

    pdf.save(`extrato_fido_${selectedMonth.month}_${selectedYear}.pdf`);
  }

  execute();
}

export { useGenerateExtractPDF };
