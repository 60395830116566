import React from "react";

import styles from "./styles.module.css";

function SessionControl({ currentSection }) {
  return (
    <ul className={styles.sessionControl}>
      {[1, 2, 3, 4, 5].map((sectionNumber) =>
        sectionNumber === currentSection ? (
          <li
            className={`${styles.sessionControlItem} ${styles.sessionControlItemActive}`}
          ></li>
        ) : (
          <li className={styles.sessionControlItem}></li>
        )
      )}
    </ul>
  );
}

export default SessionControl;
