import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";
import arrowBackIcon from "assets/images/icons/arrow-back.svg";

import {
  getOnlyNumbersOfString,
  formatDatePTBRToSQL,
} from "../../../../../utils";

import {
  passwordHasIdealLength,
  haveNumbersInSequence,
  hasRepetitionsAboveLimit,
} from "utils/validators";

import { signUp } from "services/user";
import { ModalErrorCpf } from "../ModalErrorCpf";
import { ModalErrorDataPeople } from "../ModalErrorDataPeople";

function PasswordForm({
  nextStep,
  previousStep,
  setToken,
  password,
  setPassword,
  activateLoading,
  disableLoading,
  email,
  phone,
  birthdate,
  cpf,
  income,
}) {
  const [errors, setErrors] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modalErrorCpf, setModalErrorCpf] = useState(false);
  const [modalErrorDataPeople, setModalErrorDataPeople] = useState(false);

  const closeModal = () => {
    setModalErrorCpf(false);
    setModalErrorDataPeople(false);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleChangePassword(event) {
    const { value } = event.target;
    const onlyNumbers = getOnlyNumbersOfString(value);

    setPassword(onlyNumbers);
  }

  function getErrors() {
    let _errors = [];

    if (!passwordHasIdealLength(password)) {
      _errors = [..._errors, "Senha não possui 6 digítos"];
    } else {
      if (haveNumbersInSequence(password)) {
        _errors = [..._errors, "A senha possui números em sequência"];
      }

      if (hasRepetitionsAboveLimit(password)) {
        _errors = [..._errors, "A senha possui 4 dígitos repetidos"];
      }
    }

    return _errors;
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setHasErrors(false);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    const _errors = getErrors();
    setErrors(_errors);

    if (_errors.length > 0) {
      setHasErrors(true);
      return;
    }

    activateLoading();

    try {
      const formattedBirthdateSQL = formatDatePTBRToSQL(birthdate);

      const response = await signUp(email, password, phone, formattedBirthdateSQL, cpf, income);

      if (response?.status === 200) {
        const { token } = response.data;
        const expirationTime = new Date(Date.now() + 3600000);
        
        localStorage.setItem("token", token);
        localStorage.setItem("expirationTimeSession", expirationTime.getTime());

        setToken(token);
        setErrors([]);
        setHasErrors(false);
        nextStep();
      }

      if (
        (response?.status === 400 &&
          response?.data?.message.error === "NOT_VALIDATED_CPF" &&
          response?.data?.message.reason === "NOT_FOUND") ||
        (response?.status === 400 &&
          response?.data?.message.error === "NOT_VALIDATED_CPF" &&
          response?.data?.message_error?.reason === "NOT_FOUND")
      ) {
        setModalErrorCpf(true);
        return;
      }

      if (
        (response?.status === 400 &&
          response?.data?.message.error === "NOT_VALIDATED_CPF" &&
          response?.data?.message.reason === "OBITO") ||
        (response?.status === 400 &&
          response?.data?.message.error === "NOT_VALIDATED_CPF" &&
          response?.data?.message_error?.reason === "OBITO")
      ) {
        setModalErrorDataPeople(true);
        return;
      }

      if (response?.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      disableLoading();
    }
  }

  return (
    <div className={mainPageStyles.card}>
      <header className={styles.header}>
        <button
          title="Voltar para etapa anterior"
          className={styles.backButton}
          onClick={previousStep}
        >
          <img src={arrowBackIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
          Crie sua senha
        </h2>
      </header>

      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div className={mainPageStyles.formInputGroup}>
          <label htmlFor="password" className={mainPageStyles.formLabel}>
            Senha
          </label>
          <input
            type={showPassword ? "number" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={handleChangePassword}
            className={mainPageStyles.formInput}
            maxLength="6"
            required
          />
          <button
            type="button"
            onClick={handlePasswordVisibility}
            className={mainPageStyles.passwordVisibilityButton}
          >
            {showPassword ? "Esconder" : "Mostrar"}
          </button>
        </div>
        <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
          <strong>A senha deve conter 6 digítos numéricos</strong>
        </p>
        <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
          Um número não pode se repetir mais de 3 vezes
        </p>

        <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
          A senha não pode possuir números em sequência
        </p>

        <button
          type="submit"
          className={`${mainPageStyles.primaryButton} ${styles.submitButton}`}
        >
          Continuar
        </button>
      </form>

      {hasErrors &&
        errors?.map((error) => (
          <Snackbar
            key={error}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={hasErrors}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              variant="filled"
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        ))}
      {modalErrorCpf && <ModalErrorCpf closeModal={closeModal} />}
      {modalErrorDataPeople && <ModalErrorDataPeople closeModal={closeModal} />}
    </div>
  );
}

export default PasswordForm;
