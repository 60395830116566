import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { formatMoney } from "utils";
import { deleteCode, editCodeStatus } from "services/direct-loan";

import styles from "./styles.module.css";
import stylesModalCodeSave from "../ModalCodeSave/styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";
import threePeopleCommemorationIllustration from "assets/images/illustrations/three-people-commemoration.svg";
import copyIcon from "assets/images/icons/copy.svg";
import trashIcon from "assets/images/icons/trash.svg";

const CONSTANTS = {
  STEP_INITIAL: 1,
  STEP_CONFIRM_CHANGE_CODE_STATUS: 2,
};

function ModalCodeEdit({
  close,
  selectedCode,
  activeLoading,
  disableLoading,
  codeList,
  setCodeList,
  token,
}) {
  const [currentStep, setCurrentStep] = useState(CONSTANTS.STEP_INITIAL);
  const [selectedStatusCode, setSelectedStatusCode] = useState(
    selectedCode?.status
  );
  const navigate = useNavigate();

  const nextStep = () => setCurrentStep(currentStep + 1);

  const refreshPage = () => navigate(0);

  async function copyCode(code) {
    await navigator.clipboard.writeText(code);
    alert("Código copiado!");
  }

  async function handleRemoveCode(code) {
    activeLoading();

    try {
      const response = await deleteCode(token, code);

      if (response?.status !== 200) {
        throw new Error();
      }

      const updatedCodeList = codeList?.filter(
        (codeItem) => codeItem.code !== selectedCode.code
      );
      setCodeList(updatedCodeList);
      close();
    } catch (error) {
      console.error(error);
      console.error("Erro ao remover código");
    } finally {
      disableLoading();
    }
  }

  function handleChangeSelectStatusCode(event) {
    const { value } = event.target;
    setSelectedStatusCode(value);
  }

  function handleSubmitForm(event) {
    event.preventDefault();
    nextStep();
  }

  async function changeStatusCode() {
    activeLoading();

    try {
      const response = await editCodeStatus(
        token,
        selectedStatusCode,
        selectedCode?.code
      );

      if (response?.status !== 200) {
        throw new Error();
      }

      refreshPage();
      close();
    } catch (error) {
      console.error(error);
      console.error("Erro ao alterar status do código");
    } finally {
      disableLoading();
    }
  }

  function getRenderContent(currentStep) {
    if (currentStep === CONSTANTS.STEP_INITIAL) {
      return (
        <>
          <header className={styles.header}>
            <h2 className={styles.headerTitle}>Editar status</h2>
            <button
              className={styles.headerCloseButton}
              title="Fechar"
              onClick={close}
            >
              <img src={closeCircleIcon} alt="Fechar" />
            </button>
          </header>
          <p className={styles.messageInfo}>
            O código do empréstimo pode ser compartilhado com as pessoas a quem
            você pretende conceder o seu empréstimo, podendo personalizá-lo de
            acordo com as suas preferências.
          </p>
          <img
            src={threePeopleCommemorationIllustration}
            alt="Três pessoas comemorando"
            className={styles.illustration}
          />
          <div className={styles.containerCode}>
            <strong>Código do empréstimo</strong>
            <MDBox
              mt={2}
              alignSelf="center"
              display="flex"
              gap="0.75rem"
              alignItems="center"
            >
              <p>{selectedCode?.code}</p>
              <button
                title="Copiar código"
                className={styles.containerCodeActionButton}
                onClick={() => copyCode(selectedCode?.code)}
              >
                <img src={copyIcon} alt="Copiar" />
              </button>
              <button
                title="Remover código"
                className={styles.containerCodeActionButton}
                onClick={() => handleRemoveCode(selectedCode?.code)}
              >
                <img src={trashIcon} alt="Lixeira" />
              </button>
            </MDBox>
            {/* <MDBox mt={1} textAlign="center"></MDBox> */}
          </div>

          <form
            className={styles.form}
            autoComplete="off"
            onSubmit={handleSubmitForm}
          >
            <div className={styles.formGrid}>
              <div className={styles.formInputGroup}>
                <label htmlFor="status-list">Status do código</label>
                <select
                  id="status-list"
                  name="status-list"
                  className={styles.formSelect}
                  defaultValue={selectedCode?.status}
                  onChange={handleChangeSelectStatusCode}
                  value={selectedStatusCode}
                >
                  <option
                    value="ENABLE"
                    selected={selectedCode?.status === "ENABLE"}
                  >
                    Ativo
                  </option>
                  <option
                    value="DISABLE"
                    selected={selectedCode?.status === "DISABLE"}
                  >
                    Inativo
                  </option>
                </select>
                {/* <Autocomplete
                options={["State 1", "State 2", "State 3"]}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="State" />
                )}
              /> */}
              </div>
              <div className={styles.formInputGroup}>
                <label htmlFor="investor-rate">Juros de empréstimo</label>
                <p id="investor-rate">
                  {selectedCode?.investor_rate.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                  })}
                  % a.m.
                </p>
              </div>

              <div className={styles.formInputGroup}>
                <label htmlFor="installments-min">Nº minímo de parcelas</label>
                <p id="installments-min">{selectedCode?.min_installments}x</p>
              </div>

              <div className={styles.formInputGroup}>
                <label htmlFor="installments-max">Nº máximo de parcelas</label>
                <p id="installments-max">{selectedCode?.max_installments}x</p>
              </div>

              <div className={styles.formInputGroup}>
                <label htmlFor="amount-min">Valor minímo para empréstimo</label>
                <p id="amount-min">{formatMoney(selectedCode?.min_value)}</p>
              </div>

              <div className={styles.formInputGroup}>
                <label htmlFor="amount-max">Valor máximo para empréstimo</label>
                <p id="amount-max">{formatMoney(selectedCode?.max_value)}</p>
              </div>
            </div>

            <button type="submit" className={styles.primaryButton}>
              Confirmar alteração
            </button>
          </form>
        </>
      );
    } else if (currentStep === CONSTANTS.STEP_CONFIRM_CHANGE_CODE_STATUS) {
      return (
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="3rem"
        >
          <button
            className={`${styles.headerCloseButton} ${styles.headerCloseButtonRight}`}
            title="Fechar"
            onClick={close}
          >
            <img src={closeCircleIcon} alt="Fechar" />
          </button>

          <h3 className={styles.headerTitle}>Você deseja continuar?</h3>

          <div className={styles.containerActionButtons}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              onClick={changeStatusCode}
            >
              Sim
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={close}
            >
              Não
            </MDButton>
          </div>
        </MDBox>
      );
    }

    return null;
  }

  // useEffect(() => console.log(selectedStatusCode), [selectedStatusCode]);

  return (
    <div className={stylesModalCodeSave.overlay}>
      <aside className={stylesModalCodeSave.modal}>
        {getRenderContent(currentStep)}
      </aside>
    </div>
  );
}

export { ModalCodeEdit };
