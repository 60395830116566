import React from "react";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import globalStyles from "../CardForm/styles.module.css";
import closeIcon from "../../../../../assets/images/icons/close.svg";

function ModalEditEmail({
  close,
  isMobile,
  handleSubmitEditEmail,
  email,
  setEmail,
}) {
  return (
    <MDBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bgColor="rgba(0, 0, 0, 0.5)"
      zIndex="1201"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MDBox display="flex" alignItems="flex-start" position="relative">
        <MDBox
          width={isMobile ? "18.75rem" : "32.25rem"}
          bgColor="#fff"
          complement="aside"
          borderRadius="lg"
          p={2}
        >
          <MDTypography
            variant="h2"
            fontWeight="light"
            sx={{
              fontSize: "1.5rem",
              lineHeight: "calc(1.5rem * 1.3)",
              color: "#3D555A",
              fontWeight: 300,
            }}
          >
            Editar e-mail
          </MDTypography>

          <MDBox
            component="form"
            width="100%"
            mt={4}
            onSubmit={handleSubmitEditEmail}
          >
            <MDInput
              type="email"
              variant="outlined"
              label="Email"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoFocus
            />
            <MDBox
              width="100%"
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <button type="submit" className={globalStyles.button}>
                Salvar
              </button>
            </MDBox>
          </MDBox>
        </MDBox>

        <button
          className={globalStyles.closeButton}
          title="Fechar modal"
          onClick={close}
        >
          <img src={closeIcon} alt="Fechar" />
        </button>
      </MDBox>
    </MDBox>
  );
}

export { ModalEditEmail };
