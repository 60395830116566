import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { InfoInvites } from "../InfoInvites";
import { setStoredValue, getStoredValue } from "metrics/storage";
import { getUrlInviteInvestor } from "services/invite-investor";

import styles from "./styles.module.css";
import inviteImage from "../../../../../assets/images/image-invite.svg";
import shareIcon from "../../../../../assets/images/icons/toShare.svg";

function ShareInvite() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [hideContent, setHideContent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [URL, setURL] = useState("");
  const navigate = useNavigate();

  const buttonList = [
    { index: 0, bool: false },
    { index: 1, bool: true },
  ];

  const handleButtonClick = (index, bool) => {
    setHideContent(bool);
    setSelectedButton(index);
  };

  async function copyUrlInviteInvestor() {
    try {
      setIsLoading(true);

      const token = getStoredValue("token").replace(/['"]+/g, "");
      const response = await getUrlInviteInvestor(token);
      const URL = response.data.url;

      sessionStorage.setItem("URL", URL);

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(URL);
        setShowModal(true);
      } else {
        setURL(URL);
        setShowModalError(true);
        throw new Error(
          "A funcionalidade Clipboard não é suportada neste navegador."
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className={styles.card}>
      {!hideContent ? (
        <>
          <h2 className={styles.h2}>Convide novos investidores e ganhe!</h2>
          <div className={styles.contentCenter}>
            <p>
              Indique a plataforma de investimentos da FIDO e receba uma
              recompensa por cada pessoa que <strong>investir.</strong>
            </p>
            <img src={inviteImage} className={styles.imageShare} />
          </div>
          <button
            type="button"
            className={styles.buttonShare}
            onClick={copyUrlInviteInvestor}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className={styles.loadingIndicator}>
                <div className={styles.loading} />
              </div>
            ) : (
              <>
                <img src={shareIcon} alt="Share" />
                <p>Compartilhar meu link</p>
              </>
            )}
          </button>
          {showModal && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <p>Código copiado com sucesso!</p>
                <button
                  className={styles.modalClose}
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Sair
                </button>
              </div>
            </div>
          )}
          {showModalError && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <p>
                  <strong>Compartilhe esse link: </strong>
                </p>
                <p>{URL}</p>
                <button
                  className={styles.modalClose}
                  onClick={() => {
                    setShowModalError(false);
                  }}
                >
                  Sair
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <InfoInvites />
      )}
      <ul className={styles.controlButtons}>
        {buttonList.map(({ index, bool }) => (
          <li
            className={`${styles.buttonSelected} ${
              selectedButton === index ? styles.selected : ""
            }`}
            onClick={() => handleButtonClick(index, bool)}
          />
        ))}
      </ul>
    </section>
  );
}

export { ShareInvite };
