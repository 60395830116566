import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Footer from "../../../examples/Footer";
import Loading from "components/Loading";
import { CardTotalBalance } from "./components/CardTotalBalance";
import { CardList } from "./components/CardList";

import { getAllTransactions } from "services/extract";
import { getWallet, getInvestments } from "services/investments";
import { getWalletData } from "services/investments";
import { getWalletReceivements } from "services/investments";

import { checkSessionTimer } from "utils";

const CONSTANTS = {
  DIMENSIONS_TABLET: 768,
  DIMENSIONS_MOBILE: 550,
  MONTHS: [
    {
      month: "jan",
      label: "Jan",
      name: "Janeiro",
      numericMonth: 1,
    },
    {
      month: "feb",
      label: "Fev",
      name: "Fevereiro",
      numericMonth: 2,
    },
    {
      month: "mar",
      label: "Mar",
      name: "Março",
      numericMonth: 3,
    },
    {
      month: "apr",
      label: "Abr",
      name: "Abril",
      numericMonth: 4,
    },
    {
      month: "may",
      label: "Mai",
      name: "Maio",
      numericMonth: 5,
    },
    {
      month: "jun",
      label: "Jun",
      name: "Junho",
      numericMonth: 6,
    },
    {
      month: "jul",
      label: "Jul",
      name: "Julho",
      numericMonth: 7,
    },
    {
      month: "aug",
      label: "Ago",
      name: "Agosto",
      numericMonth: 8,
    },
    {
      month: "sep",
      label: "Set",
      name: "Setembro",
      numericMonth: 9,
    },
    {
      month: "oct",
      label: "Out",
      name: "Outubro",
      numericMonth: 10,
    },
    {
      month: "nov",
      label: "Nov",
      name: "Novembro",
      numericMonth: 11,
    },
    {
      month: "dec",
      label: "Dez",
      name: "Dezembro",
      numericMonth: 12,
    },
  ],
  CURRENT_MONTH: new Date().getMonth() + 1,
  CURRENT_YEAR: new Date().getFullYear(),
};

function Extract() {
  const tokenStorage = localStorage.getItem("token");
  const [transactionsAll, setTransactionsAll] = useState([]);
  const [
    filteredTransactionsPerSelectedMonth,
    setFilteredTransactionsPerSelectedMonth,
  ] = useState([]);
  const [onlyInvestments, setOnlyInvestments] = useState([]);
  const [investmentsWithReceivements, setInvestmentsWithReceivements] =
    useState([]);
  const [
    filteredInvestmentsPerMonthAndYear,
    setFilteredInvestmentsPerMonthAndYear,
  ] = useState([]);
  const [receivementsInvestments, setReceivementsInvestments] = useState([]);
  const [wallet, setWallet] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [isLoadingInvestments, setIsLoadingInvestments] = useState(false);

  const [selectedMounth, setSelectedMounth] = useState(CONSTANTS.MONTHS[0] || {});
  const [selectedYear, setSelectedYear] = useState(CONSTANTS.CURRENT_YEAR);
  const [filterListYears, setFilterListYears] = useState([
    CONSTANTS.CURRENT_YEAR,
  ]);
  const [selectedTypeTransactions, setSelectedTypeTransactions] =
    useState("transfers");
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlert({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  function formattedDateInvestment(dateMilliseconds) {
    const date = new Date(dateMilliseconds);
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate();
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function getMappedInvestments(investments = []) {
    const mappedInvestments = investments?.map((investment) => {
      const formattedDate = formattedDateInvestment(
        investment.created_at.$date
      );

      return {
        ...investment,
        type: "investment",
        date: formattedDate,
      };
    });

    return mappedInvestments;
  }

  function getMappedReceivements(receivements = []) {
    const mappedReceivements = receivements.map((receivement) => ({
      type: "receivement",
      date: receivement?.payment_date
        ? receivement?.payment_date
        : receivement?.due_date,
      amount: receivement?.value,
      hasPaid: receivement?.paid,
      mainValue: receivement?.main_value,
      interestValue: receivement?.interest_value,
      cpf: receivement?.taker_cpf,
      months: receivement?.months,
      installment: receivement?.installment
    }));

    return mappedReceivements;
  }

  function getInvestmentsSortedByMostRecent(investments = []) {
    const investmentsSortedByMostRecent = investments.sort(
      (investmentA, investmentB) => {
        if (investmentA.date > investmentB.date) {
          return -1;
        } else if (investmentA.date < investmentB.date) {
          return 1;
        }

        return 0;
      }
    );

    return investmentsSortedByMostRecent;
  }

  function getTransactionsSortedByMostRecent(transactions = []) {
    const transactionsSortedByMostRecent = transactions.sort(
      (transactionA, transactionB) => {
        const transactionADate = transactionA.details.dateCreated;
        const transactionBDate = transactionB.details.dateCreated;

        if (transactionADate > transactionBDate) {
          return -1;
        } else if (transactionADate < transactionBDate) {
          return 1;
        }

        return 0;
      }
    );

    return transactionsSortedByMostRecent;
  }

  function getInvestmentsYears(investments = []) {
    const years = investments
      .reduce((rangeYears, investment) => {
        const [year] = investment.date.split("-");
        if (rangeYears.includes(year)) {
          return rangeYears;
        }

        return [...rangeYears, year];
      }, [])
      .map((years) => Number(years));

    return years;
  }

  function getTransactionsYears(transactions = []) {
    const years = transactions
      .reduce((rangeYears, transaction) => {
        const [year] = transaction.details.dateCreated.split("-");

        if (rangeYears.includes(year)) {
          return rangeYears;
        }

        return [...rangeYears, year];
      }, [])
      .map((years) => Number(years));

    return years;
  }

  function setCurrentMonthInSelectedMonth() {
    const currentMonth = CONSTANTS.MONTHS.filter(
      (month) => month.numericMonth === CONSTANTS.CURRENT_MONTH
    ).at(0);

    setSelectedMounth(currentMonth);
  }

  async function loadTransactions() {
    setIsLoadingTransactions(true);

    try {
      const response = await getAllTransactions();

      if (response.status !== 200) {
        throw new Error();
      }

      const { transactions = [] } = response?.data || {};

      const filteredTransactions = transactions.filter((transaction) => {
        if (transaction.withdraw) {
          return true;
        } else {
          return transaction.status;
        }
      });
      const transactionsSortedByMostRecente =
        getTransactionsSortedByMostRecent(filteredTransactions);
      const years = getTransactionsYears(transactionsSortedByMostRecente);

      setFilterListYears(years);
      setTransactionsAll(transactionsSortedByMostRecente);
    } catch (error) {
      console.error(error);
      setTransactionsAll([]);
    } finally {
      setIsLoadingTransactions(false);
    }
  }

  useEffect(() => {
    checkSessionTimer(
      () => loadTransactions(), 
      () => navigate('/login')
    );

    return () => {
      setTransactionsAll([]);
    };
  }, []);

  useEffect(() => {
    async function loadWallet() {
      setIsLoadingWallet(true);

      try {
        const responseWallet = await getWalletData();
        const { status, data } = responseWallet;

        if (status !== 200) {
          throw new Error();
        }

        setWallet(data);
      } catch (error) {
        console.error(error);
        console.error("Erro ao realizar requisicao para obter a wallet");
      } finally {
        setIsLoadingWallet(false);
      }
    }

    loadWallet();

    return () => {
      setWallet({});
    };
  }, []);

  useEffect(() => {
    async function loadWalletReceivements() {
      setIsLoadingWallet(true);

      try {
        const responseWallet = await getWalletReceivements();
        const { status, data } = responseWallet;

        if (status !== 200) {
          throw new Error();
        }

        setReceivementsInvestments(data?.receivements);
      } catch (error) {
        console.error(error);
        console.error("Erro ao realizar requisicao para obter a wallet");
      } finally {
        setIsLoadingWallet(false);
      }
    }

    loadWalletReceivements();
  }, []);

  useEffect(() => {
    async function loadInvestments() {
      setIsLoadingInvestments(true);

      try {
        const responseInvestments = await getInvestments();
        const { status, data } = responseInvestments;

        if (status !== 200) {
          throw new Error();
        }

        setOnlyInvestments(data);
      } catch (error) {
        console.error(error);
        setAlert({
          isOpen: true,
          message: "Error ao buscar investimentos. Tente novamente.",
          type: "error",
        });

      } finally {
        setIsLoadingInvestments(false);
      }
    }

    loadInvestments();

    return () => {
      setOnlyInvestments([]);
    };
  }, []);

  useEffect(() => {
    setCurrentMonthInSelectedMonth();

    return () => {
      setSelectedMounth({});
    };
  }, []);

  useEffect(() => {
    let listYears = [];

    if (selectedTypeTransactions === "investments") {
      listYears = getInvestmentsYears(investmentsWithReceivements);
      setFilterListYears(listYears);
    } else {
      listYears = getTransactionsYears(transactionsAll);
      setFilterListYears(listYears);
    }

    if (listYears.length === 0) {
      setFilterListYears([CONSTANTS.CURRENT_YEAR]);
      setSelectedYear(CONSTANTS.CURRENT_YEAR);
    } else {
      setSelectedYear(listYears.at(0));
    }

    return () => {
      setSelectedYear(CONSTANTS.CURRENT_YEAR);
      setFilterListYears([CONSTANTS.CURRENT_YEAR]);
    };
  }, [selectedTypeTransactions, transactionsAll, investmentsWithReceivements]);

  useEffect(() => {
    if (receivementsInvestments && onlyInvestments) {
      if (receivementsInvestments.length === 0 && onlyInvestments.length === 0) {
        console.log('Nenhum dado encontrado em recebimentos e investimentos');
        return;
      }
    }

    const receivementsOnlyPaid = receivementsInvestments.filter(
      (receivement) => receivement?.paid
    );
    const mappedReceivements = getMappedReceivements(receivementsOnlyPaid);

    const mappedInvestments = getMappedInvestments(onlyInvestments);
    const investmentsSortedByMostRecent = getInvestmentsSortedByMostRecent([
      ...mappedReceivements,
      ...mappedInvestments,
    ]);
    const years = getInvestmentsYears(investmentsSortedByMostRecent);

    setFilterListYears(years);
    setInvestmentsWithReceivements(investmentsSortedByMostRecent);
  }, [receivementsInvestments, onlyInvestments]);

  useEffect(() => {
    if (!isLoadingInvestments && !isLoadingTransactions && !isLoadingWallet) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
  }, [isLoadingInvestments, isLoadingTransactions, isLoadingWallet]);

  useEffect(() => {
    const filteredInvestments = investmentsWithReceivements?.filter(
      (investment) => {
        const [year, month] = investment.date.split("-");
        return (
          Number(month) === selectedMounth.numericMonth &&
          Number(year) === Number(selectedYear)
        );
      }
    );

    setFilteredInvestmentsPerMonthAndYear(filteredInvestments);
  }, [investmentsWithReceivements, selectedMounth, selectedYear]);

  useEffect(() => {
    const filteredTransactions = transactionsAll?.filter((transaction) => {
      const transactionDate = transaction.details.dateCreated;
      const [year, month] = transactionDate.split("-");
      return (
        Number(month) === selectedMounth.numericMonth &&
        Number(year) === Number(selectedYear)
      );
    });

    setFilteredTransactionsPerSelectedMonth(filteredTransactions);
  }, [transactionsAll, selectedMounth, selectedYear]);

  if (!tokenStorage) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5}>
        <CardTotalBalance wallet={wallet} />

        <CardList
          selectedTypeTransactions={selectedTypeTransactions}
          setSelectedTypeTransactions={setSelectedTypeTransactions}
          selectedMounth={selectedMounth}
          setSelectedMounth={setSelectedMounth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          filterListYears={filterListYears}
          currentYear={CONSTANTS.CURRENT_YEAR}
          filteredTransactionsPerSelectedMonth={
            filteredTransactionsPerSelectedMonth
          }
          filteredInvestmentsPerMonthAndYear={
            filteredInvestmentsPerMonthAndYear
          }
        />
      </MDBox>
      <Footer />

      {isLoading && <Loading />}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
        sx={{ maxWidth: "37.5rem" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Extract;
