import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import EmailForm from "../../components/EmailForm";
import ModalConfirmCodeByEmail from "../../components/ModalConfirmCodeByEmail";
import ModalEmailSent from "../../components/ModalEmailSent";
import Loading from "components/Loading";
import ModalEmailExists from "../../components/ModalEmailExists";

function StepConfirmationEmail({ previousStep, nextStep, email, setEmail }) {
  const [error, setError] = useState("");
  const [isOpenModalConfirmCode, setIsOpenModalConfirmCode] = useState(false);
  const [isOpenModalEmailSent, setIsOpenModalEmailSent] = useState(false);
  const [isOpenModalEmailExists, setIsOpenModalEmailExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isFirstAccess, setIsFirstAccess] = useState(true);
  const [previousEmail, setPreviousEmail] = useState("");

  const activateLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  const closeModalEmailExists = () => setIsOpenModalEmailExists(false);

  const openModalEmailExists = () => setIsOpenModalEmailExists(true);

  const closeModalConfirmCode = () => setIsOpenModalConfirmCode(false);

  const openModalConfirmCode = () => setIsOpenModalConfirmCode(true);

  const openModalEmailSent = () => setIsOpenModalEmailSent(true);

  const closeModalEmailSent = () => setIsOpenModalEmailSent(false);

  const openSnackbar = () => setIsOpenSnackbar(true);

  const closeSnackbar = () => setIsOpenSnackbar(false);

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    closeSnackbar();
  }

  return (
    <>
      <EmailForm
        previousStep={previousStep}
        openModalEmailSent={openModalEmailSent}
        openModalEmailExists={openModalEmailExists}
        email={email}
        setEmail={setEmail}
        disableLoading={disableLoading}
        activateLoading={activateLoading}
        setIsFirstAccess={setIsFirstAccess}
        isFirstAccess={isFirstAccess}
        previousEmail={previousEmail}
        setPreviousEmail={setPreviousEmail}
      />
      {isOpenModalEmailSent && (
        <ModalEmailSent
          closeModalEmailSent={closeModalEmailSent}
          openModalConfirmCode={openModalConfirmCode}
          email={email}
        />
      )}
      {isOpenModalConfirmCode && (
        <ModalConfirmCodeByEmail
          email={email}
          nextStep={nextStep}
          closeModalConfirmCode={closeModalConfirmCode}
          disableLoading={disableLoading}
          activateLoading={activateLoading}
          setError={setError}
          openSnackbar={openSnackbar}
          closeSnackbar={closeSnackbar}
        />
      )}
      {isOpenModalEmailExists && (
        <ModalEmailExists
          email={email}
          closeModalEmailExists={closeModalEmailExists}
        />
      )}
      {isLoading && <Loading />}
      {isOpenSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isOpenSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default StepConfirmationEmail;
