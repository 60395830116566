import { getStoredValue } from "../metrics/storage";
import { api } from "./api";

const CONSTANTS = {
  ACCOUNT_TYPE_INVESTOR: 3,
};

async function signUp(email, password, phone, birthdate, cpf, income) {
  let response = undefined;

  try {
    response = await api.post("/investor/sign_up", {
      email,
      cpf,
      password,
      phone,
      income,
      birthDate: birthdate,
    });

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function firstUserAccess(email, inviteCode) {
  let response = undefined;

  try {
    response = await api.post("/investor/first_access", {
      email,
      invite_code: inviteCode,
    });
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function sendCodeByEmail(email) {
  let response = undefined;

  try {
    response = await api.post("/send-email-for-confirmation", {
      email,
      account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
    });
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function sendCodeBySMS(phone, email) {
  let response = undefined;

  try {
    response = await api.post("/send_sms_for_confirmation", {
      email,
      phone,
      account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
    });
  } catch (error) {
    console.error(error);
    //throw new Error();
  } finally {
    return response;
  }
}

async function confirmCodeBySMS(email, code) {
  let response = undefined;

  try {
    response = await api.post("/user/confirm_sms", {
      email,
      token: code,
      account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
    });
  } catch (error) {
    console.error(error);
    // throw new Error();
  } finally {
    return response;
  }
}

async function confirmCodeByEmail(email, code) {
  let response = undefined;

  try {
    response = await api.post("/user/confirm_email", {
      email,
      token: code,
      account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
    });
  } catch (error) {
    console.error(error);
    // throw new Error();
  } finally {
    return response;
  }
}

async function getUserBankDetails() {
  let data = null;

  try {
    const token = getStoredValue("token").replace(/['"]+/g, "");

    const response = await api.get("/user/bank", {
      headers: { Authorization: token },
    });

    data = response.data;
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return data;
  }
}

async function editUserData(userData) {
  let response = null;

  try {
    const token = getStoredValue("token").replace(/['"]+/g, "");

    response = await api.post("/user/edit", userData, {
      headers: { Authorization: token },
    });
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return response;
  }
}

async function getLoggedUser() {
  const token = getStoredValue("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/user", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function changePasswordUser(currentPassword, newPassword) {
  let response = null;

  try {
    const token = getStoredValue("token").replace(/['"]+/g, "");

    response = await api.post(
      "/user/change_password",
      { old_password: currentPassword, password: newPassword },
      {
        headers: { Authorization: token },
      }
    );
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return response;
  }
}

async function resultDocumentationValidation(token) {
  const _token = token.replace(/['"]+/g, "");

  try {
    const response = await api.post(
      "/mati-report",
      {},
      {
        headers: { Authorization: _token },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function userExists(cpf) {
  let response = undefined;

  try {
    response = await api.post("/user/cpf_exists", {
      cpf,
      // account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
    });
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function signUpFinish(cpf, email, token) {
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    const response = await api.post(
      "/investor/sign_up/finish",
      {
        cpf,
        email,
      },
      {
        headers: {
          Authorization: tokenWithoutQuotes,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function login(cpf, password) {
  let response = undefined;

  try {
    response = await api.post("/investor/login", {
      cpf,
      password,
    });
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function forgetPassword(cpf) {
  let response = undefined;

  try {
    response = await api.post("/user/forgot_password", { cpf });
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function saveAccountBalance(
  token,
  bankCode,
  agency,
  account,
  accountDigit,
  bankAccountType
) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/user/bank/main_account",
      {
        bankingDetails: {
          bankCode,
          agency,
          account,
          accountDigit,
          bankAccountType,
        },
      },
      {
        headers: {
          Authorization: tokenWithoutQuotes,
        },
      }
    );
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function getAccountStatusAsaas(cpf) {
  let response = undefined;

  try {
    response = await api.post(
      "/asaas/myAccount/status",
      {
        cpf,
        account_type: CONSTANTS.ACCOUNT_TYPE_INVESTOR,
      },
      {}
    );
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function confirmPassword(token, password) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/user/valid-password",
      {
        password,
      },
      {
        headers: {
          Authorization: tokenWithoutQuotes,
        },
      }
    );
  } catch (error) {
    console.error(error);
  } finally {
    return response;
  }
}

async function recoverPassword(token, password) {
  const response = await api.post(
    `/user/password_recovery/${token}`,
    {
      password,
    },
    {}
  );
  return response;
}

export {
  confirmPassword,
  getUserBankDetails,
  getLoggedUser,
  editUserData,
  changePasswordUser,
  sendCodeBySMS,
  confirmCodeBySMS,
  sendCodeByEmail,
  confirmCodeByEmail,
  firstUserAccess,
  signUp,
  resultDocumentationValidation,
  signUpFinish,
  userExists,
  login,
  forgetPassword,
  saveAccountBalance,
  getAccountStatusAsaas,
  recoverPassword,
};
