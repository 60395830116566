import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

import MDBox from "../../../../../components/MDBox";
// import ReportsBarChart from "../../../../../examples/Charts/BarCharts/ReportsBarChart";
import { getReceivementsByMonth } from "services/receivements";

import styles from "./styles.module.css";
import receivementsByMonthJSON from "mocks/receivements-by-month.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CONSTANTS = {
  CURRENT_YEAR: new Date().getFullYear(),
  MONTHS: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  OPTIONS_LINE_CHART: {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  },
};

function CardReceivingLoans({ setIsLoading }) {
  const [receivementsLoans, setReceivementsLoans] = useState({
    received: [],
    notReceived: [],
  });
  const [receivementsLoansByYear, setReceivementsLoansByYear] = useState({
    received: [],
    notReceived: [],
  });
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(CONSTANTS.CURRENT_YEAR);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const isRenderChart =
    receivementsLoansByYear?.received?.length > 0 ||
    receivementsLoansByYear?.notReceived?.length > 0;
  const navigate = useNavigate();

  function getMonthsReceivementsLoans(receivements = []) {
    const allReceivements = [
      ...receivements?.received,
      ...receivements?.notReceived,
    ];
    const allReceivementsSorted = allReceivements.sort(
      (receivementA, receivementB) => {
        if (receivementA.date > receivementB.date) {
          return 1;
        } else if (receivementA.date < receivementB.date) {
          return -1;
        }

        return 0;
      }
    );
    const months = Array.from(
      new Set(allReceivementsSorted.map((receivement) => receivement.month))
    );

    return months;
  }

  function getYearsReceivementsLoans(receivementsLoans) {
    let years = [];

    const receivementsLoansAll = [
      ...receivementsLoans?.received,
      ...receivementsLoans?.notReceived,
    ];

    years = Array.from(
      new Set(
        receivementsLoansAll?.map((receivementLoan) => {
          const [year] = receivementLoan?.date?.split("-");
          return Number(year);
        })
      )
    );

    return years;
  }

  function mapReceivementLoan(receivements = []) {
    const mappedReceivements = receivements.map((receivement) => {
      const [_, month] = receivement?.date?.split("-");
      const labelMonth = CONSTANTS.MONTHS[Number(month) - 1];

      return {
        ...receivement,
        month: labelMonth,
      };
    });

    return mappedReceivements;
  }

  const filterReceivementByYear = (
    year = CONSTANTS.CURRENT_YEAR,
    receivements = []
  ) =>
    receivements?.filter((receivementLoan) => {
      const [dateYear] = receivementLoan?.date?.split("-");
      return Number(dateYear) === year;
    }) || [];

  async function loadReceivements() {
    setIsLoading(true);

    try {
      const response = await getReceivementsByMonth();

      if (response?.status !== 200) {
        throw new Error();
      }

      const { data = {} } = response;
      setReceivementsLoans({
        received: mapReceivementLoan(data?.received || []),
        notReceived: mapReceivementLoan(data?.not_received || []),
      });

      /*
      setReceivementsLoans({
        received: mapReceivementLoan(
          receivementsByMonthJSON?.data?.received || []
        ),
        notReceived: mapReceivementLoan(
          receivementsByMonthJSON?.data?.not_received || []
        ),
      });
      */
    } catch (error) {
      console.error(error);
      console.error("Erro ao buscar recebimentos");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadReceivements();
  }, []);

  useEffect(() => {
    const years = getYearsReceivementsLoans(receivementsLoans);
    setYears(years);
  }, [receivementsLoans]);

  useEffect(() => {
    const filteredReceivementsLoans = {
      received: filterReceivementByYear(
        selectedYear,
        receivementsLoans?.received
      ),
      notReceived: filterReceivementByYear(
        selectedYear,
        receivementsLoans?.notReceived
      ),
    };
    const months = getMonthsReceivementsLoans(filteredReceivementsLoans);
    const receivements = {
      received: [],
      notReceived: [],
    };

    months.forEach((monthItem) => {
      const [receivementNotReceived = { value: 0, month: monthItem }] =
        filteredReceivementsLoans.notReceived.filter(
          (receivementItem) => receivementItem?.month === monthItem
        );
      const [receivementReceived = { value: 0, month: monthItem }] =
        filteredReceivementsLoans.received.filter(
          (receivementItem) => receivementItem?.month === monthItem
        );

      receivements.received.push(receivementReceived);
      receivements.notReceived.push(receivementNotReceived);
    });

    setReceivementsLoansByYear(receivements);
    setLineChartLabels(months);
  }, [selectedYear, receivementsLoans]);

  return (
    <>
      <MDBox
        width="100%"
        heigth="100%"
        bgColor="#305568"
        display="flex"
        borderRadius="1rem 1rem 0 0"
        shadow="lg"
        p={3}
      >
        <h3 className={styles.title}>Recebimento dos empréstimos</h3>
      </MDBox>
      <MDBox
        width="100%"
        bgColor="#fff"
        borderRadius="0 0 1rem 1rem"
        shadow="lg"
        p={5}
      >
        {isRenderChart ? (
          <>
            <MDBox
              mb="2rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {years?.map((year, index) => (
                <button
                  className={`${styles.filterButton} ${
                    selectedYear === year ? styles.filterButtonActive : ""
                  }`}
                  key={index}
                  onClick={() => setSelectedYear(year)}
                >
                  {year}
                </button>
              ))}
            </MDBox>
            <Bar
              options={CONSTANTS.OPTIONS_LINE_CHART}
              data={{
                labels: lineChartLabels,
                datasets: [
                  {
                    label: "Recebidos",
                    data: receivementsLoansByYear?.received?.map(
                      (receivment) => receivment?.value
                    ),
                    borderColor: "#305568",
                    backgroundColor: "#305568",
                  },
                  {
                    label: "A receber",
                    data: receivementsLoansByYear?.notReceived?.map(
                      (receivment) => receivment?.value
                    ),
                    borderColor: "#898686",
                    backgroundColor: "#898686",
                  },
                ],
              }}
            />
          </>
        ) : (
          <MDBox
            minHeight="218px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <p className={styles.messageEmpty}>
              Você não possui recebimentos ainda
            </p>
          </MDBox>
        )}
      </MDBox>
    </>
  );
}

export default CardReceivingLoans;
