import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";
import investorProfileIcon from "../../../../../assets/images/illustrations/investor-profile.svg";

import styles from "./styles.module.css";

const CONSTANTS = {
  INITIAL_STEP: 0,
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  STEP_FOUR: 4,
  STEP_FIVE: 5,
  STEP_SIX: 6,
  OPTION_ONE: 1,
  OPTION_TWO: 2,
  OPTION_THREE: 3,
  TYPE_PROFILE_DASHING: "dashing",
  TYPE_PROFILE_AGGRESSIVE: "aggressive",
  TYPE_PROFILE_MODERATE: "moderate",
  MOBILE_SCREEN_DIMENSIONS: 550,
};

function CardInvestorProfile() {
  const [stepIndex, setStepIndex] = useState(CONSTANTS.INITIAL_STEP);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [investorProfile, setInvestorProfile] = useState("");
  const [answers, setAnswers] = useState([]);

  const isMobile = useMediaQuery(
    `(max-width: ${CONSTANTS.MOBILE_SCREEN_DIMENSIONS}px)`
  );

  const previousStep = () =>
    stepIndex > CONSTANTS.INITIAL_STEP && setStepIndex(stepIndex - 1);

  const nextStep = () =>
    stepIndex < CONSTANTS.STEP_SIX && setStepIndex(stepIndex + 1);

  const returnInit = () =>
    stepIndex < CONSTANTS.STEP_SIX && setStepIndex(stepIndex - 5);

  const isInvestorProfileModerate = () =>
    investorProfile === CONSTANTS.TYPE_PROFILE_MODERATE;

  const isInvestorProfileAggressive = () =>
    investorProfile === CONSTANTS.TYPE_PROFILE_AGGRESSIVE;

  const handleActiveOptionClass = (option) => {
    // if (answers.length > 0) {
    //   // setCurrentAnswer(answers[stepIndex - 1]?.answer)
    //   // setSelectedOptionIndex(answers[stepIndex - 1]?.option)
    //   return answers[stepIndex - 1]?.optionIndex === option
    //     ? styles.formLabelActive
    //     : "";
    // }

    return selectedOptionIndex === option ? styles.formLabelActive : "";
  };

  function addAnswer() {
    let _answers = [...answers];

    // _answers[stepIndex - 1] = {
    //   answer: currentAnswer,
    //   optionIndex: selectedOptionIndex,
    // };
    _answers[stepIndex - 1] = currentAnswer;

    setAnswers(_answers);
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    if (!currentAnswer) return;

    nextStep();
    addAnswer();
    setSelectedOptionIndex(-1);
    setCurrentAnswer(null);
  }

  function handleStepFiveTitle() {
    const typesProfiles = {
      dashing: "Arrojado",
      moderate: "Moderado",
      aggressive: "Agressivo",
    };

    return typesProfiles[investorProfile];
  }

  function renderSteps() {
    switch (stepIndex) {
      case CONSTANTS.INITIAL_STEP:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            p={2}
            component="article"
            borderRadius="lg"
          >
            <h2 className={styles.headerTitle}>
              Descubra o seu perfil investidor
            </h2>
            <MDBox display="flex" flexDirection="column" alignItems="center">
              <MDBox
                my="5rem"
                component="img"
                height={isMobile ? "18rem" : "20rem"}
                src={investorProfileIcon}
                alt="Perfil de investidor"
              />
              <button
                className={styles.button}
                onClick={nextStep}
                title="Clique para descobrir seu perfil investidor"
              >
                Começar
              </button>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_ONE:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            component="article"
            borderRadius="lg"
            display="flex"
            flexDirection="column"
          >
            <MDBox component="header" height="8rem" p={2}>
              <MDBox display="flex" alignItems="center" gap="1rem">
                <button
                  title="Voltar para etapa inicial"
                  className={styles.headerBackButton}
                  onClick={previousStep}
                >
                  <img src={arrowLeftIcon} alt="Seta para esquerda" />
                </button>
                <h2 className={styles.headerTitle}>
                  Descubra o seu perfil investidor
                </h2>
              </MDBox>
            </MDBox>
            <MDBox flex="1" p={2} bgColor="#e9ebec" display="flex">
              <MDBox
                flex="1"
                component="form"
                mt={5}
                display="flex"
                flexDirection="column"
                onSubmit={handleFormSubmit}
              >
                <MDBox display="flex" flexDirection="column" gap="1.5rem">
                  <input
                    type="radio"
                    id="option-one"
                    name="options"
                    className={styles.formInput}
                    // checked={answers.length > 0 && answers[stepIndex - 1]?.optionIndex === CONSTANTS.OPTION_ONE}
                    required
                  />
                  <label
                    htmlFor="option-one"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_ONE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_ONE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_DASHING);
                    }}
                  >
                    Até 10%
                  </label>
                  <input
                    type="radio"
                    id="option-two"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-two"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_TWO
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_TWO);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_MODERATE);
                    }}
                  >
                    Até 20%
                  </label>
                  <input
                    type="radio"
                    id="option-three"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-three"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_THREE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_THREE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_AGGRESSIVE);
                    }}
                  >
                    Mais de 20%
                  </label>
                </MDBox>

                <MDBox
                  mt={5}
                  component="ul"
                  alignSelf="center"
                  alignItems="center"
                  display="flex"
                  gap="1rem"
                >
                  <li
                    className={`${styles.stepIndicatorBall} ${styles.stepIndicatorBallActive}`}
                  ></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                </MDBox>

                <MDBox
                  flex="1"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <button type="submit" className={styles.button}>
                    Continuar
                  </button>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_TWO:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            component="article"
            borderRadius="lg"
            display="flex"
            flexDirection="column"
          >
            <MDBox component="header" height="8rem" p={2}>
              <MDBox display="flex" alignItems="center" gap="1rem">
                <button
                  title="Voltar para etapa anterior"
                  className={styles.headerBackButton}
                  onClick={previousStep}
                >
                  <img src={arrowLeftIcon} alt="Seta para esquerda" />
                </button>
                <h2 className={styles.headerTitle}>
                  Qual é a sua experiência com investimentos?
                </h2>
              </MDBox>
            </MDBox>
            <MDBox flex="1" p={2} bgColor="#e9ebec" display="flex">
              <MDBox
                flex="1"
                component="form"
                mt={5}
                display="flex"
                flexDirection="column"
                onSubmit={handleFormSubmit}
              >
                <MDBox display="flex" flexDirection="column" gap="1.5rem">
                  <input
                    type="radio"
                    id="option-one"
                    name="options"
                    className={styles.formInput}
                    required
                  />
                  <label
                    htmlFor="option-one"
                    className={`${styles.formLabel} ${
                      styles.formLabelTwo
                    } ${handleActiveOptionClass(CONSTANTS.OPTION_ONE)}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_ONE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_DASHING);
                    }}
                  >
                    <strong>Limitada</strong>
                    <p>Nunca fiz investimentos</p>
                  </label>
                  <input
                    type="radio"
                    id="option-two"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-two"
                    className={`${styles.formLabel} ${
                      styles.formLabelTwo
                    } ${handleActiveOptionClass(CONSTANTS.OPTION_TWO)}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_TWO);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_MODERATE);
                    }}
                  >
                    <strong>Moderada</strong>
                    <p>
                      Já fiz investimentos em renda fixa, CBDs, tesouro direto,
                      fundos e outros.
                    </p>
                  </label>
                  <input
                    type="radio"
                    id="option-three"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-three"
                    className={`${styles.formLabel} ${
                      styles.formLabelTwo
                    } ${handleActiveOptionClass(CONSTANTS.OPTION_THREE)}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_THREE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_AGGRESSIVE);
                    }}
                  >
                    <strong>Ampla</strong>
                    <p>
                      Já fiz investimentos em renda variável, como ações e
                      fundos imobiliários.
                    </p>
                  </label>
                </MDBox>

                <MDBox
                  mt={3}
                  component="ul"
                  alignSelf="center"
                  alignItems="center"
                  display="flex"
                  gap="1rem"
                >
                  <li className={styles.stepIndicatorBall}></li>
                  <li
                    className={`${styles.stepIndicatorBall} ${styles.stepIndicatorBallActive}`}
                  ></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                </MDBox>

                <MDBox
                  flex="1"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <button type="submit" className={styles.button}>
                    Continuar
                  </button>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_THREE:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            component="article"
            borderRadius="lg"
            display="flex"
            flexDirection="column"
          >
            <MDBox component="header" height="8rem" p={2}>
              <MDBox display="flex" alignItems="center" gap="1rem">
                <button
                  title="Voltar para etapa anterior"
                  className={styles.headerBackButton}
                  onClick={previousStep}
                >
                  <img src={arrowLeftIcon} alt="Seta para esquerda" />
                </button>
                <h2 className={styles.headerTitle}>
                  Por que você decidiu investir com a FIDO?
                </h2>
              </MDBox>
            </MDBox>
            <MDBox flex="1" p={2} bgColor="#e9ebec" display="flex">
              <MDBox
                flex="1"
                component="form"
                mt={5}
                display="flex"
                flexDirection="column"
                onSubmit={handleFormSubmit}
              >
                <MDBox display="flex" flexDirection="column" gap="1.5rem">
                  <input
                    type="radio"
                    id="option-one"
                    name="options"
                    className={styles.formInput}
                    required
                  />
                  <label
                    htmlFor="option-one"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_ONE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_ONE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_AGGRESSIVE);
                    }}
                  >
                    Gostaria de ter retorno no curto prazo.
                  </label>
                  <input
                    type="radio"
                    id="option-two"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-two"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_TWO
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_TWO);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_MODERATE);
                    }}
                  >
                    Prefiro investimentos de maior prazo e com retorno mais
                    consistente.
                  </label>
                  <input
                    type="radio"
                    id="option-three"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-three"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_THREE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_THREE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_DASHING);
                    }}
                  >
                    Procuro uma modalidade de investimento com maior
                    previsibilidade.
                  </label>
                </MDBox>

                <MDBox
                  mt={5}
                  component="ul"
                  alignSelf="center"
                  alignItems="center"
                  display="flex"
                  gap="1rem"
                >
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li
                    className={`${styles.stepIndicatorBall} ${styles.stepIndicatorBallActive}`}
                  ></li>
                  <li className={styles.stepIndicatorBall}></li>
                </MDBox>

                <MDBox
                  flex="1"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <button type="submit" className={styles.button}>
                    Continuar
                  </button>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_FOUR:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            component="article"
            borderRadius="lg"
            display="flex"
            flexDirection="column"
          >
            <MDBox component="header" height="8rem" p={2}>
              <MDBox display="flex" alignItems="center" gap="1rem">
                <button
                  title="Voltar para etapa anterior"
                  className={styles.headerBackButton}
                  onClick={previousStep}
                >
                  <img src={arrowLeftIcon} alt="Seta para esquerda" />
                </button>
                <h2 className={styles.headerTitle}>
                  No último ano, com que frequência você fez aplicações
                  financeiras?
                </h2>
              </MDBox>
            </MDBox>
            <MDBox flex="1" p={2} bgColor="#e9ebec" display="flex">
              <MDBox
                flex="1"
                component="form"
                mt={5}
                display="flex"
                flexDirection="column"
                onSubmit={handleFormSubmit}
              >
                <MDBox display="flex" flexDirection="column" gap="1.5rem">
                  <input
                    type="radio"
                    id="option-one"
                    name="options"
                    className={styles.formInput}
                    required
                  />
                  <label
                    htmlFor="option-one"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_ONE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_ONE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_DASHING);
                    }}
                  >
                    Nenhuma
                  </label>
                  <input
                    type="radio"
                    id="option-two"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-two"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_TWO
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_TWO);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_MODERATE);
                    }}
                  >
                    Uma vez a cada 3 meses
                  </label>
                  <input
                    type="radio"
                    id="option-three"
                    name="options"
                    className={styles.formInput}
                  />
                  <label
                    htmlFor="option-three"
                    className={`${styles.formLabel} ${handleActiveOptionClass(
                      CONSTANTS.OPTION_THREE
                    )}`}
                    onClick={() => {
                      setSelectedOptionIndex(CONSTANTS.OPTION_THREE);
                      setCurrentAnswer(CONSTANTS.TYPE_PROFILE_AGGRESSIVE);
                    }}
                  >
                    Praticamente todos os meses
                  </label>
                </MDBox>

                <MDBox
                  mt={5}
                  component="ul"
                  alignSelf="center"
                  alignItems="center"
                  display="flex"
                  gap="1rem"
                >
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li className={styles.stepIndicatorBall}></li>
                  <li
                    className={`${styles.stepIndicatorBall} ${styles.stepIndicatorBallActive}`}
                  ></li>
                </MDBox>

                <MDBox
                  flex="1"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <button type="submit" className={styles.button}>
                    Continuar
                  </button>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_FIVE:
        return (
          <MDBox
            width="100%"
            height="100%"
            maxHeight="46rem"
            bgColor="#fff"
            component="article"
            borderRadius="lg"
            display="flex"
            flexDirection="column"
          >
            <MDBox
              component="header"
              width="100%"
              height="14rem"
              bgColor="linear-gradient(270deg, #305569 0%, #396277 100%);"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
            >
              <MDBox
                mr={4}
                component="img"
                height="9rem"
                src={investorProfileIcon}
                alt="Pessoa acompanhada de um celular"
              />
              <MDBox>
                <MDTypography
                  component="strong"
                  fontWeight="bold"
                  sx={{
                    fontSize: "0.875rem",
                    lineHeight: "150%",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Perfil Investidor
                </MDTypography>
                <MDTypography
                  variant="h2"
                  fontWeight="light"
                  sx={{ fontSize: "3rem", lineHeight: "110%", color: "#fff" }}
                >
                  {handleStepFiveTitle(investorProfile)}
                </MDTypography>
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="column"
              gap="1rem"
              py={3}
              px={2}
            >
              <p className={styles.textBodyStepFive}>
                {isInvestorProfileAggressive() || isInvestorProfileModerate()
                  ? "Parece que você já tem experiência em investimentos."
                  : "Sua prioridade é a segurança na hora de investir."}
              </p>
              <p className={styles.textBodyStepFive}>
                {isInvestorProfileAggressive() || isInvestorProfileModerate()
                  ? "O empréstimo entre pessoas é uma modalidade que envolve risco para o investidor, embora a Fido promova a diversificação dos investimentos entre vários beneficiários de forma a minimizar as perdas."
                  : "Embora a Fido promova a diversificação dos investimentos entre vários beneficiários de forma a minimizar as perdas, o empréstimo entre pessoas é uma modalidade que envolve risco para o investidor."}
              </p>
              <p className={styles.textBodyStepFive}>
                {isInvestorProfileAggressive() || isInvestorProfileModerate()
                  ? "Esteja atento a esse aspecto ao escolher a composição dos escores de risco nos seus investimentos."
                  : "Caso você queira conhecer o empréstimo entre pessoas, sugerimos começar com uma quantia menor e escolher a opção de menor risco entre as oportunidades oferecidas."}
              </p>
            </MDBox>

            <MDBox
              flex="1"
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              pb={4}
            >
              <button className={styles.button} onClick={returnInit}>
                Finalizar
              </button>
            </MDBox>
          </MDBox>
        );

      case CONSTANTS.STEP_SIX:
        return <h1>Etapa 6</h1>;

      default:
        return null;
    }
  }

  useEffect(() => {
    if (stepIndex !== CONSTANTS.STEP_FIVE) return;

    let withoutDuplicateAnswers = [...new Set(answers)];

    const profilesTypesAndAmounts = withoutDuplicateAnswers.map(
      (profileType) => {
        const amount = answers.filter(
          (profileTypeItem) => profileTypeItem === profileType
        ).length;

        return {
          profileType,
          amount,
        };
      }
    );

    profilesTypesAndAmounts.sort((itemA, itemB) => {
      if (itemA.amount > itemB.amount) return -1;
      if (itemA.amount < itemB.amount) return 1;
      return 0;
    });

    // console.log(profilesTypesAndAmounts);
    const [{ profileType }] = profilesTypesAndAmounts;
    // console.log(profileType);
    setInvestorProfile(profileType);
  }, [stepIndex]);

  return renderSteps();
}

export default CardInvestorProfile;
