import { useEffect, useRef, useState } from "react";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { ModalConfirmPassword } from "../ModalConfirmPassword";
import { ModalEditBankAccount } from "../ModalEditBankAccount";
import { ModalEditAddress } from "../ModalEditAddress";
import { ModalEditEmail } from "../ModalEditEmail";

import { getBankList } from "services/bank";
import { editUserData } from "services/user";
import { formatDateToPtBr } from "../../../../../utils";

import styles from "./styles.module.css";
import editIcon from "../../../../../assets/images/edit.svg";

function CardForm({ loggedUser, setIsLoadingBankList }) {
  // const [userBankDetails, setUserBankDetails] = useState(null);
  const [email, setEmail] = useState("");
  // const [address, setAddress] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [base, setBase] = useState("");
  const [bank, setBank] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [agency, setAgency] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountDigit, setAccountDigit] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  // const [isEditAddress, setIsEditAddress] = useState(false);
  // const [isEditBankAccount, setIsEditBankAccount] = useState(false);
  const [isOpenModalEditEmail, setIsOpenModalEditEmail] = useState(false);
  const [isOpenModalEditAddress, setIsOpenModalEditAddress] = useState(false);
  const [isOpenModalConfirmPassword, setIsOpenModalConfirmPassword] =
    useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [configAlert, setConfigAlert] = useState({ status: "", message: "" });
  const [isOpenModalEditBankAccount, setIsOpenModalEditBankAccount] =
    useState(false);
  const [banksList, setBanksList] = useState([]);
  const [currentBank, setCurrentBank] = useState("");
  const inputEmailRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const token = localStorage.getItem("token");

  const activateLoading = () => setIsLoading(true);

  const deactivateLoading = () => setIsLoading(false);

  const openModalConfirmPassword = () => setIsOpenModalConfirmPassword(true);

  const closeModalConfirmPassword = () => setIsOpenModalConfirmPassword(false);

  const closeModalEditEmail = () => setIsOpenModalEditEmail(false);

  const openModalEditEmail = () => setIsOpenModalEditEmail(true);

  const openModalEditAddress = () => setIsOpenModalEditAddress(true);

  const closeModalEditAddress = () => setIsOpenModalEditAddress(false);

  const closeModalEditBankAccount = () => setIsOpenModalEditBankAccount(false);

  const openModalEditBankAccount = () => setIsOpenModalEditBankAccount(true);

  function joinBanks(banks) {
    const allBanks = [...banks?.top_banks, ...banks?.others];
    return allBanks;
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setIsShowAlert(false);
  }

  async function handleSubmitEditEmail(event) {
    event.preventDefault();
    activateLoading();

    try {
      const response = await editUserData({ email });

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      setConfigAlert({
        status: "success",
        message: "Email alterado com sucesso",
      });

      // const updatedLoggedUser = { ...loggedUser, email }
      sessionStorage.removeItem("loggedUser");
    } catch (error) {
      console.error(error);
      console.error("Erro ao alterar email");
      setConfigAlert({
        status: "error",
        message:
          "Ocorreu um problema ao alterar o email. Tente novamente, mais tarde",
      });
    } finally {
      deactivateLoading();
      closeModalEditEmail();
      setIsShowAlert(true);
    }
  }

  async function handleSubmitEditAddress(event) {
    event.preventDefault();
    activateLoading();

    try {
      const response = await editUserData({
        address: { zipCode, comp: complement, district, city, state, base },
      });

      if (response?.data?.status_code !== 200) throw new Error();

      setConfigAlert({
        status: "success",
        message: "Endereço alterado com sucesso",
      });

      sessionStorage.removeItem("loggedUser");
    } catch (error) {
      console.error(error);
      console.error("Erro ao alterar dados de endereço");
      setConfigAlert({
        status: "error",
        message:
          "Ocorreu um problema ao alterar dados do endereço. Tente novamente, mais tarde",
      });
    } finally {
      deactivateLoading();
      closeModalEditAddress();
      setIsShowAlert(true);
    }
  }

  useEffect(() => {
    const bankListStoraged = sessionStorage.getItem("bankList");

    async function loadBankList() {
      setIsLoadingBankList(true);

      try {
        const data = await getBankList();
        const allBanks = joinBanks(data);

        setBanksList(allBanks);

        sessionStorage.setItem("bankList", JSON.stringify(dataBankList));
      } catch (error) {
        console.error("Erro so buscar por lista de bancos");
        console.error(error);
      } finally {
        setIsLoadingBankList(false);
      }
    }

    if (!bankListStoraged) {
      loadBankList();
      return;
    }

    const allBanks = joinBanks(JSON.parse(bankListStoraged));
    setBanksList(allBanks);
  }, []);

  useEffect(() => {
    if (!loggedUser) return;

    const formattedBirthdate = loggedUser?.birthDate
      ? formatDateToPtBr(loggedUser?.birthDate)
      : "";
    setBirthdate(formattedBirthdate);
    setEmail(loggedUser?.email);
    setCpf(loggedUser?.cpf);
    setPhone(loggedUser?.phone);
    setZipCode(loggedUser?.address?.zipCode);
    setComplement(loggedUser?.address?.comp);
    setDistrict(loggedUser?.address?.district);
    setCity(loggedUser?.address?.city);
    setState(loggedUser?.address?.state);
    setBase(loggedUser?.address?.base);

    setBankAccountType(loggedUser?.bankingDetails?.bankAccountType);
    setBankCode(loggedUser?.bankingDetails?.bankCode);
    setAgency(loggedUser?.bankingDetails?.agency);
    setAccountNumber(loggedUser?.bankingDetails?.account);
    setAccountDigit(loggedUser?.bankingDetails?.accountDigit);
  }, [loggedUser]);

  useEffect(() => {
    if (!bank) return;
    if (!bank?.includes("-")) {
      setBankCode("");
      return;
    }

    const dataBank = bank?.split("-");
    const _code = dataBank?.at(-1)?.trim();
    setBankCode(_code);
  }, [bank]);

  return (
    <>
      <MDBox
        width="100%"
        height="100%"
        bgColor="#fff"
        p={2}
        component="article"
        borderRadius="lg"
      >
        <MDTypography
          variant="h2"
          fontWeight="light"
          sx={{
            fontSize: "1.5rem",
            lineHeight: "calc(1.5rem * 1.3)",
            color: "#3D555A",
            fontWeight: 300,
          }}
        >
          Meus dados
        </MDTypography>
        <MDBox
          mt={3}
          component="form"
          display="flex"
          flexDirection="column"
          gap="1.5rem"
        >
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox display="flex" flexDirection="column" gap="0.25rem">
              <label htmlFor="email" className={styles.label}>
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={styles.input}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                readOnly={!isEditEmail}
                ref={inputEmailRef}
              />
            </MDBox>
            {/* <button
              type="button"
              title="Clique para editar e-mail"
              className={styles.editButton}
              onClick={() => {
                setIsEditEmail(!isEditEmail);
                openModalEditEmail();
                inputEmailRef.current.focus();
              }}
            >
              <img src={editIcon} alt="Editar" />
            </button> */}
          </MDBox>

          <MDBox display="block" border="1px solid rgba(0, 0, 0, 0.05)"></MDBox>

          <MDBox display="flex" flexDirection="column" gap="0.25rem">
            <label htmlFor="birthdate" className={styles.label}>
              Data de nascimento
            </label>
            <input
              type="text"
              name="birthdate"
              id="birthdate"
              className={styles.input}
              value={birthdate}
              readOnly
            />
          </MDBox>

          <MDBox display="block" border="1px solid rgba(0, 0, 0, 0.05)"></MDBox>

          <MDBox display="flex" flexDirection="column" gap="0.25rem">
            <label htmlFor="cpf" className={styles.label}>
              CPF
            </label>
            <input
              type="text"
              name="cpf"
              id="cpf"
              className={styles.input}
              value={cpf}
              readOnly
            />
          </MDBox>

          <MDBox display="block" border="1px solid rgba(0, 0, 0, 0.05)"></MDBox>

          <MDBox display="flex" flexDirection="column" gap="0.25rem">
            <label htmlFor="phone" className={styles.label}>
              Telefone
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              className={styles.input}
              value={phone}
              readOnly
            />
          </MDBox>

          <MDBox display="block" border="1px solid rgba(0, 0, 0, 0.05)"></MDBox>

          {/* <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox display="flex" flexDirection="column" gap="0.25rem">
              <label htmlFor="address" className={styles.label}>
                Endereço
              </label>
              <textarea
                name="address"
                id="address"
                rows="5"
                className={styles.input}
                value={address}
                readOnly
              />
            </MDBox>
            <button
              type="button"
              title="Clique para editar endereço"
              className={styles.editButton}
              onClick={() => {
                setIsEditAddress(!isEditAddress);
                openModalEditAddress();
              }}
            >
              <img src={editIcon} alt="Editar" />
            </button>
          </MDBox>

          <MDBox display="block" border="1px solid rgba(0, 0, 0, 0.05)"></MDBox> */}

          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox display="flex" flexDirection="column" gap="0.25rem">
              <span className={styles.label}>Conta bancária</span>
            </MDBox>
            <button
              type="button"
              title="Clique para editar conta bancária"
              className={styles.editButton}
              onClick={openModalConfirmPassword}
            >
              <img src={editIcon} alt="Editar" />
            </button>
          </MDBox>
        </MDBox>
      </MDBox>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isShowAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={configAlert?.status}
          sx={{ width: "100%" }}
        >
          {configAlert?.message}
        </Alert>
      </Snackbar>

      {isLoading && <Loading />}

      {isOpenModalEditEmail && (
        <ModalEditEmail
          close={closeModalEditEmail}
          email={email}
          setEmail={setEmail}
          isMobile={isMobile}
          handleSubmitEditEmail={handleSubmitEditEmail}
        />
      )}

      {isOpenModalEditAddress && (
        <ModalEditAddress
          close={closeModalEditAddress}
          base={base}
          setBase={setBase}
          city={city}
          setCity={setCity}
          complement={complement}
          setComplement={setComplement}
          district={district}
          setDistrict={setDistrict}
          state={state}
          setState={setState}
          zipCode={zipCode}
          setZipCode={setZipCode}
          isMobile={isMobile}
          handleSubmitEditAddress={handleSubmitEditAddress}
        />
      )}

      {isOpenModalEditBankAccount && (
        <ModalEditBankAccount
          close={closeModalEditBankAccount}
          banksList={banksList}
          bankCode={bankCode}
          bankAccountType={bankAccountType}
          setBankAccountType={setBankAccountType}
          agency={agency}
          setAgency={setAgency}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          accountDigit={accountDigit}
          setAccountDigit={setAccountDigit}
          bank={bank}
          setBank={setBank}
          setIsShowAlert={setIsShowAlert}
          setConfigAlert={setConfigAlert}
          activateLoading={activateLoading}
          deactivateLoading={deactivateLoading}
          closeModalEditBankAccount={closeModalEditBankAccount}
          token={token}
          loggedUser={loggedUser}
        />
      )}

      {isOpenModalConfirmPassword && (
        <ModalConfirmPassword
          close={closeModalConfirmPassword}
          openModalEditBankAccount={openModalEditBankAccount}
          activateLoading={activateLoading}
          deactivateLoading={deactivateLoading}
          token={token}
        />
      )}
    </>
  );
}

export default CardForm;
