import { api } from "./api";

async function getReceivementsByMonth() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investor/wallet/receivements_by_month", {
      headers: {
        Authorization: tokenWithoutQuotes,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

export { getReceivementsByMonth };
