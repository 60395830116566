import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { formatMoney, formatDateToPtBr } from "utils";
import { approveDirectLoan, refuseDirectLoan } from "services/direct-loan";
import { getStoredValue } from "metrics/storage";

import styles from "./styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";
import infoIcon from "assets/images/icons/info-icon.svg";

const CONSTANTS = {
  DIRECT_LOAN: {
    STATUS: {
      PENDING: "CONFIRMED",
      APPROVED: "ACTIVE",
      REFUSED: "CANCELLED",
    },
  },
};

function ModalContractDetails({
  closeModal,
  selectedDirectLoan,
  activeLoading,
  disableLoading,
  setAlertConfig,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const [typeOperation, setTypeOperation] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const token = getStoredValue("token");
  const navigate = useNavigate();
  const directLoanIsPending =
    selectedDirectLoan?.loan?.status === CONSTANTS.DIRECT_LOAN.STATUS.PENDING;

  const nextStep = () => setCurrentStep(currentStep + 1);

  const previousStep = () => setCurrentStep(currentStep - 1);

  const goToStep = (stepNumber) => setCurrentStep(stepNumber);

  const refreshPage = () => navigate(0);

  async function handleApproveDirectLoan() {
    activeLoading();
    let response = null;

    try {
      response = await approveDirectLoan(token, selectedDirectLoan.id);

      if (response.status !== 200) {
        throw new Error();
      }

      sessionStorage.setItem(
        "resultDirectLoanOperation",
        JSON.stringify({ type: "approve", success: true })
      );
    } catch (error) {
      console.error("Erro ao aprovar empréstimo fácil");
      if (response) {
        sessionStorage.setItem(
          "resultDirectLoanOperation",
          JSON.stringify({ type: "approve", success: false, error: response.data.error })
        );
      }
    } finally {
      setTimeout(() => {
        refreshPage();
      }, [2000]);
      disableLoading();
      closeModal();
      setAlertConfig({
        isOpen: true,
        type: "warning",
        message: "Atualizando...",
      });
    }
  }

  async function handleRefuseDirectLoan() {
    activeLoading();

    try {
      const response = await refuseDirectLoan(token, selectedDirectLoan.id);

      if (response.status !== 200) {
        throw new Error();
      }

      sessionStorage.setItem(
        "resultDirectLoanOperation",
        JSON.stringify({ type: "refuse", success: true })
      );
    } catch (error) {
      console.error("Erro ao recusar empréstimo direto");
      sessionStorage.setItem(
        "resultDirectLoanOperation",
        JSON.stringify({ type: "refuse", success: false })
      );
    } finally {
      setTimeout(() => {
        refreshPage();
      }, [2000]);
      disableLoading();
      closeModal();
      setAlertConfig({
        isOpen: true,
        type: "warning",
        message: "Atualizando...",
      });
    }
  }

  function getTypeOperationLabel(typeOperation) {
    if (typeOperation === "approve") {
      return "aprovar";
    } else if (typeOperation === "refuse") {
      return "recusar";
    }

    return null;
  }

  function getRenderCotent() {
    if (currentStep === 1) {
      return (
        <>
          <header className={styles.header}>
            <h2 className={styles.headerTitle}>Dados do contrato</h2>
            <button
              className={styles.headerCloseButton}
              title="Fechar"
              onClick={closeModal}
            >
              <img src={closeCircleIcon} alt="Fechar" />
            </button>
          </header>

          <MDBox
            mt="2rem"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <div className={styles.dataContainerColumn}>
              <strong>Código</strong>
              <p>{selectedDirectLoan?.investor_code}</p>
            </div>
            <div className={styles.dataContainerColumn}>
              <strong>Margem livre mensal</strong>
              <p>
                {formatMoney(selectedDirectLoan?.userTaker[0]?.max_installment)}
              </p>
            </div>
            <div className={styles.dataContainerColumn}>
              <div
                style={{
                  display: "flex",
                  gap: 15,
                }}
              >
                <strong>Score</strong>
                <button
                  className={styles.buttonInfo}
                  onClick={() => setShowInfo(!showInfo)}
                >
                  <img width={20} src={infoIcon} alt="ícone de informação" />
                </button>
              </div>
              <p>{selectedDirectLoan?.taker?.score}</p>
            </div>
            {showInfo && (
              <div className={styles.dataContainerInfoScore}>
                <p
                  style={{
                    fontSize: 12,
                    textAlign: "justify",
                    lineHeight: 2,
                  }}
                >
                  <strong>Score A e B:</strong> Perfis associados a baixo risco
                  de inadimplência, proporcionando taxas de juros menores e
                  consequentemente menor retorno financeiro do seu investimento.{" "}
                  <br />
                  <strong>Score C:</strong> Perfil caracterizado por risco
                  moderado de inadimplência, resultando em taxas de juros
                  intermediárias e retorno financeiro médio do seu investimento.{" "}
                  <br />
                  <strong>Score D ao H:</strong> Perfil vinculado a alto risco
                  de inadimplência, implicando em taxas de juros elevadas e
                  maior potencial de retorno financeiro do seu investimento.
                  <br />
                  <strong>Observação:</strong> Quanto maior o score (A e B),
                  menor será o lucro obtido no investimento devido ao baixo
                  risco de inadimplência. Por outro lado, quando o score é menor
                  (D), o retorno financeiro tende a ser maior, embora apresente
                  um risco de inadimplência mais elevado.
                </p>
              </div>
            )}
          </MDBox>

          <h3 className={styles.sectionTitle}>Dados do usuário</h3>

          <ul className={styles.containerDataGrid}>
            <li className={styles.dataContainerColumn}>
              <strong>Nome do Beneficiário</strong>
              <p>{selectedDirectLoan?.userTaker[0]?.fullname}</p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>CPF</strong>
              <p>{selectedDirectLoan?.userTaker[0]?.cpf}</p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>Data de Nascimento</strong>
              <p>
                {formatDateToPtBr(selectedDirectLoan?.userTaker[0]?.birthDate)}
              </p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>Telefone</strong>
              <p>{selectedDirectLoan.userTaker[0]?.phone}</p>
            </li>
            {/* <li className={styles.dataContainerColumn}>
            <strong>Renda</strong>
            <p>R$ 3.000,00 a R$ 5.000,00</p>
          </li> */}
          </ul>

          <h3 className={styles.sectionTitle}>Dados de endereço</h3>

          {selectedDirectLoan?.userTaker[0]?.address ? (
            <ul className={styles.containerDataGrid}>
              <li className={styles.dataContainerColumn}>
                <strong>CEP</strong>
                <p>{selectedDirectLoan?.userTaker[0]?.address?.zipCode}</p>
              </li>
              <li className={styles.dataContainerColumn}>
                <strong>Endereço</strong>
                <p>{`${selectedDirectLoan?.userTaker[0]?.address?.base}, ${selectedDirectLoan?.userTaker[0]?.address?.district}`}</p>
              </li>
              <li className={styles.dataContainerColumn}>
                <strong>Número</strong>
                <p>{selectedDirectLoan?.userTaker[0]?.address?.number}</p>
              </li>
              <li className={styles.dataContainerColumn}>
                <strong>Cidade</strong>
                <p>{selectedDirectLoan?.userTaker[0]?.address?.city}</p>
              </li>
              <li className={styles.dataContainerColumn}>
                <strong>UF</strong>
                <p>{selectedDirectLoan?.userTaker[0]?.address?.state}</p>
              </li>
            </ul>
          ) : (
            <MDTypography component="p" sx={{ fontSize: "0.875rem" }}>
              Não informado
            </MDTypography>
          )}

          <h3 className={styles.sectionTitle}>Dados do empréstimo</h3>

          <ul className={styles.containerDataGrid}>
            <li className={styles.dataContainerColumn}>
              <strong>Valor da parcela</strong>
              <p>{formatMoney(selectedDirectLoan?.loan?.installment)}</p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>Número de parcelas</strong>
              <p>{selectedDirectLoan?.installments?.length}x</p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>Juros do investidor</strong>
              <p>
                {selectedDirectLoan?.rates?.accumulative?.investor_rate.toLocaleString(
                  "pt-BR",
                  {
                    minimumFractionDigits: 2,
                  }
                )}
                % a.m.
              </p>
            </li>
            <li className={styles.dataContainerColumn}>
              <strong>Valor solicitado</strong>
              <p>{formatMoney(selectedDirectLoan?.loan?.amount_collected)}</p>
            </li>
          </ul>

          {directLoanIsPending && (
            <div className={styles.containerButtons}>
              <MDButton
                variant="contained"
                color="info"
                fullWidth
                onClick={() => {
                  setTypeOperation("approve");
                  nextStep();
                }}
              >
                Emprestar
              </MDButton>
              <MDButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  setTypeOperation("refuse");
                  nextStep();
                }}
              >
                Recusar
              </MDButton>
            </div>
          )}
        </>
      );
    } else if (currentStep === 2) {
      return (
        <>
          <button
            className={`${styles.headerCloseButton} ${styles.closeButtonRight}`}
            title="Fechar"
            onClick={() => {
              closeModal();
              goToStep(1);
            }}
          >
            <img src={closeCircleIcon} alt="Fechar" />
          </button>
          <p className={styles.messageLabel}>
            Você tem certeza que deseja {getTypeOperationLabel(typeOperation)}{" "}
            este empréstimo?
          </p>
          <div className={styles.containerButtons}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              onClick={
                typeOperation === "approve"
                  ? handleApproveDirectLoan
                  : handleRefuseDirectLoan
              }
            >
              Sim
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={previousStep}
            >
              Não
            </MDButton>
          </div>
        </>
      );
    }

    return null;
  }

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>{getRenderCotent()}</aside>
    </div>
  );
}

export { ModalContractDetails };
