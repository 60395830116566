
import MDBox from "../MDBox";

import styles from "./styles.module.css";

function Loading() {
  return (
    <MDBox
      position="fixed"
      top="0"
      right="0"
      left="0"
      bottom="0"
      zIndex="1000000"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgColor="rgba(255, 255, 255, 0.9)"
    >
      <div className={styles.spinner}></div>
    </MDBox>
  );
}

export default Loading;
