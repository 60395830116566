import React, { useState } from "react";

import styles from "./styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";

function ModalRulesShare({ closeModal }) {
  const handleClose = () => {
    closeModal();
  };
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.title}>
            TERMOS E CONDIÇÕES PARA CONVIDAR INVESTIDORES
          </h2>
          <button
            className={styles.headerCloseButton}
            title="Fechar"
            onClick={handleClose}
          >
            <img src={closeCircleIcon} alt="Fechar" />
          </button>
        </header>

        <p className={styles.subtitle}>
          Termos e Condições para a Campanha de Recompensa por Indicação da Fido
          Sociedade de Empréstimo Entre Pessoas S.A.
        </p>
        <p className={styles.instructions}>
          A Fido Sociedade de Empréstimo Entre Pessoas S.A. ("Fido") é uma instituição financeira
          autorizada pelo Banco Central do Brasil, com CNPJ nº 40.819.834/0001-48 e sede na
          SGAN 601 conjunto H, CEP 70.830-010. Estes Termos e Condições ("Termos") regem a
          utilização da Campanha de Recompensa por Indicação de Investidores realizada pela
          Fido.
        </p>
        <p className={styles.subtitle}>
          1. Uso de Licença
        </p>
        <p className={styles.instructions}>
          Ao aderir a estes Termos, você concorda em participar do sistema de recompensas da
          Fido para a indicação de novos investidores. De acordo com esta campanha, os
          investidores com contas ativas podem convidar novos investidores para a plataforma e receber
          uma recompensa no valor de R$100,00 (cem) reais, que será creditada em seu saldo
          disponível.
        </p>
        <p className={styles.subtitle}>2. Detalhes da Campanha de Recompensa por Indicação</p>
        <p className={styles.instructions}>
          A campanha de recompensa por indicação tem como objetivo incentivar os investidores com contas
          ativas da plataforma Fido a convidarem novos investidores. Quando um investidor
          envia um convite bem-sucedido a um novo investidor, que por sua vez efetua o cadastro,
          realiza seu primeiro depósito para investimento e atinge o mínimo de investimento
          elegível de R$1.500,00 (mil e quinhentos) reais na plataforma, o investidor que fez o
          convite estará apto a receber uma recompensa no valor de R$100,00 (cem) reais.
        </p>
        <p className={styles.subtitle}>
          2.1 Bônus de recompensa por indicação
        </p>
        <p className={styles.instructions}>
          O valor da recompensa de R$100,00 (cem) reais será depositado automaticamente na
          conta do investidor que fez o convite, após a conclusão de todos os critérios pré-
          estabelecidos. O montante estará disponível em seu saldo disponível na sua conta de
          Investidor.
        </p>
        <p className={styles.subtitle}>
          2.2 Limite de recompensa por convite e compartilhamento de link
        </p>
        <p className={styles.instructions}>
          O link de indicação pode ser compartilhado com um número ilimitado de novos investidores. 
          No entanto, é importante observar que o valor da recompensa, que é de R$100,00 (cem) reais, 
          é limitado por convite, conforme os critérios estabelecidos. Isso significa que, para cada 
          novo investidor convidado que atenda a esses critérios, o investidor que enviou o convite 
          será qualificado para receber a recompensa de R$100,00 (cem) reais, respeitando o 
          limite por convite.
        </p>
        <p className={styles.subtitle}>
          2.3 Vigência da campanha
        </p>
        <p className={styles.instructions}>
          A campanha de recompensa por indicação estará em vigor a partir de 13/10/2023 por
          tempo indeterminado.
        </p>
        <p className={styles.subtitle}>
          2.4 Regras da campanha
        </p>
        <p className={styles.instructions}>
          É importante destacar que a recompensa será concedida somente se todos os seguintes critérios forem atendidos:
          <ul className={styles.itemList}>
            <li>O novo investidor convidado deve ter seu cadastro ativo na plataforma web.</li>
            <li>O novo investidor realiza seu primeiro depósito para investimento na Fido.</li>
            <li>O novo investidor atinge o mínimo de investimento elegível para a campanha, que é de R$1.500,00 (mil e quinhentos) reais, podendo ser alcançado em mais de um investimento.</li>
            <li>O valor total do mínimo de R$1.500,00 (mil e quinhentos) reais investido pelo convidado deve estar integralmente emprestado para os clientes tomadores da Fido.</li>
          </ul>
        </p>
        <p className={styles.subtitle}>
          3. Produtos de Empréstimo Disponíveis
        </p>
        <p className={styles.instructions}>
          A Fido oferece dois produtos de empréstimo:
        </p>
        <p className={styles.instructions}>
          <strong>Empréstimo diversificado:</strong> Neste modelo, o valor é distribuído entre vários
          tomadores de empréstimo. A recompensa por indicação só será gerada após o valor de R$1.500,00
          (mil e quinhentos) reais ter sido inteiramente emprestado pelos novos investidores
          convidados.
        </p>
        <p className={styles.instructions}>
          <strong>Empresta fácil:</strong> Neste caso, o valor do empréstimo é destinado a uma única pessoa.
          Assim como no Empréstimo diversificado, a recompensa por indicação será creditada
          somente após o valor de R$1.500,00 (mil e quinhentos) reais ter sido inteiramente
          emprestado.
        </p>
        <p className={styles.subtitle}>
          4. Considerações Finais
        </p>
        <p className={styles.instructions}>
          A Fido Sociedade de Empréstimo Entre Pessoas S.A. se reserva o direito de encerrar ou
          modificar esta campanha a qualquer momento, mediante aviso prévio. Ao aderir a esta
          campanha, os investidores concordam em cumprir os Termos de Uso da Fido.
        </p>
        <p className={styles.instructions}>
          Em caso de dúvidas ou informações adicionais sobre a campanha de recompensa por
          indicação, entre em contato com o suporte ao cliente da Fido.
        </p>
        <p className={styles.instructions}>
          Comece a convidar seus amigos para investir nesta revolução do mercado financeiro e
          aproveite os benefícios da nossa campanha de recompensa por indicação!
        </p>
      </aside>
    </div>
  );
}

export { ModalRulesShare };
