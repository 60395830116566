import React from "react";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDDatePicker from "../../../../../components/MDDatePicker";

import styles from "./styles.module.css";

function CardChooseLimitDate({ setCurrentRenderedContent }) {
  const [deadline, setDeadline] = React.useState(new Date());

  return (
    <MDBox
      width="100%"
      height="100%"
      bgColor="#fff"
      borderRadius="lg"
      shadow="lg"
      p={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <MDTypography variant="body1" fontWeight="light" sx={{ color: "#3D555A" }}>
        Até quando você quer deixar o investimento automático ativado?
      </MDTypography>
      <MDBox mt="2.5rem" width="20rem" display="flex">
        <button className={`${styles.button} ${styles.buttonActive} ${styles.buttonLeft}`}>
          Escolha a data
        </button>
        <button className={`${styles.button} ${styles.buttonRight}`}>Sem data limite</button>
      </MDBox>
      <MDBox mt={4}>
        <MDDatePicker input={{ placeholder: "Data limite" }} />
      </MDBox>
    </MDBox>
  );
}

export default CardChooseLimitDate;
