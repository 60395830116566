import axios from "axios";

// const CONSTANTS = {
//   API_DEV: "https://api-dev.fintechp2p.com",
//   API_PROD: "https://api.fintechp2p.com/v1"
// };

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setupInterceptors = (navigate) => {
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log('CONFIG ERROR: ', error.config)
      if (error.response && error.response.status === 403) {
        const originalRequest = error.config;

        if (!originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshToken = localStorage.getItem("refreshToken").replace(/['"]+/g, '');
            if (refreshToken) {
              const response = await api.post('/user/refresh_token', { token: refreshToken });
              const { token } = response.data;

              localStorage.setItem('token', token);

              await new Promise(resolve => setTimeout(resolve, 5000));

              const tokenWithoutQuotes = token.replace(/['"]+/g, "");
              originalRequest.headers.Authorization = tokenWithoutQuotes;

              return api(originalRequest);
            } else {
              throw new Error();
            }

          } catch (error) {
            console.error(error);
            window.alert('Infelizmente sua sessão expirou. Faça login novamente.');
            navigate("/login");
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export { api, setupInterceptors };
