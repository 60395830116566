import React from 'react';

import styles from './styles.module.css';

function VerticalProgress({ value }) {
  const fillStyle = {
    height: `${value / 5}rem`,
  };

  return (
    <div className={styles.verticalProgress}>
      <div className={styles.verticalProgressBar}>
        <div className={styles.fill} style={fillStyle} />
      </div>
    </div>
  );
}

export default VerticalProgress;
