import styles from "./styles.module.css";
import imageErrorCpf from "../../../../../assets/images/phone-error-cpf.svg";

function ModalErrorCpf({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={styles.title}>
          Ops! identificamos um problema com seu CPF
        </h2>
        <img
          src={imageErrorCpf}
          className={styles.illustration}
          alt="Celular bloqueado"
          loading="lazy"
        />
        <p className={styles.description}>
          Infelizmente não podemos prosseguir com a abertura de sua conta neste
          momento.
        </p>
        <p className={styles.description}>
          A Receita Federal tem todos os recursos e informações necessárias para
          oferecer um diagnóstico preciso e orientar você sobre todas as suas
          dúvidas.
        </p>
        <button className={styles.primaryButton} onClick={closeModal}>
          Voltar
        </button>
      </aside>
    </div>
  );
}

export { ModalErrorCpf };
