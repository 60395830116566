import React, { useState, useEffect } from "react";
import { Switch, useMediaQuery } from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import {
  postAutoReinvestment,
  getInvestments,
  getAutoReinvestment,
} from "services/investments";
import { ModalAutoReinvest } from "./Components/ModalAutoReinvest";

import styles from "./styles.module.css";
import infoIcon from "assets/images/icons/info-icon-white.svg";

function CardAutomaticReinvestment({ setIsLoading, setIsLoadingInvestments }) {
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [autoReinvestmentEnabled, setAutoReinvestmentEnabled] = useState("");

  const closeModalInfo = () => setIsOpenModalInfo(false);

  const screenStyles = {
    smallMobile: {
      query: useMediaQuery("(max-width: 767px)"),
      style: {
        pt: 1,
        pb: 1,
        pr: 3,
        pl: 3,
      },
    },
    mobile: {
      query: useMediaQuery("(max-width: 976px)"),
      style: {
        pt: 6,
        pb: 7,
        pr: 2,
        pl: 2,
      },
    },
    x2Small: {
      query: useMediaQuery("(max-width: 1151px)"),
      style: {
        pt: 1,
        pb: 1.5,
        pr: 2,
        pl: 2,
      },
    },
    extraSmall: {
      query: useMediaQuery("(max-width: 1199px)"),
      style: {
        pt: 3,
        pb: 3,
        pr: 2,
        pl: 2,
      },
    },
    small: {
      query: useMediaQuery("(max-width: 1227px)"),
      style: {
        pt: 6,
        pb: 7,
        pr: 3,
        pl: 3,
      },
    },
    mediumSmall: {
      query: useMediaQuery("(max-width: 1288px)"),
      style: {
        pt: 5,
        pb: 5,
        pr: 3,
        pl: 3,
      },
    },
    medium: {
      query: useMediaQuery("(max-width: 1439px)"),
      style: {
        pt: 4.8,
        pb: 5,
        pr: 3,
        pl: 3,
      },
    },
    mediumLarge: {
      query: useMediaQuery("(max-width: 1677px)"),
      style: {
        pt: 1,
        pb: 1.5,
        pr: 3,
        pl: 3,
      },
    },
    large: {
      query: useMediaQuery("(max-width: 1738px)"),
      style: {
        pt: 3,
        pb: 3,
        pr: 3,
        pl: 3,
      },
    },
    extraLarge: {
      query: useMediaQuery("(max-width: 2020px)"),
      style: {
        pt: 1.2,
        pb: 1.2,
        pr: 3,
        pl: 3,
      },
    },
    x2Large: {
      query: useMediaQuery("(max-width: 2870px)"),
      style: {
        pt: 3,
        pb: 3,
        pr: 3,
        pl: 3,
      },
    },
    default: {
      style: {
        pt: 4.5,
        pb: 5,
        pr: 3,
        pl: 3,
      },
    }
  }

  let currentStyle = screenStyles.default.style;

  for (const screen in screenStyles) {
    if (screenStyles[screen].query) {
      currentStyle = screenStyles[screen].style;
      break;
    }
  }

  const { pt, pb, pr, pl } = currentStyle;

  useEffect(() => {
    const storedValue = localStorage.getItem("autoReinvestmentEnabled");
    if (storedValue !== null) {
      setAutoReinvestmentEnabled(storedValue === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("autoReinvestmentEnabled", autoReinvestmentEnabled);
  }, [autoReinvestmentEnabled]);

  async function loadInvestments() {
    setIsLoadingInvestments(true);
    try {
      const responseInvestments = await getInvestments();
      const { status } = responseInvestments;

      if (status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingInvestments(false);
    }
  }

  async function handleAutoReinvestment(bool) {
    const body = {
      status: bool,
    };
    setIsLoading(true);

    try {
      const response = await postAutoReinvestment(body);

      if (response?.status === 200) {
        loadInvestments();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error("Erro ao reinvestir", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function statusAutoReinvestment() {
      setIsLoading(true);

      try {
        const response = await getAutoReinvestment();

        if (response?.status === 200) {
          setAutoReinvestmentEnabled(response?.data.reinvestment_state);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.error("Erro ao reinvestir", error);
      } finally {
        setIsLoading(false);
      }
    }

    statusAutoReinvestment();
  }, []);

  return (
    <>
      <MDBox
        display="flex"
        gap="1rem"
        alignItems="center"
        bgColor="#305568"
        borderRadius="1rem 1rem 0 0"
        shadow="lg"
        p={3}
      >
        <h3 className={styles.title}>Reinvestimento automático</h3>
        <button
          className={styles.buttonInfo}
          onClick={() => setIsOpenModalInfo(!isOpenModalInfo)}
        >
          <img width={20} src={infoIcon} alt="ícone de informação" />
        </button>
      </MDBox>
      <MDBox
        bgColor="#fff"
        borderRadius="0 0 1rem 1rem"
        shadow="lg"
        pt={pt}
        pb={pb}
        pl={pl}
        pr={pr}
      >
        <MDBox mt={2} display="flex" flexDirection="column" gap="1rem">
          <p className={styles.paragraph}>
            Habilitando o reinvestimento automático, seus recebimentos serão
            automaticamente reinvestidos.
          </p>
          <p className={styles.paragraph}>
            Você pode ativar ou desativar esta opção a qualquer momento.{" "}
          </p>
        </MDBox>
        <MDBox
          mt="2rem"
          mb="0.5rem"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <label
            htmlFor="enable-automatic-reinvestment"
            className={styles.label}
          >
            {autoReinvestmentEnabled ? "Habilitado" : "Desabilitado"}
          </label>

          <Switch
            inputProps={{
              id: "enable-automatic-reinvestment",
              "aria-label": "Reinvestimento automático",
            }}
            checked={autoReinvestmentEnabled}
            onChange={(e) => {
              setAutoReinvestmentEnabled(e.target.checked);
              handleAutoReinvestment(e.target.checked);
            }}
          />
        </MDBox>
      </MDBox>
      {isOpenModalInfo && <ModalAutoReinvest closeModal={closeModalInfo} />}
    </>
  );
}

export default CardAutomaticReinvestment;
