import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { formatDateToPtBr, formatMoney } from "utils";

import styles from "../ListItemInvestment/styles.module.css";
import greaterRiskIcon from "assets/images/icons/greater-risk.svg";
import lowerRiskIcon from "assets/images/icons/lower-risk.svg";

function ListItemTransfer({ isMobile, transaction }) {
  return (
    <MDBox
      component="li"
      width="100%"
      height={isMobile ? "auto" : "4.5rem"}
      borderRadius="lg"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      shadow="lg"
    >
      <MDBox
        bgColor="#F2F4F5"
        height="100%"
        py={isMobile ? 1 : 0}
        width={isMobile ? "100%" : "min(18rem, 35%)"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderTopLeftRadius: isMobile ? 0 : "1rem",
          borderBottomLeftRadius: isMobile ? 0 : "1rem",
        }}
      >
        <MDTypography
          variant="body1"
          sx={{
            color: "#3D555A",
            fontSize: isMobile ? "0.875rem" : "1rem",
          }}
        >
          {formatDateToPtBr(transaction?.details?.dateCreated)}
        </MDTypography>
      </MDBox>
      <MDBox
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        gap="0.75rem"
        py={isMobile ? 2 : 0}
        px={isMobile ? 1 : 0}
      >
        {transaction?.withdraw ? (
          <MDBox
            component="img"
            width={"1.5rem"}
            height={"1.5rem"}
            src={greaterRiskIcon}
            alt="Retirada"
            title="Retirada"
          />
        ) : (
          <MDBox
            component="img"
            width={"1.5rem"}
            height={"1.5rem"}
            src={lowerRiskIcon}
            alt="Depósito"
            title="Depósito"
          />
        )}
        <strong className={styles.labelType}>
          {transaction?.withdraw ? "Retirada" : "Depósito"}
        </strong>
        <span className={styles.labelType}>
          {transaction?.withdraw ? (
            <>
              <strong>-</strong>
              {formatMoney(transaction.amount)}
            </>
          ) : (
            <>
              <strong>+</strong>
              {formatMoney(transaction.amount)}
            </>
          )}
        </span>
      </MDBox>
    </MDBox>
  );
}

export { ListItemTransfer };
