import { useState } from "react";

import styles from "./styles.module.css";
import userInfoRg from "../../../../../assets/images/send-rg.svg";
import userInfoCnh from "../../../../../assets/images/send-cnh.svg";
import arrowBack from "../../../../../assets/images/icons/arrow-back.svg";

import MDBox from "components/MDBox";

function InstructionCarousel({ closeModal }) {
  const [selectedButton, setSelectedButton] = useState(0);
  const [hideContent, setHideContent] = useState(false);
  const buttonList = [
    { index: 0, bool: false },
    { index: 1, bool: true },
  ];

  const handleButtonClick = (index, bool) => {
    setHideContent(bool);
    setSelectedButton(index);
  };

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        {!hideContent ? (
          <>
            <div className={styles.containerButton}>
              <div className={styles.primaryButton}>
              </div>
            </div>
            <div className={styles.header}>
              <h2 className={styles.title}>
                Observe cuidadosamente os documentos aceitos
              </h2>
            </div>
            <img
              src={userInfoRg}
              className={styles.illustration}
              alt="Investidor com documentação na mão"
              loading="lazy"
            />
            <div className={styles.containerText}>
              <p className={styles.number}>1. </p>
              <p className={styles.description}>O <strong>RG</strong> deverá ter sido emitido há pelo menos 10 anos.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>2. </p>
              <p className={styles.description}>O documento precisa estar legível e todas as informações vísiveis.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>3. </p>
              <p className={styles.description}>Verifique se não há sombras, reflexos ou cortes nas imagens.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>4. </p>
              <p className={styles.description}>Retire o RG do plástico de proteção (se houver).</p>
            </div>
            <MDBox
              mx={2}
              mt={2}
              p={2}
              mb={1}
            >
              <ul className={styles.controlButtons}>
                {buttonList.map(({ index, bool }) => (
                  <li
                    className={`${styles.buttonSelected} ${selectedButton === index ? styles.selected : ""
                      }`}
                    onClick={() => handleButtonClick(index, bool)}
                  />
                ))}
              </ul>
            </MDBox>

          </>
        ) : (
          <>
            <div className={styles.containerButton}>
              <button className={styles.primaryButton} onClick={() => handleButtonClick(0, false)}>
                <img src={arrowBack} alt="icone de fechar" />
              </button>
            </div>
            <div className={styles.header}>
              <h2 className={styles.title}>
                Observe cuidadosamente os documentos aceitos
              </h2>
            </div>
            <img
              src={userInfoCnh}
              className={styles.illustration}
              alt="Investidor com documentação na mão"
              loading="lazy"
            />
            <div className={styles.containerText}>
              <p className={styles.number}>1. </p>
              <p className={styles.description}>Retire a <strong>CNH</strong> do plástico de proteção.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>2. </p>
              <p className={styles.description}>Aceitamos apenas CNH dentro da validade.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>3. </p>
              <p className={styles.description}>Ao enviar a CNH, abra-a para garantir que todas as informações estejam visíveis.</p>
            </div>
            <div className={styles.containerText}>
              <p className={styles.number}>4. </p>
              <p className={styles.description}>Caso possua CNH digital, acesse o aplicativo correspondente e clique em “Exportar” para obter uma versão legível.</p>
            </div>
            <MDBox
              mx={2}
              mt={2}
              p={2}
            >
              <button className={styles.button} onClick={closeModal}>
                Continuar
              </button>
            </MDBox>
          </>
        )}
      </aside>
    </div>
  );
}

export { InstructionCarousel };
