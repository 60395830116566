import React from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import styles from "../../styles.module.css";
import { Colors } from "common/Colors";
import arrowUpIcon from "../../../../../assets/images/icons/arrow-up.svg";

function CardArrojadoInvest({ submitHighRisk, setCurrentContentHTML }) {
  return (
    <div className={styles.containerCard}>
      <input
        type="radio"
        id="high-risk"
        name="risks"
        className={styles.inputRadio}
      />
      <label htmlFor="high-risk" className={styles.containerRisk} role="button">
        <MDBox
          width="100%"
          bgColor={Colors.ORANGE_GRAFIC}
          display="flex"
          gap="1rem"
          alignItems="center"
          borderRadius="1rem 1rem 0 0"
          p={2}
        >
          <img
            src={arrowUpIcon}
            alt="Seta para cima"
            className={styles.containerRiskHeaderIcon}
          />
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="h3"
              fontWeight="light"
              sx={{
                fontSize: "1.5rem",
                lineHeight: "calc(1.5rem) * 1.5",
                color: Colors.WHITE,
              }}
            >
              Arrojado
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              sx={{
                fontSize: "0.75rem",
                lineHeight: "calc(0.75rem) * 1.5",
                color: Colors.WHITE,
              }}
            >
              Prazo até 12 meses
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          py={4}
          px={2}
          display="flex"
          flexDirection="column"
          gap="0.75rem"
        >
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightBold}`}
            >
              Juros pagos pelas pessoas
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalPlus}>+</p>{" "}
              <p className={styles.fontWeightBold}>6,60% a.m.</p>
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Inadimplência esperada
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalMinus}>-</p> 20,4% a.a.
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Impostos e taxas
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalMinus}>-</p> 1,37% a.m.
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Rentabilidade esperada
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalEqual}>=</p>{" "}
              <p>44,5% a.a.</p>
            </p>
          </MDBox>

          <MDBox mt={2} display="flex" justifyContent="center">
            <button
              className={styles.primaryButton}
              onClick={() => {
                submitHighRisk();
                setCurrentContentHTML("amountToInvest");
              }}
            >
              Avançar
            </button>
          </MDBox>
        </MDBox>
      </label>
    </div>
  );
}

export default CardArrojadoInvest;
