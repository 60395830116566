import React from "react";

import styles from "./styles.module.css";

import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";

function CardCustomizeInvestment({ setCurrentContentHTML }) {
  return (
    <article className={styles.card}>
      <button
        title="Voltar para a seção anterior"
        className={styles.backButton}
        onClick={() => setCurrentContentHTML("initial")}
      >
        <img src={arrowLeftIcon} alt="Seta para esquerda" />
      </button>
      <h3 className={styles.title}>Personalize o seu investimento:</h3>
      <div className={styles.scoreTypeItem}>
        <div className={`${styles.miniCard} ${styles.miniCardTypeA}`}>
          <h2>A</h2>
          <span>score</span>
        </div>
        <h2 className={styles.scoreTypeItemSectionRightAmount}>R$ 3.000,00</h2>
      </div>
      <span className={styles.divider}></span>
      <div className={styles.scoreTypeItem}>
        <div className={`${styles.miniCard} ${styles.miniCardTypeB}`}>
          <h2>B</h2>
          <span>score</span>
        </div>
        <h3 className={styles.scoreTypeItemSectionRightAmount}>R$ 1.000,00</h3>
      </div>
      <span className={styles.divider}></span>
      <div className={styles.scoreTypeItem}>
        <div className={`${styles.miniCard} ${styles.miniCardTypeC}`}>
          <h2>C</h2>
          <span>score</span>
        </div>
        <h3 className={styles.scoreTypeItemSectionRightAmount}>R$ 500,00</h3>
      </div>
      <span className={styles.divider}></span>
      <div className={styles.scoreTypeItem}>
        <div className={`${styles.miniCard} ${styles.miniCardTypeD}`}>
          <h2>D</h2>
          <span>score</span>
        </div>
        <h3 className={styles.scoreTypeItemSectionRightAmount}>R$ 500,00</h3>
      </div>
      <span className={styles.divider}></span>
      <div className={styles.scoreTypeItem}>
        <div className={styles.contentLeft}>
          <strong>=</strong>
          <span>Total</span>
        </div>
        <div className={styles.contentRight}>
          <strong>R$4.000,00</strong>
          <span>Rentabilidade esperada 23,5% a.a.</span>
        </div>
      </div>
      <button
        className={styles.button}
        onClick={() => setCurrentContentHTML("formDeadline")}
      >
        Avançar
      </button>
    </article>
  );
}

export default CardCustomizeInvestment;
