import { Paper } from "@mui/material";

import styles from "./styles.module.css";

function DropdownFilter({ close, filters, filterActive, setFilterActive }) {
  function handleClick(filter) {
    setFilterActive(filter);
    close();
  }

  return (
    <Paper
      elevation={3}
      sx={{
        minWidth: "5rem",
        maxWidth: "10rem",
        position: "absolute",
        top: "2.5rem",
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {filters?.map((filter) => (
        <button
          key={filter.key}
          className={`${styles.option} ${
            filterActive?.key === filter.key ? styles.optionActive : ""
          }`}
          onClick={() => handleClick(filter)}
        >
          {filter.label}
        </button>
      ))}
    </Paper>
  );
}

export { DropdownFilter };
