import React from "react";
import { Box, Modal } from "@mui/material";
import { NavLink } from "react-router-dom";
import MDTypography from "components/MDTypography";

import styles from "./styles.module.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(18.75rem, 95%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "0.5rem",
};

function ModalLogout({ isOpenModalLogout, closeModalLogout }) {
  return (
    <Modal open={isOpenModalLogout} onClose={closeModalLogout}>
      <Box sx={modalStyle}>
        <MDTypography variant="h5" fontWeight="bold" sx={{ color: "#212121" }}>
          Deseja mesmo sair?
        </MDTypography>
        <MDTypography
          mt={2}
          variant="body2"
          sx={{ color: "#444", textAlign: "left" }}
        >
          Você será redirecionado para página de Login
        </MDTypography>
        <div className={styles.modalLogoutcontainerButtons}>
          <NavLink to="/authentication/sign-in/basic">Sim</NavLink>
          <button onClick={closeModalLogout}>Não</button>
        </div>
      </Box>
    </Modal>
  );
}

export { ModalLogout };
