import React from "react";
import { Paper } from "@mui/material";

import styles from "./styles.module.css";
import stylesLoansTable from "../LoansTable/styles.module.css";

const CONSTANTS = {
  CLASS_NAME: {
    ACTIVE: `${styles.option} ${stylesLoansTable.statusApproved}`,
    CANCELLED: `${styles.option} ${stylesLoansTable.statusRefused}`,
    CONFIRMED: `${styles.option} ${stylesLoansTable.statusPending}`,
    ALL: `${styles.option}`,
  },
};

function DropdownFilterByStatus({ close, filters, setFilterActive }) {
  function handleClickOption(filter) {
    setFilterActive(filter);
    close();
  }

  return (
    <Paper
      elevation={3}
      sx={{
        width: "10rem",
        position: "absolute",
        top: "2.5rem",
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {filters.map((filter) => {
        if (["REJECTED", "COMPLETED"].includes(filter.KEY)) {
          return null;
        }

        return (
          <button
            className={CONSTANTS.CLASS_NAME[filter.KEY]}
            onClick={() => handleClickOption(filter)}
          >
            {filter.LABEL}
          </button>
        );
      })}
    </Paper>
  );
}

export { DropdownFilterByStatus };
