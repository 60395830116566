import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getOnlyNumbersOfString } from "utils";
import { confirmPassword } from "services/user";

import globalStyles from "../../styles.module.css";
import styles from "./styles.module.css";
import closeIcon from "assets/images/icons/close-white.svg";
import eyeOnIcon from "assets/images/icons/eyeOn.svg";
import eyeOffIcon from "assets/images/icons/eyeOff.svg";
import { Alert, Snackbar } from "@mui/material";

function ModalConfirmPassword({
  close,
  openModalEditBankAccount,
  activateLoading,
  deactivateLoading,
  token,
}) {
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPassowordVisible] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const navigate = useNavigate();

  const togglePasswordVisible = () => setIsPassowordVisible(!isPasswordVisible);

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlertConfig({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  function handleInputChangePassword(event) {
    const { value } = event.target;
    const valueOnlyNumbers = getOnlyNumbersOfString(value);
    setPassword(valueOnlyNumbers);
  }

  async function handleConfirmPassword(event) {
    event.preventDefault();
    activateLoading();

    try {
      const response = await confirmPassword(token, password);

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      close();
      openModalEditBankAccount();
    } catch (error) {
      console.error("Erro ao confirmar senha");
      console.error(error);
      setAlertConfig({
        isOpen: true,
        type: "error",
        message: "Senha incorreta. Verifique sua senha e tente novamente",
      });
    } finally {
      deactivateLoading();
    }
  }

  return (
    <div className={globalStyles.overlay}>
      <aside className={globalStyles.modal}>
        <header className={globalStyles.header}>
          <h2 className={globalStyles.title}>Confirme sua senha</h2>
          <button
            title="Fechar modal"
            className={globalStyles.closeButton}
            onClick={close}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <form
          className={globalStyles.form}
          autoComplete="off"
          onSubmit={handleConfirmPassword}
        >
          <div className={globalStyles.formInputGroup}>
            <label htmlFor="password" className={globalStyles.formLabel}>
              Senha
            </label>
            <div className={styles.formInputPassword}>
              <input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={handleInputChangePassword}
                className={globalStyles.formInput}
                placeholder="Digite aqui sua senha"
                maxLength="6"
                required
              />
              <button
                type="button"
                className={styles.buttonIconEye}
                title={isPasswordVisible ? "Esconder senha" : "Ver senha"}
                onClick={togglePasswordVisible}
              >
                <img
                  src={isPasswordVisible ? eyeOnIcon : eyeOffIcon}
                  alt={isPasswordVisible ? "Olho aberto" : "Olho fechado"}
                />
              </button>
            </div>
          </div>

          <button
            type="submit"
            className={`${globalStyles.primaryButton} ${styles.primaryButton}`}
          >
            Continuar
          </button>
        </form>
      </aside>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertConfig.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alertConfig.type}
          sx={{ width: "100%" }}
        >
          {alertConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export { ModalConfirmPassword };
