import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";
import "./styles.css";
import arrowBackIcon from "assets/images/icons/arrow-back.svg";

import { saveAccountBalance } from "services/user";

import { InstructionCarousel } from "../InstructionCarousel";

const CONSTANTS = {
  FLOW_ID: process.env.REACT_APP_METAMAP_FLOW_ID,
  CLIENT_ID: process.env.REACT_APP_METAMAP_CLIENT_ID,
};

function CardValidationDocuments({
  previousStep,
  activeLoading,
  disableLoading,
  cpf,
  token,
  bankCode,
  agency,
  accountNumber,
  digitAccount,
  accountType,
}) {
  const navigate = useNavigate();
  const [instructionCarousel, setInstructionCarousel] = useState(true);

  const closeInstructionCarousel = () => setInstructionCarousel(false);

  useEffect(() => {
    async function saveAccountBalanceAPI() {
      activeLoading();
      const bankAccountType =
        accountType.toUpperCase() === "CONTA CORRENTE"
          ? "CONTA_CORRENTE"
          : "CONTA_POUPANCA";

      try {
        const response = await saveAccountBalance(
          token,
          bankCode,
          agency,
          accountNumber,
          digitAccount,
          bankAccountType
        );
        // console.log(response);

        if (response?.status !== 200) {
          throw new Error();
        }

        // console.log("Salvou a conta bancária");
      } catch (error) {
        console.error(error);
        console.error("Erro ao salvar conta bancaria");
      } finally {
        disableLoading();
      }
    }

    saveAccountBalanceAPI();
  }, []);

  useEffect(() => {
    const matiButton = document.querySelector("mati-button");

    matiButton?.addEventListener("mati:userFinishedSdk", ({ detail }) => {
      console.log("Metamap finalizou");
      // console.log(detail);
      navigate("/aguardar-validacao-dados");
    });
  }, []);

  return (
    <div className={`${mainPageStyles.card} ${styles.containerCard}`}>
      <header className={styles.header}>
        <button
          title="Voltar para etapa anterior"
          className={styles.backButton}
          onClick={previousStep}
        >
          <img src={arrowBackIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
          Validação de documentos
        </h2>
      </header>

      <section className={styles.mainContent}>
        <p className={mainPageStyles.b16}>
          Iremos fazer uma validação de seus documentos pessoais e um
          reconhecimento facial para garantir que ninguém esteja se passando por
          você.
        </p>

        <p className={mainPageStyles.b16}>
          É preciso que você esteja em um ambiente bem iluminado para realizar o
          reconhecimento facial.
        </p>

        <p className={mainPageStyles.b16}>
          Para a validação de documentos, tenha em mãos seu RG ou sua CNH.
        </p>
      </section>

      <mati-button
        clientId={CONSTANTS.CLIENT_ID} // from your Mati dashboard
        flowId={CONSTANTS.FLOW_ID} // from your Mati dashboard
        color="#305569" // any color
        metadata={JSON.stringify({
          fixedLanguage: "pt",
          cpf: cpf,
          account_type: 3,
        })}
      />
      {instructionCarousel && (
        <InstructionCarousel closeModal={closeInstructionCarousel} />
      )}
    </div>
  );
}

export default CardValidationDocuments;
