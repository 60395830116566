import React from "react";

import { Tag } from "../Tag";

import styles from "./styles.module.css";
import stylesCardMessage from "../CardMessage/styles.module.css";
import closeIcon from "assets/images/icons/close-circle.svg";
import { formateDateWithOutHours } from "utils";

function ModalMessageDetails({ selectedMessage, close }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <div className={styles.header}>
          <span className={stylesCardMessage.cardMessageUpdatedAt}>
            {formateDateWithOutHours(selectedMessage?.init_date?.$date)}
          </span>
          <Tag type={selectedMessage?.tag} />
          <button
            className={styles.headerCloseButton}
            title="Fechar modal"
            onClick={close}
          >
            <img src={closeIcon} alt="Círculo com X" />
          </button>
        </div>

        <h2 className={stylesCardMessage.cardMessageTitle}>
          {selectedMessage?.title}
        </h2>

        <p className={styles.textBody}>{selectedMessage?.description}</p>
      </aside>
    </div>
  );
}

export { ModalMessageDetails };
