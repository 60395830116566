import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { formatDateToPtBr, formatMoney } from "utils";

import styles from "./styles.module.css";
import greaterRiskIcon from "assets/images/icons/greater-risk.svg";
import lowerRiskIcon from "assets/images/icons/lower-risk.svg";
import downArrow from "assets/images/down-arrow.svg";

function ListItemInvestment({ isMobile, investment, value, title }) {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const getLabelInvestment = (investment) => {
    if (!investment?.risk) {
      return title || "Recebimento de parcela";
    }

    if (investment?.risk === "menor_risco") {
      return "Conservador";
    }

    if (investment?.risk === "medio_risco") {
      return "Balanceado";
    }

    return "Arrojado";
  };

  function DetailItem({ label, value, isActiveBorder = true }) {
    const styleProps = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      pb: "0.5rem",
      mb: "0.5rem",
      fontSize: isMobile ? "0.85rem" : "1rem"
    };

    if (isActiveBorder) {
      styleProps.borderBottom = "1px solid #ccc";
    }

    return (
      <MDBox {...styleProps}>
        <MDBox textAlign="center" flex="1">
          <strong>{label}</strong>
        </MDBox>
        <MDBox textAlign="center" flex="1">
          {value}
        </MDBox>
      </MDBox>
    );
  }

  return (
    <>
      <MDBox
        component="li"
        width="100%"
        height={isMobile ? "auto" : "4.5rem"}
        borderRadius="lg"
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        shadow="lg"
      >
        <MDBox
          bgColor="#F2F4F5"
          height="100%"
          py={isMobile ? 1 : 0}
          width={isMobile ? "100%" : "18rem"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            borderTopLeftRadius: isMobile ? 0 : "1rem",
            borderBottomLeftRadius: isMobile ? 0 : "1rem",
          }}
        >
          <MDTypography
            variant="body1"
            sx={{
              color: "#3D555A",
              fontSize: isMobile ? "0.875rem" : "1rem",
            }}
          >
            {formatDateToPtBr(investment.date)}
          </MDTypography>
        </MDBox>
        <MDBox
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          gap="0.75rem"
          width="100%"
          height="100%"
          py={isMobile ? 2 : 0}
          px={isMobile ? 1 : 0}
          borderRadius={isMobile ? "0 0 0 0" : "0 1rem 1rem 0"}
          flexDirection={isMobile ? "column" : "row"}
        >
          <div className={styles.columnStyles}>
            {investment?.type === "receivement" ? (
              <MDBox
                component="img"
                width={"1.5rem"}
                height={"1.5rem"}
                src={lowerRiskIcon}
                alt="Recebimento de parcela"
                title="Recebimento de parcela"
              />
            ) : (
              <MDBox
                component="img"
                width={"1.5rem"}
                height={"1.5rem"}
                src={greaterRiskIcon}
                alt="Maior risco"
                title="Maior risco"
              />
            )}
          </div>
          <div className={styles.columnStyles}>
            <div className={styles.labelType}>
              {getLabelInvestment(investment)}
            </div>
          </div>
          {investment?.type === "receivement" ?
            <div className={styles.columnStyles}>
              <span className={styles.labelType}>
                <strong>+</strong>
                <strong>
                  {value ? formatMoney(value) :
                    investment?.investment_amount
                      ? formatMoney(investment.investment_amount)
                      : formatMoney(investment.amount)}
                </strong>
              </span>
            </div>
            :
            <div className={styles.columnStyles}>
              <span className={styles.labelType}>
                <strong>-</strong>
                <strong>
                  {value ? formatMoney(value) :
                    investment?.investment_amount
                      ? formatMoney(investment.investment_amount)
                      : formatMoney(investment.amount)}
                </strong>
              </span>
            </div>
          }
        </MDBox>
        {investment?.type === "receivement" ? (
          <MDBox
            alignItems="center"
            justifyContent="center"
            pt={isMobile ? 0 : "1.5rem"}
            pl={isMobile ? 0 : "-10rem"}
            pr={isMobile ? 0 : "1rem"}
            px={isMobile ? "48%" : "2%"}
            pb={isMobile ? "1rem" : 0}
          >
            <button
              className={`${styles.downArrowButton} ${showDetails ? styles.rotated : ""}`}
              onClick={toggleDetails}
            >
              <img
                src={downArrow}
                alt="Seta para Baixo"
                width="20"
                height="20"
              />
            </button>
          </MDBox>
        ) : (
          <MDBox
            alignItems="center"
            justifyContent="center"
            pt={isMobile ? 0 : "1.5rem"}
            pl={isMobile ? 0 : "-10rem"}
            pr={isMobile ? 0 : "1rem"}
            px={isMobile ? "48%" : "3%"}
          ></MDBox>
        )}
      </MDBox>

      {showDetails && (
        <MDBox
          width="100%"
          height="14rem"
          borderRadius="lg"
          shadow="md"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px="3rem"
          py="5rem"
          mt="-2.5rem"
        >
          <MDBox
            display="flex"
            width="100%"
            mt="3rem"
          >
            <DetailItem
              label = "CPF"
              value = {investment?.cpf}
            />
          </MDBox>
          <DetailItem
            label = "Parcelas pagas"
            value = {`${investment?.installment} de ${investment?.months}`}
          />
          <DetailItem
            label = "Valor principal"
            value = {formatMoney(investment?.mainValue)}
          />
          <DetailItem
            label = "Valor juros"
            value = {formatMoney(investment?.interestValue)}
            isActiveBorder = {false}
          />
        </MDBox>
      )}
    </>
  );
}

export { ListItemInvestment };
