import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Snackbar } from "@mui/material";
import ListContracts from "./layout/ListContracts";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import CardTotalBalance from "./components/CardTotalBalance";
import MDBox from "../../../components/MDBox";
import ModalContractDetails from "./components/ModalContractDetails";
import Loading from "components/Loading";

import { getWalletData } from "services/investments";
import { getContracts } from "services/contracts";
import { getStoredValue } from "metrics/storage";

function Contracts() {
  const token = getStoredValue("token");
  const navigate = useNavigate();
  const [contractsAll, setContractsAll] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState({});
  const [wallet, setWallet] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  });
  const [isActiveContractsLoading, setIsActiveContractsLoading] =
    useState(false);
  const [isActiveWalletLoading, setIsActiveWalletLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const activeWalletLoading = () => setIsActiveWalletLoading(true);

  const disableWalletLoading = () => setIsActiveWalletLoading(false);

  const activeContractsLoading = () => setIsActiveContractsLoading(true);

  const disableContractsLoading = () => setIsActiveContractsLoading(false);

  const activeLoading = () => setIsActiveLoading(true);

  const disableLoading = () => setIsActiveLoading(false);

  const openModal = () => setIsOpenModal(true);

  const closeModal = () => setIsOpenModal(false);

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlert({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  async function loadContracts() {
    activeContractsLoading();

    try {
      const { limit, page } = pagination;
      const response = await getContracts(token, limit, page);

      // response.status = 403;

      if (response.status === 403) {
        disableContractsLoading();
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      const { contracts = [], totalPages = 1 } = response.data;
      setContractsAll(contracts);
      setFilteredContracts(contracts);
      setPagination({ ...pagination, totalPages });
    } catch (error) {
      setAlert({
        isOpen: true,
        message: "Erro ao obter contratos. Tente novamente.",
        type: "error",
      });
      
      console.error("Erro ao obter contratos", error);
    } finally {
      disableContractsLoading();
    }
  }

  useEffect(() => {
    if (isActiveContractsLoading || isActiveWalletLoading) {
      activeLoading();
    } else {
      disableLoading();
    }

    return () => {
      disableLoading();
    };
  }, [isActiveContractsLoading, isActiveWalletLoading]);

  useEffect(() => {
    if (pagination.page > 0) {
      loadContracts();
    }

    return () => {
      setContractsAll([]);
      setFilteredContracts([]);
    };
  }, [pagination.page]);

  useEffect(() => {
    async function loadWallet() {
      activeWalletLoading();

      try {
        const response = await getWalletData();

        if (response.status === 403) {
          window.alert("Infelizmente sua sessão expirou. Faça login novamente");
          navigate("/login");
          throw new Error("Token expirado");
        } else if (response.status !== 200) {
          throw new Error();
        }

        setWallet(response.data);
      } catch (error) {
        console.error(error);
        console.error("Erro ao obter wallet");
      } finally {
        disableWalletLoading();
      }
    }

    loadWallet();

    return () => {
      setWallet({});
    };
  }, []);

  if (token === "null") {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        component="main"
        display="flex"
        flexDirection="column"
        gap="2rem"
        position="relative"
      >
        {/* <ReleaseSoon /> */}
        <CardTotalBalance wallet={wallet} />
        <ListContracts
          openModal={openModal}
          contractsAll={contractsAll}
          filteredContracts={filteredContracts}
          setFilteredContracts={setFilteredContracts}
          setSelectedContract={setSelectedContract}
          pagination={pagination}
          setPagination={setPagination}
        />
      </MDBox>

      {isOpenModal && (
        <ModalContractDetails
          token={token}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          closeModal={closeModal}
          selectedContract={selectedContract}
          setAlert={setAlert}
        />
      )}

      {isActiveLoading && <Loading />}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
        sx={{ maxWidth: "37.5rem" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Contracts;
