import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";
import Loading from "components/Loading";

import {
  getLoggedUser,
  getAccountStatusAsaas,
  resultDocumentationValidation,
} from "services/user";

import styles from "./styles.module.css";
import refreshIcon from "../../../assets/images/icons/refresh.svg";

const CONSTANTS = {
  ARRAY_ACCOUNT_STATUS_APPROVED: [
    "verification_updated",
    "verification_completed",
  ],
};

function WaitDataValidation() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState({});
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isActiveLoadingLoggedUser, setIsActiveLoadingLoggedUser] =
    useState(false);
  const [isActiveLoadingAsaasAndMetamap, setIsActiveLoadingAsaasAndMetamap] =
    useState(false);

  const activeLoadingLoggedUser = () => setIsActiveLoadingLoggedUser(true);

  const activeLoading = () => setIsActiveLoading(true);

  const activeLoadingAsaasAndMetamap = () =>
    setIsActiveLoadingAsaasAndMetamap(true);

  const disableLoadingLoggedUser = () => setIsActiveLoadingLoggedUser(false);

  const disableLoading = () => setIsActiveLoading(false);

  const disableLoadingAsaasAndMetamap = () =>
    setIsActiveLoadingAsaasAndMetamap(false);

  const userHasDocumentationValidated = (reportMati, reportAsaas) =>
    reportAsaas.general === "APPROVED" &&
    reportMati?.approved &&
    CONSTANTS.ARRAY_ACCOUNT_STATUS_APPROVED.some(
      (statusItem) => statusItem === reportMati?.last_status
    );

  const userIsRejectedAsaas = (reportAsaas) =>
    reportAsaas.general === "REJECTED" ||
    reportAsaas.bankAccountInfo === "REJECTED";

  const userIsRejectedMati = (reportMati) => {
    if (
      [
        "verification_updated",
        "verification_completed",
        "verification_inputs_completed",
      ].includes(reportMati?.last_status) === false &&
      reportMati?.approved === false &&
      reportMati?.finished === true
    ) {
      return true;
    } else if (
      reportMati?.approved === false &&
      reportMati?.finished === true
    ) {
      return true;
    } else if (reportMati?.last_status === "not started") {
      return true;
    }

    return false;
  };

  const userHasReprovedDocumentation = (reportMati, reportAsaas) =>
    userIsRejectedAsaas(reportAsaas) || userIsRejectedMati(reportMati);

  const userIsUnderReview = (reportMati, reportAsaas) =>
    userIsRejectedAsaas(reportAsaas) === false &&
    reportMati?.finished === true &&
    reportMati?.last_status === "verification_inputs_completed";

  async function loadLoggedUser() {
    activeLoadingLoggedUser();

    try {
      const data = await getLoggedUser();

      setLoggedUser(data);
      sessionStorage.setItem("loggedUser", JSON.stringify(data));
    } catch (error) {
      console.error(error);
    } finally {
      disableLoadingLoggedUser();
    }
  }

  function handleUserStatus(responseMatiReport, responseAccountStatusAsaas) {
    if (
      userIsUnderReview(
        responseMatiReport.data,
        responseAccountStatusAsaas.data
      )
    ) {
      console.log("Pendente");
      sessionStorage.setItem("userStatus", "pending");
      return;
    } else if (
      userHasDocumentationValidated(
        responseMatiReport.data,
        responseAccountStatusAsaas.data
      )
    ) {
      console.log("Aprovado");
      sessionStorage.setItem("userStatus", "approved");
      return;
    } else if (
      userHasReprovedDocumentation(
        responseMatiReport.data,
        responseAccountStatusAsaas.data
      )
    ) {
      console.log("Rejeitado");
      sessionStorage.setItem("userStatus", "rejected");
      return;
    }
  }

  async function loadAsaasAndMetamap(cpf) {
    activeLoadingAsaasAndMetamap();

    try {
      const responseAsaas = await getAccountStatusAsaas(cpf);
      const responseMetamap = await resultDocumentationValidation(token);

      sessionStorage.setItem(
        "accountStatusInfo",
        JSON.stringify({
          asaas: responseAsaas.data,
          metamap: responseMetamap.data,
        })
      );
      sessionStorage.setItem(
        "matiReport",
        JSON.stringify(responseMetamap?.data)
      );

      handleUserStatus(responseMetamap, responseAsaas);
    } catch (error) {
      console.error(error);
    } finally {
      disableLoadingAsaasAndMetamap();
    }
  }

  useEffect(() => {
    if (isActiveLoadingAsaasAndMetamap || isActiveLoadingLoggedUser) {
      activeLoading();
    } else {
      disableLoading();
    }

    return () => {
      disableLoading();
    };
  }, [isActiveLoadingAsaasAndMetamap, isActiveLoadingLoggedUser]);

  useEffect(() => {
    loadLoggedUser();

    return () => {
      setLoggedUser({});
    };
  }, []);

  useEffect(() => {
    if (loggedUser?.cpf) {
      loadAsaasAndMetamap(loggedUser.cpf);
    }
  }, [loggedUser]);

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <PageLayout width="100%">
      <main className={styles.mainContent}>
        <div className={styles.container}>
          <div className={styles.containerIcon}>
            <img src={refreshIcon} alt="Atualização" className={styles.icon} />
          </div>

          <h1 className={styles.title}>Olá investidor</h1>

          <p className={styles.textBody}>
            <strong>Estamos analisando seus dados</strong>, o que pode levar até
            2 dias úteis. Assim que concluirmos a análise, enviaremos um e-mail
            de confirmação e você terá acesso completo a todos os recursos da
            nossa plataforma.
          </p>
          <p className={styles.textBody}>
            Enquanto isso, aproveite para explorar sua dashboard de investidor,
            que possui algumas funcionalidades limitadas no momento.
          </p>

          <Link to="/carteira" className={styles.linkButton}>
            Acessar dashboard
          </Link>
        </div>
      </main>
      {isActiveLoading && <Loading />}
    </PageLayout>
  );
}

export default WaitDataValidation;
