function formatMoney(value = 0) {
  const formattedMoney = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedMoney;
}

function formateDateWithHours(milisseconds) {
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const date = new Date(milisseconds + timezoneOffset);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().padStart(4, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const dateFormated = `${day}/${month}/${year} às ${hour}:${minutes}h`;
  return dateFormated;
}

function formateDateWithOutHours(milisseconds) {
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const date = new Date(milisseconds + timezoneOffset);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().padStart(4, "0");
  const dateFormated = `${day}/${month}/${year}`;
  return dateFormated;
}

function formatDatePTBRToSQL(date = "") {
  const splittedDate = date.split("/");
  return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
}

function formatDateToPtBr(date = "") {
  const splittedDate = date.split("-");
  const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
  return formattedDate;
}

function getOnlyNumbersOfString(string) {
  const onlyNumbers = string.replace(/[^0-9]/g, "");

  return onlyNumbers;
}

function getDaysOfMonth(month, year) {
  const date = new Date(year, month, 0);
  return date.getDate();
}

function stringHasOnlyNumbers(value = "") {
  const onlyNumbers = value.trim().replace(/[^0-9]/g, "");

  return onlyNumbers.length === value.length;
}

function getAmountOfRepetitions(array, item) {
  return array.reduce((acc, currentItem) => {
    if (currentItem !== item) return acc;
    return acc + 1;
  }, 0);
}

function brazilianSecuritySocialNumberIsValid(cpf) {
  const cpfStr = cpf.toString().replace(/\D/g, "");
  let sum;
  let rest;
  sum = 0;
  if (
    cpfStr === "00000000000" ||
    cpfStr === "11111111111" ||
    cpfStr === "22222222222" ||
    cpfStr === "33333333333" ||
    cpfStr === "44444444444" ||
    cpfStr === "55555555555" ||
    cpfStr === "66666666666" ||
    cpfStr === "77777777777" ||
    cpfStr === "88888888888" ||
    cpfStr === "99999999999"
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpfStr.substring(i - 1, i)) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpfStr.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpfStr.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpfStr.substring(10, 11))) {
    return false;
  }

  return true;
}

async function checkSessionTimer(callFunction, navigate) {
  const currentTime = new Date().getTime();
  const expirationTime = localStorage.getItem("expirationTimeSession");

  if (expirationTime && currentTime < expirationTime) {
    await callFunction();
  } else {
    window.alert('Infelizmente sua sessão expirou. Faça login novamente.');
    navigate();
  }
}

export {
  formatMoney,
  formatDateToPtBr,
  getOnlyNumbersOfString,
  getAmountOfRepetitions,
  stringHasOnlyNumbers,
  brazilianSecuritySocialNumberIsValid,
  formatDatePTBRToSQL,
  getDaysOfMonth,
  formateDateWithHours,
  formateDateWithOutHours,
  checkSessionTimer
};
