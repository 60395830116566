import React from "react";

import MDBox from "components/MDBox";
import CardTotalBalance from "../../components/CardTotalBalance";
import CardFormDeadline from "../../components/CardFormDeadline";

function SectionDeadlineInvestment({ setCurrentContentHTML, wallet }) {
  return (
    <MDBox mt={3} display="flex" flexDirection="column" gap="2rem">
      <CardTotalBalance wallet={wallet} />
      <CardFormDeadline setCurrentContentHTML={setCurrentContentHTML} />
    </MDBox>
  );
}

export default SectionDeadlineInvestment;
