import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getOnlyNumbersOfString } from "utils";
import { isValidBankCode } from "utils/validators";
import { saveAccountBalance } from "services/user";

import globalStyles from "../../styles.module.css";
import styles from "./styles.module.css";
import closeIcon from "assets/images/icons/close-white.svg";

function ModalEditBankAccount({
  close,
  banksList,
  bankCode,
  bankAccountType,
  setBankAccountType,
  agency,
  setAgency,
  accountNumber,
  setAccountNumber,
  accountDigit,
  setAccountDigit,
  bank,
  setBank,
  setIsShowAlert,
  setConfigAlert,
  activateLoading,
  deactivateLoading,
  closeModalEditBankAccount,
  token,
  loggedUser,
}) {
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  function handleInputChange(event, setValue) {
    const { value = "" } = event.target;
    const onlyNumbersValue = getOnlyNumbersOfString(value);

    setValue(onlyNumbersValue);
  }

  function handleInputSelectChange(event, setValue) {
    const { value } = event.target;

    setValue(value);
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlertConfig({
      isOpen: false,
      message: "",
      type: "",
    });
  }

  async function handleSubmitEditBankAccount(event) {
    event.preventDefault();

    if (!isValidBankCode(bankCode, banksList)) {
      setAlertConfig({
        isOpen: true,
        type: "error",
        message: "Banco inválido. Verifique o banco selecionado",
      });
      return;
    }

    // console.table({
    //   bankCode,
    //   agency,
    //   accountNumber,
    //   accountDigit,
    //   bankAccountType,
    // });

    activateLoading();

    try {
      const response = await saveAccountBalance(
        token,
        bankCode,
        agency,
        accountNumber,
        accountDigit,
        bankAccountType
      );

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      setConfigAlert({
        status: "success",
        message: "Dados bancários alterados com sucesso",
      });

      sessionStorage.removeItem("loggedUser");
      const updatedLoggedUser = {
        ...loggedUser,
        bankingDetails: response?.data,
      };
      sessionStorage.setItem("loggedUser", JSON.stringify(updatedLoggedUser));
    } catch (error) {
      console.error(error);
      console.error("Erro ao alterar dados bancários");
      setConfigAlert({
        status: "error",
        message:
          "Ocorreu um problema ao alterar dados bancários. Tente novamente, mais tarde",
      });
    } finally {
      deactivateLoading();
      setIsShowAlert(true);
      closeModalEditBankAccount();
    }
  }

  useEffect(() => {
    const [currentBank = {}] = banksList?.filter(
      (bankItem) => bankItem?.code === bankCode
    );
    setBank(`${currentBank?.bank} - ${currentBank?.code}`);
  }, []);

  return (
    <div className={globalStyles.overlay}>
      <aside className={globalStyles.modal}>
        <header className={globalStyles.header}>
          <h2 className={globalStyles.title}>Editar conta bancária</h2>
          <button
            title="Fechar modal"
            className={globalStyles.closeButton}
            onClick={close}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <form
          className={globalStyles.form}
          autoComplete="off"
          onSubmit={handleSubmitEditBankAccount}
        >
          <div className={globalStyles.formInputGroup}>
            <label htmlFor="bank" className={globalStyles.formLabel}>
              Banco
            </label>
            <input
              type="text"
              id="bank"
              name="bank"
              className={globalStyles.formInput}
              value={bank}
              onChange={(event) => handleInputSelectChange(event, setBank)}
              placeholder="Selecione ou pesquise um banco"
              list="banks"
              required
            />
            <datalist id="banks">
              {banksList?.map((bank) => (
                <option
                  key={bank.bank}
                  value={`${bank.bank} - ${bank.code}`}
                  code={bank.code}
                />
              ))}
            </datalist>
          </div>

          <div className={globalStyles.formInputGroup}>
            <label htmlFor="account-types" className={globalStyles.formLabel}>
              Tipo de conta
            </label>
            <select
              id="account-types"
              name="account-types"
              className={globalStyles.formInput}
              defaultValue={bankAccountType}
              value={bankAccountType}
              onChange={(event) =>
                handleInputSelectChange(event, setBankAccountType)
              }
              required
            >
              <option value="CONTA_POUPANCA">Poupança</option>
              <option value="CONTA_CORRENTE">Corrente</option>
            </select>
          </div>

          <div className={globalStyles.formInputGroup}>
            <label htmlFor="agency" className={globalStyles.formLabel}>
              Agência
            </label>
            <input
              type="text"
              id="agency"
              name="agency"
              className={globalStyles.formInput}
              value={agency}
              onChange={(event) => handleInputChange(event, setAgency)}
              placeholder="XXXX"
              minLength={4}
              maxLength={4}
              required
            />
          </div>

          <div className={styles.rowAccount}>
            <div className={globalStyles.formInputGroup}>
              <label
                htmlFor="account-number"
                className={globalStyles.formLabel}
              >
                Conta
              </label>
              <input
                type="text"
                id="account-number"
                name="account-number"
                className={globalStyles.formInput}
                value={accountNumber}
                onChange={(event) => handleInputChange(event, setAccountNumber)}
                placeholder="Somente números"
                required
              />
            </div>

            <div className={globalStyles.formInputGroup}>
              <label htmlFor="digit" className={globalStyles.formLabel}>
                Dígito
              </label>
              <input
                type="text"
                id="digit"
                name="digit"
                className={globalStyles.formInput}
                value={accountDigit}
                onChange={(event) => handleInputChange(event, setAccountDigit)}
                placeholder="X"
                minLength={1}
                maxLength={1}
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className={`${globalStyles.primaryButton} ${styles.formButton}`}
          >
            Editar
          </button>
        </form>
      </aside>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertConfig.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alertConfig.type}
          sx={{ width: "100%" }}
        >
          {alertConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export { ModalEditBankAccount };
