import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";

import ModalConfirmCodeBySMS from "../../components/ModalConfirmCodeBySMS";
import CodeBySMSForm from "../../components/CodeBySMSForm";
import Loading from "components/Loading";

import { sendCodeBySMS } from "services/user";

const CONSTANTS = {
  SECONDS_TIME_SEND_SMS_CODE: 30,
};

function StepCodeBySMS({ previousStep, nextStep, phone, setPhone, email }) {
  const [error, setError] = useState("");
  const [timeSmsCode, setTimeSmsCode] = useState(
    CONSTANTS.SECONDS_TIME_SEND_SMS_CODE
  );
  const [activeTimeSmsCode, setActiveTimeSmsCode] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  let countdownSmsCode = undefined;

  const openSnackbar = () => setIsOpenSnackbar(true);

  const closeSnackbar = () => setIsOpenSnackbar(false);

  const openModal = () => setIsOpenModal(true);

  const closeModal = () => setIsOpenModal(false);

  const activateLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  function startTimeSmsCode() {
    setTimeSmsCode(CONSTANTS.SECONDS_TIME_SEND_SMS_CODE);
    setActiveTimeSmsCode(true);
  }

  async function sendSmsCode() {
    startTimeSmsCode();

    try {
      const response = await sendCodeBySMS(phone, email);

      if (response?.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    closeSnackbar();
  }

  useEffect(() => {
    if (activeTimeSmsCode && timeSmsCode > 0) {
      countdownSmsCode = setTimeout(() => {
        setTimeSmsCode(timeSmsCode - 1);
      }, 1000);
    } else if (activeTimeSmsCode && timeSmsCode === 0) {
      clearTimeout(countdownSmsCode);
      setActiveTimeSmsCode(false);
      setTimeout(
        () => setTimeSmsCode(CONSTANTS.SECONDS_TIME_SEND_SMS_CODE),
        2000
      );
    }
  }, [timeSmsCode, activeTimeSmsCode]);

  return (
    <>
      <CodeBySMSForm
        previousStep={previousStep}
        openModal={openModal}
        phone={phone}
        setPhone={setPhone}
        email={email}
        disableLoading={disableLoading}
        activateLoading={activateLoading}
        setError={setError}
        openSnackbar={openSnackbar}
        closeSnackbar={closeSnackbar}
        startTimeSmsCode={startTimeSmsCode}
      />

      {isOpenModal && (
        <ModalConfirmCodeBySMS
          email={email}
          nextStep={nextStep}
          closeModal={closeModal}
          disableLoading={disableLoading}
          activateLoading={activateLoading}
          setError={setError}
          openSnackbar={openSnackbar}
          closeSnackbar={closeSnackbar}
          timeSmsCode={timeSmsCode}
          activeTimeSmsCode={activeTimeSmsCode}
          sendSmsCode={sendSmsCode}
        />
      )}

      {isLoading && <Loading />}

      {isOpenSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isOpenSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default StepCodeBySMS;
