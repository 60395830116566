import { api } from "./api";

async function getBankList() {
  let data = undefined;

  try {
    const response = await api.get("/banklist");

    data = response.data.data;
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return data;
  }
}

export { getBankList };
