import React from "react";

import styles from "./styles.module.css";
import mainPageStyles from "../../styles.module.css";

import emailIcon from "../../../../../assets/images/icons/mark-email.svg";

function ModalEmailSent({
  closeModalEmailSent,
  openModalConfirmCode,
  email
}) {
  function handleClickButton() {
    closeModalEmailSent();
    openModalConfirmCode();
    // nextStep();
  }

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <h2 className={`${mainPageStyles.h24} ${styles.title}`}>
          E-mail enviado
        </h2>

        <section className={styles.mainContent}>
          <div className={styles.containerIcon}>
            <img src={emailIcon} alt="Email com check" />
          </div>

          <p className={`${mainPageStyles.b16} ${styles.message}`}>
            Enviamos um e-mail com o código para <strong>{email}</strong>. É preciso
            que você faça a confirmação.
          </p>
        </section>

        <button
          className={mainPageStyles.primaryButton}
          onClick={handleClickButton}
        >
          Continuar
        </button>
      </aside>
    </div>
  );
}

export default ModalEmailSent;
