import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";
import { ModalInitial } from "./components/ModalInitial";
import { ListSteps } from "./components/ListSteps";
import { CardAccountBalance } from "./components/CardAccountBalance";
import { CardValidationDocuments } from "./components/CardValidationDocuments";
import Loading from "components/Loading";

import styles from "./styles.module.css";
import logoFidoImg from "../../../assets/images/logo.png";

const CONSTANTS = {
  STEP_ACCOUNT_BALANCE: 1,
  STEP_VALIDATION_DOCUMENTS: 2,
  ARRAY_METAMAP_ACCOUNT_STATUS_APPROVED: [
    "verification_updated",
    "verification_completed",
  ],
};

function ResendDocuments() {
  const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  const { cpf, token } = loggedUser;
  const accountStatusInfo = JSON.parse(
    sessionStorage.getItem("accountStatusInfo")
  );
  const reprovedAccountStatusAsaas =
    accountStatusInfo.asaas.bankAccountInfo === "REJECTED";
  const reprovedAccountStatusMetaMap =
    (accountStatusInfo.metamap.last_status !== "verification_updated" &&
      accountStatusInfo.metamap.last_status !== "verification_completed") ||
    accountStatusInfo.metamap?.approved === false;
  // const reprovedAccountStatusAsaas = true;
  // const reprovedAccountStatusMetaMap = true;

  // console.table({
  //   reprovedAccountStatusAsaas,
  //   reprovedAccountStatusMetaMap,
  // });

  const [isOpenModalInitial, setIsOpenModalInitial] = useState(true);
  const [currentStep, setCurrentStep] = useState(
    CONSTANTS.STEP_ACCOUNT_BALANCE
  );
  const [bank, setBank] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountType, setAccountType] = useState("Selecione");
  const [agency, setAgency] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [digitAccount, setDigitAccount] = useState("");
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const navigate = useNavigate();

  const activeLoading = () => setIsActiveLoading(true);

  const disableLoading = () => setIsActiveLoading(false);

  const closeModalInitial = () => setIsOpenModalInitial(false);

  const nextStep = () => setCurrentStep(currentStep + 1);

  const previousStep = () => setCurrentStep(currentStep - 1);

  function getCurrentStepHTML(currentStep) {
    if (currentStep === CONSTANTS.STEP_ACCOUNT_BALANCE) {
      return (
        <CardAccountBalance
          nextStep={nextStep}
          token={token}
          bank={bank}
          bankCode={bankCode}
          accountType={accountType}
          agency={agency}
          accountNumber={accountNumber}
          digitAccount={digitAccount}
          setAccountNumber={setAccountNumber}
          setAgency={setAgency}
          setBank={setBank}
          setBankCode={setBankCode}
          setDigitAccount={setDigitAccount}
          setAccountType={setAccountType}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          isLastStep={!reprovedAccountStatusMetaMap}
        />
      );
    } else if (currentStep === CONSTANTS.STEP_VALIDATION_DOCUMENTS) {
      return (
        <CardValidationDocuments
          previousStep={previousStep}
          cpf={cpf}
          isLastStep={reprovedAccountStatusAsaas}
        />
      );
    } else {
      return null;
    }
  }

  function handleRenderStep() {
    if (reprovedAccountStatusAsaas) {
      return (
        <CardAccountBalance
          nextStep={nextStep}
          token={token}
          bank={bank}
          bankCode={bankCode}
          accountType={accountType}
          agency={agency}
          accountNumber={accountNumber}
          digitAccount={digitAccount}
          setAccountNumber={setAccountNumber}
          setAgency={setAgency}
          setBank={setBank}
          setBankCode={setBankCode}
          setDigitAccount={setDigitAccount}
          setAccountType={setAccountType}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          isLastStep={!reprovedAccountStatusMetaMap}
        />
      );
    } else if (reprovedAccountStatusMetaMap) {
      return (
        <CardValidationDocuments
          previousStep={previousStep}
          cpf={cpf}
          isLastStep={reprovedAccountStatusAsaas}
        />
      );
    }
  }

  if (!loggedUser) {
    navigate("/login");
    return null;
  }

  return (
    <PageLayout width="100%">
      <main className={styles.mainContainer}>
        <img src={logoFidoImg} alt="Logo da Fido" className={styles.logo} />
        <h1 className={styles.h1}>Reenviar dados</h1>
        <ListSteps
          currentStepNumber={currentStep}
          renderStepAccountBalance={reprovedAccountStatusAsaas}
          renderStepValidationDocuments={reprovedAccountStatusMetaMap}
        />
        <section className={styles.sectionForm}>
          {reprovedAccountStatusAsaas && reprovedAccountStatusMetaMap
            ? getCurrentStepHTML(currentStep)
            : handleRenderStep()}
        </section>
      </main>

      {isOpenModalInitial && (
        <ModalInitial
          closeModal={closeModalInitial}
          reprovedAccountStatusAsaas={reprovedAccountStatusAsaas}
          reprovedAccountStatusMetaMap={reprovedAccountStatusMetaMap}
        />
      )}

      {isActiveLoading && <Loading />}
    </PageLayout>
  );
}

export default ResendDocuments;
