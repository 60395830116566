import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";

import { saveCode } from "services/direct-loan";
import { moneyMaskFormatPTBR } from "utils/mask";
import { getOnlyNumbersOfString } from "utils";
import { parceTextToNumber } from "metrics/simulation";

import styles from "./styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";
import copyIcon from "assets/images/icons/copy.svg";
import threePeopleCommemorationIllustration from "assets/images/illustrations/three-people-commemoration.svg";
import { percentMask } from "utils/mask";

const CONSTANTS = {
  AMOUNT_MIN: 3000,
  AMOUNT_MAX: 15000,
  RANGE_INSTALLMENTS: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
};

function ModalCodeSave({
  close,
  token,
  activeLoading,
  disableLoading,
  codeList,
  setCodeList,
  generatedCode,
  setGeneratedCode,
  openModalCopyCreatedCode,
  setAlertConfig,
}) {
  const [isVisibleGeneratedCode, setIsVisibleGeneratedCode] = useState(false);
  const [investorRate, setInvestorRate] = useState("");
  const [minInstallments, setMinInstallments] = useState(
    CONSTANTS.RANGE_INSTALLMENTS.at(0)
  );
  const [maxInstallments, setMaxInstallments] = useState(
    CONSTANTS.RANGE_INSTALLMENTS.at(-1)
  );
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const [hasFormErrors, setHasFormErrors] = useState(false);

  const handleMinChange = (event) => {
    setMinInstallments(Number(event.target.value));
  };

  const handleMaxChange = (event) => {
    setMaxInstallments(Number(event.target.value));
  };

  function getFormErrors(code) {
    let errors = [];

    if (code.investor_rate <= 0) {
      errors = [...errors, "Juros do investidor inválido"];
    }

    if (code.min_value < CONSTANTS.AMOUNT_MIN) {
      errors = [
        ...errors,
        "O valor minímo deve ser maior ou igual a R$ 3.000,00",
      ];
    }

    if (code.max_value > CONSTANTS.AMOUNT_MAX) {
      errors = [
        ...errors,
        "O valor máximo deve ser menor ou igual a R$ 15.000,00",
      ];
    }

    if (code.min_installments > code.max_installments) {
      errors = [
        ...errors,
        "A quantidade de parcelas minímas devem ser menores que a quantidade de parcelas máximas",
      ];
    }

    if (code.min_value > code.max_value) {
      errors = [...errors, "O valor minímo deve ser menor que o valor máximo"];
    }

    return errors;
  }

  async function copyCode(code) {
    await navigator.clipboard.writeText(code);
    alert("Código copiado!");
  }

  async function handleSaveCode(event) {
    event.preventDefault();

    let code = {
      investor_rate: Number(investorRate),
      min_installments: minInstallments,
      max_installments: maxInstallments,
      min_value: parceTextToNumber(minAmount),
      max_value: parceTextToNumber(maxAmount),
    };

    const errors = getFormErrors(code);
    setFormErrors(errors);

    const hasErrors = errors.length > 0;
    if (hasErrors) return;

    activeLoading();

    try {
      const response = await saveCode(token, code);

      if (response.status !== 200) {
        throw new Error();
      }

      code = response.data;
      setGeneratedCode(code);
      const updatedCodeList = [...codeList, code];
      setCodeList(updatedCodeList);
      setIsVisibleGeneratedCode(true);
      setFormErrors([]);
      setHasFormErrors(false);
      openModalCopyCreatedCode();
    } catch (error) {
      console.error(error);
      console.error("Erro ao salvar código");
      setAlertConfig({
        isOpen: true,
        type: "error",
        message: "Não foi possível criar o código. Tente novamente mais tarde.",
      });
    } finally {
      close();
      disableLoading();
    }
  }

  function handleInputChangeTypeNumber(event, setUpdate) {
    const { value } = event.target;
    const valueNumber = Number(getOnlyNumbersOfString(value));

    if (valueNumber < 0) return;

    setUpdate(valueNumber);
  }

  function handleInputChangeTypeMoney(event, setUpdate) {
    const { value } = event.target;
    const formattedMoney = moneyMaskFormatPTBR(value);

    setUpdate(formattedMoney);
  }

  function handleInputChangeTypeInvestorRate(event) {
    const { value } = event.target;
    const formattedValue = percentMask(value);

    setInvestorRate(formattedValue);
  }

  useEffect(() => {
    if (formErrors?.length > 0) {
      setHasFormErrors(true);
    } else {
      setHasFormErrors(false);
    }

    return () => {
      setHasFormErrors(false);
    };
  }, [formErrors]);

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.headerTitle}>Cadastrar código</h2>
          <button
            className={styles.headerCloseButton}
            title="Fechar"
            onClick={close}
          >
            <img src={closeCircleIcon} alt="Fechar" />
          </button>
        </header>

        <p className={styles.messageInfo}>
          O código do empréstimo pode ser compartilhado com as pessoas a quem
          você pretende conceder o seu empréstimo, podendo personalizá-lo de
          acordo com as suas preferências.
        </p>

        <img
          src={threePeopleCommemorationIllustration}
          alt="Três pessoas comemorando"
          className={styles.illustration}
        />

        {isVisibleGeneratedCode && (
          <div className={styles.containerCode}>
            <strong>Seu código do empréstimo foi criado!</strong>
            <MDBox
              alignSelf="center"
              display="flex"
              gap="0.75rem"
              alignItems="center"
            >
              <p>{generatedCode?.code}</p>
              <button
                title="Copiar código"
                onClick={() => copyCode(generatedCode?.code)}
              >
                <img src={copyIcon} alt="Copiar" />
              </button>
            </MDBox>
          </div>
        )}

        <form
          className={styles.form}
          autoComplete="off"
          onSubmit={handleSaveCode}
        >
          <div className={styles.inputGroup}>
            <label htmlFor="investor-rate" className={styles.inputGroupLabel}>
              JUROS DO INVESTIDOR (% a.m.)
            </label>
            <input
              type="text"
              id="investor-rate"
              className={styles.inputGroupInput}
              value={investorRate}
              onChange={handleInputChangeTypeInvestorRate}
              placeholder="1.25"
              required
            />
            <span className={styles.inputGroupMessage}>
              Exemplo: Se você deseja juros de 10% a.m. preencha somente 10
            </span>
          </div>

          <div className={styles.formGrid}>
            <div className={styles.inputGroup}>
              <label
                htmlFor="select-min-installments"
                className={styles.inputGroupLabel}
              >
                NÚMERO MÍN. DE PARCELAS
              </label>
              <select
                id="select-min-installments"
                name="select-min-installments"
                defaultValue="6x"
                className={styles.inputGroupInput}
                value={minInstallments}
                onChange={handleMinChange}
                required
              >
                {CONSTANTS.RANGE_INSTALLMENTS.map((installment) => (
                  <option value={installment} key={installment}>
                    {installment}x
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.inputGroup}>
              <label
                htmlFor="select-max-installments"
                className={styles.inputGroupLabel}
              >
                NÚMERO MÁX. DE PARCELAS
              </label>
              <select
                id="select-max-installments"
                name="select-max-installments"
                defaultValue="12x"
                className={styles.inputGroupInput}
                value={maxInstallments}
                onChange={handleMaxChange}
                required
              >
                {CONSTANTS.RANGE_INSTALLMENTS.map((installment) => (
                  <option value={installment} key={installment}>
                    {installment}x
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.formGrid}>
            <div className={styles.inputGroup}>
              <label htmlFor="amount-min" className={styles.inputGroupLabel}>
                VALOR MÍN. (DE R$ 3 MIL)
              </label>
              <input
                type="text"
                id="amount-min"
                className={styles.inputGroupInput}
                value={minAmount}
                onChange={(event) =>
                  handleInputChangeTypeMoney(event, setMinAmount)
                }
                placeholder="R$ 3.000,00"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="amount-max" className={styles.inputGroupLabel}>
                VALOR MÁX. (ATÉ R$ 15 MIL)
              </label>
              <input
                type="text"
                id="amount-max"
                className={styles.inputGroupInput}
                value={maxAmount}
                onChange={(event) =>
                  handleInputChangeTypeMoney(event, setMaxAmount)
                }
                placeholder="R$ 15.000,00"
                required
              />
            </div>
          </div>

          {hasFormErrors && (
            <MDBox
              mt="1.5rem"
              display="flex"
              flexDirection="column"
              gap="0.75rem"
            >
              {formErrors?.map((error) => (
                <p key={error} className={styles.formMessageError}>
                  {error}
                </p>
              ))}
            </MDBox>
          )}

          <button type="submit" className={styles.primaryButton}>
            Criar código
          </button>
        </form>
      </aside>
    </div>
  );
}

export { ModalCodeSave };
