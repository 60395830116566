import React, { useState, useEffect } from "react";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CardTotalBalance } from "../components/CardTotalBalance";
import { CodeTable } from "./components/CodeTable";
import { ModalCodeSave } from "./components/ModalCodeSave";
import { ModalCreateCodeInfo } from "./components/ModalCreateCodeInfo";
import Loading from "components/Loading";
import { ModalCopyCreatedSave } from "./components/ModalCopyCreatedCode";

import { getCodeList } from "services/direct-loan";
import { getWalletData } from "services/investments";
import { getStoredValue } from "metrics/storage";

import styles from "./styles.module.css";
import infoIcon from "assets/images/icons/info-icon.svg";

function ConsultCode() {
  const token = getStoredValue("token");
  const navigate = useNavigate();
  const [codeList, setCodeList] = useState([]);
  const [filteredCodeList, setFilteredCodeList] = useState([]);
  const [wallet, setWallet] = useState({});
  const [codeToSearch, setCodeToSearch] = useState("");
  const [generatedCode, setGeneratedCode] = useState({});
  const [isOpenModalCodeSave, setIsOpenModalCodeSave] = useState(false);
  const [isOpenModalCopyCreatedCode, setIsOpenModalCopyCreatedCode] =
    useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isActiveLoadingCodeList, setIsActiveLoadingCodeList] = useState(false);
  const [isActiveLoadingWallet, setIsActiveLoadingWallet] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(true);

  const closeModalInfo = () => setIsOpenModalInfo(false);

  const activeLoading = () => setIsActiveLoading(true);

  const activeLoadingWallet = () => setIsActiveLoadingWallet(true);

  const activeLoadingCodeList = () => setIsActiveLoadingCodeList(true);

  const disableLoading = () => setIsActiveLoading(false);

  const disableLoadingWallet = () => setIsActiveLoadingWallet(false);

  const disableLoadingCodeList = () => setIsActiveLoadingCodeList(false);

  const openModalCodeSave = () => setIsOpenModalCodeSave(true);

  const closeModalCodeSave = () => setIsOpenModalCodeSave(false);

  const openModalCopyCreatedCode = () => setIsOpenModalCopyCreatedCode(true);

  const closeModalCopyCreatedCode = () => setIsOpenModalCopyCreatedCode(false);

  function handleInputChangeCodeToSearch(event) {
    const { value } = event.target;

    setCodeToSearch(value);
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlertConfig({
      ...alertConfig,
      isOpen: false,
    });
  }

  useEffect(() => {
    async function loadCodeList() {
      activeLoadingCodeList();

      try {
        const response = await getCodeList(token);

        if (response.status !== 200) {
          throw new Error();
        }

        setCodeList(response.data);
        setFilteredCodeList(response.data);
      } catch (error) {
        console.error("Erro ao carregar a lista de códigos");
      } finally {
        disableLoadingCodeList();
      }
    }

    loadCodeList();

    return () => {
      setCodeList([]);
    };
  }, []);

  useEffect(() => {
    async function loadWallet() {
      activeLoadingWallet();

      try {
        const response = await getWalletData();

        if (response.status !== 200) {
          throw new Error();
        }

        setWallet(response.data);
      } catch (error) {
        console.error(error);
        console.error("Erro ao obter wallet");
      } finally {
        disableLoadingWallet();
      }
    }

    loadWallet();

    return () => {
      setWallet({});
    };
  }, []);

  useEffect(() => {
    if (isActiveLoadingCodeList || isActiveLoadingWallet) {
      activeLoading();
    } else {
      disableLoading();
    }

    return () => {
      setIsActiveLoading(false);
    };
  }, [isActiveLoadingCodeList, isActiveLoadingWallet]);

  useEffect(() => {
    if (codeToSearch?.length === 0) {
      setFilteredCodeList(codeList);
      return;
    }

    const filteredList = filteredCodeList?.filter((codeItem) =>
      codeItem.code.includes(codeToSearch)
    );
    setFilteredCodeList(filteredList);
  }, [codeToSearch]);

  if (token === "null") {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CardTotalBalance wallet={wallet} />
      <section className={styles.sectionListLoans}>
        <div className={styles.header}>
          <div className={styles.headerInformation}>
            <h2>Lista de códigos</h2>
            <button
              className={styles.buttonInfo}
              onClick={() => setIsOpenModalInfo(!isOpenModalInfo)}
            >
              <img width={20} src={infoIcon} alt="ícone de informação" />
            </button>
          </div>
          <form>
            <TextField
              id="standard-basic"
              label="Pesquisar código"
              variant="standard"
              value={codeToSearch}
              onChange={handleInputChangeCodeToSearch}
            />
          </form>
        </div>

        <div className={styles.containerTable}>
          <CodeTable
            activeLoading={activeLoading}
            disableLoading={disableLoading}
            codeList={codeList}
            setCodeList={setCodeList}
            filteredCodeList={filteredCodeList}
            setFilteredCodeList={setFilteredCodeList}
            token={token}
          />

          {codeList?.length === 0 && (
            <p className={styles.messageEmptyTable}>
              Você não possui códigos gerados no momento
            </p>
          )}

          {codeList?.length > 0 && filteredCodeList?.length === 0 ? (
            <p className={styles.messageEmptyTable}>
              Não encontramos o que você busca
            </p>
          ) : null}
        </div>

        <button className={styles.primaryButton} onClick={openModalCodeSave}>
          Cadastrar código
        </button>
      </section>

      {isOpenModalInfo && <ModalCreateCodeInfo closeModal={closeModalInfo} />}

      {isOpenModalCodeSave && (
        <ModalCodeSave
          close={closeModalCodeSave}
          token={token}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          codeList={codeList}
          setCodeList={setCodeList}
          generatedCode={generatedCode}
          setGeneratedCode={setGeneratedCode}
          openModalCopyCreatedCode={openModalCopyCreatedCode}
          setAlertConfig={setAlertConfig}
        />
      )}

      {isOpenModalCopyCreatedCode && (
        <ModalCopyCreatedSave
          close={closeModalCopyCreatedCode}
          setAlertConfig={setAlertConfig}
          generatedCode={generatedCode}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertConfig.isOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alertConfig.type}
          sx={{ width: "100%" }}
        >
          {alertConfig.message}
        </Alert>
      </Snackbar>

      {isActiveLoading && <Loading />}
    </DashboardLayout>
  );
}

export default ConsultCode;
