import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

import errorIcon from "assets/images/icons/error-x.svg";
import closeIcon from "assets/images/icons/close-background-red.svg";

function AlertError({ children, route, close, routeLabel }) {
  return (
    <div className={styles.alert}>
      <img src={errorIcon} alt="Erro" className={styles.alertIcon} />

      <p className={styles.alertText}>{children}</p>

      {route && (
        <Link to={`/${route}`} className={styles.alertLinkButton}>
          {routeLabel}
        </Link>
      )}

      {close && (
        <button
          className={styles.alertCloseButton}
          title="Fechar alerta"
          onClick={close}
        >
          <img src={closeIcon} alt="X" />
        </button>
      )}
    </div>
  );
}

export default AlertError;
