import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import styles from "../../styles.module.css";
import bancoDoBrasilLogo from "assets/images/logos/banco-do-brasil.svg";
import bradescoLogo from "assets/images/logos/bradesco.svg";
import caixaLogo from "assets/images/logos/caixa.svg";
import interLogo from "assets/images/logos/inter.svg";
import itauLogo from "assets/images/logos/itau.svg";
import nubankLogo from "assets/images/logos/nubank.svg";
import santanderLogo from "assets/images/logos/santander.svg";

function StepChooseBank({ handleSubmitFormChooseBank, isMobile }) {
  const banks = [
    { label: "Banco do Brasil", src: bancoDoBrasilLogo },
    { label: "Itaú", src: itauLogo },
    { label: "Santander", src: santanderLogo },
    { label: "Inter", src: interLogo },
    { label: "Caixa", src: caixaLogo },
    { label: "Bradesco", src: bradescoLogo },
    { label: "Nubank", src: nubankLogo },
  ];

  return (
    <form className={styles.formBanks} onSubmit={handleSubmitFormChooseBank}>
      <MDTypography
        variant="h3"
        fontWeight="light"
        sx={{
          fontSize: "1.5rem",
          lineHeight: "calc(1.5rem * 1.5)",
          color: "#3D555A",
        }}
      >
        Qual é o seu banco?
      </MDTypography>
      <MDBox
        mt={2}
        display="grid"
        gridTemplateColumns={isMobile ? "repeat(2, 7rem)" : "repeat(3, 7rem)"}
        gap="1rem 0.5rem"
      >
        {banks?.map((bank) => (
          <MDBox key={bank.label}>
            <input
              type="radio"
              id={bank.label}
              name={"banks"}
              className={styles.formBanksInput}
            />
            <label htmlFor={bank.label} className={styles.formBanksLabel}>
              <img src={bank.src} alt={bank.label} />
            </label>
          </MDBox>
        ))}
        <MDBox>
          <input
            type="radio"
            id="Outro"
            name={"banks"}
            className={styles.formBanksInput}
            required
          />
          <label htmlFor="Outro" className={styles.formBanksLabel}>
            Outro
          </label>
        </MDBox>
      </MDBox>
      <button
        type="submit"
        className={`${styles.button} ${styles.formBanksButton}`}
      >
        Continuar
      </button>
    </form>
  );
}

export { StepChooseBank };
