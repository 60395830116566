import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import CardAutomaticReinvestment from "../../components/CardAutomaticReinvestment";
import CardChooseLimitDate from "../../components/CardChooseLimitDate";
import ModalAutomaticReinvestmentEnabled from "../../components/ModalAutomaticReinvestmentEnabled";

import arrowUpIcon from "../../../../../assets/images/icons/arrow-up.svg";

import styles from "./styles.module.css";

function AutomaticReinvestment({ setCurrentRenderedContent }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xl: 2, lg: 3, md: 2 }}>
        <Grid item xl md={6}>
          <CardAutomaticReinvestment
            setCurrentRenderedContent={setCurrentRenderedContent}
          />
        </Grid>
        <Grid item xl md={6}>
          <CardChooseLimitDate />
        </Grid>
        <Grid item xl={3}>
          <MDBox height="100%" display="flex" flexDirection="column">
            <div>
              <input
                type="radio"
                id="high-risk"
                name="risks"
                className={styles.radio}
              />
              <label
                htmlFor="high-risk"
                className={styles.containerRisk}
                role="button"
              >
                <MDBox
                  width="100%"
                  bgColor="#DB8F1B"
                  display="flex"
                  gap="1rem"
                  alignItems="center"
                  borderRadius="1rem 1rem 0 0"
                  p={2}
                >
                  <img
                    src={arrowUpIcon}
                    alt="Seta para cima"
                    className={styles.containerRiskHeaderIcon}
                  />
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h3"
                      fontWeight="light"
                      sx={{
                        fontSize: "1.5rem",
                        lineHeight: "calc(1.5rem) * 1.5",
                        color: "#fff",
                      }}
                    >
                      Arrojado
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="light"
                      sx={{
                        fontSize: "0.75rem",
                        lineHeight: "calc(0.75rem) * 1.5",
                        color: "#fff",
                      }}
                    >
                      Prazo até 12 meses
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  py={4}
                  px={2}
                  display="flex"
                  flexDirection="column"
                  gap="0.75rem"
                >
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      Juros pagos pelas pessoas
                    </p>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalPlus}>
                        +
                      </strong>{" "}
                      40%a.a.
                    </p>
                  </MDBox>
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      Inadimplência esperada
                    </p>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalMinus}>
                        -
                      </strong>{" "}
                      17%a.a.
                    </p>
                  </MDBox>
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <strong className={styles.containerRiskLabel}>
                      Rentabilidade esperada
                    </strong>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalEqual}>
                        =
                      </strong>{" "}
                      <strong>7%a.a.</strong>
                    </p>
                  </MDBox>
                </MDBox>
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="high-risk"
                name="risks"
                className={styles.radio}
              />
              <label
                htmlFor="high-risk"
                className={styles.containerRisk}
                role="button"
              >
                <MDBox
                  mt={4}
                  width="100%"
                  bgColor="#4B93FF"
                  display="flex"
                  gap="1rem"
                  alignItems="center"
                  borderRadius="1rem 1rem 0 0"
                  p={2}
                >
                  <img
                    src={arrowUpIcon}
                    alt="Seta para cima"
                    className={styles.containerRiskHeaderIcon}
                  />
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h3"
                      fontWeight="light"
                      sx={{
                        fontSize: "1.5rem",
                        lineHeight: "calc(1.5rem) * 1.5",
                        color: "#fff",
                      }}
                    >
                      Menor risco
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="light"
                      sx={{
                        fontSize: "0.75rem",
                        lineHeight: "calc(0.75rem) * 1.5",
                        color: "#fff",
                      }}
                    >
                      Prazo até 12 meses
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  py={4}
                  px={2}
                  display="flex"
                  flexDirection="column"
                  gap="0.75rem"
                >
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      Juros pagos pelas pessoas
                    </p>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalPlus}>
                        +
                      </strong>{" "}
                      30%a.a.
                    </p>
                  </MDBox>
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      Inadimplência esperada
                    </p>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalMinus}>
                        -
                      </strong>{" "}
                      10%a.a.
                    </p>
                  </MDBox>
                  <MDBox
                    display="flex"
                    gap="0.5rem"
                    justifyContent="space-between"
                  >
                    <strong className={styles.containerRiskLabel}>
                      Rentabilidade esperada
                    </strong>
                    <p
                      className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
                    >
                      <strong className={styles.containerRiskSignalEqual}>
                        =
                      </strong>{" "}
                      <strong>20%a.a.</strong>
                    </p>
                  </MDBox>
                </MDBox>
              </label>
            </div>

            <MDBox
              width="100%"
              flex="1"
              display="flex"
              alignItems="flex-end"
              justifyContent="center"
            >
              <button className={styles.button} onClick={openModal}>
                Confirmar
              </button>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {isOpenModal && (
        <ModalAutomaticReinvestmentEnabled closeModal={closeModal} />
      )}
    </>
  );
}

export default AutomaticReinvestment;
