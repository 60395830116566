import React from "react";
import { Link } from "react-router-dom";

import { CustomTooltip } from "layouts/pages/home/components/CardMyWallet/styles";

import { formatMoney } from "utils";

import styles from "./styles.module.css";
import suitcaseIcon from "../../../../../assets/images/icons/suitcase.svg";
import clockIcon from "../../../../../assets/images/icons/clock.svg";
import walletIcon from "../../../../../assets/images/icons/wallet-soft-blue.svg";

function CardTotalBalance({ wallet }) {
  const amountInvested = wallet?.my_wallet?.amount_receivable;
  const amountInFunding = wallet?.my_wallet?.invested;
  const balanceAvailable = wallet?.my_wallet?.available;

  // console.log(wallet);

  return (
    <div className={styles.card}>
      <h1 className={styles.title}>Contratos</h1>
      <div className={styles.walletItems}>
        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Saldo disponível
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Saldo disponível para transferir para a sua conta bancária
                cadastrada ou para investir em novos empréstimos.
              </p>

              <strong>
                <Link to="/investir">Clique para investir</Link>
              </strong>
            </>
          }
        >
          <div
            className={styles.walletItem}
            // title="Saldo disponível para tranferir para a sua conta bancária
            //       cadastrada ou para investir em novos empréstimos."
          >
            <img
              src={walletIcon}
              alt="Carteira"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Saldo disponível</p>
            <strong className={styles.walletItemBody1}>
              {formatMoney(balanceAvailable)}
            </strong>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor em captação
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma das ordens de investimento, em fase de distribuição para
                novos empréstimos.
              </p>
            </>
          }
        >
          <div
            className={styles.walletItem}
            // title={"Valor em contratos de empréstimos ativos neste momento."}
          >
            <img
              src={clockIcon}
              alt="Relógio"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor em captação</p>
            <strong className={styles.walletItemBody1}>
              {formatMoney(amountInFunding)}
            </strong>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor investido
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma dos empréstimos em andamento. <br />
                Obs: O valor investido poderá retornar à captação nos casos de
                empréstimos não concedidos.
              </p>
              {/* <Link to="#">Clique aqui para ver seus contratos ativos.</Link> */}
            </>
          }
        >
          <div
            className={styles.walletItem}
            // title={`Valor recentemente investido, em distribuição para novos empréstimos.`}
          >
            <img
              src={suitcaseIcon}
              alt="Maleta"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor investido</p>
            <strong className={styles.walletItemBody1}>
              {formatMoney(amountInvested)}
            </strong>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
}

export default CardTotalBalance;
