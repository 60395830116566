import React from "react";

import styles from "./styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";
// import copyIcon from "assets/images/icons/copy.svg";

function ModalCopyCreatedSave({ close, setAlertConfig, generatedCode }) {
  async function copyCode(code) {
    await navigator.clipboard.writeText(code);
  }

  async function handleClickButtonCopyCode(code) {
    await copyCode(code);
    setAlertConfig({
      isOpen: true,
      type: "success",
      message: "Código copiado",
    });
    close();
  }

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.headerTitle}>Código criado</h2>
          <button
            className={styles.headerCloseButton}
            title="Fechar"
            onClick={close}
          >
            <img src={closeCircleIcon} alt="Fechar" />
          </button>
        </header>

        <p className={styles.messageLabel}>
          Agora compartilhe o código com quem você deseja emprestar
        </p>

        <strong className={styles.code}>{generatedCode?.code}</strong>
        {/* <p>{generatedCode?.code}</p> */}

        <button
          className={styles.primaryButton}
          // onClick={() => copyCode(generatedCode?.code)}
          onClick={() => handleClickButtonCopyCode(generatedCode?.code)}
        >
          Copiar código
        </button>
      </aside>
    </div>
  );
}

export { ModalCopyCreatedSave };
