import { useState } from "react";
import { useMediaQuery, Alert, Snackbar } from "@mui/material";

import MDBox from "../../../../../components/MDBox";
// import MDProgress from "../../../../../components/MDProgress";
import Loading from "components/Loading";
import { ModalBilletDownloaded } from "./components/ModalBilletDownloaded";

// import { formatMoney } from "../../../../../utils";
import { moneyMaskFormatPTBR } from "../../../../../utils/mask";
import { formatMoney } from "../../../../../utils";
import { paymentInvestment } from "services/payments";
import { parceTextToNumber } from "metrics/simulation";
// import { getStoredValue } from "metrics/storage";

import styles from "./styles.module.css";

import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";
import closeIcon from "../../../../../assets/images/icons/close-circle.svg";
import updateIcon from "../../../../../assets/images/icons/update.svg";

const MINIMUN_INVESTMENT = 100;

function CardDeposit({ setCurrentRenderedContent, loggedUser }) {
  const isTablet = useMediaQuery("(max-width: 768px)");

  const [amount, setAmount] = useState("");
  const [imagePixBase64, setImagePixBase64] = useState(null);
  const [bankSlipUrl, setBankSlipUrl] = useState(null);
  const [pixCode, setPixCode] = useState(null);
  const [smsCode, setSmsCode] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activeLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  const isFirstStep = () => currentStep === 1;

  const isLastStep = () => currentStep === 4;

  const goHome = () => setCurrentRenderedContent("initial");

  const nextStep = () => setCurrentStep(currentStep + 1);

  const previousStep = () => setCurrentStep(currentStep - 1);

  function handleClickBackButton() {
    isFirstStep() ? goHome() : previousStep();
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    if (parceTextToNumber(amount) < MINIMUN_INVESTMENT) {
      setAlertConfig({
        isOpen: true,
        message: `O valor mínimo de depósito é ${formatMoney(
          MINIMUN_INVESTMENT
        )}`,
        type: "error",
      });
      return;
    }

    activeLoading();

    try {
      const data = await paymentInvestment(parceTextToNumber(amount));

      setImagePixBase64(data.encodedImage);
      setPixCode(data.payload);
      if (data.bankSlipUrl) {
        setBankSlipUrl(data.bankSlipUrl);
      } else {
        setBankSlipUrl(data.invoiceUrl);
      }
      nextStep();
    } catch (error) {
      console.error("Erro ao depositar dinheiro");
      console.error(error);
    } finally {
      disableLoading();
    }
  }

  function handleAmountChange(event) {
    const { value } = event.target;

    const formattedMoney = moneyMaskFormatPTBR(value);

    setAmount(formattedMoney);
  }

  function openLinkPDFForDownload() {
    window.open(bankSlipUrl, "_blank");
  }

  function copyPixCode() {
    navigator.clipboard.writeText(pixCode);
  }

  function handleClickButtonCopyCodePix() {
    copyPixCode();
    setAlertConfig({
      isOpen: true,
      message: "Código PIX copiado",
      type: "success",
    });
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setAlertConfig({
      ...alertConfig,
      isOpen: false,
    });
  }

  function handleSmsCodeChange(event) {
    const { value } = event.target;

    setSmsCode(value);
  }

  function handleSteps() {
    switch (currentStep) {
      case 1:
        return (
          <form
            className={styles.form}
            onSubmit={handleFormSubmit}
            autoComplete="off"
          >
            <label htmlFor="amount" className={styles.formLabel}>
              Quanto você quer depositar na sua conta Fido?
            </label>
            <input
              type="text"
              className={styles.formInput}
              id="amount"
              name="amount"
              autoComplete="off"
              placeholder="R$ 1.000,00"
              value={amount}
              onChange={handleAmountChange}
              required
            />
            <button
              type="submit"
              className={`${styles.formButton} ${styles.formButtonStep1}`}
              disabled={isLoading}
            >
              Confirmar
            </button>
          </form>
        );

      case 2:
        return (
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <MDBox
              mt={2}
              display="flex"
              flexDirection="column"
              color="#667DBB"
              alignItems="center"
              textAlign="center"
            >
              <p className={`${styles.text16} ${styles.colorBlue}`}>Valor</p>
              <p className={`${styles.text24} ${styles.colorBlue}`}>{amount}</p>
            </MDBox>
            <img
              src={`data:image/png;base64,${imagePixBase64}`}
              className={`${styles.qrCode} ${styles.mt1}`}
              alt="Qr Code"
            />
            <p
              className={`${styles.text16} ${styles.maxWidth70} ${styles.colorGreenDark} ${styles.mt1}`}
            >
              Escaneie o código para pagar o boleto via PIX
            </p>
            {/* <button
              className={`${styles.mt24} ${styles.formButton}`}
              onClick={openLinkPDFForDownload}
            >
              Baixar boleto
            </button> */}
            <button
              className={`${styles.textButton} ${styles.mt1}`}
              onClick={handleClickButtonCopyCodePix}
            >
              Copiar código PIX
            </button>
            <button
              className={`${styles.secondaryButton} ${styles.mt24}`}
              onClick={goHome}
            >
              Voltar ao início
            </button>
          </MDBox>
        );

      default:
        return null;
    }
  }

  const closeModal = () => setIsOpenModal(false);

  return (
    <MDBox
      width={isTablet ? "100%" : "min(22rem, 100%)"}
      color="dark"
      bgColor="#fff"
      borderRadius="lg"
      shadow="lg"
      p={2}
    >
      <MDBox display="flex" alignItems="center" justifyContent="space-between">
        <button
          className={styles.headerBackButton}
          onClick={handleClickBackButton}
        >
          <img
            src={arrowLeftIcon}
            alt="Voltar ao início"
            title="Voltar ao início"
          />
        </button>
        <h3 className={styles.headerTitle}>Depósito</h3>
        {isLastStep() ? (
          <button className={styles.headerBackButton} onClick={goHome}>
            <img
              src={closeIcon}
              alt="Voltar ao início"
              title="Voltar ao início"
            />
          </button>
        ) : (
          <img
            src={updateIcon}
            alt="Atualizar"
            className={styles.headerUpdateIcon}
          />
        )}
      </MDBox>

      {handleSteps()}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertConfig.isOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={alertConfig.type}
          sx={{ width: "100%" }}
        >
          {alertConfig.message}
        </Alert>
      </Snackbar>

      {isOpenModal && <ModalBilletDownloaded close={closeModal} />}

      {isLoading && <Loading />}
    </MDBox>
  );
}

export default CardDeposit;
