import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";
import mainStyles from "../../styles.module.css";

import closeIcon from "../../../../../assets/images/icons/close-white.svg";
import markEmailIcon from "../../../../../assets/images/icons/mark-email.svg";

function ModalSendEmail({ closeModal, email }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.title}>Senha enviada</h2>
          <button
            className={styles.closeButton}
            title="Fechar modal"
            onClick={closeModal}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <div className={styles.containerIcon}>
          <img src={markEmailIcon} alt="Email com check" />
        </div>
        <p className={styles.description}>
          Enviamos um link para a mudança da sua senha no seguinte e-mail
          cadastrado <strong>{email}</strong>.
        </p>

        <Link
          to="/login"
          className={`${mainStyles.primaryButton} ${styles.linkButton}`}
        >
          Fazer Login
        </Link>
      </aside>
    </div>
  );
}

export default ModalSendEmail;
