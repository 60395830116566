import React, { useState } from "react";

import SessionControl from "../SessionControl";

import styles from "./styles.module.css";

import secretaryIllustration from "../../../../assets/images/illustrations/secretary.svg";
import diversificationIllustration from "../../../../assets/images/illustrations/diversification.svg";
import papersIllustration from "../../../../assets/images/illustrations/papers.svg";
import walletIllustration from "../../../../assets/images/illustrations/wallet.svg";
import chargesIllustration from "../../../../assets/images/illustrations/charges.svg";
import personWithMoneyIllustration from "../../../../assets/images/illustrations/person-with-money.svg";
import arrowRightIcon from "../../../../assets/images/icons/arrow-right.svg";
import arrowLeftIcon from "../../../../assets/images/icons/arrow-left-green.svg";

function CardLearnInvest() {
  const [currentSection, setCurrentSection] = useState(1);

  const goSection = (sectionNumber = 1) => setCurrentSection(sectionNumber);

  function nextSection() {
    if (currentSection >= 6) {
      setCurrentSection(6);
      return;
    }

    setCurrentSection(currentSection + 1);
  }

  function previousSection() {
    if (currentSection <= 1) {
      setCurrentSection(1);
      return;
    }

    setCurrentSection(currentSection - 1);
  }

  function contentSectionRender() {
    switch (currentSection) {
      case 1:
        return (
          <>
            <h2 className={styles.h2}>Saiba como investir na FIDO</h2>
            <img
              src={secretaryIllustration}
              alt="Secretária"
              className={styles.illustration}
            />
            <button className={styles.primaryButton} onClick={nextSection}>
              Ver como funciona
            </button>
          </>
        );

      case 2:
        return (
          <>
            <h2 className={styles.h2}>Invista</h2>
            <img
              src={papersIllustration}
              alt="Papéis com pessoas"
              className={styles.illustration2}
            />
            <p className={styles.textBody}>
              Escolha o <strong>quanto</strong> quer alocar em cada{" "}
              <strong>score de risco</strong> do grupo de favorecidos e o{" "}
              <strong>prazo</strong> máximo dos empréstimos.
            </p>
            <p className={styles.textBody}>
              Os scores de risco são A, B, C e D. O prazo dos empréstimos varia
              entre 6 e 18 meses.
            </p>
            <SessionControl currentSection={currentSection - 1} />
            <div className={styles.containerControlButtons}>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonBack}`}
                title="Voltar"
                onClick={previousSection}
              >
                <img src={arrowLeftIcon} alt="Seta para esquerda" />
              </button>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonNext}`}
                title="Avançar"
                onClick={nextSection}
              >
                <img src={arrowRightIcon} alt="Seta para direito" />
              </button>
            </div>
          </>
        );

      case 3:
        return (
          <>
            <h2 className={styles.h2}>Diversificação</h2>
            <img
              src={diversificationIllustration}
              alt="Duas pessoas em uma apresentação"
              className={styles.illustration2}
            />
            <p className={styles.textBody}>
              A plataforma faz a <strong>diversificação automática</strong> do
              valor para dezenas de favorecidos à medida que novos empréstimos
              são solicitados ao longo do tempo.
            </p>
            <p className={styles.textBody}>
              Esse processo pode levar vários dias.
            </p>
            <p className={styles.textBody}>
              Do outro lado, cada favorecido recebe o valor do empréstimo a
              partir de vários investidores.
            </p>
            <SessionControl currentSection={currentSection - 1} />
            <div className={styles.containerControlButtons}>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonBack}`}
                title="Voltar"
                onClick={previousSection}
              >
                <img src={arrowLeftIcon} alt="Seta para esquerda" />
              </button>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonNext}`}
                title="Avançar"
                onClick={nextSection}
              >
                <img src={arrowRightIcon} alt="Seta para direito" />
              </button>
            </div>
          </>
        );

      case 4:
        return (
          <>
            <h2 className={styles.h2}>Segurança</h2>
            <img
              src={personWithMoneyIllustration}
              alt="Duas pessoas em uma apresentação"
              className={styles.illustration2}
            />
            <p className={styles.textBody}>
              Verificamos o histórico de inadiplencia e{" "}
              <strong>validamos</strong> a documentação de cada favorecido em
              diversos bancos de dados.
            </p>
            <p className={styles.textBody}>
              Caso passe por todos os <strong>critérios</strong>, o favorecido
              recebe o score de risco que vai de A a D.
            </p>
            <SessionControl currentSection={currentSection - 1} />
            <div className={styles.containerControlButtons}>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonBack}`}
                title="Voltar"
                onClick={previousSection}
              >
                <img src={arrowLeftIcon} alt="Seta para esquerda" />
              </button>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonNext}`}
                title="Avançar"
                onClick={nextSection}
              >
                <img src={arrowRightIcon} alt="Seta para direito" />
              </button>
            </div>
          </>
        );

      case 5:
        return (
          <>
            <h2 className={styles.h2}>Receba</h2>
            <img
              src={walletIllustration}
              alt="Pessoas colocando dinheiro na carteira"
              className={styles.illustration2}
            />
            <p className={styles.textBody}>
              As pessoas pagam prestações mensais, e o dinheiro{" "}
              <strong>retorna</strong> à sua carteira Fido.
            </p>
            <p className={styles.textBody}>
              A inadimplência faz parte deste tipo modalidade de investimento.
              No final das contas, mesmo com alguma perda, o investimento ainda
              deve trazer uma boa <strong>rentabilidade</strong>.
            </p>
            <p className={styles.textBody}>
              É assim que os bancos ganham dinheiro.
            </p>
            <SessionControl currentSection={currentSection - 1} />
            <div className={styles.containerControlButtons}>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonBack}`}
                title="Voltar"
                onClick={previousSection}
              >
                <img src={arrowLeftIcon} alt="Seta para esquerda" />
              </button>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonNext}`}
                title="Avançar"
                onClick={nextSection}
              >
                <img src={arrowRightIcon} alt="Seta para direito" />
              </button>
            </div>
          </>
        );

      case 6:
        return (
          <>
            <h2 className={styles.h2}>Cobrança</h2>
            <img
              src={chargesIllustration}
              alt="Pessoa com um check list de cobranças"
              className={styles.illustration2}
            />
            <p className={styles.textBody}>
              A Fido se encarrega de cobrar todas as pessoas com parcelas em
              atraso. A sequência de cobrança segue 4 fases: convencimento ,
              amigável, negocial e extra-judicial.
            </p>
            <p className={styles.textBody}>
              O inadimplente é negativado junto ao Banco Central do Brasil e ao
              SPC.
            </p>
            <p className={styles.textBody}>
              A Fido fará cobranças judiciais para os empréstimos de maior
              valor.
            </p>
            <SessionControl currentSection={currentSection - 1} />
            <div className={styles.containerControlButtons}>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonBack}`}
                title="Voltar"
                onClick={previousSection}
              >
                <img src={arrowLeftIcon} alt="Seta para esquerda" />
              </button>
              <button
                className={`${styles.buttonSessionControl} ${styles.buttonNext}`}
                title="Avançar"
                onClick={nextSection}
                disabled={currentSection === 6}
              >
                <img src={arrowRightIcon} alt="Seta para direito" />
              </button>
            </div>
            <button
              className={`${styles.primaryButton} ${styles.primaryButtonLastSection}`}
              onClick={() => goSection(1)}
            >
              Voltar ao início
            </button>
          </>
        );
    }
  }

  return <section className={styles.card}>{contentSectionRender()}</section>;
}

export default CardLearnInvest;
