import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, useMediaQuery } from "@mui/material";

import { ListOrderCard } from "./components/ListOrderCard";

import { cancelInvestments, getInvestments } from "services/investments";
import { formateDateWithOutHours } from "utils";
import { dateMask } from "utils/mask";

import styles from "./styles.module.css";

const CONSTANTS = {
  DIMENSIONS_MOBILE: 911,
};

function CardInvestmentOrder({ setIsLoading, setIsLoadingInvestments }) {
  const [listOrders, setListOrders] = useState([]);
  const [listOrdersFiltered, setListOrdersFiltered] = useState([]);
  const [searchCreatedDateOrder, setSearchCreatedDateOrder] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalOutOfHours, setShowModalOutOfHours] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery(
    `(max-width: ${CONSTANTS.DIMENSIONS_MOBILE}px)`
  );

  async function loadInvestments() {
    setIsLoadingInvestments(true);
    try {
      const responseInvestments = await getInvestments();
      const { status, data } = responseInvestments;

      if (status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (status !== 200 && status !== 202) {
        throw new Error();
      }

      setListOrders([...data.reverse()]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingInvestments(false);
    }
  }

  async function handleCancelOrderData(id) {
    const body = {
      investment_id: id,
    };
    setIsLoading(true);

    try {
      const response = await cancelInvestments(body);

      if (response?.status === 200) {
        loadInvestments();
        setShowModal(true);
      } else if (response?.status === 202) {
        loadInvestments();
        setShowModalOutOfHours(true);
      } else if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error("Erro ao cancelar ordem", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadInvestments();
  }, []);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  function filterListOrder() {
    if (searchCreatedDateOrder?.length === 0) {
      setListOrdersFiltered([]);
      return;
    }

    const filteredByDate = listOrders.filter((order) =>
      formateDateWithOutHours(order.created_at.$date).includes(
        searchCreatedDateOrder
      )
    );
    setListOrdersFiltered([...filteredByDate]);
  }

  useEffect(() => {
    filterListOrder();
  }, [searchCreatedDateOrder]);

  return (
    <div className={styles.card}>
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Ordem de investimento</h2>
        <nav className={styles.headerNav}>
          <form autoComplete="off">
            <TextField
              id="date-to-search"
              label="Buscar por data"
              variant="standard"
              value={dateMask(searchCreatedDateOrder)}
              onChange={({ target }) => setSearchCreatedDateOrder(target.value)}
            />
          </form>
        </nav>
      </header>
      <ul className={styles.ordersList}>
        {listOrdersFiltered.length > 0 ? (
          listOrdersFiltered.map(
            ({ _id, investment_amount, created_at, status, balance, risk }) => (
              <ListOrderCard
                id={_id.$oid}
                created_at={created_at}
                investment_amount={investment_amount}
                status={status}
                handleCancelOrderData={handleCancelOrderData}
                balance={balance}
                risk={risk}
                isMobile={isMobile}
              />
            )
          )
        ) : searchCreatedDateOrder.length > 0 ? (
          <div className={styles.responseNotOrders}>
            Não há ordens de investimento para a data pesquisada.
          </div>
        ) : listOrders.length === 0 ? (
          <div className={styles.responseNotOrders}>
            Ops! No momento não há ordens de investimento realizadas.
          </div>
        ) : (
          listOrders.map(
            ({ _id, investment_amount, created_at, status, balance, risk }) => (
              <ListOrderCard
                id={_id.$oid}
                created_at={created_at}
                investment_amount={investment_amount}
                status={status}
                handleCancelOrderData={handleCancelOrderData}
                balance={balance}
                risk={risk}
                isMobile={isMobile}
              />
            )
          )
        )}
      </ul>

      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <p className={styles.descModalContentTitle}>
              Sua ordem de investimento foi interrompida com sucesso!
            </p>
            <button
              className={styles.modalClose}
              onClick={() => {
                setShowModal(false);
                setShouldReload(true);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
      {showModalOutOfHours && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <p>
              Você solicitou o cancelamento do seu investimento fora do horário
              permitido. O valor investido e que ainda não foi emprestado será
              estornado e o processo de cancelamento será iniciado a partir do
              meio-dia do próximo dia útil, este processo pode levar até 12
              horas para ser concluído.
            </p>
            <button
              className={styles.modalClose}
              onClick={() => {
                setShowModalOutOfHours(false);
                setShouldReload(true);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardInvestmentOrder;
