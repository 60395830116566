import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CardTotalBalance from "../../components/CardTotalBalance";

import { formatMoney } from "utils";
import { getStoredValue, setStoredValue } from "metrics/storage";

import styles from "./styles.module.css";
import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";
import arrowUpIcon from "../../../../../assets/images/icons/arrow-up.svg";
import arrowBottomIcon from "../../../../../assets/images/icons/arrow-bottom.svg";
import balanceIcon from "../../../../../assets/images/icons/balance-invest.svg";

const CONSTANTS = {
  MAX_AMOUNT_WITHDRAW: 1000000,
  MIN_AMOUNT_WITHDRAW: 100,
  MAX_AMOUNT_WITHDRAW_NOT_VALIDATE_DOCUMENTS: 5000,
  ARRAY_ACCOUNT_STATUS_APPROVED: [
    "verification_updated",
    "verification_completed",
  ],
};

function SectionAmountToInvest({
  setCurrentContentHTML,
  amountToInvest,
  setAmountToInvest,
  wallet,
}) {
  const [minAmountAvailableToWithdraw, setMinAmountAvailableToWithdraw] =
    useState(CONSTANTS.MIN_AMOUNT_WITHDRAW);
  const [maxAmountAvailableToWithdraw, setMaxAmountAvailableToWithdraw] =
    useState(CONSTANTS.MAX_AMOUNT_WITHDRAW);
  const [
    accountStatusValidationDocuments,
    setAccountStatusValidationDocuments,
  ] = useState("");
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [numberValue, setNumberValue] = useState(0);

  const amountAvailable = wallet?.my_wallet?.available;
  const matiReport = JSON.parse(sessionStorage.getItem("matiReport"));
  const plan = JSON.parse(getStoredValue("plan"));

  function handleChangeAmountToInvest(event) {
    const { value } = event.target;

    const numericValue = parseFloat(value.replace(/[^\d]/g, '')) || 0;
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
    }).format(numericValue / 100);

    setAmountToInvest(formattedValue);
  }

  useEffect(() => {
    const numericValue = parseFloat(amountToInvest.replace(/[^\d]/g, '')) || 0;
    setNumberValue(numericValue);
  }, [numberValue, amountToInvest]);

  function canMakeInvestmentsFreely() {
    return (
      Number(amountToInvest) >
      CONSTANTS.MAX_AMOUNT_WITHDRAW_NOT_VALIDATE_DOCUMENTS &&
      accountStatusValidationDocuments !== "approved"
    );
  }

  function handleSubmitFormAmountToInvest(event) {
    event.preventDefault();

    const formattedValue = (numberValue / 100).toFixed(2);
    setAmountToInvest(formattedValue);

    if (canMakeInvestmentsFreely()) {
      setIsOpenSnackbar(true);
      setMessageSnackbar(
        `Você pode fazer investimento de até ${formatMoney(
          CONSTANTS.MAX_AMOUNT_WITHDRAW_NOT_VALIDATE_DOCUMENTS
        )}. Seus documentos ainda não foram validados`
      );
      return;
    }

    if (amountAvailable < CONSTANTS.MIN_AMOUNT_WITHDRAW) {
      setIsOpenSnackbar(true);
      setMessageSnackbar(
        `Seu saldo atual é ${formatMoney(
          amountAvailable
        )}. O investimento mínimo é de ${formatMoney(
          CONSTANTS.MIN_AMOUNT_WITHDRAW
        )}. Você precisa ir até a carteira e adicionar fundos para começar a investir`
      );
      return;
    }

    setStoredValue("amountToInvest", amountToInvest);
    setCurrentContentHTML("investmentDetails");
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setIsOpenSnackbar(false);
  }

  useEffect(() => {
    if (
      matiReport?.approved &&
      CONSTANTS.ARRAY_ACCOUNT_STATUS_APPROVED.some(
        (statusItem) => statusItem === matiReport?.last_status
      )
    ) {
      setAccountStatusValidationDocuments("approved");
    } else if (matiReport?.approved) {
      setAccountStatusValidationDocuments("in-progress");
    } else {
      setAccountStatusValidationDocuments("reproved");
    }
  }, []);

  useEffect(() => {
    if (amountAvailable === undefined) {
      return;
    }

    if (amountAvailable < CONSTANTS.MIN_AMOUNT_WITHDRAW) {
      setMinAmountAvailableToWithdraw(amountAvailable);
    } else {
      setMinAmountAvailableToWithdraw(CONSTANTS.MIN_AMOUNT_WITHDRAW);
    }

    if (amountAvailable < CONSTANTS.MAX_AMOUNT_WITHDRAW) {
      setMaxAmountAvailableToWithdraw(amountAvailable);
    } else {
      setMaxAmountAvailableToWithdraw(CONSTANTS.MAX_AMOUNT_WITHDRAW);
    }
  }, []);

  useEffect(() => {
    if (minAmountAvailableToWithdraw === undefined) return;

  }, [minAmountAvailableToWithdraw]);

  return (
    <MDBox component="section" mt={3}>
      <CardTotalBalance wallet={wallet} />
      <button
        title="Voltar para seção anterior"
        className={styles.backButton}
        onClick={() => setCurrentContentHTML("initial")}
      >
        <img src={arrowLeftIcon} alt="Seta para esquerda" />
      </button>
      <MDBox display="flex" gap="2rem" flexWrap="wrap">
        <div className={styles.cardAmountToInvest}>
          <h4 className={styles.cardAmountToInvestTitle}>
            Quanto você quer investir?
          </h4>
          <form
            className={styles.formAmount}
            onSubmit={handleSubmitFormAmountToInvest}
          >
            <label htmlFor="amount" className={styles.gone}>
              Valor a investir
            </label>
            <MDBox position="relative" display="flex" alignItems="center">
              <span className={styles.formAmountReal}>R$</span>
              <input
                type="text"
                id="amount"
                name="amount"
                placeholder="100,00"
                max={maxAmountAvailableToWithdraw}
                className={styles.formAmountInput}
                value={amountToInvest}
                onChange={handleChangeAmountToInvest}
                autoComplete="off"
                required
              />
            </MDBox>
            <input
              type="range"
              name="amount-range"
              id="amount-range"
              value={numberValue === "" ? 0 : numberValue}
              max={maxAmountAvailableToWithdraw * 100}
              onChange={handleChangeAmountToInvest}
              className={styles.formAmountInputRange}
            />

            <p className={styles.formAmountBalance}>
              Você está investindo{" "}
              <strong>{amountToInvest}</strong>
            </p>

            <button
              type="submit"
              className={`${styles.button} ${styles.formAmountButton}`}
            >
              Continuar
            </button>
          </form>
        </div>
      </MDBox>
      {isOpenSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isOpenSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {messageSnackbar}
          </Alert>
        </Snackbar>
      )}
    </MDBox>
  );
}

export default SectionAmountToInvest;
