import React from "react";

import MDBox from "components/MDBox";

import styles from "./styles.module.css";

function ModalBilletDownloaded({ close }) {
  return (
    <MDBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor="rgba(0, 0, 0, 0.5)"
      zIndex="1201"
    >
      <aside className={styles.modal}>
        <p className={styles.modalMessage}>Boleto baixado com sucesso</p>
        <button className={styles.modalButton} onClick={close}>
          Entendi
        </button>
      </aside>
    </MDBox>
  );
}

export { ModalBilletDownloaded };
