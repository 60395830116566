import React from "react";

import CardValidationDocuments from "../../components/CardValidationDocuments";

function StepValidationDocuments({
  previousStep,
  activeLoading,
  disableLoading,
  token,
  cpf,
  bankCode,
  agency,
  accountNumber,
  digitAccount,
  accountType,
}) {
  return (
    <CardValidationDocuments
      previousStep={previousStep}
      activeLoading={activeLoading}
      disableLoading={disableLoading}
      token={token}
      cpf={cpf}
      bankCode={bankCode}
      agency={agency}
      accountNumber={accountNumber}
      digitAccount={digitAccount}
      accountType={accountType}
    />
  );
}

export default StepValidationDocuments;
