import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";
import mainStyles from "../../styles.module.css";

import closeIcon from "../../../../../assets/images/icons/close-white.svg";
import errorCircleIcon from "../../../../../assets/images/icons/error-circle.svg";

function ModalCpfExists({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.title}>CPF não encontrado</h2>
          <button
            className={styles.closeButton}
            title="Fechar modal"
            onClick={closeModal}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <div className={styles.containerIcon}>
          <img
            src={errorCircleIcon}
            alt="Sinal de exclamação com círculo envolta"
          />
        </div>
        <p className={styles.description}>
          Você não possui uma conta na FIDO. Crie uma conta agora.
        </p>

        <Link
          to="/criar-conta"
          className={`${mainStyles.primaryButton} ${styles.linkButton}`}
        >
          Criar conta
        </Link>
      </aside>
    </div>
  );
}

export default ModalCpfExists;
