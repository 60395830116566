import { api } from "./api";

async function getListHistoryInvites(token) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.get("/investor/invites", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getUrlInviteInvestor(token) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.get("/investor/invite-url", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

export { getListHistoryInvites, getUrlInviteInvestor };
