import React from "react";

import styles from "./styles.module.css";

import threePeopleCommemorationIllustration from "../../../../../assets/images/illustrations/three-people-commemoration.svg";

function CardConfirmedInvestment({ setCurrentContentHTML }) {
  return (
    <article className={styles.card}>
      <h2 className={styles.title}>Investimento solicitado com sucesso!</h2>
      <img
        src={threePeopleCommemorationIllustration}
        alt="Três pessoas pulando e comemorando"
        className={styles.illustration}
      />
      <p className={styles.textBody}>
        A partir de agora, faremos a distribuição automática do seu investimento
        em vários empréstimos, na proporção da diversificação dos scores de
        risco escolhida por você.
      </p>
      <p className={styles.textBody}>
        O valor investido vai sendo consumido à medida que novos empréstimos são
        solicitados na plataforma, o que pode levar alguns dias.
      </p>
      <button
        className={styles.button}
        onClick={() => {
          window.location.reload();
        }}
      >
        Voltar ao início
      </button>
    </article>
  );
}

export default CardConfirmedInvestment;
