import React from "react";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";

import { signUpFinish } from "services/user";

import arrowBackIcon from "assets/images/icons/arrow-back.svg";

const CONSTANTS = {
  ERROR_ASSAS: "ASAAS_DUPLICATED_ERROR",
  STEP_EMAIL: 2,
};

function TermsOfUse({
  previousStep,
  nextStep,
  token,
  email,
  cpf,
  activeLoading,
  disableLoading,
  setCurrentStepNumber,
  setAlert,
}) {
  async function handleClickButton() {
    activeLoading();
    // let response = undefined;

    try {
      const response = await signUpFinish(cpf, email, token);
      // console.log("Response try", response);

      if (response?.status !== 200) {
        if (response?.data?.error === CONSTANTS.ERROR_ASSAS) {
          setAlert({
            type: "error",
            message: `Encontramos um usuário utilizando o e-mail ${email}. Tente criar conta com outro e-mail`,
            isOpen: true,
          });
          setCurrentStepNumber(CONSTANTS.STEP_EMAIL);
        }

        throw new Error();
      }

      nextStep();
    } catch (error) {
      console.error(error);
      console.error("Erro ao finalizar a criação de conta");
    } finally {
      disableLoading();
    }
  }

  return (
    <>
      <div className={`${mainPageStyles.card} ${styles.containerCard}`}>
        <header className={styles.header}>
          <button
            title="Voltar para etapa anterior"
            className={styles.backButton}
            onClick={previousStep}
          >
            <img src={arrowBackIcon} alt="Seta para esquerda" />
          </button>
          <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
            Termos de Uso e Política de Privacidade
          </h2>
        </header>
        <section className={styles.sectionTerms}>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Estes Termos de Uso e Política de Privacidade (“Termos”) têm como
            objetivo garantir a transparência no tratamento de seus dados
            pessoais pela{" "}
            <strong>Fido Sociedade de Empréstimo Entre Pessoas S.A.</strong>,
            sociedade anônima, instituição financeira estabelecida na Cidade de
            Brasília, Distrito Federal, CNPJ nº 40.819.834/0001-48.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            A Fido estabelece nestes Termos as condições para utilização do site
            institucional e aplicativos da Fido, de forma a esclarecer as
            obrigações e responsabilidades de seus usuários (“Usuários”), os
            quais poderão acessar os serviços e conteúdos disponibilizados pela
            Fido.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Ao acessar o site institucional e o aplicativo da Fido, o usuário
            concorda em cumprir estes termos de serviço, todas as leis e
            regulamentos aplicáveis. Se você não concordar com algum desses
            termos, está proibido de usar ou acessar este aplicativo. Os
            materiais contidos neste aplicativo são protegidos pelas leis de
            direitos autorais e marcas comerciais aplicáveis.
          </p>
          <strong className={`${styles.termSubtitle}`}>
            1. Uso de Licença
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            É concedida permissão para baixar temporariamente uma cópia do
            aplicativo e dos materiais do site da Fido, apenas para visualização
            transitória pessoal e não comercial. Esta é a concessão de uma
            licença, não uma transferência de título e, sob esta licença, você
            não pode:
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                modificar ou copiar os materiais;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                usar os materiais para qualquer finalidade comercial ou para
                exibição pública (comercial ou não comercial);
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                tentar descompilar ou fazer engenharia reversa de qualquer
                software contido no aplicativo;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                remover quaisquer direitos autorais ou outras notações de
                propriedade da Fido; ou
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                transferir o site e/ou o aplicativo para outra pessoa ou
                'espelhar' os mesmos ou qualquer software de propriedade da Fido
                em qualquer servidor ou computador.
              </p>
            </li>
          </ul>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Esta licença será automaticamente rescindida se você violar alguma
            dessas restrições e poderá ser rescindida pela Fido a qualquer
            momento. Ao término desta licença, você deverá apagar todos os
            materiais baixados em sua posse.
          </p>

          <strong className={`${styles.termSubtitle}`}>
            2. Isenção de responsabilidade
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O site e o aplicativo Fido são fornecidos 'como estão'. A Fido não
            oferece garantias, expressas ou implícitas, e, por este meio, isenta
            e nega todas as outras garantias, incluindo, sem limitação,
            garantias implícitas ou condições de comercialização, adequação a um
            fim específico ou não violação de propriedade intelectual ou outra
            violação de direitos.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Além disso, a Fido não garante ou faz qualquer representação
            relativa à precisão, aos resultados prováveis ou à confiabilidade do
            uso dos materiais em seu site, aplicativo ou de outra forma
            relacionado a esses materiais ou em sites vinculados a estes.
          </p>
          <strong className={`${styles.termSubtitle}`}>3. Limitações</strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Em nenhum caso o Fido ou seus fornecedores serão responsáveis por
            quaisquer danos (incluindo, sem limitação, por perda de dados ou
            interrupção de negócios) decorrentes do uso ou da incapacidade de
            usar os sistemas da Fido, mesmo que Fido ou um representante
            autorizado da Fido tenha sido notificado oralmente ou por escrito da
            possibilidade de tais danos. Como algumas jurisdições não permitem
            limitações em garantias implícitas, ou limitações de
            responsabilidade por danos consequentes ou incidentais, essas
            limitações podem não se aplicar a você.
          </p>

          <strong className={`${styles.termSubtitle}`}>
            4. Precisão dos materiais
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Os materiais exibidos no site e no aplicativo da Fido podem incluir
            erros técnicos, tipográficos ou fotográficos. A Fido não garante que
            qualquer material em seu site e aplicativo seja preciso, completo ou
            atual. A Fido pode fazer alterações nos materiais a qualquer
            momento, sem aviso prévio. No entanto, Fido não se compromete a
            atualizá-los.
          </p>

          <strong className={`${styles.termSubtitle}`}>5. Modificações</strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            A Fido poderá revisar estes termos de uso a qualquer momento, sem
            aviso prévio. Ao usar o site e/ou o aplicativo Fido, você concorda
            em ficar vinculado à versão atual dos termos de uso.
          </p>

          <strong className={`${styles.termSubtitle}`}>6. Privacidade</strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            A sua privacidade é importante para nós. É política da Fido
            respeitar a sua privacidade em relação a qualquer informação
            disponibilizada por você à Fido.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Solicitamos informações pessoais apenas quando realmente precisamos
            delas para lhe fornecer um serviço, com o seu conhecimento e
            consentimento. Também informamos o motivo pelo qual estamos
            coletando e para que propósito as informações serão usadas.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Apenas retemos as informações coletadas pelo tempo necessário para
            fornecer o serviço solicitado. Quando armazenamos dados, protegemos
            dentro de meios comercialmente aceitáveis para evitar perdas e
            roubos, bem como acesso, divulgação, cópia, uso ou modificação não
            autorizados.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O site e o aplicativo Fido podem conter links para sites externos
            que não são operados por nós. Esteja ciente de que não temos
            controle sobre o conteúdo e práticas desses sites e não podemos
            aceitar responsabilidade por suas respectivas políticas de
            privacidade.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Você é livre para recusar a nossa solicitação de informações
            pessoais, entendendo que talvez não possamos fornecer alguns dos
            serviços desejados.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O uso continuado do site e/ou do aplicativo Fido será considerado
            como aceitação de nossas práticas em torno de privacidade e
            informações pessoais. Se você tiver alguma dúvida sobre como lidamos
            com dados do usuário e informações pessoais, entre em contacto
            conosco.
          </p>

          <strong className={`${styles.termSubtitle}`}>
            7. Política de Cookies
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>O que são cookies?</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Como é prática comum em quase todos os sites profissionais, este
            site usa cookies, que são pequenos arquivos baixados no seu
            computador, para melhorar sua experiência. Esta página descreve
            quais informações eles coletam, como as usamos e por que às vezes
            precisamos armazenar esses cookies. Também compartilharemos como
            você pode impedir que esses cookies sejam armazenados, no entanto,
            isso pode fazer o downgrade ou 'quebrar' certos elementos da
            funcionalidade do site.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Como usamos os cookies?</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Utilizamos cookies por vários motivos. Infelizmente, na maioria dos
            casos, não existem opções padrão do setor para desativar os cookies
            sem desativar completamente a funcionalidade e os recursos que eles
            adicionam ao site. É recomendável que você deixe todos os cookies se
            não tiver certeza se precisa ou não deles, caso sejam usados para
            fornecer um serviço que você utiliza.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Desativar cookies</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Você pode impedir a configuração de cookies ajustando as
            configurações do seu navegador (consulte a Ajuda do navegador para
            saber como fazer isso). Esteja ciente de que a desativação de
            cookies afetará a funcionalidade do site Fido e de muitos outros
            sites que você visita. A desativação de cookies geralmente resultará
            na desativação de determinadas funcionalidades e recursos do site.
            Portanto, é recomendável que você não desative os cookies.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies que definimos</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Cookies relacionados à conta.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Se você criar uma conta conosco, usaremos cookies para o
            gerenciamento do processo de inscrição e administração geral. Esses
            cookies geralmente serão excluídos quando você sair do sistema,
            porém, em alguns casos, eles poderão permanecer posteriormente para
            lembrar as suas preferências.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies relacionados ao login.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Utilizamos cookies quando você está logado, para que possamos
            lembrar dessa ação. Isso evita que você precise fazer login sempre
            que visitar uma nova página. Esses cookies são normalmente removidos
            ou limpos quando você efetua logout para garantir que você possa
            acessar apenas a recursos e áreas restritas ao efetuar login.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies relacionados a boletins por e-mail.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O site e o aplicativo Fido oferecem serviços de assinatura de
            boletim informativo ou e-mail e os cookies podem ser usados para
            lembrar se você já está registrado e se deve mostrar determinadas
            notificações válidas apenas para usuários inscritos / não inscritos.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Pedidos processando cookies relacionados.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O site e o aplicativo Fido oferecem facilidades de comércio
            eletrônico ou pagamento e alguns cookies são essenciais para
            garantir que seu pedido seja lembrado entre as páginas, para que
            possamos processá-lo adequadamente.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies relacionados a pesquisas.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Periodicamente, oferecemos pesquisas e questionários para fornecer
            informações interessantes, ferramentas úteis ou para entender nossa
            base de usuários com mais precisão. Essas pesquisas podem usar
            cookies para lembrar quem já participou numa pesquisa ou para
            fornecer resultados precisos após a alteração das páginas.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies relacionados a formulários.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Quando você envia dados por meio de um formulário como os
            encontrados nas páginas de contato ou no aplicativo, os cookies
            podem ser configurados para lembrar os detalhes do usuário para
            correspondência futura.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Cookies de preferências do site.</i>
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Para proporcionar uma ótima experiência no site da Fido, fornecemos
            a funcionalidade para definir suas preferências de como o site é
            executado quando você o usa. Para lembrar suas preferências,
            precisamos definir cookies para que essas informações possam ser
            chamadas sempre que você interagir com uma página for afetada por
            suas preferências.
          </p>

          <strong className={`${styles.termSubtitle}`}>
            8. Cookies de Terceiros
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Em alguns casos especiais, também usamos cookies fornecidos por
            terceiros confiáveis. A seção a seguir detalha quais cookies de
            terceiros você pode encontrar através deste site.
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                O site da Fido usa o Google Analytics, que é uma das soluções de
                análise mais difundidas e confiáveis da Web, para nos ajudar a
                entender como você usa o site e como podemos melhorar sua
                experiência. Esses cookies podem rastrear itens como quanto
                tempo você gasta no site e as páginas visitadas, para que
                possamos continuar produzindo conteúdo atraente.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Para mais informações sobre cookies do Google Analytics,
                consulte a página oficial do Google Analytics.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                As análises de terceiros são usadas para rastrear e medir o uso
                do site Fido, para que possamos continuar produzindo conteúdo
                atrativo. Esses cookies podem rastrear itens como o tempo que
                você passa no site ou as páginas visitadas, o que nos ajuda a
                entender como podemos melhorar o site para você.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Periodicamente, testamos novos recursos e fazemos alterações
                subtis na maneira como o site se apresenta. Quando ainda estamos
                testando novos recursos, esses cookies podem ser usados para
                garantir que você receba uma experiência consistente enquanto
                estiver no site, enquanto entendemos quais otimizações os nossos
                usuários mais apreciam.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                O serviço Google AdSense que usamos para veicular publicidade
                usa um cookie DoubleClick para veicular anúncios mais relevantes
                em toda a Web e limitar o número de vezes que um determinado
                anúncio é exibido para você.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Para mais informações sobre o Google AdSense, consulte as FAQs
                oficiais sobre privacidade do Google AdSense.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Podemos utilizar anúncios para compensar os custos de
                funcionamento do site Fido e fornecer financiamento para futuros
                desenvolvimentos. Os cookies de publicidade comportamental
                usados por este site foram projetados para garantir que você
                forneça os anúncios mais relevantes sempre que possível,
                rastreando anonimamente seus interesses e apresentando coisas
                semelhantes que possam ser do seu interesse.
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Os cookies de rastreamento de afiliados simplesmente nos
                permitem ver se nossos clientes acessaram o site através de um
                dos sites de nossos parceiros, para que possamos creditá-los
                adequadamente e, quando aplicável, permitir que nossos parceiros
                afiliados ofereçam qualquer promoção que pode fornecê-lo para
                fazer uma compra.
              </p>
            </li>
          </ul>

          <strong className={`${styles.termSubtitle}`}>
            9. Compromisso do Usuário
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O usuário se compromete a fazer uso adequado dos conteúdos e da
            informação que a Fido oferece através do site e do aplicativo Fido,
            incluindo, mas não se limitando a:
          </p>
          <ol>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Não se envolver em atividades que sejam ilegais ou contrárias à
                boa fé a à ordem pública;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Não difundir propaganda ou conteúdo de natureza racista ou
                xenofóbica, apostas desportivas, jogos de sorte e azar, qualquer
                tipo de pornografia ilegal, de apologia ao terrorismo ou contra
                os direitos humanos;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                Não causar danos aos sistemas físicos (hardwares) e lógicos
                (softwares) da Fido, de seus fornecedores ou de terceiros, para
                introduzir ou disseminar vírus, trojans, malware, worm, bot,
                backdoor, spyware, rootkit, ransonware ou por quaisquer outros
                códigos maliciosos ou quaisquer outros sistemas de hardware ou
                software que sejam capazes de causar os danos.
              </p>
            </li>
          </ol>

          <strong className={`${styles.termSubtitle}`}>
            10. Consentimento para o compartilhamento de dados
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Além das finalidades determinadas para uso dentro da plataforma
            Fido, o Usuário consente, pelo período de 12 meses, o
            compartilhamento das seguintes informações com instituições
            financeiras, de pagamento e/ou demais instituições autorizadas a
            funcionar pelo Banco Central do Brasil:
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                dados pessoais (nome, data de nascimento, número de inscrição no
                Cadastro de Pessoas Físicas - CPF, filiação, endereço, renda,
                score de risco, motivo do empréstimo e e-mail);
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                dados transacionais das operações realizadas na plataforma Fido.
              </p>
            </li>
          </ul>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O compartilhamento dos dados se dará pelas regras do ambiente Open
            Banking, conforme o Comunicado nº 33.455/2019 do Banco Central do
            Brasil e os demais atos normativos relacionados ao Open Banking.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O Usuário consente, ao efetuar simulação ou aquisição de empréstimo,
            que a Fido consulte e envie informações ao Sistema de Informações de
            Créditos (SCR), do Banco Central do Brasil.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            A qualquer momento o usuário poderá solicitar, através dos canais de
            atendimento Fido, o cancelamento do compartilhamento de dados.
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            Para fins da abertura da conta de pagamento ou conta corrente de
            titularidade do Usuário, com a finalidade exclusiva de movimentação
            das operações de empréstimo através da plataforma Fido, os dados
            pessoais do Usuário serão necessariamente repassados à instituição
            de pagamento ou agente custodiante da conta.
          </p>

          <strong className={`${styles.termSubtitle}`}>
            11. Funcionalidade dos aplicativos
          </strong>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            O Usuário fica ciente e concorda, desde já, sobre as seguintes ações
            originadas a partir da inscrição nos aplicativos da Fido e do seu
            uso, válidas para qualquer sistema operacional (Android e iOS).
          </p>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Usuário Mutuante (investidor):</i>
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                a partir da aceitação deste Termo, será aberta, automaticamente,
                uma conta de pagamento no CPF do Usuário, exclusivamente para a
                movimentação das operações de empréstimo, da qual será mostrado
                o saldo através do aplicativo da Fido;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                o Usuário poderá adicionar dinheiro via TED ou PIX à sua conta
                de pagamento, bem como solicitar a transferência do saldo para
                uma conta externa, a qualquer tempo, desde que seja de mesma
                titularidade;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                as ordens de empréstimo serão feitas com a possibilidade de
                escolha das seguintes variáveis: critério de risco, prazo máximo
                de quitação e valor;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                os juros da ordem de empréstimo correspondem à média da
                categoria de risco escolhida, podendo variar a cada Instrumento
                Particular de Mútuo (Anexo I) firmado;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                o prazo de retorno da ordem de investimento corresponde a prazos
                menores e/ou iguais ao prazo escolhido;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                as informações pessoais dos Mutuários (tomadores) somente serão
                exibidas ao Mutuante após a realização do empréstimo, no formato
                do Instrumento Particular de Mútuo (Anexo I);
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                as ordens de empréstimo poderão ser interrompidas a qualquer
                momento, sendo o dinheiro ainda não emprestado retornado ao
                caixa do Usuário;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                cada ordem de empréstimo será materializada em um ou mais
                contratos de mútuo, que totalizarão o valor da ordem de
                empréstimo;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                está ciente de que é o único e exclusivo responsável pela
                declaração do imposto de renda referente às suas operações de
                empréstimo;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                autoriza a Fido a efetuar o serviço de cobrança dos contratos
                inadimplentes.
              </p>
            </li>
          </ul>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Usuário Mutuário (tomador):</i>
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                compromete-se a efetuar os pagamentos das parcelas de empréstimo
                por boleto e/ou PIX QR code gerados a partir do aplicativo Fido;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                o Usuário Mutuário autoriza, desde já, a Fido a transferir seus
                contratos de empréstimo para terceiros (Mutuantes), que
                participem ou não da plataforma Fido, assegurada a continuidade
                de quitação das parcelas de empréstimo dentro na plataforma
                Fido.
              </p>
            </li>
          </ul>
          <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
            <i>Todos os Usuários (Mutuário e Mutuante):</i>
          </p>
          <ul>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                o Usuário fica ciente de que a Fido não envia boleto ou PIX QR
                code fora do ambiente dos aplicativos Fido, e que o pagamento de
                qualquer cobrança recebida fora do aplicativo, ainda que em nome
                da Fido, pode ter motivação fraudulenta de terceiros e não
                constituirá como prova de pagamento, sendo de inteira
                responsabilidade do Usuário;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                a Fido não será responsável por atrasos na transferência de
                dinheiro ao Usuário que cadastrar conta com dados faltantes ou
                inválidos na plataforma da Fido;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                concorda e aceita, desde já, os termos do presente modelo de
                Instrumento Particular de Mútuo (Anexo I), o qual será
                materializado nas operações de empréstimo realizadas;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                as condições específicas da cláusula 1 do Instrumento Particular
                de Mútuo (Anexo I) serão exibidas antes da confirmação da ordem
                de empréstimo por parte do Mutuante ou da solicitação de
                empréstimo por parte do Mutuário, e a aceitação pelas partes
                implicará na respectiva contratação do empréstimo;
              </p>
            </li>
            <li>
              <p className={`${mainPageStyles.b16} ${styles.termTextBody}`}>
                o Instrumento Particular de Mútuo ficará disponível para, sempre
                que solicitado através da plataforma Fido, ser enviado para o
                e-mail das partes.
              </p>
            </li>
          </ul>
        </section>

        <div className={styles.containerButton}>
          <button
            className={`${mainPageStyles.primaryButton}`}
            onClick={handleClickButton}
          >
            Continuar
          </button>
        </div>
      </div>
    </>
  );
}

export default TermsOfUse;
