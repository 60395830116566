import React from "react";

import MDBox from "components/MDBox";

import styles from "../../styles.module.css";

function StepFormPix({
  handleSubmitFormPix,
  pix,
  setPix,
  handleClickDoNotHavePix,
  hasDataPix,
  isMobile,
}) {
  return (
    <form className={styles.formPix} onSubmit={handleSubmitFormPix}>
      <label htmlFor="pix" className={styles.formPixLabel}>
        Você tem PIX?
      </label>
      <input
        type="text"
        className={styles.formPixInput}
        id="pix"
        name="pix"
        value={pix}
        onChange={(event) => setPix(event.target.value)}
        required
      />
      <a
        href="#"
        className={styles.formPixTextButton}
        onClick={handleClickDoNotHavePix}
      >
        Não tenho PIX
      </a>
      {hasDataPix ? (
        <MDBox
          bgColor="#F2F4F5"
          mt={3}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <MDBox
            display="grid"
            gridTemplateColumns={isMobile ? "100%" : "1fr 1fr"}
            gap="1rem 1.5rem"
            p={2}
          >
            <MDBox display="flex" flexDirection="column">
              <p className={styles.formPixDataPixHat}>Para</p>
              <p className={styles.formPixDataPixValue}>Pedro Alvares Cabral</p>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
              <p className={styles.formPixDataPixHat}>CPF</p>
              <p className={styles.formPixDataPixValue}>***.345.670-**</p>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
              <p className={styles.formPixDataPixHat}>Chave</p>
              <p className={styles.formPixDataPixValue}>
                pedrocabral@gmail.com
              </p>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
              <p className={styles.formPixDataPixHat}>Instituição</p>
              <p className={styles.formPixDataPixValue}>Banco do Brasil</p>
            </MDBox>
          </MDBox>
          <button
            type="submit"
            className={`${styles.button} ${styles.formPixDataPixButton}`}
          >
            Continuar
          </button>
        </MDBox>
      ) : (
        <button
          type="submit"
          className={`${styles.button} ${styles.formPixButton}`}
        >
          Continuar
        </button>
      )}
    </form>
  );
}

export { StepFormPix };
