import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMediaQuery } from "@mui/material";

import styles from "./styles.module.css";

import { formateDateWithHours, formatMoney } from "utils";

import arrowRightIcon from "../../../../../../../assets/images/icons/arrow-right.svg";

const CONSTANTS = {
  DIMENSIONS: 570
}

function ListOrderCard({
  id,
  created_at,
  investment_amount,
  status,
  handleCancelOrderData,
  risk,
  balance,
  isMobile,
}) {
  const [showModal, setShowModal] = useState(false);
  const [investorType, setInvestorType] = useState("");
  const [styleInvestor, setStyleInvestor] = useState(styles.containerInvestor);

  const handleCancelOrder = () => {
    setShowModal(false);
    handleCancelOrderData(id);
  };

  const changeMobile = useMediaQuery(
    `(max-width: ${CONSTANTS.DIMENSIONS}px)`
  );

  useEffect(() => {
    function getInvestmentType() {
      if (status === "PAID") {
        setStyleInvestor(styles.textInvestorTypePaid);
      } else {
        setStyleInvestor(styles.textInvestorTypePending);
      }

      if (risk === "menor_risco") {
        return setInvestorType("Conservador");
      }
      if (risk === "medio_risco") {
        return setInvestorType("Balanceado");
      }
      if (risk === "custom") {
        return setInvestorType("Empresta fácil")
      }
      return setInvestorType("Arrojado");
    }

    getInvestmentType();
  }, [investorType, styleInvestor]);

  return (
    <MDBox
      component="li"
      width="100%"
      height={"auto"}
      borderRadius="lg"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      shadow="lg"
      pb={1}
    >
      <MDBox
        bgColor="#FFFFFF"
        height="180%"
        py={isMobile ? 1 : 0}
        width={isMobile ? "100%" : "min(18rem, 35%)"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderTopLeftRadius: isMobile ? 0 : "1rem",
          borderBottomLeftRadius: isMobile ? 0 : "1rem",
        }}
      >
        <MDTypography
          variant="body1"
          sx={{
            fontSize: isMobile ? "0.875rem" : "1rem",
          }}
        >
          {
            <MDBox
              flexDirection="column"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {status === "PAID" ? (
                <MDBox color="#3D555A">
                  {formateDateWithHours(created_at.$date)}
                </MDBox>
              ) : (
                <MDBox color="#a2a2a3">
                  {formateDateWithHours(created_at.$date)}
                </MDBox>
              )}
            </MDBox>
          }
        </MDTypography>
      </MDBox>
      <MDBox
        bgColor="#F2F4F5"
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent={changeMobile ? "center" : "space-around"}
        gap="0.75rem"
        width="100%"
        height="180%"
        py={isMobile ? 2 : 0}
        px={isMobile ? 1 : 0}
        borderRadius={isMobile ? "0 0 0 0" : "0 1rem 1rem 0"}
        flexDirection={changeMobile ? "column" : "row"}
      >
        {status === "PAID" ? (
          <>
            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div className={styles.investorCard}>
                <div>
                  <p className={styleInvestor}>{investorType}</p>
                </div>
              </div>
            </div>

            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div>
                {risk === "custom"
                  ? ''
                  : <button
                    onClick={() => setShowModal(true)}
                    className={styles.buttonInterruptOrder}
                    title="Clique para interromper essa ordem"
                  >
                    Interromper ordem
                    <img src={arrowRightIcon} alt="Seta para direita" />
                  </button>
                }

              </div>
            </div>

            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span className={styles.labelType}>
                <p className={styles.textValue}>
                  {formatMoney(investment_amount)}
                </p>
              </span>
            </div>
          </>
        ) : (
          <>
            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div>
                <p className={styleInvestor}>{investorType}</p>
              </div>
            </div>

            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div>
                <div className={styles.textFinished}>
                  Ordem finalizada
                  <div className={styles.transparentDiv} />
                </div>
              </div>
            </div>

            <div style={{ flex: 1, flexBasis: "33.33%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span className={styles.labelType}>
                <p className={styles.textAmountFinished}>
                  {formatMoney(investment_amount)}
                </p>
              </span>
            </div>
          </>
        )}
      </MDBox>


      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <p className={styles.descModalContentTitle}>
              Deseja encerrar a ordem de investimento?
            </p>
            <p className={styles.descModalContentText}>
              O valor investido e não emprestado será retornado para seu "Saldo
              Disponível".
              <br /> O retorno pode levar até 12 horas para ser processado.{" "}
            </p>
            <p className={styles.descModalContentText}>
              Valor disponível para resgate:{" "}
            </p>
            <p className={styles.descModalContentValue}>
              {formatMoney(investment_amount - balance)}
            </p>
            <div className={styles.buttonContainer}>
              <button
                className={styles.secondaryButton}
                onClick={handleCancelOrder}
              >
                SIM
              </button>
              <button
                className={styles.primaryButton}
                onClick={() => setShowModal(false)}
              >
                NÃO
              </button>
            </div>
          </div>
        </div>
      )}
    </MDBox>
  );
}

export { ListOrderCard };
