import { api } from "./api";

async function getWallet() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investor/wallet", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getWalletData() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investor/wallet/data", {
      headers: { Authorization: tokenWithoutQuotes },
    });
    
    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getWalletReceivements() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");
  try {
    const response = await api.get("/investor/wallet/receivements", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getInvestmentPlans() {
  let data = null;

  try {
    const response = await api.get("/investment/plans");

    data = response.data;
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return data;
  }
}

async function requestInvestment(body) {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.post("/investment/request", body, {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getInvestments() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investment", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function cancelInvestments(body) {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.post("/investment/cancel", body, {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function postAutoReinvestment(body) {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.post("/investor/reinvest", body, {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getAutoReinvestment() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investor/reinvest", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

async function getValueTirPercent() {
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    const response = await api.get("/investor/wallet/tir", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error?.response;
  }
}

export {
  getWallet,
  getWalletData,
  getWalletReceivements,
  getInvestmentPlans,
  requestInvestment,
  getInvestments,
  cancelInvestments,
  postAutoReinvestment,
  getAutoReinvestment,
  getValueTirPercent,
};
