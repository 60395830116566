import { getAmountOfRepetitions, stringHasOnlyNumbers } from "utils";

const CONSTANTS = {
  LENGHT_PASSWORD: 6,
  MAX_REPETITIONS_OF_NUMBER_IN_PASSWORD: 4,
  SIZE_AGENCY: 4,
  ACCOUNT_TYPES: ["CONTA_POUPANCA", "CONTA_CORRENTE"],
  SIZE_BANK_CODE: 3,
  SIZE_DIGIT: 1,
};

function passwordHasIdealLength(password) {
  return password.length === CONSTANTS.LENGHT_PASSWORD;
}

function haveNumbersInSequence(password = "") {
  const passwordArray = [...password];

  for (let i = 0; i < passwordArray.length - 1; i++) {
    const currentNumber = Number(passwordArray[i]);
    const nextNumber = Number(passwordArray[i + 1]);
    const nextNextNumber = Number(passwordArray[i + 2]);

    if (
      (currentNumber + 1 === nextNumber && nextNumber + 1 === nextNextNumber) ||
      (currentNumber - 1 === nextNumber && nextNumber - 1 === nextNextNumber)
    ) {
      return true;
    }
  }

  return false;
}

function hasRepetitionsAboveLimit(password) {
  const passwordArray = [...password];
  const passwordWithoutRepetitionsArray = [...new Set(passwordArray)];

  for (const number of passwordWithoutRepetitionsArray) {
    const amount = getAmountOfRepetitions(passwordArray, number);

    if (amount >= CONSTANTS.MAX_REPETITIONS_OF_NUMBER_IN_PASSWORD) {
      return true;
    }
  }

  return false;
}

const isValidAgency = (agency = "") =>
  agency.length === CONSTANTS.SIZE_AGENCY && stringHasOnlyNumbers(agency);

const isValidAccountType = (accountType = "") =>
  CONSTANTS.ACCOUNT_TYPES.some(
    (accountTypeItem) => accountTypeItem === accountType
  );

const isValidAccountNumber = (accountNumber = "") =>
  stringHasOnlyNumbers(accountNumber);

const isValidBankCode = (bankCode = "", bankList = []) =>
  bankCode.length === CONSTANTS.SIZE_BANK_CODE &&
  stringHasOnlyNumbers(bankCode) &&
  bankList.some((bankItem) => bankItem.code === bankCode);

const isValidAccountDigit = (digit = "") =>
  digit.length === CONSTANTS.SIZE_DIGIT && stringHasOnlyNumbers(digit);

export {
  passwordHasIdealLength,
  haveNumbersInSequence,
  hasRepetitionsAboveLimit,
  isValidAgency,
  isValidAccountType,
  isValidAccountNumber,
  isValidBankCode,
  isValidAccountDigit,
};
