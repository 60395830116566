import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left.svg";
import arrowBottomIcon from "../../../../../assets/images/icons/arrow-bottom-blue.svg";
import arrowUpIcon from "../../../../../assets/images/icons/arrow-up-orange.svg";
import balanceIcon from "../../../../../assets/images/icons/balance-invest-green.svg";

import { formatMoney } from "utils";
import { getStoredValue } from "metrics/storage";
import { requestInvestment } from "services/investments";

function CardInvestmentDetails({
  setCurrentContentHTML,
  amountToInvest,
  setIsLoading,
  loggedUser,
}) {
  const [disabledButton, setDisabledButton] = useState(false);
  const navigate = useNavigate();
  const plan = JSON.parse(getStoredValue("plan"));

  async function handleSubmitRequestInvestment() {
    const body = {
      amount: Number(amountToInvest),
      risk: plan.risk,
    };
    setDisabledButton(true);
    setIsLoading(true);

    try {
      const response = await requestInvestment(body);

      if (response.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      setCurrentContentHTML("confirmedInvestment");
    } catch (error) {
      console.error("Erro ao investir");
      console.error(error);
      setCurrentContentHTML("initial");
    } finally {
      setDisabledButton(false);
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.card}>
      <button
        title="Voltar para seção anterior"
        className={styles.cardBackButton}
        onClick={() => setCurrentContentHTML("amountToInvest")}
      >
        <img src={arrowLeftIcon} alt="Seta para esquerda" />
      </button>
      <h2 className={styles.cardTitle}>A sua escolha ficou assim:</h2>
      <span className={styles.cardDivider}></span>
      <div className={styles.cardDataRowOne}>
        <img
          src={
            plan?.risk === "menor_risco"
              ? arrowBottomIcon
              : plan?.risk === "maior_risco"
                ? arrowUpIcon
                : balanceIcon
          }
          alt={
            plan?.risk === "menor_risco"
              ? "Conservador"
              : plan?.risk === "maior_rico"
                ? "Arrojado"
                : "Balanceado"
          }
        />
        <div>
          <h3 className={styles.cardDataRowOneTitle}>
            {plan.risk === "menor_risco"
              ? "Conservador"
              : plan.risk === "maior_risco"
                ? "Arrojado"
                : "Balanceado"}
          </h3>
          <span className={styles.cardDataRowOneSubtitle}>
            Prazo: até 12 meses
          </span>
        </div>
      </div>
      {plan?.risk === "menor_risco" ? (
        <>
          <p className={`${styles.cardDataTextBody} ${styles.fontWeightBold}`}>
            Juros pagos pelas pessoas{" "}
            <strong className={styles.cardDataTextBodyPlus}>+</strong> 5,25%
            a.m.
          </p>
          <p className={styles.cardDataTextBody}>
            Inadimplência esperada{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            16,9% a.a.
          </p>
          <p className={styles.cardDataTextBody}>
            Impostos e taxas{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            1,37% a.m.
          </p>
          <strong className={styles.cardDataTextBody}>
            <p>Rentabilidade esperada = 28,4% a.a.</p>
          </strong>
        </>
      ) : plan?.risk === "maior_risco" ? (
        <>
          <p className={`${styles.cardDataTextBody} ${styles.fontWeightBold}`}>
            Juros pagos pelas pessoas{" "}
            <strong className={styles.cardDataTextBodyPlus}>+</strong> 6,60%
            a.m.
          </p>
          <p className={styles.cardDataTextBody}>
            Inadimplência esperada{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            20,4% a.a.
          </p>
          <p className={styles.cardDataTextBody}>
            Impostos e taxas{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            1,37% a.m.
          </p>
          <p className={styles.cardDataTextBody}>
            <p>Rentabilidade esperada = 44,5% a.a.</p>
          </p>
        </>
      ) : (
        <>
          <p className={`${styles.cardDataTextBody} ${styles.fontWeightBold}`}>
            Juros pagos pelas pessoas{" "}
            <strong className={styles.cardDataTextBodyPlus}>+</strong> 5,92%
            a.m.
          </p>
          <p className={styles.cardDataTextBody}>
            Inadimplência esperada{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            18,7% a.a.
          </p>
          <p className={styles.cardDataTextBody}>
            Impostos e taxas{" "}
            <strong className={styles.cardDataTextBodySubtraction}>-</strong>{" "}
            1,37% a.m.
          </p>
          <p className={styles.cardDataTextBody}>
            <p>Rentabilidade esperada = 36,5% a.a.</p>
          </p>
        </>
      )}

      <span className={styles.cardDivider}></span>
      <div className={styles.cardSectionInvestmentAmount}>
        <p className={styles.cardTextBody}>Investimento no valor de:</p>
        <h2 className={styles.cardTitle}>{formatMoney(amountToInvest)}</h2>
        <button
          className={styles.button}
          onClick={() => handleSubmitRequestInvestment()}
          disabled={disabledButton}
        >
          Investir
        </button>
      </div>
    </div>
  );
}

export default CardInvestmentDetails;
