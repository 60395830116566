import React, { useState, useEffect } from "react";

import { ModalCodeEdit } from "../ModalCodeEdit";
import { DropdownFilterByStatus } from "../DropdownFilterByStatus";

import { formatMoney } from "utils";

import styles from "./styles.module.css";
import moreIcon from "assets/images/icons/more-vertical.svg";
import unfoldIcon from "assets/images/icons/unfold-less.svg";

const CONSTANTS = {
  STATUS_CODE_ENABLE: "ENABLE",
  STATUS_CODE_DISABLE: "DISABLE",
  FILTERS: [
    { key: "ALL", label: "Todos" },
    { key: "ENABLE", label: "Ativo" },
    { key: "DISABLE", label: "Inativo" },
  ],
};

function CodeTable({
  codeList,
  setCodeList,
  filteredCodeList,
  setFilteredCodeList,
  activeLoading,
  disableLoading,
  token,
}) {
  const [isOpenModalCodeEdit, setIsOpenModalCodeEdit] = useState(false);
  const [isOpenDropdownFilterByStatus, setIsOpenDropdownFilterByStatus] =
    useState(false);
  const [selectedCode, setSelectedCode] = useState({});
  const [filterActive, setFilterActive] = useState(CONSTANTS.FILTERS[0]);
  const [investorRateTypeOrder, setInvestorRateTypeOrder] = useState("DESC");
  const [minInstallmentsTypeOrder, setMinInstallmentsTypeOrder] =
    useState("DESC");
  const [maxInstallmentsTypeOrder, setMaxInstallmentsTypeOrder] =
    useState("DESC");
  const [minAmountTypeOrder, setMinAmountTypeOrder] = useState("DESC");
  const [maxAmountTypeOrder, setMaxAmountTypeOrder] = useState("DESC");

  const toggleDropdownFilterByStatus = () =>
    setIsOpenDropdownFilterByStatus(!isOpenDropdownFilterByStatus);

  const closeDropdownFilterByStatus = () =>
    setIsOpenDropdownFilterByStatus(false);

  const openModalCodeEdit = () => setIsOpenModalCodeEdit(true);

  const closeModalCodeEdit = () => setIsOpenModalCodeEdit(false);

  function handleClickEditButton(code) {
    setSelectedCode(code);
    openModalCodeEdit();
  }

  function sortAscending(attribute) {
    const orderedCodeList = filteredCodeList.sort((codeA, codeB) => {
      if (codeA[attribute] > codeB[attribute]) {
        return 1;
      } else if (codeA[attribute] < codeB[attribute]) {
        return -1;
      }

      return 0;
    });

    // console.log(orderedCodeList);
    setFilteredCodeList([...orderedCodeList]);
  }

  function sortDescending(attribute) {
    const orderedCodeList = filteredCodeList.sort((codeA, codeB) => {
      if (codeA[attribute] < codeB[attribute]) {
        return 1;
      } else if (codeA[attribute] > codeB[attribute]) {
        return -1;
      }

      return 0;
    });

    // console.log(orderedCodeList);
    setFilteredCodeList([...orderedCodeList]);
  }

  function handleClickHeaderItemOrder(typeOrder, setUpdateTypeOrder) {
    if (typeOrder === "ASC") {
      setUpdateTypeOrder("DESC");
      return;
    }

    setUpdateTypeOrder("ASC");
  }

  function handleOrderCodeList(typeOrder, attribute) {
    if (typeOrder === "ASC") {
      sortAscending(attribute);
    } else if (typeOrder === "DESC") {
      sortDescending(attribute);
    }
  }

  useEffect(() => {
    if (filterActive.key === "ALL") {
      setFilteredCodeList(codeList);
    } else {
      const filteredList = codeList.filter(
        (codeItem) => codeItem.status === filterActive.key
      );
      setFilteredCodeList(filteredList);
    }

    return () => {
      setFilteredCodeList([]);
    };
  }, [filterActive, codeList]);

  useEffect(() => {
    handleOrderCodeList(investorRateTypeOrder, "investor_rate");
  }, [investorRateTypeOrder]);

  useEffect(() => {
    handleOrderCodeList(minInstallmentsTypeOrder, "min_installments");
  }, [minInstallmentsTypeOrder]);

  useEffect(() => {
    handleOrderCodeList(maxInstallmentsTypeOrder, "max_installments");
  }, [maxInstallmentsTypeOrder]);

  useEffect(() => {
    handleOrderCodeList(minAmountTypeOrder, "min_value");
  }, [minAmountTypeOrder]);

  useEffect(() => {
    handleOrderCodeList(maxAmountTypeOrder, "max_value");
  }, [maxAmountTypeOrder]);

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Código</th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Juros</strong>
                <button
                  title="Ordernar"
                  className={styles.headerButtonAction}
                  onClick={() =>
                    handleClickHeaderItemOrder(
                      investorRateTypeOrder,
                      setInvestorRateTypeOrder
                    )
                  }
                >
                  <img src={unfoldIcon} alt="Ordernar" />
                </button>
              </div>
            </th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Nº min. de parcelas</strong>
                <button
                  title="Ordernar"
                  className={styles.headerButtonAction}
                  onClick={() =>
                    handleClickHeaderItemOrder(
                      minInstallmentsTypeOrder,
                      setMinInstallmentsTypeOrder
                    )
                  }
                >
                  <img src={unfoldIcon} alt="Ordernar" />
                </button>
              </div>
            </th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Nº max. de parcelas</strong>
                <button
                  title="Ordernar"
                  className={styles.headerButtonAction}
                  onClick={() =>
                    handleClickHeaderItemOrder(
                      maxInstallmentsTypeOrder,
                      setMaxInstallmentsTypeOrder
                    )
                  }
                >
                  <img src={unfoldIcon} alt="Ordernar" />
                </button>
              </div>
            </th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Valor min. do empréstimo</strong>
                <button
                  title="Ordernar"
                  className={styles.headerButtonAction}
                  onClick={() =>
                    handleClickHeaderItemOrder(
                      minAmountTypeOrder,
                      setMinAmountTypeOrder
                    )
                  }
                >
                  <img src={unfoldIcon} alt="Ordernar" />
                </button>
              </div>
            </th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Valor max. do empréstimo</strong>
                <button
                  title="Ordernar"
                  className={styles.headerButtonAction}
                  onClick={() =>
                    handleClickHeaderItemOrder(
                      maxAmountTypeOrder,
                      setMaxAmountTypeOrder
                    )
                  }
                >
                  <img src={unfoldIcon} alt="Ordernar" />
                </button>
              </div>
            </th>
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Status</strong>
                <button
                  title="Filtrar"
                  className={styles.headerButtonAction}
                  onClick={toggleDropdownFilterByStatus}
                >
                  <img src={moreIcon} alt="Filtrar" />
                </button>
                {isOpenDropdownFilterByStatus && (
                  <DropdownFilterByStatus
                    close={closeDropdownFilterByStatus}
                    filters={CONSTANTS.FILTERS}
                    setFilterActive={setFilterActive}
                  />
                )}
              </div>
            </th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {filteredCodeList?.map((codeItem) => (
            <tr key={codeItem.code}>
              <td>{codeItem.code}</td>
              <td>
                {/* {codeItem.investor_rate.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })} */}
                {codeItem.investor_rate} % a.m.
              </td>
              <td>{codeItem.min_installments}x</td>
              <td>{codeItem.max_installments}x</td>
              <td>{formatMoney(codeItem.min_value)}</td>
              <td>{formatMoney(codeItem.max_value)}</td>
              <td>
                {codeItem.status === CONSTANTS.STATUS_CODE_ENABLE ? (
                  <strong className={styles.colorStatusEnable}>Ativo</strong>
                ) : (
                  <strong className={styles.colorStatusDisable}>Inativo</strong>
                )}
              </td>
              <td>
                <button
                  className={styles.actionsButton}
                  title="Visualizar detalhes"
                  onClick={() => handleClickEditButton(codeItem)}
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isOpenModalCodeEdit && (
        <ModalCodeEdit
          close={closeModalCodeEdit}
          selectedCode={selectedCode}
          activeLoading={activeLoading}
          disableLoading={disableLoading}
          setCodeList={setCodeList}
          codeList={codeList}
          token={token}
        />
      )}
    </>
  );
}

export { CodeTable };
