const moment = require('moment')

const CONSTANTS_PF = {
    fido_rate: 0.7,
    iof: {
        fixed_fee: 0.38,
        daily_fee: 0.0082,
        yearly_fee: 3
    }
}

const CONSTANTS_PJ = {
    fido_rate: 0.7,
    iof: {
        fixed_fee: 0.38,
        daily_fee: 0.0041,
        yearly_fee: 1.5
    }
}

function loanSimulation(tax, months, value, isPJ = true) {
    const CONSTANTS = isPJ ? CONSTANTS_PJ : CONSTANTS_PF
    tax = parceTextToNumber(tax)
    months = parceTextToNumber(months)
    value = parceTextToNumber(value)

    let loan = format_new_loan(tax, months, value, CONSTANTS)
    let beforeIofInstallment = calculateBeforeIofInstallment(loan)
    loan.installments = calculateInstallmentsBeforeIof(loan, beforeIofInstallment)
    loan = calculateInstallmentsIof(loan, CONSTANTS)
    loan = addIofToInstallments(loan)
    loan.loan.installment = loan.installments[0].value
    loan.loan.total = calculateLoanTotal(loan)
    loan.loan.exactInterest = calculateExactInterest(loan, tax)
    
    return loan
}

function format_new_loan(tax, months, value, CONSTANTS) {
    let now = new Date()
    let loan = {
        rates: {
            fido_rate: CONSTANTS.fido_rate,
            investor_rate: tax,
            total: CONSTANTS.fido_rate + tax
        },
        loan: {
            value: value,
            months: months,
            total: null,
            totalIofFee: 0,
            installment: null,
            exactInterest: null,
            billetExpirationDay: now.getDate()
        },
        installments: []
    }
    return loan
}

function calculateBeforeIofInstallment(loan) {
    let value = loan.loan.value
    let months = loan.loan.months
    let rate = loan.rates.total / 100
    let x = (1 - (Math.pow(1 + rate, (-months))))
    let install = (rate / x) * value
    return install.toFixed(2)
}

function calculateInstallmentsBeforeIof(loan, installment) {
    let balance = loan.loan.value.toFixed(2)
    let rate = loan.rates.total / 100
    let installments = []
    for (let _ = 0; _ < loan.loan.months; _++) {
        let amortization = (parseFloat(installment) - parseFloat(balance) * rate).toFixed(2)
        balance -= parseFloat(amortization)
        balance = balance.toFixed(2)
        installments.push({
            value: installment,
            amortization: amortization,
            balance: balance,
            dueDate: null,
            passedDays: null,
            iof: {
                fixed: 0,
                variable: 0,
                total: 0
            }
        })
    }
    return installments
}

function calculateInstallmentsIof(loan, CONSTANTS) {
    let currentDate = moment()
    let totalIofFee = 0
    let passedDays = 0
    let extraMonths = 1

    let monthlyFixediIof = (CONSTANTS.iof.fixed_fee * loan.loan.value / (loan.loan.months * 100)).toFixed(2)

    for (let index = 0; index < loan.installments.length; index++) {
        let nextPaymentDate = getDueDate(extraMonths)
        let diff = nextPaymentDate.diff(currentDate)
        let dayDelta = moment.duration(diff).asDays()
        passedDays += dayDelta

        let variableIof = CONSTANTS.iof.daily_fee / 100 * passedDays
        if (passedDays > 365) {
            variableIof = CONSTANTS.iof.yearly_fee / 100
        }

        let variableIofvalue = variableIof * loan.installments[index].amortization
        loan.installments[index].iof.variable = variableIofvalue.toFixed(2)
        loan.installments[index].iof.fixed = monthlyFixediIof
        loan.installments[index].iof.total = (parseFloat(monthlyFixediIof) + parseFloat(variableIofvalue)).toFixed(2)
        totalIofFee += parseFloat(loan.installments[index].iof.total)

        loan.installments[index].dueDate = nextPaymentDate.format('DD-MM-YYYY')
        loan.installments[index].passedDays = parseInt(passedDays)
        currentDate = nextPaymentDate
        extraMonths += 1
    }
    loan.loan.totalIofFee = totalIofFee.toFixed(2)
    return loan
}

function addIofToInstallments(loan) {
    let monthlyIof = (loan.loan.totalIofFee / loan.loan.months).toFixed(2)
    for (let index = 0; index < loan.installments.length; index++) {
        loan.installments[index].value = (parseFloat(monthlyIof) + parseFloat(loan.installments[index].value)).toFixed(2)
    }
    return loan
}

function calculateLoanTotal(loan) {
    return (loan.loan.installment * loan.loan.months).toFixed(2)
}

function calculateExactInterest(loan, tax) {
    let total = (parseFloat(loan.loan.total) - parseFloat(loan.loan.value)).toFixed(2)
    return {
        total: total,
        fido: ((parseFloat(total) * loan.rates.fido_rate) / loan.rates.total).toFixed(2),
        investor: ((parseFloat(total) * loan.rates.investor_rate) / loan.rates.total).toFixed(2)
    }
}

function getDueDate(extraMonths) {
    let now = moment()
    return now.add(extraMonths, 'M')
}

function parceTextToNumber(number) {
    let num
    number = number.replace('R$ ', '')
    number = number.replace(' %', '')
    if ( number.includes(',') && number.includes('.') ){
        num = number.replace(/(\d+),(\d+).(\d+)/,"$1$2.$3");
        if (num === number) {
            num = number.replace(/(\d+).(\d+),(\d+)/,"$1$2.$3");
        }
        return parseFloat(num)
    }
    if ( number.includes(',') ) {
        num = number.replace(/(\d+),(\d+)/,"$1.$2");
        return parseFloat(num)
    }
    if ( number.includes('.') ) {
        return parseFloat(number)
    }
    return parseInt(number)
}

// console.log(JSON.stringify(loanSimulation('2.00', '12', '200000', true), null, 4))
export { loanSimulation, parceTextToNumber }
