import React from "react";

import styles from "./styles.module.css";

import arrowLeftIcon from "../../../../../assets/images/icons/arrow-left-white.svg";

function CardFormDeadline({ setCurrentContentHTML }) {
  function handleSubmitForm(event) {
    event.preventDefault()

    setCurrentContentHTML('customInvestmentDetails')
  }

  return (
    <article className={styles.card}>
      <header className={styles.header}>
        <button
          title="Voltar para a seção anterior"
          className={styles.backButton}
          onClick={() => setCurrentContentHTML('customizeInvestment')}
        >
          <img src={arrowLeftIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={styles.title}>Qual é o prazo máximo dos empréstimos?</h2>
      </header>
      <div className={styles.mainContent}>
        <form className={styles.form} onSubmit={handleSubmitForm}>
          <div className={styles.formInputGroup}>
            <input
              type="radio"
              id="option-one"
              name="options"
              className={styles.formInputRadio}
              required
            />
            <label
              htmlFor="option-one"
              className={`${styles.formLabel}`}
              onClick={() => {}}
            >
              De 6 a 9 meses
            </label>
          </div>

          <div className={styles.formInputGroup}>
            <input
              type="radio"
              id="option-two"
              name="options"
              className={styles.formInputRadio}
              required
            />
            <label
              htmlFor="option-two"
              className={`${styles.formLabel}`}
              onClick={() => {}}
            >
              De 9 a 12 meses
            </label>
          </div>

          <div className={styles.formInputGroup}>
            <input
              type="radio"
              id="option-three"
              name="options"
              className={styles.formInputRadio}
              required
            />
            <label
              htmlFor="option-three"
              className={`${styles.formLabel}`}
              onClick={() => {}}
            >
              De 6 a 18 meses
            </label>
          </div>

          <button type="submit" className={styles.button}>Avançar</button>
        </form>
      </div>
    </article>
  );
}

export default CardFormDeadline;
