import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CardMessage } from "./components/CardMessage";
import { ModalMessageDetails } from "./components/ModalMessageDetails";
import Loading from "components/Loading";
import MDTypography from "components/MDTypography";

import { useMaterialUIController, setMessages } from "context";

import styles from "./styles.module.css";

// import messagesJson from "mocks/notifications-investor.json";

function Messages() {
  const [selectedMessage, setSelectedMessage] = useState({});
  const [isActiveModalMessageDetails, setIsActiveModalMessageDetails] =
    useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { messages } = controller;
  const token = localStorage.getItem("token");
  const hasMessages = messages?.length > 0;

  const closeModalMessageDetails = () => setIsActiveModalMessageDetails(false);

  const openModalMessageDetails = () => setIsActiveModalMessageDetails(true);

  const activeLoading = () => setIsActiveLoading(true);

  const disableLoading = () => setIsActiveLoading(false);

  function sortMessagesByMostRecent() {
    const messagesSortedByMostRecent = messages?.sort((messageA, messageB) => {
      const messageADate = messageA?.init_date?.$date;
      const messageBDate = messageB?.init_date?.$date;

      if (messageADate > messageBDate) {
        return -1;
      } else if (messageADate < messageBDate) {
        return 1;
      }

      return 0;
    });

    setMessages(dispatch, messagesSortedByMostRecent);
  }

  useEffect(() => {
    sortMessagesByMostRecent();
  }, [messages]);

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <section className={styles.sectionMessages}>
        {hasMessages ? (
          <ul className={styles.listMessages}>
            {messages?.map((messageItem) => (
              <li key={messageItem?._id?.$oid}>
                <CardMessage
                  dataMessage={messageItem}
                  openModal={openModalMessageDetails}
                  setSelectedMessage={setSelectedMessage}
                  activeLoading={activeLoading}
                  disableLoading={disableLoading}
                  messages={messages}
                  setMessages={setMessages}
                  dispatch={dispatch}
                />
              </li>
            ))}
          </ul>
        ) : (
          <MDTypography align="center" variant="h3">
            Você não possui mensagens
          </MDTypography>
        )}
      </section>

      {isActiveModalMessageDetails && (
        <ModalMessageDetails
          selectedMessage={selectedMessage}
          close={closeModalMessageDetails}
        />
      )}

      {isActiveLoading && <Loading />}
    </DashboardLayout>
  );
}

export default Messages;
