import { api } from "./api";

async function getAllTransactions() {
  let response = undefined;
  const tokenWithoutQuotes = localStorage.getItem("token").replace(/['"]+/g, "");

  try {
    response = await api.get("/user/transactions", {
      headers: { Authorization: tokenWithoutQuotes },
    });
  } catch (error) {
    console.error("Erro ao buscar transações na API");
  } finally {
    return response;
  }
}

export { getAllTransactions };
