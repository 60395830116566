import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

import { TableContracts } from "../../components/TableContracts";
import { Pagination } from "../Pagination";

import styles from "./styles.module.css";

function ListContracts({
  openModal,
  contractsAll,
  filteredContracts,
  setFilteredContracts,
  setSelectedContract,
  pagination,
  setPagination,
}) {
  const [searchToName, setSearchToName] = useState("");

  function handleSearchByNameAndCpf() {
    if (searchToName?.length === 0) {
      setFilteredContracts(contractsAll);
      return;
    }
  
    const filteredContractsByNameAndCpf = filteredContracts.filter((contract) => {
      const searchTerm = searchToName.toLowerCase();
      const contractNomeMutuario = contract.nome_mutuario ? contract.nome_mutuario.toLowerCase() : '';
      const contractCpf = contract.cpf_mutuario ? contract.cpf_mutuario.replace(/\D/g, "") : '';
  
      return contractNomeMutuario.includes(searchTerm) || contractCpf.includes(searchTerm);
    });
  
    setFilteredContracts(filteredContractsByNameAndCpf);
  }

  useEffect(() => {
    handleSearchByNameAndCpf();
  }, [searchToName]);

  return (
    <div className={styles.containerListContracts}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>Contratos de empréstimos</h2>
        <form autoComplete="off">
          <TextField
            id="name-to-search"
            label="Buscar por nome/cpf"
            variant="standard"
            value={searchToName}
            onChange={({ target }) => setSearchToName(target.value)}
          />
        </form>
      </div>

      <section className={styles.containerTable}>
        <h2 className={styles.gone}>Lista de Contratos </h2>
        <TableContracts
          openModal={openModal}
          contractsAll={contractsAll}
          filteredContracts={filteredContracts}
          setFilteredContracts={setFilteredContracts}
          setSelectedContract={setSelectedContract}
        />

        {contractsAll?.length === 0 && (
          <p className={styles.messageEmptyTable}>
            Você não possui contratos no momento
          </p>
        )}

        {contractsAll?.length > 0 && filteredContracts?.length === 0 ? (
          <p className={styles.messageEmptyTable}>
            Não encontramos o que você busca
          </p>
        ) : null}
      </section>

      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
}

export default ListContracts;
