import { api } from "./api";

async function getCodeList(token) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.get("/investment/code", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getDirectLoansList(token) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.get("/investor/direct-loans", {
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function deleteCode(token, code) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.delete("/investment/code", {
      data: {
        code,
      },
      headers: { Authorization: tokenWithoutQuotes },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function editCodeStatus(token, status, code) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");
  let url = "";

  if (status === "ENABLE") {
    url = "/investment/code/enable";
  } else {
    url = "/investment/code/disable";
  }

  try {
    response = await api.put(
      url,
      { code },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function saveCode(
  token,
  { investor_rate, min_installments, max_installments, min_value, max_value }
) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/investment/code",
      {
        investor_rate,
        min_installments,
        max_installments,
        min_value,
        max_value,
      },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function approveDirectLoan(token, loanId) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/investor/direct-loans/approve",
      {
        loan_id: loanId,
      },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function refuseDirectLoan(token, loanId) {
  let response = undefined;
  const tokenWithoutQuotes = token.replace(/['"]+/g, "");

  try {
    response = await api.post(
      "/investor/direct-loans/disapprove",
      {
        loan_id: loanId,
      },
      {
        headers: { Authorization: tokenWithoutQuotes },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export {
  getCodeList,
  deleteCode,
  editCodeStatus,
  saveCode,
  getDirectLoansList,
  approveDirectLoan,
  refuseDirectLoan,
};
