import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    textAlign: "left",
    boxShadow: "0px 0px 20px 2px rgba(0, 0, 0, 0.1);",

    "& > a": {
      marginTop: "0.25rem",
      fontSize: "0.875rem",
      color: "#5A0F7D",
      textDecoration: "underline",
    },
  },
}));
