import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import styles from "./styles.module.css";
import mainPageStyles from "../../styles.module.css";

import { getOnlyNumbersOfString } from "utils";
import { confirmCodeBySMS } from "services/user";

import closeIcon from "../../../../../assets/images/icons/close-white.svg";

const CONSTANTS = {
  LENGTH_CODE: 6,
  STATUS_CODE_SUCCESS: 200,
};

function ModalConfirmCodeBySMS({
  nextStep,
  closeModal,
  email,
  disableLoading,
  activateLoading,
  setError,
  openSnackbar,
  closeSnackbar,
  timeSmsCode,
  activeTimeSmsCode,
  sendSmsCode,
}) {
  const [code, setCode] = useState("");

  const isValidCode = () => code?.length === CONSTANTS.LENGTH_CODE;

  function handleInputChangeCode(event) {
    const { value } = event.target;
    const valueOnlyNumbers = getOnlyNumbersOfString(value);

    setCode(valueOnlyNumbers);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    if (!isValidCode()) {
      openSnackbar();
      setError(
        "O código está incompleto. Ele deve possuir 6 dígitos numéricos."
      );
      return;
    }

    activateLoading();

    try {
      const response = await confirmCodeBySMS(email, code);

      if (response?.status !== CONSTANTS.STATUS_CODE_SUCCESS) {
        throw new Error();
      }

      setError("");
      closeSnackbar();
      closeModal();
      nextStep();
    } catch (error) {
      console.error(error);
      console.error("Erro ao confirmar código via SMS");

      setError("O código informado está incorreto. Por favor, tente novamente");
      openSnackbar();
    } finally {
      disableLoading();
    }
  }

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={`${mainPageStyles.h24} ${styles.title}`}>
            Confirme o código SMS
          </h2>
          <button
            title="Fechar modal"
            className={styles.closeButton}
            onClick={closeModal}
          >
            <img src={closeIcon} alt="X" />
          </button>
        </header>

        <form
          className={styles.form}
          onSubmit={handleFormSubmit}
          autoComplete="off"
        >
          <div className={mainPageStyles.formInputGroup}>
            <label htmlFor="code" className={mainPageStyles.formLabel}>
              Código
            </label>
            <input
              type="text"
              id="code"
              name="code"
              className={mainPageStyles.formInput}
              value={code}
              onChange={handleInputChangeCode}
              maxLength={CONSTANTS.LENGTH_CODE}
              minLength={CONSTANTS.LENGTH_CODE}
              placeholder="XXXXXX"
              required
            />
          </div>

          <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
            O código possui 6 dígitos numéricos
          </p>

          <div className={styles.sectionResendCode}>
            <h3>{timeSmsCode}s</h3>

            <button
              type="button"
              disabled={activeTimeSmsCode}
              onClick={sendSmsCode}
            >
              Enviar código
            </button>
          </div>

          <button
            type="submit"
            className={`${mainPageStyles.primaryButton} ${styles.button}`}
          >
            Continuar
          </button>
        </form>
      </aside>
    </div>
  );
}

export default ModalConfirmCodeBySMS;
