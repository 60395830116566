import { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import Loading from "components/Loading";

import { changePasswordUser } from "services/user";
import { getOnlyNumbersOfString } from "utils";

import styles from "./styles.module.css";
import eyeOn from "../../../../../assets/images/icons/eyeOn.svg";
import eyeOff from "../../../../../assets/images/icons/eyeOff.svg";

const CONSTANTS = {
  MIN_PASSWORD_LENGTH: 6,
  MAX_REPETITIONS_OF_NUMBER_IN_PASSWORD: 3,
};

function CardPassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [configAlert, setConfigAlert] = useState({ status: "", message: "" });
  const [
    isNewPasswordEqualConfirmedNewPassword,
    setIsNewPasswordEqualConfirmedNewPassword,
  ] = useState(false);
  const [isOnlyNumbers, setIsOnlyNumbers] = useState(false);
  const [isNumbersInSequence, setIsNumbersInSequence] = useState(false);
  const [isPasswordIdealLength, setIsPasswordIdealLength] = useState(false);
  const [isExceededRepetitionsLimit, setIsExceededRepetitionsLimit] =
    useState(true);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewPasswordActually, setViewPasswordActually] = useState(false);
  const [viewNewPassword, setViewNewPasswordActually] = useState(false);
  const [viewRepeatNewPassword, setViewRepeatNewPassword] = useState(false);
  const navigate = useNavigate();

  const activateLoading = () => setIsLoading(true);

  const deactivateLoading = () => setIsLoading(false);

  const hidePasswordActually = () =>
    setViewPasswordActually(!viewPasswordActually);

  const hideNewPassword = () => setViewNewPasswordActually(!viewNewPassword);

  const hideRepeatNewPassword = () =>
    setViewRepeatNewPassword(!viewRepeatNewPassword);

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setIsShowAlert(false);
  }

  function handleInputChangePassword(value, setPassword) {
    const password = getOnlyNumbersOfString(value);

    setPassword(password);
  }

  function handleShowMessageComparationBetweenNewPasswordAndConfirmedNewPassword() {
    if (newPassword?.length === 0 && confirmedNewPassword?.length === 0) {
      return null;
    }

    return isNewPasswordEqualConfirmedNewPassword ? (
      <p
        className={`${styles.auxiliaryMessage} ${styles.auxiliaryMessageSuccess}`}
      >
        Senhas iguais
      </p>
    ) : (
      <p
        className={`${styles.auxiliaryMessage} ${styles.auxiliaryMessageError} ${styles.auxiliaryMessageErrorReduceMargin}`}
      >
        Senhas diferentes. As senhas dos campos "Nova senha" e "Nova senha
        novamente" devem ser iguais
      </p>
    );
  }

  async function handleSubmitEditPassword(event) {
    event.preventDefault();

    if (
      !isNewPasswordEqualConfirmedNewPassword ||
      !isOnlyNumbers ||
      !isPasswordIdealLength ||
      isNumbersInSequence ||
      isExceededRepetitionsLimit
    ) {
      setConfigAlert({
        status: "error",
        message:
          "Ocorreu um problema ao alterar sua senha. Tente novamente, mais tarde",
      });
      return;
    }

    activateLoading();

    try {
      const response = await changePasswordUser(currentPassword, newPassword);

      if (response?.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      setConfigAlert({
        status: "success",
        message: "Senha alterada com sucesso",
      });
    } catch (error) {
      console.error("Erro ao alterar senha do usuário");
      console.error(error);
      setConfigAlert({
        status: "error",
        message:
          "Ocorreu um problema ao alterar sua senha. Tente novamente, mais tarde",
      });
    } finally {
      deactivateLoading();
      setIsShowAlert(true);
    }
  }

  function passwordsHaveOnlyNumbers() {
    if (Number(newPassword) && Number(confirmedNewPassword)) {
      setIsOnlyNumbers(true);
      return;
    }

    setIsOnlyNumbers(false);
  }

  function passwordsHaveIdealLength() {
    if (
      (newPassword?.length === CONSTANTS.MIN_PASSWORD_LENGTH &&
        confirmedNewPassword?.length === CONSTANTS.MIN_PASSWORD_LENGTH) ||
      (newPassword?.length == "" && confirmedNewPassword?.length == "")
    ) {
      setIsPasswordIdealLength(true);
      return;
    }

    setIsPasswordIdealLength(false);
  }

  function passwordsAreEquals() {
    if (newPassword === confirmedNewPassword) {
      setIsNewPasswordEqualConfirmedNewPassword(true);
      return;
    }

    setIsNewPasswordEqualConfirmedNewPassword(false);
  }

  function haveNumbersInSequence(password = "") {
    const passwordArray = [...password];

    for (let i = 0; i < passwordArray.length - 1; i++) {
      const currentNumber = Number(passwordArray[i]);
      const nextNumber = Number(passwordArray[i + 1]);
      const nextNextNumber = Number(passwordArray[i + 2]);

      if (
        (currentNumber + 1 === nextNumber &&
          nextNumber + 1 === nextNextNumber) ||
        (currentNumber - 1 === nextNumber && nextNumber - 1 === nextNextNumber)
      ) {
        return true;
      }
    }

    return false;
  }

  function handleNumbersOfSequenceInPassword() {
    if (newPassword?.length === 0 || confirmedNewPassword?.length === 0) {
      setIsNumbersInSequence(false);
      return;
    }

    if (
      haveNumbersInSequence(newPassword) ||
      haveNumbersInSequence(confirmedNewPassword)
    ) {
      setIsNumbersInSequence(true);
      return;
    }

    setIsNumbersInSequence(false);
  }

  function hasRepetitionsAboveLimit(numbers) {
    const count = {};

    for (const number of numbers) {
      count[number] = (count[number] || 0) + 1;

      if (count[number] > CONSTANTS.MAX_REPETITIONS_OF_NUMBER_IN_PASSWORD) {
        return true;
      }
    }

    return false;
  }

  function handleMaxRepetitionsOfNumberInSequence() {
    if (newPassword?.length === 0 || confirmedNewPassword?.length === 0) {
      setIsExceededRepetitionsLimit(false);
      return;
    }

    if (
      hasRepetitionsAboveLimit(newPassword) ||
      hasRepetitionsAboveLimit(confirmedNewPassword)
    ) {
      setIsExceededRepetitionsLimit(true);
      return;
    }

    setIsExceededRepetitionsLimit(false);
  }

  function isFilledNewPasswordAndConfirmedNewPassword() {
    return newPassword?.length > 0 && confirmedNewPassword?.length > 0;
  }

  // useEffect(
  //   () => console.log(isExceededRepetitionsLimit),
  //   [isExceededRepetitionsLimit]
  // );

  useEffect(() => {
    // if (newPassword?.length === 0 && confirmedNewPassword?.length === 0) return;

    passwordsHaveOnlyNumbers();
    passwordsHaveIdealLength();
    passwordsAreEquals();
    handleNumbersOfSequenceInPassword();
    handleMaxRepetitionsOfNumberInSequence();

    // console.log(newPassword?.length, confirmedNewPassword?.length)
  }, [newPassword, confirmedNewPassword]);

  return (
    <MDBox
      width="100%"
      height="100%"
      maxHeight="39.3rem"
      bgColor="#FFFFFF"
      component="article"
      p={2}
      borderRadius="lg"
    >
      <MDTypography
        variant="h2"
        fontWeight="light"
        sx={{ fontSize: "1.5rem", lineHeight: "120%", color: "#3D555A" }}
      >
        Alteração de senha
      </MDTypography>

      <MDBox
        mt={4}
        component="form"
        width="100%"
        display="flex"
        flexDirection="column"
        gap="2rem"
        onSubmit={handleSubmitEditPassword}
        autoComplete="off"
      >
        <MDBox display="flex" flexDirection="column" gap="1rem">
          <label className={styles.label} htmlFor="current-password">
            Senha atual
          </label>
          <MDBox display="flex" gap="1rem">
            <input
              type={viewPasswordActually ? "number" : "password"}
              id="current-password"
              name="current-password"
              minLength={CONSTANTS.MIN_PASSWORD_LENGTH}
              value={currentPassword}
              className={styles.input}
              onChange={(event) =>
                handleInputChangePassword(
                  event.target.value,
                  setCurrentPassword
                )
              }
              required
            />
            <button
              type="button"
              className={styles.buttonViewPassword}
              onClick={hidePasswordActually}
            >
              {viewPasswordActually ? (
                <img src={eyeOff} />
              ) : (
                <img src={eyeOn} />
              )}
            </button>
          </MDBox>
        </MDBox>

        <MDBox display="flex" flexDirection="column" gap="1rem">
          <label className={styles.label} htmlFor="new-password">
            Nova senha
          </label>
          <MDBox display="flex" gap="1rem">
            <input
              type={viewNewPassword ? "number" : "password"}
              id="new-password"
              name="new-password"
              minLength={CONSTANTS.MIN_PASSWORD_LENGTH}
              value={newPassword}
              className={styles.input}
              onChange={(event) =>
                handleInputChangePassword(event.target.value, setNewPassword)
              }
              required
            />
            <button
              type="button"
              className={styles.buttonViewPassword}
              onClick={hideNewPassword}
            >
              {viewNewPassword ? <img src={eyeOff} /> : <img src={eyeOn} />}
            </button>
          </MDBox>
        </MDBox>

        <MDBox display="flex" flexDirection="column" gap="1rem">
          <label className={styles.label} htmlFor="confirmed-new-password">
            Nova senha novamente
          </label>

          <MDBox display="flex" gap="1rem">
            <input
              type={viewRepeatNewPassword ? "number" : "password"}
              id="confirmed-new-password"
              name="confirmed-new-password"
              minLength={CONSTANTS.MIN_PASSWORD_LENGTH}
              value={confirmedNewPassword}
              className={styles.input}
              onChange={(event) =>
                handleInputChangePassword(
                  event.target.value,
                  setConfirmedNewPassword
                )
              }
              required
            />
            <button
              type="button"
              className={styles.buttonViewPassword}
              onClick={hideRepeatNewPassword}
            >
              {viewRepeatNewPassword ? (
                <img src={eyeOff} />
              ) : (
                <img src={eyeOn} />
              )}
            </button>
          </MDBox>
          {handleShowMessageComparationBetweenNewPasswordAndConfirmedNewPassword()}
        </MDBox>

        <MDBox display="flex" flexDirection="column" gap="0.5rem">
          <p
            className={
              isOnlyNumbers
                ? `${styles.auxiliaryMessage} ${styles.auxiliaryMessageSuccess}`
                : `${styles.auxiliaryMessage} `
            }
          >
            *Somente números são permitidos na senha
          </p>
          <p
            className={
              isPasswordIdealLength
                ? `${styles.auxiliaryMessage} ${styles.auxiliaryMessageSuccess}`
                : `${styles.auxiliaryMessage} ${styles.auxiliaryMessageError}`
            }
          >
            *As senhas devem ter 6 caracteres
          </p>
          <p
            className={
              isNumbersInSequence
                ? `${styles.auxiliaryMessage} ${styles.auxiliaryMessageError}`
                : `${styles.auxiliaryMessage} ${styles.auxiliaryMessageSuccess}`
            }
          >
            *A senha não pode possuir números em sequência numérica
          </p>
          {isFilledNewPasswordAndConfirmedNewPassword() ? (
            <p
              className={
                isExceededRepetitionsLimit
                  ? `${styles.auxiliaryMessage} ${styles.auxiliaryMessageError}`
                  : `${styles.auxiliaryMessage} ${styles.auxiliaryMessageSuccess}`
              }
            >
              *Um número não pode se repetir mais de 2 vezes dentro da senha
            </p>
          ) : (
            <p className={`${styles.auxiliaryMessage}`}>
              *Um número não pode se repetir mais de 2 vezes dentro da senha
            </p>
          )}
        </MDBox>

        <button
          type="submit"
          className={
            handleShowMessageComparationBetweenNewPasswordAndConfirmedNewPassword()
              ? `${styles.button} ${styles.buttonWithAuxiliaryMessage}`
              : `${styles.button} ${styles.buttonWithoutAuxiliaryMessage}`
          }
        >
          Alterar senha
        </button>
      </MDBox>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isShowAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity={configAlert?.status}
          sx={{ width: "100%" }}
        >
          {configAlert?.message}
        </Alert>
      </Snackbar>

      {isLoading && <Loading />}
    </MDBox>
  );
}

export default CardPassword;
