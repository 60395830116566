import { useState, useEffect } from "react";

import { DropdownFilter } from "../DropdownFilter";
import { moneyMaskFormatPTBR } from "utils/mask";

import styles from "./styles.module.css";
import moreIcon from "assets/images/icons/more-vertical.svg";
import unfoldIcon from "assets/images/icons/unfold-less.svg";
import { formatMoney } from "utils";

import { dateMask } from "utils/mask";

const CONSTANTS = {
  FILTERS: {
    LOAN_TYPE: [
      { key: "all", label: "Todos" },
      { key: "direct_loan", label: "Empresta fácil" },
      { key: "split_loan", label: "Diversificado" },
    ],
    SCORE: [
      { key: "all", label: "Todos" },
      { key: "A", label: "A" },
      { key: "B", label: "B" },
      { key: "C", label: "C" },
      { key: "D", label: "D" },
    ],
    STATUS: [
      { key: "all", label: "Todos" },
      { key: "inadimplente", label: "Inadimplente" },
      { key: "adimplente", label: "Adimplente" },
    ],
  },
};

function TableContracts({
  openModal,
  contractsAll,
  filteredContracts,
  setFilteredContracts,
  setSelectedContract,
}) {
  const [filterStatusActive, setFilterStatusActive] = useState(
    CONSTANTS.FILTERS.STATUS[0]
  );
  const [filterScoreActive, setFilterScoreActive] = useState(
    CONSTANTS.FILTERS.SCORE[0]
  );
  const [filterLoanTypeActive, setFilterLoanTypeActive] = useState(
    CONSTANTS.FILTERS.LOAN_TYPE[0]
  );
  const [nameTypeOrder, setNameTypeOrder] = useState("ASC");
  const [feesTypeOrder, setFeesTypeOrder] = useState("ASC");
  const [installmentsTypeOrder, setInstallmentsTypeOrder] = useState("ASC");
  const [dateTypeOrder, setDateTypeOrder] = useState("DESC");
  const [
    isActiveDropdownFilterByLoanType,
    setIsActiveDropdownFilterByLoanType,
  ] = useState(false);
  const [isActiveDropdownFilterByScore, setIsActiveDropdownFilterByScore] =
    useState(false);
  const [isActiveDropdownFilterByStatus, setIsActiveDropdownFilterByStatus] =
    useState(false);

  const closeDropdownFilterByStatus = () =>
    setIsActiveDropdownFilterByStatus(false);

  const toggleDropdownFilterByStatus = () =>
    setIsActiveDropdownFilterByStatus(!isActiveDropdownFilterByStatus);

  const closeDropdownFilterByLoanType = () =>
    setIsActiveDropdownFilterByLoanType(false);

  const toggleDropdownFilterByLoanType = () =>
    setIsActiveDropdownFilterByLoanType(!isActiveDropdownFilterByLoanType);

  const closeDropdownFilterByScore = () =>
    setIsActiveDropdownFilterByScore(false);

  const toggleDropdownFilterByScore = () =>
    setIsActiveDropdownFilterByScore(!isActiveDropdownFilterByScore);

  function handleClickViewContractDetails(contract) {
    setSelectedContract(contract);
    openModal();
  }

  function handleClickHeaderTitle(typeOrder, setUpdateTypeOrder) {
    if (typeOrder === "ASC") {
      setUpdateTypeOrder("DESC");
      return;
    }

    setUpdateTypeOrder("ASC");
  }

  function filterContracts(contracts, contractField, valueToCompare) {
    const filteredList = contracts?.filter(
      (contract) => contract[contractField] === valueToCompare
    );
    return filteredList;
  }

  function filterContractsByField(contracts, filterActive, contractField) {
    if (filterActive.key === "all") {
      setFilteredContracts(contracts);
      return;
    }

    const filteredList = filterContracts(
      contracts,
      contractField,
      filterActive.key
    );
    setFilteredContracts(filteredList);
  }

  function sortByField(typeOrder, contractField) {
    let orderedContracts = [];

    if (typeOrder === "ASC") {
      orderedContracts = filteredContracts.sort((contractA, contractB) => {
        if (contractA[contractField] > contractB[contractField]) {
          return 1;
        } else if (contractA[contractField] < contractB[contractField]) {
          return -1;
        }

        return 0;
      });
    } else if (typeOrder === "DESC") {
      orderedContracts = filteredContracts.sort((contractA, contractB) => {
        if (contractA[contractField] < contractB[contractField]) {
          return 1;
        } else if (contractA[contractField] > contractB[contractField]) {
          return -1;
        }

        return 0;
      });
    }

    setFilteredContracts(orderedContracts);
  }

  function getMillisecondsDate(date) {
    const [day, month, year] = date.split("/");
    return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
  }

  function sortByDate(contracts = [], setContracts) {
    let orderedContracts = [];

    if (dateTypeOrder === "ASC") {
      orderedContracts = contracts.sort((contractA, contractB) => {
        const contractDateMillisecondsA = getMillisecondsDate(contractA.date);
        const contractDateMillisecondsB = getMillisecondsDate(contractB.date);

        if (contractDateMillisecondsA > contractDateMillisecondsB) {
          return 1;
        } else if (contractDateMillisecondsA < contractDateMillisecondsB) {
          return -1;
        }

        return 0;
      });
    } else if (dateTypeOrder === "DESC") {
      orderedContracts = contracts.sort((contractA, contractB) => {
        const contractDateMillisecondsA = getMillisecondsDate(contractA.date);
        const contractDateMillisecondsB = getMillisecondsDate(contractB.date);

        if (contractDateMillisecondsA < contractDateMillisecondsB) {
          return 1;
        } else if (contractDateMillisecondsA > contractDateMillisecondsB) {
          return -1;
        }

        return 0;
      });
    }

    setContracts(orderedContracts);
  }

  useEffect(() => {
    filterContractsByField(contractsAll, filterStatusActive, "status");

    return () => {
      setFilteredContracts(contractsAll);
    };
  }, [filterStatusActive, contractsAll]);

  useEffect(() => {
    filterContractsByField(contractsAll, filterLoanTypeActive, "type");

    return () => {
      setFilteredContracts(contractsAll);
    };
  }, [filterLoanTypeActive, contractsAll]);

  useEffect(() => {
    filterContractsByField(contractsAll, filterScoreActive, "score");

    return () => {
      setFilteredContracts(contractsAll);
    };
  }, [filterScoreActive, contractsAll]);

  useEffect(() => {
    sortByField(feesTypeOrder, "taxa_juros");
  }, [feesTypeOrder, filteredContracts]);

  useEffect(() => {
    sortByField(installmentsTypeOrder, "numero_parcelas");
  }, [installmentsTypeOrder, filteredContracts]);

  useEffect(() => {
    sortByField(nameTypeOrder, "nome_mutuario");
  }, [nameTypeOrder, filteredContracts]);

  useEffect(() => {
    sortByDate(filteredContracts, setFilteredContracts);
  }, [dateTypeOrder, filteredContracts]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Nome</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(nameTypeOrder, setNameTypeOrder)
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Score</strong>
              <button
                title="Filtrar por score"
                className={styles.headerButtonAction}
                onClick={toggleDropdownFilterByScore}
              >
                <img src={moreIcon} alt="Filtrar por score" />
              </button>
              {isActiveDropdownFilterByScore && (
                <DropdownFilter
                  filters={CONSTANTS.FILTERS.SCORE}
                  filterActive={filterScoreActive}
                  setFilterActive={setFilterScoreActive}
                  close={closeDropdownFilterByScore}
                />
              )}
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Valor do empréstimo</strong>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitleCPF}>
              <strong>CPF</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(nameTypeOrder, setNameTypeOrder)
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Juros</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(feesTypeOrder, setFeesTypeOrder)
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Data do primeiro vencimento</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(dateTypeOrder, setDateTypeOrder)
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Parcelas pagas</strong>
            </div>
          </th>
          {/** 
            <th>
              <div className={styles.containerHeaderTitle}>
                <strong>Status</strong>
                <button
                  title="Filtrar por status"
                  className={styles.headerButtonAction}
                  onClick={toggleDropdownFilterByStatus}
                >
                  <img src={moreIcon} alt="Filtrar por status" />
                </button>
                {isActiveDropdownFilterByStatus && (
                  <DropdownFilter
                    filters={CONSTANTS.FILTERS.STATUS}
                    filterActive={filterStatusActive}
                    setFilterActive={setFilterStatusActive}
                    close={closeDropdownFilterByStatus}
                  />
                )}
              </div>
            </th>
           * 
          */}
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {filteredContracts?.map((contract, index) => (
          <tr key={index}>
            <td className={styles.minWidthColumnTable}>
              {contract?.nome_mutuario}
            </td>
            <td>{contract?.score}</td>
            <td>{formatMoney(contract?.total_emprestimo)}</td>
            <td>{contract?.cpf_mutuario}</td>
            <td>
              {contract?.taxa_juros.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })}
              %
            </td>
            <td>{contract?.next_due_date ? dateMask(contract?.next_due_date) : ''}</td>
            <td>
              {contract?.parcelas_pagas}/{contract?.numero_parcelas}
            </td>
            {/* <td>
              {contract?.status === "adimplente" ? (
                <strong className={styles.colorStatusDefaulter}>
                  Adimplente
                </strong>
              ) : (
                <strong className={styles.colorStatusOverdue}>
                  Inadimplente
                </strong>
              )}
            </td> */}
            <td>
              <button
                className={styles.actionButton}
                onClick={() => handleClickViewContractDetails(contract)}
              >
                Visualizar
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export { TableContracts };
