import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Footer from "../../../examples/Footer";
import CardCommonQuestions from "./CardCommonQuestions";
import CardLearnInvest from "./CardLearnInvest";

import contactInfoIcon from "../../../assets/images/icons/contact-info.svg";
import styles from "./styles.module.css";

function Help() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} display="flex" gap="3rem" flexWrap="wrap">
        <CardCommonQuestions />
        <MDBox
          width="min(34rem, 100%)"
          display="flex"
          flexDirection="column"
          gap="2rem"
        >
          <CardLearnInvest />
          <ul className={styles.contacts}>
            <li className={styles.contactItem}>
              <img src={contactInfoIcon} alt="Informação" />
              <p>contato@fido.com.vc</p>
            </li>
            <li className={styles.contactItem}>
              <img src={contactInfoIcon} alt="Informação" />
              <p>61 99115 7981</p>
            </li>
          </ul>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Help;
