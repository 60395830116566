import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Snackbar,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import styles from "../create-account/components/PasswordForm/styles.module.css";
import mainPageStyles from "../create-account/styles.module.css";

import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import { ModalReturnApi } from "./Modal";

import { getOnlyNumbersOfString } from "utils";
import { recoverPassword } from "services/user";
import {
  passwordHasIdealLength,
  haveNumbersInSequence,
  hasRepetitionsAboveLimit,
} from "utils/validators";

function PasswordRecovery() {
  const [errors, setErrors] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const { token } = useParams();

  const activeModal = () => {
    setIsActiveModal(true);
  };

  const deactiveModal = () => {
    setIsActiveModal(false);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function handleChangePassword(event) {
    const { value } = event.target;
    const onlyNumbers = getOnlyNumbersOfString(value);

    setPassword(onlyNumbers);
  }

  function getErrors() {
    let _errors = [];

    if (!passwordHasIdealLength(password)) {
      _errors = [..._errors, "Senha não possui 6 digítos"];
    } else {
      if (password !== confirmPassword) {
        _errors = [..._errors, "A confirmação de senha deve ser igual a senha"];
      }

      if (haveNumbersInSequence(password)) {
        _errors = [..._errors, "A senha possui números em sequência"];
      }

      if (hasRepetitionsAboveLimit(password)) {
        _errors = [..._errors, "A senha possui 3 dígitos repetidos"];
      }
    }

    return _errors;
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;
    setHasErrors(false);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    const _errors = getErrors();
    setErrors(_errors);

    if (_errors.length > 0) {
      setHasErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await recoverPassword(token, password);
      if (response.status === 200) {
        setModalTitle("Sucesso!");
        setModalDescription(
          "Sua senha foi modificada com sucesso. Volte a plataforma e faça o login."
        );
        activeModal();
      }
    } catch (error) {
      setModalTitle("Error!");
      setModalDescription(
        "Sua senha não foi modificada, por favor verifique se o link expirou."
      );
      activeModal();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <PageLayout width="100%" background="#F0F2F5">
      <MDBox
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div className={mainPageStyles.card}>
          <header className={styles.header}>
            <h2 className={`${styles.title} ${mainPageStyles.h24}`}>
              Alteração de senha
            </h2>
          </header>

          {isLoading && <Loading />}

          <form
            className={styles.form}
            onSubmit={handleFormSubmit}
            autoComplete="off"
          >
            <div className={mainPageStyles.formInputGroup}>
              <label htmlFor="password" className={mainPageStyles.formLabel}>
                Criar nova senha
              </label>
              <TextField
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={handleChangePassword}
                className={mainPageStyles.formInput}
                maxLength="6"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" c>
                      <IconButton onClick={handlePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={mainPageStyles.formInputGroup}>
              <label htmlFor="password" className={mainPageStyles.formLabel}>
                Confirmar nova senha
              </label>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                id="password"
                name="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={mainPageStyles.formInput}
                maxLength="6"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleConfirmPasswordVisibility}>
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
              Não use números em sequência
            </p>
            <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
              Use no máximo 3 números repetidos
            </p>

            <p className={`${mainPageStyles.b14} ${styles.auxilliaryText}`}>
              Deve conter 6 digítos numéricos
            </p>

            <button
              type="submit"
              className={`${mainPageStyles.primaryButton} ${styles.submitButton}`}
            >
              Continuar
            </button>
          </form>

          {hasErrors &&
            errors?.map((error) => (
              <Snackbar
                key={error}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={hasErrors}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
              >
                <Alert
                  onClose={handleCloseAlert}
                  variant="filled"
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {error}
                </Alert>
              </Snackbar>
            ))}
        </div>
      </MDBox>
      {isActiveModal && (
        <ModalReturnApi
          title={modalTitle}
          description={modalDescription}
          closeModal={deactiveModal}
        />
      )}
    </PageLayout>
  );
}

export default PasswordRecovery;
