import React, { useState } from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import styles from "../../styles.module.css";
import arrowBottomIcon from "../../../../../assets/images/icons/arrow-bottom.svg";

function CardConservadorInvest({ submitLowRisk, setCurrentContentHTML }) {
  return (
    <div className={styles.containerCard}>
      <input
        type="radio"
        id="low-risk"
        name="risks"
        className={styles.inputRadio}
      />
      <label htmlFor="low-risk" className={styles.containerRisk} role="button">
        <MDBox
          width="100%"
          bgColor="#4B93FF"
          display="flex"
          gap="1rem"
          alignItems="center"
          borderRadius="1rem 1rem 0 0"
          p={2}
        >
          <img
            src={arrowBottomIcon}
            alt="Seta para baixo"
            className={styles.containerRiskHeaderIcon}
          />
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="h3"
              fontWeight="light"
              sx={{
                fontSize: "1.5rem",
                lineHeight: "calc(1.5rem) * 1.5",
                color: "#fff",
              }}
            >
              Conservador
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              sx={{
                fontSize: "0.75rem",
                lineHeight: "calc(0.75rem) * 1.5",
                color: "#fff",
              }}
            >
              Prazo até 12 meses
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          py={4}
          px={2}
          display="flex"
          flexDirection="column"
          gap="0.75rem"
        >
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightBold}`}
            >
              Juros pagos pelas pessoas
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p
                className={`${styles.containerRiskSignalPlus} ${styles.fontWeightLight}`}
              >
                +
              </p>{" "}
              <p className={styles.fontWeightBold}>5,25% a.m.</p>
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Inadimplência esperada
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalMinus}>-</p> 16,9% a.a.
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Impostos e taxas
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalMinus}>-</p> 1,37% a.m.
            </p>
          </MDBox>
          <MDBox display="flex" gap="0.5rem" justifyContent="space-between">
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              Rentabilidade esperada
            </p>
            <p
              className={`${styles.containerRiskLabel} ${styles.fontWeightLight}`}
            >
              <p className={styles.containerRiskSignalEqual}>=</p>{" "}
              <p>28,4% a.a.</p>
            </p>
          </MDBox>
          <MDBox mt={2} display="flex" justifyContent="center">
            <button
              className={styles.primaryButton}
              // style={{
              //   marginTop: 115,
              // }}
              onClick={() => {
                submitLowRisk();
                setCurrentContentHTML("amountToInvest");
              }}
            >
              Avançar
            </button>
          </MDBox>
        </MDBox>
      </label>
    </div>
  );
}

export default CardConservadorInvest;
