import React from "react";

import { Paper } from "@mui/material";

import styles from "./styles.module.css";
import stylesCodeTable from "../CodeTable/styles.module.css";

const CONSTANTS = {
  CLASS_NAME: {
    ENABLE: `${styles.option} ${stylesCodeTable.colorStatusEnable}`,
    DISABLE: `${styles.option} ${stylesCodeTable.colorStatusDisable}`,
    ALL: `${styles.option}`,
  },
};

function DropdownFilterByStatus({ close, setFilterActive, filters }) {
  function handleClickOption(filter) {
    setFilterActive(filter);
    close();
  }

  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: "2.5rem",
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {filters.map((filter) => (
        <button
          key={filter.key}
          className={CONSTANTS.CLASS_NAME[filter.key]}
          onClick={() => handleClickOption(filter)}
        >
          {filter.label}
        </button>
      ))}
    </Paper>
  );
}

export { DropdownFilterByStatus };
