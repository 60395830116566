import React, { useState, useEffect } from "react";

import { DropdownFilterByStatus } from "../DropdownFilterByStatus";

import { formatMoney } from "utils";

import styles from "./styles.module.css";
import moreIcon from "assets/images/icons/more-vertical.svg";
import unfoldIcon from "assets/images/icons/unfold-less.svg";

// Os status CANCELLED e REJECTED visualmente possuem a mesma finalidade de REPROVADO
// COMPLETED => Foi pago completamente
const CONSTANTS = {
  LOAN_STATUS: {
    ACTIVE: <strong className={styles.statusApproved}>Aprovado</strong>,
    COMPLETED: <strong className={styles.statusApproved}>Aprovado</strong>,
    CANCELLED: <strong className={styles.statusRefused}>Reprovado</strong>,
    REJECTED: <strong className={styles.statusRefused}>Reprovado</strong>,
    CONFIRMED: <strong className={styles.statusPending}>Pendente</strong>,
  },
  FILTERS: [
    { KEY: "ALL", LABEL: "Todos" },
    { KEY: "ACTIVE", LABEL: "Aprovado" },
    { KEY: "COMPLETED", LABEL: "Aprovado" },
    { KEY: "CONFIRMED", LABEL: "Pendente" },
    { KEY: "CANCELLED", LABEL: "Reprovado" },
    { KEY: "REJECTED", LABEL: "Reprovado" },
  ],
};

function LoansTable({
  openModal,
  directLoansList = [],
  filteredDirectLoansList = [],
  setFilteredDirectLoansList,
  setSelectedDirectLoan,
}) {
  const [takerNameTypeOrder, setTakerNameTypeOrder] = useState("ASC");
  const [investorRateTypeOrder, setInvestorRateTypeOrder] = useState("ASC");
  const [amountTypeOrder, setAmountTypeOrder] = useState("ASC");
  const [installmentsTypeOrder, setInstallmentsTypeOrder] = useState("ASC");
  const [filterActive, setFilterActive] = useState(CONSTANTS.FILTERS[0]);
  const [isOpenDropdownFilterByStatus, setIsOpenDropdownFilterByStatus] =
    useState(false);

  const openDropdownFilterByStatus = () =>
    setIsOpenDropdownFilterByStatus(true);

  const closeDropdownFilterByStatus = () =>
    setIsOpenDropdownFilterByStatus(false);

  const toggleDropdown = () =>
    setIsOpenDropdownFilterByStatus(!isOpenDropdownFilterByStatus);

  function sortInstallments(typeOrder) {
    let orderedDirectLoans = [];

    if (typeOrder === "ASC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.installments.length > directLoanB.installments.length
          ) {
            return 1;
          } else if (
            directLoanA.installments.length < directLoanB.installments.length
          ) {
            return -1;
          }

          return 0;
        }
      );
    } else if (typeOrder === "DESC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.installments.length < directLoanB.installments.length
          ) {
            return 1;
          } else if (
            directLoanA.installments.length > directLoanB.installments.length
          ) {
            return -1;
          }

          return 0;
        }
      );
    }

    setFilteredDirectLoansList(orderedDirectLoans);
  }

  function sortTakerName(typeOrder) {
    let orderedDirectLoans = [];

    if (typeOrder === "ASC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (directLoanA.taker.fullname > directLoanB.taker.fullname) {
            return 1;
          } else if (directLoanA.taker.fullname < directLoanB.taker.fullname) {
            return -1;
          }

          return 0;
        }
      );
    } else if (typeOrder === "DESC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (directLoanA.taker.fullname < directLoanB.taker.fullname) {
            return 1;
          } else if (directLoanA.taker.fullname > directLoanB.taker.fullname) {
            return -1;
          }

          return 0;
        }
      );
    }

    setFilteredDirectLoansList(orderedDirectLoans);
  }

  function sortInvestorRate(typeOrder) {
    let orderedDirectLoans = [];

    if (typeOrder === "ASC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.rates.accumulative.investor_rate >
            directLoanB.rates.accumulative.investor_rate
          ) {
            return 1;
          } else if (
            directLoanA.rates.accumulative.investor_rate <
            directLoanB.rates.accumulative.investor_rate
          ) {
            return -1;
          }

          return 0;
        }
      );
    } else if (typeOrder === "DESC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.rates.accumulative.investor_rate <
            directLoanB.rates.accumulative.investor_rate
          ) {
            return 1;
          } else if (
            directLoanA.rates.accumulative.investor_rate >
            directLoanB.rates.accumulative.investor_rate
          ) {
            return -1;
          }

          return 0;
        }
      );
    }

    setFilteredDirectLoansList(orderedDirectLoans);
  }

  function sortAmount(typeOrder) {
    let orderedDirectLoans = [];

    if (typeOrder === "ASC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.loan.amount_collected >
            directLoanB.loan.amount_collected
          ) {
            return 1;
          } else if (
            directLoanA.loan.amount_collected <
            directLoanB.loan.amount_collected
          ) {
            return -1;
          }

          return 0;
        }
      );
    } else if (typeOrder === "DESC") {
      orderedDirectLoans = filteredDirectLoansList.sort(
        (directLoanA, directLoanB) => {
          if (
            directLoanA.loan.amount_collected <
            directLoanB.loan.amount_collected
          ) {
            return 1;
          } else if (
            directLoanA.loan.amount_collected >
            directLoanB.loan.amount_collected
          ) {
            return -1;
          }

          return 0;
        }
      );
    }

    setFilteredDirectLoansList(orderedDirectLoans);
  }

  function handleClickHeaderTitle(typeOrder, setUpdateTypeOrder) {
    if (typeOrder === "ASC") {
      setUpdateTypeOrder("DESC");
      return;
    }

    setUpdateTypeOrder("ASC");
  }

  function handleClickSelectedDirectLoan(directLoan) {
    setSelectedDirectLoan(directLoan);
    openModal();
  }

  useEffect(() => {
    if (filterActive.KEY === "ALL") {
      setFilteredDirectLoansList(directLoansList);
    } else if (["REJECTED", "CANCELLED"].includes(filterActive.KEY)) {
      const filteredList = directLoansList.filter((directLoan) =>
        ["REJECTED", "CANCELLED"].includes(directLoan.loan.status)
      );
      setFilteredDirectLoansList(filteredList);
    } else if (["COMPLETED", "ACTIVE"].includes(filterActive.KEY)) {
      const filteredList = directLoansList.filter((directLoan) =>
        ["COMPLETED", "ACTIVE"].includes(directLoan.loan.status)
      );
      setFilteredDirectLoansList(filteredList);
    } else {
      const filteredList = directLoansList.filter(
        (directLoan) => directLoan.loan.status === filterActive.KEY
      );
      setFilteredDirectLoansList(filteredList);
    }

    return () => {
      setFilteredDirectLoansList([]);
    };
  }, [filterActive, directLoansList]);

  useEffect(() => {
    sortTakerName(takerNameTypeOrder);
  }, [takerNameTypeOrder]);

  useEffect(() => {
    sortInvestorRate(investorRateTypeOrder);
  }, [investorRateTypeOrder]);

  useEffect(() => {
    sortAmount(amountTypeOrder);
  }, [amountTypeOrder]);

  useEffect(() => {
    sortInstallments(installmentsTypeOrder);
  }, [installmentsTypeOrder]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Nome</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(
                    takerNameTypeOrder,
                    setTakerNameTypeOrder
                  )
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>Código</th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Juros</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(
                    investorRateTypeOrder,
                    setInvestorRateTypeOrder
                  )
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>CPF</th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Valor bruto do empréstimo</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(amountTypeOrder, setAmountTypeOrder)
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Parcelas</strong>
              <button
                title="Ordernar"
                className={styles.headerButtonAction}
                onClick={() =>
                  handleClickHeaderTitle(
                    installmentsTypeOrder,
                    setInstallmentsTypeOrder
                  )
                }
              >
                <img src={unfoldIcon} alt="Ordernar" />
              </button>
            </div>
          </th>
          <th>
            <div className={styles.containerHeaderTitle}>
              <strong>Status</strong>
              <button
                title="Filtrar"
                onClick={toggleDropdown}
                className={styles.headerButtonAction}
              >
                <img src={moreIcon} alt="Opções de filtros" />
              </button>
              {isOpenDropdownFilterByStatus && (
                <DropdownFilterByStatus
                  close={closeDropdownFilterByStatus}
                  filters={CONSTANTS.FILTERS}
                  setFilterActive={setFilterActive}
                />
              )}
            </div>
          </th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {filteredDirectLoansList?.map((directLoan) => (
          <tr key={directLoan.id}>
            <td className={styles.minWidthColumnTable}>
              {directLoan.taker.fullname}
            </td>
            <td>{directLoan.investor_code}</td>
            <td>
              {directLoan.rates.accumulative.investor_rate.toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                }
              )}
              % a.m.
            </td>
            <td className={styles.minWidthColumnTable}>
              {directLoan.taker.cpf}
            </td>
            <td>{formatMoney(directLoan.loan.amount_collected)}</td>
            <td>{directLoan.installments.length}x</td>
            <td>
              <strong>{CONSTANTS.LOAN_STATUS[directLoan.loan.status]}</strong>
            </td>
            <td>
              <button
                className={styles.actionsButton}
                onClick={() => handleClickSelectedDirectLoan(directLoan)}
                title="Visualizar detalhes do empréstimo"
              >
                Visualizar
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export { LoansTable };
