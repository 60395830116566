import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

import { CustomTooltip } from "./styles";
import styles from "./styles.module.css";
import { Colors } from "common/Colors";
import clockIcon from "../../../../../assets/images/icons/clock.svg";
import helpIcon from "../../../../../assets/images/icons/help-soft-blue.svg";
import moneyCircleIcon from "../../../../../assets/images/icons/money-circle.svg";
import suitcaseIcon from "../../../../../assets/images/icons/suitcase.svg";
import walletIcon from "../../../../../assets/images/icons/wallet-soft-blue.svg";

function CardMyWallet({ setCurrentRenderedContent, wallet, userStatus }) {
  const [amountReceivable, setAmountReceivable] = useState(0);
  const [invested, setInvested] = useState(0);
  const [available, setAvailable] = useState(0);
  const [total, setTotal] = useState(0);
  const isBlockActions = userStatus !== "approved";

  function handleDeposit() {
    setCurrentRenderedContent("deposit");
  }

  function handleWithdraw() {
    setCurrentRenderedContent("withdraw");
  }

  useEffect(() => {
    setInvested(wallet?.my_wallet?.amount_receivable);
    setAmountReceivable(wallet?.my_wallet?.invested);
    setTotal(wallet?.my_wallet?.total);
    setAvailable(wallet?.my_wallet?.available);
  }, [wallet]);

  return (
    <MDBox
      width="100%"
      height="100%"
      color={Colors.WHITE}
      bgColor="linear-gradient(270deg, #396277 0%, #305568 100%)"
      borderRadius="1rem 1rem 1rem 1rem"
      shadow="lg"
      p={3}
    >
      <MDBox
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        color={Colors.WHITE}
      >
        <MDTypography color={Colors.WHITE} fontWeight="light" variant="h5">
          Minha carteira
        </MDTypography>
        <img src={moneyCircleIcon} alt="Cifrão com seta em volta" />
      </MDBox>
      <MDBox container mt={6} display="flex" gap="0.5rem" flexWrap="wrap">
        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Saldo disponível
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Saldo disponível para transferir para a sua conta bancária
                cadastrada ou para investir em novos empréstimos.
              </p>

              <strong>
                <Link to="/investir">Clique para investir</Link>
              </strong>
            </>
          }
        >
          <div
            className={styles.walletItem}
            // title="Saldo disponível para tranferir para a sua conta bancária
            //       cadastrada ou para investir em novos empréstimos."
          >
            <img
              src={walletIcon}
              alt="Carteira"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Saldo disponível</p>
            <p className={styles.walletItemBody1}>
              {available?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor em captação
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma das ordens de investimento, em fase de distribuição para
                novos empréstimos.
              </p>
            </>
          }
        >
          <div
            className={styles.walletItem}
            // title={"Valor em contratos de empréstimos ativos neste momento."}
          >
            <img
              src={clockIcon}
              alt="Relógio"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor em captação</p>
            <p className={styles.walletItemBody1}>
              {amountReceivable?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            <>
              <strong className={styles.walletItemTooltipTitle}>
                Valor investido
              </strong>
              <p className={styles.walletItemTooltipBody}>
                Soma dos empréstimos em andamento. <br />
                Obs: O valor investido poderá retornar à captação nos casos de
                empréstimos não concedidos.
              </p>
            </>
          }
        >
          <div
            className={styles.walletItem}
          >
            <img
              src={suitcaseIcon}
              alt="Maleta"
              className={styles.walletItemIcon}
            />
            <p className={styles.walletItemBody2}>Valor investido</p>
            <p className={styles.walletItemBody1}>
              {invested?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        </CustomTooltip>
      </MDBox>
      <MDBox
        my="2rem"
        width="100%"
        display="flex"
        gap="1.5rem"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <button
          className={`${styles.walletButton} ${styles.walletButtonAdd}`}
          onClick={handleDeposit}
          disabled={isBlockActions}
        >
          Adicionar fundos
        </button>
        <button
          className={`${styles.walletButton} ${styles.walletButtonWithdraw}`}
          onClick={handleWithdraw}
          disabled={isBlockActions}
        >
          Retirar fundos
        </button>
      </MDBox>
    </MDBox>
  );
}

export default CardMyWallet;
