import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import AlertWarning from "../../components/AlertWarning";
import AlertError from "../../components/AlertError";
import AlertInfo from "../../components/AlertInfo";

import { formatMoney } from "utils";

function SectionAlerts({
  isActiveAlertNewMessages,
  closeAlertNewMessages,
  isActiveAlertUserStatusPending,
  isActiveAlertUserStatusRejected,
}) {
  const [isActiveAlertNewFeatures, setIsActiveAlertNewFeatures] =
    useState(false);

  useEffect(() => {
    const isAlertEverOpened = JSON.parse(
      localStorage.getItem("isAlertEverOpened")
    );

    if (isAlertEverOpened === null) {
      setIsActiveAlertNewFeatures(true);
    } else {
      setIsActiveAlertNewFeatures(false);
    }

    return () => {
      setIsActiveAlertNewFeatures(false);
    };
  }, []);

  const closeAlertNewFeatures = () => {
    setIsActiveAlertNewFeatures(false);
    localStorage.setItem("isAlertEverOpened", true);
  };

  return (
    <MDBox
      component="section"
      display="flex"
      flexDirection="column"
      gap="1rem"
      mb="2rem"
    >
      {isActiveAlertUserStatusPending && (
        <AlertWarning>
          <strong>Atenção:</strong> Estamos analisando seus dados para sua conta
          ser aberta em breve
        </AlertWarning>
      )}

      {isActiveAlertUserStatusRejected && (
        <AlertError route="reenvio-documentos" routeLabel="Reenviar documentos">
          <strong>Atenção:</strong> Encontramos um problema em seus dados, por
          favor, confira seus dados e reenvie novamente
        </AlertError>
      )}

      {isActiveAlertNewFeatures && (
        <AlertInfo
          route="convidar-investidores"
          routeLabel="Saiba mais"
          close={closeAlertNewFeatures}
        >
          <strong>Convidar Investidores:</strong> Ganhe até{" "}
          <strong>{formatMoney(100)}</strong> para cada novo Investidor
        </AlertInfo>
      )}

      {isActiveAlertNewMessages && (
        <AlertWarning
          route="mensagens"
          routeLabel="Ver mensagens"
          close={closeAlertNewMessages}
        >
          <strong>Atenção:</strong> Olá Investor, você possui novas mensagens.
          Verifique suas mensagens.
        </AlertWarning>
      )}
    </MDBox>
  );
}

export default SectionAlerts;
