import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

import exclamationIcon from "assets/images/icons/exclamation-yellow.svg";
import closeIcon from "assets/images/icons/close-background-red.svg";

function AlertWarning({ children, route, routeLabel, close }) {
  return (
    <div className={styles.alert}>
      <img
        src={exclamationIcon}
        alt="Sinal de exclamação"
        className={styles.alertIcon}
      />

      <p className={styles.alertText}>{children}</p>

      {route && (
        <Link to={`/${route}`} className={styles.alertLinkButton}>
          {routeLabel}
        </Link>
      )}

      {close && (
        <button
          className={styles.alertCloseButton}
          title="Fechar alerta"
          onClick={close}
        >
          <img src={closeIcon} alt="X" />
        </button>
      )}
    </div>
  );
}

export default AlertWarning;
