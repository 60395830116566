import styles from "./styles.module.css";
import closeIcon from "assets/images/icons/close-circle.svg";

function ModalInfoTir({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <div className={styles.header}>
          <h2 className={styles.title}>O que é a TIR?</h2>
          <button onClick={closeModal}>
            <img src={closeIcon} alt="Fechar" />
          </button>
        </div>
        <p className={styles.description}>
          TIR quer dizer taxa interna de retorno.
        </p>
        <p className={styles.description}>
          É a taxa de desconto que, se aplicada a cada parcela do empréstimo no
          tempo, tem como resultado o investimento inicial.
        </p>
        <p className={styles.description}>
          A TIR é uma das referências mais seguras para você avaliar a
          rentabilidade dos seus investimentos, pois considera o valor do
          dinheiro ao longo do tempo. Ela também é útil para a comparação de
          investimentos com prazos e montantes diferentes.
        </p>
        <p className={styles.description}>
          O cálculo parece complicado, mas é super simples!
        </p>
        <p className={styles.description}>
          Basta aplicar na fórmula a TIR e o valor de cada prestação “Pt”, sendo
          “t” o número do mês (fazer de 1 a 12 se o empréstimo for pago em um
          ano), e o resultado será o valor do investimento:
        </p>
        <p className={styles.description}>n: número de prestações</p>
        <p className={styles.description}>t: número do mês</p>
        <p className={styles.description}>
          Pt: valor da prestação no mês “t” esse é o símbolo da soma, significa
          que os resultados da fórmula, conforme cada prestação, devem ser
          somados.
        </p>
      </aside>
    </div>
  );
}

export { ModalInfoTir };
