import React from "react";
// import { Alert, Snackbar } from "@mui/material";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";

// import { getOnlyNumbersOfString } from "utils";
import { phoneNumberMask } from "../../../../../utils/mask";
import { sendCodeBySMS } from "../../../../../services/user";

import arrowBackIcon from "assets/images/icons/arrow-back.svg";

const CONSTANTS = {
  LENGTH_PHONE: 15,
  STATUS_CODE_SUCCESS: 200,
};

function CodeBySMSForm({
  previousStep,
  openModal,
  phone,
  setPhone,
  email,
  disableLoading,
  activateLoading,
  setError,
  openSnackbar,
  closeSnackbar,
  startTimeSmsCode,
}) {
  const isValidPhone = (phone = "") =>
    phone.length === CONSTANTS.LENGTH_PHONE &&
    ["(", ")", "-"].every((character) => phone.includes(character));

  function handleInputChangePhone(event) {
    const { value } = event.target;
    // const onlyNumbersPhone = getOnlyNumbersOfString(value);
    const formattedPhoneNumber = phoneNumberMask(value);

    setPhone(formattedPhoneNumber);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    if (!isValidPhone(phone)) {
      // disableLoading();
      setError("Número incompleto. Por favor, digite seu número com o DDD");
      openSnackbar();
      return;
    }

    activateLoading();

    try {
      const response = await sendCodeBySMS(phone, email);

      if (response?.status !== CONSTANTS.STATUS_CODE_SUCCESS) {
        throw new Error();
      }

      setError("");
      closeSnackbar();
      openModal();
    } catch (error) {
      console.error(error);
      console.error("Erro ao enviar código via SMS");

      setError("Erro ao enviar código via SMS");
      openSnackbar();
    } finally {
      disableLoading();
      startTimeSmsCode();
    }
  }

  return (
    <div className={mainPageStyles.card}>
      <header className={styles.header}>
        <button title="Voltar para etapa anterior" onClick={previousStep}>
          <img src={arrowBackIcon} alt="Seta para esquerda" />
        </button>
        <h2 className={`${mainPageStyles.h24} ${styles.title}`}>
          Qual o seu telefone?
        </h2>
      </header>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div className={mainPageStyles.formInputGroup}>
          <label className={mainPageStyles.formLabel} htmlFor="phone-number">
            Número
          </label>
          <input
            type="text"
            id="phone-number"
            name="phone-number"
            className={mainPageStyles.formInput}
            value={phone}
            onChange={handleInputChangePhone}
            placeholder="DDD + número"
            maxLength={CONSTANTS.LENGTH_PHONE}
            required
          />
        </div>

        <button
          type="submit"
          className={`${mainPageStyles.primaryButton} ${styles.formButton}`}
        >
          Enviar Código
        </button>
      </form>
    </div>
  );
}

export default CodeBySMSForm;
