import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDBox from "../../../components/MDBox";
import Loading from "components/Loading";

import { HistoryInvites } from "../inviteInvestors/components/HistoryInvites";
import { ShareInvite } from "../inviteInvestors/components/ShareInvite";

export function InviteInvestors() {
  const [currentContentHTML, setCurrentContentHTML] = useState("initial");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const contentsHTML = {
    initial: () => (
      <MDBox mt={3}>
        <MDBox
          display="flex"
          gap="2rem"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          <HistoryInvites setIsLoading={setIsLoading} isLoading={isLoading} />
          <ShareInvite />
        </MDBox>
      </MDBox>
    ),
  };

  if (!token) {
    navigate("/login");
    return null;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {contentsHTML[currentContentHTML]()}
      {isLoading && <Loading />}
    </DashboardLayout>
  );
}
export default InviteInvestors;
