import React from "react";

import MDTypography from "components/MDTypography";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import styles from "../../styles.module.css";
import MDInput from "components/MDInput";

const CONSTANTS = {
  OPERATION_CAIXA_ECONOMICA_FEDERAL: 104,
  OPERATIONS_CAIXA_ECONOMICA_FEDERAL: ["001", "002", "037"],
};

function StepFormAccount({
  handleSubmitFormAccount,
  isTablet,
  isMobile,
  banksList,
  selectedBank,
  setSelectedBank,
  setBankCode,
  inputComboBoxBank,
  setInputComboBoxBank,
  setAgency,
  accountType,
  setAccountType,
  setAccount,
  setAccountDigit,
  bankCode,
  operation,
  setOperation,
  loading,
}) {
  const handleOnKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <MDTypography
        mt={isTablet ? "3rem" : "6rem"}
        variant="h3"
        fontWeight="light"
        sx={{
          fontSize: "1.5rem",
          lineHeight: "calc(1.5rem * 1.5)",
          textAlign: "center",
        }}
      >
        Qual é o seu banco?
      </MDTypography>
      <form
        className={styles.formAccountForm}
        onSubmit={handleSubmitFormAccount}
        autoComplete="off"
      >
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="combo-box-banks"
            options={banksList?.map((bank) => ({
              label: `${bank.bank} - ${bank.code}`,
              code: bank.code,
            }))}
            value={selectedBank}
            onChange={(event, newValue) => {
              // console.log(newValue);
              setSelectedBank(newValue);
              setBankCode(newValue?.code);
            }}
            inputValue={inputComboBoxBank}
            onInputChange={(event, newValue) => {
              // console.log(newValue);
              setInputComboBoxBank(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Escolha um banco" />
            )}
          />
        </FormControl>

        <MDInput
          type="text"
          id="agency"
          name="agency"
          label="Agência"
          onChange={(e) => {
            setAgency(e.target.value).replace(/[^0-9]/g, "");
          }}
          onKeyPress={(e) => {
            handleOnKeyPress(e);
          }}
          required
        />

        <FormControl fullWidth>
          <InputLabel id="account-type-label">Tipo de conta</InputLabel>
          <Select
            labelId="account-type-label"
            id="types-of-accounts"
            value={accountType}
            label="Tipo de conta"
            onChange={(event) => setAccountType(event.target.value)}
            sx={{ height: "3rem" }}
          >
            <MenuItem value={"savingsAccount"}>Poupança</MenuItem>
            <MenuItem value={"checkingAccount"}>Corrente</MenuItem>
          </Select>
        </FormControl>

        <Grid container spacing={isMobile ? 3 : 1}>
          <Grid item xs={isMobile ? 12 : 8}>
            <MDInput
              type="text"
              id="account"
              name="account"
              label="Conta"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setAccount(e.target.value.replace(/[^0-9]/g, ""));
              }}
              onKeyPress={(e) => {
                handleOnKeyPress(e);
              }}
              required
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 4}>
            <MDInput
              type="text"
              id="digit"
              name="digit"
              label="Dígito"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setAccountDigit(e.target.value.replace(/[^0-9]/g, ""));
              }}
              onKeyPress={(e) => {
                handleOnKeyPress(e);
              }}
              required
            />
          </Grid>
        </Grid>

        {bankCode == CONSTANTS.OPERATION_CAIXA_ECONOMICA_FEDERAL && (
          <FormControl fullWidth>
            <InputLabel id="operation-label">Operação</InputLabel>
            <Select
              labelId="operation-label"
              id="operations"
              value={operation}
              label="Operações"
              onChange={(event) => setOperation(event.target.value)}
              sx={{ height: "3rem" }}
            >
              {CONSTANTS.OPERATIONS_CAIXA_ECONOMICA_FEDERAL.map((operation) => (
                <MenuItem key={operation} value={operation}>
                  {operation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <button
          type="submit"
          className={`${styles.button} ${styles.formAccountFormButton}`}
          // onClick={handleSubmitFormAccount}
          disabled={loading}
        >
          Continuar
        </button>
      </form>
    </>
  );
}

export { StepFormAccount };
