import { useEffect, useState } from "react";
// import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
// react-router-dom components
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Loading from "../../../components/Loading";
// import MDBox from "../../../components/MDBox";
// import CardAuthentication from "./components/CardAuthentication";
import CardForm from "./components/CardForm";
import CardInvestorProfile from "./components/CardInvestorProfile";
import CardPassword from "./components/CardPassword";
import CardAccount from "./components/CardAccount";

// import CardAccountStatus from "./components/CardAccountStatus";

import { getLoggedUser } from "../../../services/user";
import { getStoredValue } from "metrics/storage";

// const CONSTANTS = {
//   LAPTOP_SCREEN_DIMENSIONS: 1200,
// };

function MyProfile() {
  const [loggedUser, setLoggedUser] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingLoggedUser, setIsLoadingLoggedUser] = useState(false);
  const [isLoadingBankList, setIsLoadingBankList] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const loggedUserStoraged = sessionStorage.getItem("loggedUser");

    async function loadLoggedUser() {
      setIsLoadingLoggedUser(true);

      try {
        const data = await getLoggedUser();
        setLoggedUser(data);

        sessionStorage.setItem("loggedUser", JSON.stringify(data));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingLoggedUser(false);
      }
    }

    if (!loggedUserStoraged) {
      loadLoggedUser();
      return;
    }

    setLoggedUser(JSON.parse(loggedUserStoraged));
  }, []);

  useEffect(() => {
    if (!isLoadingLoggedUser && !isLoadingBankList) {
      setIsLoadingData(false);
      return;
    }

    setIsLoadingData(true);
  });

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container rowSpacing={3} columnSpacing={{ xl: 2, lg: 3, md: 2 }}>
        <Grid item xl={15} lg={6} md={6} sm={12} xs={12}>
          <CardAccount
            loggedUser={loggedUser}
            setIsLoadingBankList={setIsLoadingBankList}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardForm
            loggedUser={loggedUser}
            setIsLoadingBankList={setIsLoadingBankList}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardInvestorProfile />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardPassword />
        </Grid>
      </Grid>
      {isLoadingData && <Loading />}
    </DashboardLayout>
  );
}

export default MyProfile;
