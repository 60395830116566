import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";

import MDBox from "components/MDBox";
import { ListItemInvestment } from "../ListItemInvestment";
import { ListItemTransfer } from "../ListItemTransfer";

import downloadIcon from "assets/images/icons/download.svg";
import { ModalGenerateExtract } from "./components/ModalGenerateExtract";

import styles from "./styles.module.css";

const CONSTANTS = {
  DIMENSIONS_TABLET: 768,
  DIMENSIONS_MOBILE: 550,
  MONTHS: [
    {
      month: "jan",
      label: "Jan",
      name: "Janeiro",
      numericMonth: 1,
    },
    {
      month: "feb",
      label: "Fev",
      name: "Fevereiro",
      numericMonth: 2,
    },
    {
      month: "mar",
      label: "Mar",
      name: "Março",
      numericMonth: 3,
    },
    {
      month: "apr",
      label: "Abr",
      name: "Abril",
      numericMonth: 4,
    },
    {
      month: "may",
      label: "Mai",
      name: "Maio",
      numericMonth: 5,
    },
    {
      month: "jun",
      label: "Jun",
      name: "Junho",
      numericMonth: 6,
    },
    {
      month: "jul",
      label: "Jul",
      name: "Julho",
      numericMonth: 7,
    },
    {
      month: "aug",
      label: "Ago",
      name: "Agosto",
      numericMonth: 8,
    },
    {
      month: "sep",
      label: "Set",
      name: "Setembro",
      numericMonth: 9,
    },
    {
      month: "oct",
      label: "Out",
      name: "Outubro",
      numericMonth: 10,
    },
    {
      month: "nov",
      label: "Nov",
      name: "Novembro",
      numericMonth: 11,
    },
    {
      month: "dec",
      label: "Dez",
      name: "Dezembro",
      numericMonth: 12,
    },
  ],
};

function CardList({
  selectedTypeTransactions,
  setSelectedTypeTransactions,
  selectedMounth,
  setSelectedMounth,
  selectedYear,
  setSelectedYear,
  filterListYears,
  currentYear,
  filteredTransactionsPerSelectedMonth,
  filteredInvestmentsPerMonthAndYear,
}) {
  const [isActiveModal, setIsActiveModal] = useState(false)

  const isTablet = useMediaQuery(
    `(max-width: ${CONSTANTS.DIMENSIONS_TABLET}px)`
  );
  const isMobile = useMediaQuery(
    `(max-width: ${CONSTANTS.DIMENSIONS_MOBILE}px)`
  );

  function handleChangeSelectedTypeTransactions(event) {
    const { value } = event.target;

    setSelectedTypeTransactions(value);
  }

  const handleChangeSelectedMounth = ({ month, numericMonth, name }) =>
    setSelectedMounth({ month, numericMonth, name });

  const openModal = () => setIsActiveModal(true)

  const closeModal = () => setIsActiveModal(false)

  const handleDownloadExtract = () => {
    openModal()
  };

  return (
    <MDBox
      mt={2}
      width="min(67.1875rem, 100%)"
      bgColor="#fff"
      borderRadius="lg"
      shadow="lg"
      pb={4}
    >
      <MDBox width="100%" display="flex">
        <MDBox flex="1" height={isMobile ? "4rem" : "7rem"}>
          <input
            type="radio"
            id="transfers"
            name="type-transactions"
            className={styles.cardTransactionsHeaderInput}
            checked={selectedTypeTransactions === "transfers"}
            value={"transfers"}
            onChange={handleChangeSelectedTypeTransactions}
          />
          <label
            htmlFor="transfers"
            className={styles.cardTransactionsHeaderLabel}
          >
            Transferências
          </label>
        </MDBox>

        <MDBox flex="1" height={isMobile ? "4rem" : "7rem"}>
          <input
            type="radio"
            id="investments"
            name="type-transactions"
            className={styles.cardTransactionsHeaderInput}
            checked={selectedTypeTransactions === "investments"}
            value={"investments"}
            onChange={handleChangeSelectedTypeTransactions}
          />
          <label
            htmlFor="investments"
            className={styles.cardTransactionsHeaderLabel}
          >
            Investimentos
          </label>
        </MDBox>
      </MDBox>

      <MDBox
        component="ul"
        width="100%"
        px={isTablet ? 1 : 0}
        display="flex"
        gap={isTablet ? "2rem" : "1rem"}
        my={isMobile ? 3 : 5}
        maxWidth="100%"
        sx={{ overflowX: "auto" }}
      >
        {CONSTANTS.MONTHS.map(({ month, label, numericMonth, name }) => (
          <MDBox component="li" flex="1" key={month}>
            <input
              type="radio"
              id={month}
              name="months"
              className={styles.cardTransactionsMonthsInput}
              checked={selectedMounth.month === month}
              value={month}
              onChange={() =>
                handleChangeSelectedMounth({ month, numericMonth, name })
              }
            />
            <label
              htmlFor={month}
              className={styles.cardTransactionsMonthsLabel}
              title={name}
            >
              {label}
            </label>
          </MDBox>
        ))}
      </MDBox>

      <MDBox display="flex" justifyContent="center">
        <MDBox width="min(12.5rem, 100%)" ml={2} mb={5}>
          <FormControl fullWidth>
            <InputLabel id={"select-years"}>Ano</InputLabel>
            <Select
              labelId={"select-years"}
              id={"select-years"}
              value={selectedYear}
              label="Ano"
              onChange={({ target }) => setSelectedYear(target.value)}
              sx={{ height: "3rem" }}
            >
              {filterListYears?.map((year) => (
                <MenuItem key={year} value={year} sx={{ height: "3rem" }}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </MDBox>

      <MDBox
        component="ul"
        px={isTablet ? 2 : 5}
        width="100%"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        {selectedTypeTransactions === "transfers" ? (
          <>
            {filteredTransactionsPerSelectedMonth?.length === 0 ? (
              <p className={styles.cardTransactionInfoMessage}>
                Você não possui transferências no mês de{" "}
                <strong>{selectedMounth?.name}</strong> no ano de{" "}
                <strong>{selectedYear}</strong>
              </p>
            ) : (
              <>
                {filteredTransactionsPerSelectedMonth?.map((transaction) => (
                  <ListItemTransfer
                    key={transaction.transaction_id}
                    isMobile={isMobile}
                    transaction={transaction}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {filteredInvestmentsPerMonthAndYear?.length === 0 ? (
              <p className={styles.cardTransactionInfoMessage}>
                Você não possui investimentos no mês de{" "}
                <strong>{selectedMounth?.name}</strong> no ano de{" "}
                <strong>{selectedYear}</strong>
              </p>
            ) : (
              <>
                {filteredInvestmentsPerMonthAndYear?.map((investment, index) => {
                  return (
                    <React.Fragment key={index}>
                      {investment.mainValue && investment.interestValue ? (
                        <ListItemInvestment
                          investment={investment}
                          isMobile={isMobile}
                          value={investment.value}
                        />
                      ) : (
                        <ListItemInvestment
                          investment={investment}
                          isMobile={isMobile}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </>
        )}
      </MDBox>

      <MDBox
        mt={4}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <button
          className={styles.cardTransactionsDownloadButton}
          title="Clique para baixar extrato"
          onClick={handleDownloadExtract}
        >
          <img src={downloadIcon} alt="Baixar extrato" />
          Baixar Extrato
        </button>
      </MDBox>

      {isActiveModal && (
        <ModalGenerateExtract
          isOpenModal={isActiveModal}
          closeModal={closeModal}
          transactionsPerSelectedMonth={filteredTransactionsPerSelectedMonth}
          investmentsPerMonthAndYear={filteredInvestmentsPerMonthAndYear}
          selectedMounth={selectedMounth}
          selectedYear={selectedYear}
        />
      )}

    </MDBox>
  );
}

export { CardList };
