import { api } from "./api";

import { getStoredValue } from "../metrics/storage";

async function paymentInvestment(amount) {
  let data = null;

  try {
    const token = getStoredValue("token").replace(/['"]+/g, "");

    const response = await api.post(
      "/payment/investment",
      {
        amount,
      },
      {
        headers: { Authorization: token },
      }
    );

    data = response.data;
  } catch (error) {
    console.error(error);
    throw new Error();
  } finally {
    return data;
  }
}

async function requestWithdraw(amount, bankCode, agency, account, accountDigit) {
  let response = undefined;

  try {
    const token = getStoredValue("token").replace(/['"]+/g, "");

    response = await api.post(
      "/payment/transfer",
      {
        amount,
        bank: {
          bankCode,
          agency,
          account,
          accountDigit
        }
      },
      {
        headers: { Authorization: token },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    
    if (error?.response?.data?.errors[0]?.description) {
      return { error: error?.response?.data?.errors[0]?.description };
    }
  }
}

export { paymentInvestment, requestWithdraw };
