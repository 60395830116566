import React from "react";

import styles from "./styles.module.css";

const CONSTANTS = {
  TAG_TYPE_CLASS: {
    INFO: styles.tagInfo,
    ALERT: styles.tagAlert,
  },

  TAG_TYPES_LABEL: {
    INFO: "Info",
    ALERT: "Alerta",
  },
};

function Tag({ type, isDisabled = false }) {
  return (
    <span
      className={`${styles.tag} ${
        isDisabled ? styles.tagDisabled : CONSTANTS.TAG_TYPE_CLASS[type]
      }`}
    >
      {CONSTANTS.TAG_TYPES_LABEL[type]}
    </span>
  );
}

export { Tag };
