import styles from "./styles.module.css";
import closeIcon from "assets/images/icons/close-circle.svg";

function ModalAutoReinvest({ closeModal }) {
  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <div className={styles.header}>
          <h2 className={styles.title}>Reinvestimento automático</h2>
          <button onClick={closeModal}>
            <img src={closeIcon} alt="Fechar" />
          </button>
        </div>
        <p className={styles.description}>
          Nos empréstimos entre pessoas, cada parcela mensal recebida engloba os
          juros e uma parte do investimento, diferente da renda fixa
          convencional, na qual se recebem apenas os juros.
        </p>
        <p className={styles.description}>
          Assim, é possível reinvestir os valores a cada mês e fazer os juros
          compostos trabalharem mais rapidamente para potencializar os seus
          rendimentos!
        </p>
        <p className={styles.description}>
          Ao habilitar o reinvestimento automático, você estará reinvestindo as
          suas parcelas a receber a partir de agora.
        </p>
        <p className={styles.description}>
          Seus recebimentos futuros serão reinvestidos em novos empréstimos de
          até 12 meses.
        </p>
      </aside>
    </div>
  );
}

export { ModalAutoReinvest };
