import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import MDBox from "components/MDBox";
import { formatMoney } from "utils";
import styles from "../../styles.module.css";

function StepFormAmount({
  available,
  amountWithdraw,
  setAmountWithdraw,
  nextStep,
}) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [numberValue, setNumberValue] = useState(0);

  function handleFormSubmit(event) {
    event.preventDefault();

    const formattedValue = (numberValue / 100).toFixed(2);

    if (formattedValue <= 0 || formattedValue > available) {
      setIsOpenSnackbar(true);
      return;
    }
    
    nextStep();
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setIsOpenSnackbar(false);
  }

  function handleChangeAmountWithdraw(event) {
    const { value } = event.target;

    const numericValue = parseFloat(value.replace(/[^\d]/g, '')) || 0;
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
    }).format(numericValue / 100);

    setAmountWithdraw(formattedValue);
  }

  useEffect(() => {
    const numericValue = parseFloat(amountWithdraw.replace(/[^\d]/g, '')) || 0;
    setNumberValue(numericValue);
  }, [numberValue, amountWithdraw])

  return (
    <>
      <form className={styles.formAmount} onSubmit={handleFormSubmit}>
        <label htmlFor="amount" className={styles.formAmountLabel}>
          Qual valor você deseja sacar?
        </label>
        <MDBox mt={2} position="relative" display="flex" alignItems="center">
          <span className={styles.formAmountReal}>R$</span>
          <input
            type="text"
            id="amount"
            name="amount"
            placeholder="100,00"
            max={available}
            className={styles.formAmountInput}
            value={amountWithdraw}
            onChange={handleChangeAmountWithdraw}
            autoComplete="off"
            required
          />
        </MDBox>
        <input
          type="range"
          name="amount-range"
          id="amount-range"
          value={numberValue === "" ? 0 : numberValue}
          min={0}
          max={available * 100}
          onChange={handleChangeAmountWithdraw}
          className={styles.formAmountInputRange}
        />

        <p className={styles.formAmountBalance}>
          Saldo disponível <strong>{formatMoney(available)}</strong>
        </p>
        <button
          type="submit"
          className={`${styles.button} ${styles.formAmountButton}`}
        >
          Continuar
        </button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          Não é possível sacar o valor digitado.
        </Alert>
      </Snackbar>
    </>
  );
}

export { StepFormAmount };
