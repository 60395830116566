import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MDBox from "../../../../../components/MDBox";
import { ModalInfoTir } from "./Components/ModalInfoTir";

import { getValueTirPercent } from "services/investments";

import { checkSessionTimer } from "utils";
import styles from "./styles.module.css";
import infoIcon from "assets/images/icons/info-icon-white.svg";

function CardTir({ isLoading, setIsLoading }) {
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [valueTir, setValueTir] = useState("");
  const navigate = useNavigate();

  const closeModalInfo = () => setIsOpenModalInfo(false);

  const screenStyles = {
    mobile: {
      query: useMediaQuery("(max-width: 767px)"),
      style: {
        pt: 3,
        pb: 3,
        pr: 3,
        pl: 3,
      },
    },
    smallTablet: {
      query: useMediaQuery("(max-width: 827px)"),
      style: {
        pt: 13,
        pb: 14,
        pr: 3,
        pl: 3,
      },
    },
    tablet: {
      query: useMediaQuery("(max-width: 1199px)"),
      style: {
        pt: 11,
        pb: 11,
        pr: 3,
        pl: 3,
      },
    },
    extraSmall: {
      query: useMediaQuery("(max-width: 1260px)"),
      style: {
        pt: 13,
        pb: 13.5,
        pr: 3,
        pl: 3,
      },
    },
    small: {
      query: useMediaQuery("(max-width: 1535px)"),
      style: {
        pt: 11,
        pb: 11,
        pr: 3,
        pl: 3,
      },
    },
    medium: {
      query: useMediaQuery("(max-width: 1738px)"),
      style: {
        pt: 11,
        pb: 11,
        pr: 3,
        pl: 3,
      },
    },
    large: {
      query: useMediaQuery("(max-width: 2020px)"),
      style: {
        pt: 7,
        pb: 7.5,
        pr: 3,
        pl: 3,
      },
    },
    extraLarge: {
      query: useMediaQuery("(max-width: 2871px)"),
      style: {
        pt: 7,
        pb: 8,
        pr: 3,
        pl: 3,
      },
    },
    default: {
      style: {
        pt: 7,
        pb: 7.9,
        pr: 3,
        pl: 3,
      },
    },
  };

  let currentStyle = screenStyles.default.style;

  for (const screen in screenStyles) {
    if (screenStyles[screen].query) {
      currentStyle = screenStyles[screen].style;
      break;
    }
  }

  const { pt, pb, pr, pl } = currentStyle;

  async function handleValueTirInPercent() {
    setIsLoading(true);
    try {
      const response = await getValueTirPercent();
      const { data, status } = response;

      if (status !== 200) {
        throw new Error();
      }

      setValueTir(data?.tir);
    } catch (error) {
      console.error("Erro ao monstrar Tir", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    checkSessionTimer(
      () => handleValueTirInPercent(), 
      () => navigate('/login')
    );
  }, []);

  return (
    <>
      <MDBox
        width="100%"
        heigth="100%"
        display="flex"
        gap="1rem"
        alignItems="center"
        bgColor="#305568"
        borderRadius="1rem 1rem 0 0"
        shadow="lg"
        p={3}
      >
        <h3 className={styles.title}>Rentabilidade (TIR)</h3>
        <button
          className={styles.buttonInfo}
          onClick={() => setIsOpenModalInfo(!isOpenModalInfo)}
        >
          <img width={20} src={infoIcon} alt="ícone de informação" />
        </button>
      </MDBox>
      <MDBox
        width="100%"
        bgColor="#fff"
        borderRadius="0 0 1rem 1rem"
        shadow="lg"
        pt={pt}
        pb={pb}
        pl={pl}
        pr={pr}
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <div className={styles.loadingIndicator}>
            <div className={styles.loading} />
          </div>
        ) : (
          <div className={styles.boxTir}>
            <p className={styles.paragraph}>{valueTir}%</p>
            <p className={styles.paragraph__two}>(a.a)</p>
          </div>
        )}
      </MDBox>
      {isOpenModalInfo && <ModalInfoTir closeModal={closeModalInfo} />}
    </>
  );
}

export default CardTir;
