import React from "react";
import { useNavigate } from "react-router-dom";

import { formatMoney } from "utils";
import { downloadContract, sendContractByEmail } from "services/contracts";

import styles from "./styles.module.css";
import closeCircleIcon from "assets/images/icons/close-circle.svg";
import emailIcon from "../../../../../assets/images/icons/email.svg";
import downloadIcon from "assets/images/icons/download.svg";

function ModalContractDetails({
  closeModal,
  selectedContract,
  token,
  activeLoading,
  disableLoading,
  setAlert,
}) {
  const navigate = useNavigate();

  function downloadPDF(binary) {
    const linkSource = `data:application/pdf;base64,${binary}`;
    const downloadLink = document.createElement("a");
    const fileName = "contrato.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  }

  async function handleContractDownload() {
    activeLoading();

    try {
      const response = await downloadContract(
        token,
        selectedContract.cpf_mutuario
      );

      if (response.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      const binaryPDF = response.data["$binary"];
      downloadPDF(binaryPDF);
    } catch (error) {
      console.error(error);
      console.error("Erro ao fazer download do contrato");
    } finally {
      disableLoading();
    }
  }

  async function handleSendContractByEmail() {
    activeLoading();

    try {
      const response = await sendContractByEmail(
        token,
        selectedContract.cpf_mutuario
      );

      if (response.status === 403) {
        window.alert("Infelizmente sua sessão expirou. Faça login novamente");
        navigate("/login");
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      setAlert({
        isOpen: true,
        message: "Enviaremos o contrato por e-mail",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      console.error("Erro ao enviar contrato por e-mail");
      setAlert({
        isOpen: true,
        message:
          "Não foi possível enviar o contrato por e-mail. Tente novamente mais tarde ou faça o download do contrato",
        type: "error",
      });
    } finally {
      closeModal();
      disableLoading();
    }
  }

  return (
    <div className={styles.overlay}>
      <aside className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.headerTitle}>Dados do contrato</h2>
          <button
            title="Fechar modal"
            className={styles.headerCloseButton}
            onClick={closeModal}
          >
            <img src={closeCircleIcon} alt="Fechar modal" />
          </button>
        </header>

        <div className={styles.containerContractDataInfo}>
          <div className={styles.rowContractDataInfo}>
            <strong>Número do contrato</strong>
            <span>{selectedContract?.loan_id}</span>
          </div>

          {/* <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Status</strong>
            <span>
              {selectedContract?.status === "inadimplente"
                ? "Inadimplente"
                : "Adimplente"}
            </span>
          </div> */}

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>CPF</strong>
            <span>{selectedContract?.cpf_mutuario}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Nome do beneficiário</strong>
            <span>{selectedContract?.nome_mutuario}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Score</strong>
            <span>{selectedContract?.score}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Valor solicitado no empréstimo</strong>
            <span>{formatMoney(selectedContract?.valor_liquido)}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Tipo de empréstimo</strong>
            <span>
              {selectedContract?.type === "direct_loan"
                ? "Empresta fácil"
                : "Diversificado"}
            </span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Quantidade de parcelas solicitadas</strong>
            <span>{selectedContract?.numero_parcelas}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Quantidade de parcelas pagas</strong>
            <span>{selectedContract?.parcelas_pagas}</span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Juros</strong>
            <span>
              {selectedContract?.taxa_juros.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })}
              %
            </span>
          </div>

          <span className={styles.divider}></span>

          <div className={styles.rowContractDataInfo}>
            <strong>Valor total do contrato</strong>
            <span>{formatMoney(selectedContract?.total_emprestimo)}</span>
          </div>
        </div>

        <div className={styles.containerButtons}>
          <button
            className={`${styles.buttonWithIcon} ${styles.primaryButton}`}
            onClick={handleContractDownload}
          >
            <img src={downloadIcon} alt="Baixar contrato" />
            Baixar contrato
          </button>

          <button
            className={`${styles.buttonWithIcon} ${styles.secondButton}`}
            onClick={handleSendContractByEmail}
          >
            <img src={emailIcon} alt="E-mail" />
            Enviar contrato por e-mail
          </button>
        </div>
      </aside>
    </div>
  );
}

export default ModalContractDetails;
