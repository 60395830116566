import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

import Loading from "components/Loading";

import mainPageStyles from "../../styles.module.css";
import styles from "./styles.module.css";

import { cpfMask, dateMask } from "../../../../../utils/mask";
import {
  brazilianSecuritySocialNumberIsValid,
  getDaysOfMonth,
} from "../../../../../utils";
import { userExists } from "services/user";

const CONSTANTS = {
  CPF_SIZE: 14,
  BIRTHDATE_SIZE: 10,
  ACCOUNT_TYPE_TAKER: 2,
};

function IdentificationForm({
  nextStep,
  cpf,
  setCpf,
  birthdate,
  setBirthdate,
}) {
  const [errors, setErrors] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const activateLoading = () => setIsLoading(true);

  const disableLoading = () => setIsLoading(false);

  const cpfIsValid = (cpf = "") =>
    cpf.length === CONSTANTS.CPF_SIZE &&
    brazilianSecuritySocialNumberIsValid(cpf);

  function birthdateIsValid(birthdate = "") {
    if (birthdate.length !== CONSTANTS.BIRTHDATE_SIZE) return false;

    const currentDate = new Date();
    const [day, month, year] = birthdate.split("/").map((date) => Number(date));

    if (month < 1 || month > 12) return false;
    if (year < 1 || year >= currentDate.getFullYear()) return false;

    const totalDaysOfMonth = getDaysOfMonth(month, year);

    if (day < 1 || day > totalDaysOfMonth) return false;

    return true;
  }

  function handleChangeCpf(event) {
    const { value } = event.target;
    const maskedCpf = cpfMask(value);

    setCpf(maskedCpf);
    // setIsValidCpf(cpfIsValid(cpf))
  }

  function handleChangeBirthdate(event) {
    const { value } = event.target;
    const dateMasked = dateMask(value);

    setBirthdate(dateMasked);
  }

  function getFieldErrors() {
    let errors = [];

    if (!cpfIsValid(cpf)) {
      errors = [...errors, "CPF está invalido ou incompleto"];
    }

    if (!birthdateIsValid(birthdate)) {
      errors = [...errors, "Data de nascimento está inválida ou incompleta"];
    }

    return errors;
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    const errors = getFieldErrors();
    setErrors(errors);

    if (errors.length > 0) return;

    activateLoading();

    try {
      //const onlyNumbersCpf = getOnlyNumbersOfString(cpf);
      const response = await userExists(cpf);

      if (response?.data?.account_type === CONSTANTS.ACCOUNT_TYPE_TAKER) {
        setErrors([
          ...errors,
          "Identificamos uma conta de usuário como tomador em seu CPF. Não é possível se inscrever como um investidor.",
        ]);
        throw new Error("CPF já está cadastrado como tomador");
      }

      if (response?.data?.user_exists) {
        setErrors([
          ...errors,
          "Identificamos uma conta de Investidor com o seu CPF. Por favor, faça o login.",
        ]);
        throw new Error("CPF já está cadastrado como investidor");
      }

      setErrors([]);
      nextStep();
    } catch (error) {
      console.error(error);
    } finally {
      disableLoading();
    }
  }

  function getCurrentDate() {
    const [currentDate] = new Date().toISOString().split("T");

    return currentDate;
  }

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") return;

    setHasErrors(false);
  }

  useEffect(() => {
    const _currentDate = getCurrentDate();

    setCurrentDate(_currentDate);
  }, []);

  useEffect(() => {
    if (errors?.length > 0) {
      setHasErrors(true);
      return;
    }

    setHasErrors(false);
  }, [errors]);

  return (
    <div className={mainPageStyles.card}>
      <h2 className={`${mainPageStyles.h24} ${styles.title}`}>
        Identifique-se
      </h2>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div className={mainPageStyles.formInputGroup}>
          <label className={mainPageStyles.formLabel} htmlFor="cpf">
            CPF
          </label>
          <input
            type="text"
            id="cpf"
            name="cpf"
            className={mainPageStyles.formInput}
            value={cpf}
            onChange={handleChangeCpf}
            placeholder="XXX.XXX.XXX-XX"
            required
          />
        </div>

        <div className={mainPageStyles.formInputGroup}>
          <label className={mainPageStyles.formLabel} htmlFor="birthdate">
            Data de Nascimento
          </label>
          <input
            type="text"
            id="birthdate"
            name="birthdate"
            className={mainPageStyles.formInput}
            max={currentDate}
            value={birthdate}
            onChange={handleChangeBirthdate}
            placeholder="DD/MM/AAAA"
            required
          />
        </div>

        <button
          type="submit"
          className={`${mainPageStyles.primaryButton} ${styles.formButton}`}
        >
          Continuar
        </button>
      </form>
      <p className={`${mainPageStyles.b14} ${styles.auxiliaryText}`}>
        Seus dados estão seguros com a FIDO. Respeitamos a LGPD (Lei n°
        13.709/2018).
      </p>
      <div className={styles.containerLinkLogin}>
        <p className={mainPageStyles.b14}>Já possui uma conta na FIDO? </p>
        <Link to="/login">Faça login</Link>
      </div>

      {isLoading && <Loading />}

      {hasErrors &&
        errors?.map((error) => (
          <Snackbar
            key={error}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={hasErrors}
            autoHideDuration={10000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              variant="filled"
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        ))}
    </div>
  );
}

export default IdentificationForm;
