import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// react-router-dom components
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import AutomaticReinvestment from "./layouts/AutomaticReinvestment";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import Loading from "../../../components/Loading";
import CardMyWallet from "./components/CardMyWallet";
import CardDeposit from "./components/CardDeposit";
import CardWithdraw from "./components/CardWithdraw";
import CardAutomaticReinvestment from "./components/CardAutomaticReinvestment";
import CardReceivingLoans from "./components/CardReceivingLoans";
import CardTir from "./components/CardTir";
import SectionAlerts from "./layouts/SectionAlerts";

import {
  useMaterialUIController,
  setHasNewMessages,
  setMessages,
} from "context";
import {
  getLoggedUser,
  resultDocumentationValidation,
} from "../../../services/user";
import { getWalletData } from "services/investments";
import { getMessages } from "services/messages";

import { checkSessionTimer } from "utils";

function Home() {
  const changeColumnsCards = useMediaQuery("(max-width: 1738px)");
  const tokenStorage = localStorage.getItem("token");
  const userStatus = sessionStorage.getItem("userStatus");
  const [loggedUser, setLoggedUser] = useState(undefined);
  const [wallet, setWallet] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentRenderedContent, setCurrentRenderedContent] =
    useState("initial");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInvestments, setIsLoadingInvestments] = useState(false);
  const [isLoadingLoggedUser, setIsLoadingLoggedUser] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [isLoadingBankList, setIsLoadingBankList] = useState(false);
  const [isLoadingMatiReport, setIsLoadingMatiReport] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingReceivements, setIsLoadingReceivements] = useState(false);
  const [isActiveAlertNewMessages, setIsActiveAlertNewMessages] =
    useState(false);
  const [isActiveAlertUserStatusPending, setIsActiveAlertUserStatusPending] =
    useState(false);
  const [isActiveAlertUserStatusRejected, setIsActiveAlertUserStatusRejected] =
    useState(false);
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();

  const openAlertNewMessages = () => setIsActiveAlertNewMessages(true);

  const openAlertUserStatusPending = () =>
    setIsActiveAlertUserStatusPending(true);

  const openAlertUserStatusRejected = () =>
    setIsActiveAlertUserStatusRejected(true);

  const closeAlertNewMessages = () => setIsActiveAlertNewMessages(false);

  const closeAlertUserStatusPending = () =>
    setIsActiveAlertUserStatusPending(false);

  const closeAlertUserStatusRejected = () =>
    setIsActiveAlertUserStatusRejected(false);

  const contents = {
    initial: () => (
      <>
        <SectionAlerts
          isActiveAlertNewMessages={isActiveAlertNewMessages}
          closeAlertNewMessages={closeAlertNewMessages}
          isActiveAlertUserStatusPending={isActiveAlertUserStatusPending}
          isActiveAlertUserStatusRejected={isActiveAlertUserStatusRejected}
        />
        <Grid container rowSpacing={3} columnSpacing={{ xl: 2, lg: 3, md: 2 }}>
          <Grid
            item
            xl={changeColumnsCards ? 5 : 4}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ width: "100%" }}
          >
            <CardMyWallet
              setCurrentRenderedContent={setCurrentRenderedContent}
              wallet={wallet}
              userStatus={userStatus}
            />
          </Grid>
          <Grid
            item
            xl={changeColumnsCards ? 5 : 4}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <CardAutomaticReinvestment
              setIsLoading={setIsLoading}
              setIsLoadingInvestments={setIsLoadingInvestments}
              loggedUser={loggedUser}
            />
          </Grid>
          <Grid
            item
            xl={changeColumnsCards ? 5 : 4}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <CardTir isLoading={isLoading} setIsLoading={setIsLoading} />
          </Grid>
          <Grid
            item
            xl={changeColumnsCards ? 5 : 4}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <CardReceivingLoans
              setIsLoading={setIsLoadingReceivements}
            />
          </Grid>
        </Grid>
        <Footer />
      </>
    ),

    deposit: () => (
      <CardDeposit
        setCurrentRenderedContent={setCurrentRenderedContent}
        loggedUser={loggedUser}
      />
    ),

    withdraw: () => (
      <CardWithdraw
        setCurrentRenderedContent={setCurrentRenderedContent}
        loggedUser={loggedUser}
        wallet={wallet}
      />
    ),

    automaticReinvestment: () => (
      <AutomaticReinvestment
        setCurrentRenderedContent={setCurrentRenderedContent}
      />
    ),
  };

  function verifyIfHasNewMessages(_messages = []) {
    const _hasNewMessages = _messages?.some(
      (message) => message?.read === false
    );
    setHasNewMessages(dispatch, _hasNewMessages);

    if (_hasNewMessages) {
      openAlertNewMessages();
      return;
    }

    closeAlertNewMessages();
  }

  function handleStateAlertUserStatus(status, openAlert, closeAlert) {
    if (userStatus === status) {
      openAlert();
      return;
    }

    closeAlert();
  }

  async function loadMessages() {
    setIsLoadingMessages(true);

    try {
      const token = tokenStorage.replace(/['"]+/g, "");
      const response = await getMessages(token);

      if (response.status !== 200) {
        throw new Error();
      }

      const { notifications = [] } = response?.data;
      setMessages(dispatch, notifications);
      sessionStorage.setItem("messages", JSON.stringify(notifications));
      verifyIfHasNewMessages(notifications);
    } catch (error) {
      console.error(error);
      console.error("Erro ao buscar mensagens");
    } finally {
      setIsLoadingMessages(false);
    }
  }

  useEffect(() => {
    if (
      !isLoadingLoggedUser &&
      !isLoadingWallet &&
      !isLoadingBankList &&
      !isLoadingMatiReport &&
      !isLoadingMessages &&
      !isLoadingReceivements
    ) {
      setIsLoadingData(false);
      return;
    }

    setIsLoadingData(true);
  }, [
    isLoadingLoggedUser,
    isLoadingWallet,
    isLoadingBankList,
    isLoadingMatiReport,
    isLoadingMessages,
    isLoadingReceivements,
  ]);

  async function loadWallet() {
    setIsLoadingWallet(true);

    try {
      const response = await getWalletData();

      if (response?.status !== 200) {
        throw new Error();
      }

      const { data } = response;
      setWallet(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingWallet(false);
    }
  };

  useEffect(() => {
    checkSessionTimer(
      () => loadWallet(),
      () => navigate('/login')
    );
  }, []);

  async function loadLoggedUser() {
    try {
      setIsLoadingLoggedUser(true);

      const data = await getLoggedUser();
      setLoggedUser(data);

      sessionStorage.setItem("loggedUser", JSON.stringify(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingLoggedUser(false);
    }
  }

  useEffect(() => {
    const loggedUserStoraged = sessionStorage.getItem("loggedUser");

    if (!loggedUserStoraged) {
      loadLoggedUser()
      return;
    }

    setLoggedUser(JSON.parse(loggedUserStoraged));
  }, []);

  async function loadUserDocumentationValidationStatus() {
    setIsLoadingMatiReport(true);

    try {
      const token = tokenStorage.replace(/['"]+/g, "");
      const response = await resultDocumentationValidation(token);

      if (response?.status === 403) {
        throw new Error("Token expirado");
      } else if (response.status !== 200) {
        throw new Error();
      }

      sessionStorage.setItem("matiReport", JSON.stringify(response?.data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMatiReport(false);
    }
  }

  useEffect(() => {
    const matiReportStoraged = sessionStorage.getItem("matiReport");

    if (matiReportStoraged) return;

    loadUserDocumentationValidationStatus();
  }, []);

  useEffect(() => {
    const messagesStoraged = JSON.parse(sessionStorage.getItem("messages"));

    if (!messagesStoraged) {
      loadMessages();
    } else {
      setMessages(dispatch, messagesStoraged);
    }

    return () => {
      setIsLoadingMessages(false);
    };
  }, []);

  useEffect(() => {
    handleStateAlertUserStatus(
      "pending",
      openAlertUserStatusPending,
      closeAlertUserStatusPending
    );
    handleStateAlertUserStatus(
      "rejected",
      openAlertUserStatusRejected,
      closeAlertUserStatusRejected
    );

    return () => {
      closeAlertUserStatusPending();
      closeAlertUserStatusRejected();
    };
  }, []);

  if (!tokenStorage) {
    navigate("/login");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {contents[currentRenderedContent]()}
      {isLoadingData && <Loading />}
    </DashboardLayout>
  );
}

export default Home;
