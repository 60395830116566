import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDProgress from "components/MDProgress";
import VerticalProgress from "./components/VerticalProgress"; // Importe o novo componente
import { getStoredValue } from "metrics/storage";
import { getListHistoryInvites } from "services/invite-investor";
import { formateDateWithOutHours } from "utils";

import styles from "./styles.module.css";
import checkIcon from "../../../../../assets/images/icons/check.svg";
import warningIcon from "../../../../../assets/images/icons/warning.svg";

function HistoryInvites() {
  const [listInvites, setListInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function listHistoryInvites() {
      try {
        setIsLoading(true);

        const token = getStoredValue("token").replace(/['"]+/g, "");
        const response = await getListHistoryInvites(token);
        const { data, status } = response;

        if (status === 403) {
          window.alert("Infelizmente sua sessão expirou. Faça login novamente");
          navigate("/login");
          throw new Error("Token expirado");
        } else if (status !== 200) {
          throw new Error();
        }

        setListInvites(
          data.guests
            .sort(
              (current, next) =>
                current.created_at.$date - next.created_at.$date
            )
            .reverse()
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    listHistoryInvites();
  }, []);

  function getStatusProgress(status) {
    switch (status) {
      case "REGISTER_COMPLETED":
        return 0;
      case "FIRST_DEPOSIT":
        return 34;
      case "INVESTMENT_MADE":
        return 67;
      case "INVESTMENT_COMPLETED":
        return 100;
      default:
        return 0;
    }
  }

  function getStatusText(status) {
    switch (status) {
      case "REGISTER_COMPLETED":
        return "Cadastro realizado";
      case "FIRST_DEPOSIT":
        return "Primeiro depósito";
      case "INVESTMENT_MADE":
        return "Investimento realizado";
      case "INVESTMENT_COMPLETED":
        return "Valor 100% emprestado";
      default:
        return "";
    }
  }

  function renderBallProgress(icon, altText) {
    return (
      <div className={styles.ballProgress}>
        <img
          className={styles.imageCheckBallProgress}
          src={icon}
          alt={altText}
        />
      </div>
    );
  }

  function renderItemHistory(item) {
    const { cpf, created_at, fullname, status } = item;
    const isRegisterCompleted = status === "REGISTER_COMPLETED";
    const isFirstDeposit = status === "FIRST_DEPOSIT";
    const isInvestmentMade = status === "INVESTMENT_MADE";
    const isInvestmentCompleted = status === "INVESTMENT_COMPLETED";

    return (
      <li key={cpf} className={styles.itemHistory}>
        <div className={styles.cardContainer}>
          <div className={styles.questionSummary}>
            <h3 className={styles.questionSummaryTitle}>
              {fullname.toLowerCase().replace(/(?:^|\s)\S/g, function (text) {
                return text.toUpperCase();
              })}
            </h3>
            <h4 className={styles.questionSummarySubtitle}>
              Campanha iniciada no dia{" "}
              {formateDateWithOutHours(created_at.$date)}
            </h4>
          </div>
          {window.innerWidth < 557 ? (
            <VerticalProgress value={getStatusProgress(status)} />
          ) : (
            <MDProgress
              value={getStatusProgress(status)}
              variant="gradient"
              color="dark"
              className={styles.mdProgress}
            />
          )}
          {isRegisterCompleted ||
          isFirstDeposit ||
          isInvestmentMade ||
          isInvestmentCompleted ? (
            <section className={styles.sectionProgress}>
              <div className={styles.metricsBallProgressOne}>
                {renderBallProgress(checkIcon, "check item")}
                <div className={styles.textBallContainer}>
                  <p className={styles.textBallProgress}>Cadastro realizado</p>
                </div>
              </div>
              <div className={styles.metricsBallProgressTwoAndThree}>
                {renderBallProgress(
                  isRegisterCompleted ? warningIcon : checkIcon,
                  isRegisterCompleted || isFirstDeposit || isInvestmentMade
                    ? "check item"
                    : "warning item"
                )}
                <div className={styles.textBallContainer}>
                  <p className={styles.textBallProgress}>Primeiro depósito</p>
                </div>
              </div>
              <div className={styles.metricsBallProgressTwoAndThree}>
                {renderBallProgress(
                  isInvestmentMade || isInvestmentCompleted
                    ? checkIcon
                    : warningIcon,
                  isInvestmentMade || isInvestmentCompleted
                    ? "check item"
                    : "warning item"
                )}
                <p className={styles.textBallProgress}>
                  {isInvestmentMade
                    ? getStatusText(status)
                    : "Investimento realizado"}
                </p>
              </div>
              <div className={styles.metricsBallProgressFour}>
                {renderBallProgress(
                  isInvestmentCompleted ? checkIcon : warningIcon,
                  isInvestmentCompleted ? "check item" : "warning item"
                )}
                <p className={styles.textBallProgress}>Valor 100% emprestado</p>
              </div>
            </section>
          ) : null}
        </div>
      </li>
    );
  }

  return (
    <section className={styles.card}>
      <h2 className={styles.titleHistory}>Histórico de convites</h2>
      {isLoading ? (
        <div className={styles.loadingIndicator}>
          <div className={styles.loading} />
        </div>
      ) : (
        <ul className={styles.historyList}>
          {listInvites.length !== 0 ? (
            listInvites.map(renderItemHistory)
          ) : (
            <div className={styles.responseNotInvites}>
              <p>Ops! No momento não há convites aceitos.</p>
            </div>
          )}
        </ul>
      )}
    </section>
  );
}

export { HistoryInvites };
