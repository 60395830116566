import React from "react";

import MDBox from "components/MDBox";
import CardTotalBalance from "../../components/CardTotalBalance";
import CardCustomInvestmentDetails from "../../components/CardCustomInvestmentDetails";

function SectionCustomInvestmentDetails({ setCurrentContentHTML, wallet }) {
  return (
    <MDBox mt={3} display="flex" flexDirection="column" gap="2rem">
      <CardTotalBalance wallet={wallet} />
      <CardCustomInvestmentDetails
        setCurrentContentHTML={setCurrentContentHTML}
      />
    </MDBox>
  );
}

export default SectionCustomInvestmentDetails;
