import React from "react";

import MDBox from "components/MDBox";
import { Button } from "@mui/material";

import styles from "../../styles.module.css";

function StepConfirmedPassword({
  handleSubmitFormConfirmedPassword,
  isPasswordView,
  password,
  setPassword,
  handlePasswordView,
  loading,
}) {
  return (
    <form
      className={styles.form}
      onSubmit={handleSubmitFormConfirmedPassword}
      autoComplete="off"
    >
      <label htmlFor="password" className={styles.formLabel2}>
        Digite sua senha
      </label>
      <input
        type={isPasswordView ? "number" : "password"}
        className={`${styles.formInput2} ${styles.mt6}`}
        id="password"
        name="password"
        autoComplete="off"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />

      <MDBox mt={3}>
        <Button
          type="button"
          onClick={handlePasswordView}
          variant="text"
          size="medium"
        >
          {isPasswordView ? "Esconder senha" : "Ver senha"}
        </Button>
      </MDBox>

      <button
        type="submit"
        className={`${styles.formButton} ${styles.formButtonStep1}`}
        disabled={loading}
      >
        Transferir
      </button>
    </form>
  );
}

export { StepConfirmedPassword };
